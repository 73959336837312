import React, { useContext, useEffect, useState } from "react";
import "./extensionMenu.css";
import { Form } from "react-bootstrap";
import image_avatar from "../../../assets/images/645fbc5da4a21.png";
import {
  Button,
  Dropdown,
  IconButton,
  InputPicker,
  Modal,
  Popover,
  Whisper,
} from "rsuite";
import { sendGet } from "../../../axios/axiosClient";
import Cookies from "js-cookie";
import axios from "axios";
import { AiFillAlert, AiOutlineWarning } from "react-icons/ai";
import { ExtensionContext } from "../../../context/ExtensionContext";
import { GoDotFill } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import { getMe } from "../../../axios/authApi";
import not_found_img from "../../../assets/images/notFound.png";
import { DataContext } from "../../../context/DataContext";
import { SocketContext } from "../../../context/socket";
import extension from "../../../assets/images/extension.png";
import extension1 from "../../../assets/images/extension1.png";

const ExtensionMenu = () => {
  const [extensionAccounts, setExtensionAccounts] = useState([]);
  const [selectedItem, setSelectedItem] = useState(image_avatar);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [checkVersion, setCheckVersion] = useState();
  const location = useLocation();
  const socket = useContext(SocketContext);

  const { setListPagesAT, setListPagesNonAT, setAdAccount } =
    useContext(DataContext);
  const { selectedExtension, setSelectedExtension } =
    useContext(ExtensionContext);

  const getExtension = async () => {
    try {
      const clientId = Cookies.get("clientId");
      const res = await sendGet(`/users/extensions?client_id=${clientId}`);
      if (res.status === 200) {
        // console.log(res.data?.data?.length);
        if (res.data?.data?.length === 0) {
          handleOpen();
        } else {
          setExtensionAccounts(res?.data?.data);
          // setListExtensions(res?.data?.data);
          if (
            !selectedExtension ||
            Object.values(selectedExtension).length == 0
          ) {
            const filterList = res?.data?.data?.filter(
              (item) => item.status == 1
            );
            if (!filterList || filterList.length == 0) {
              handleOpen();
            } else {
              setSelectedExtension(filterList[0]);
              localStorage.setItem("extension", JSON.stringify(filterList[0]));
              localStorage.setItem("currEx", filterList[0].id);
            }
          } else {
            if (selectedExtension.status !== 1) {
              const filterList = res?.data?.data?.filter(
                (item) => item.status == 1
              );
              if (!filterList || filterList.length == 0) {
                handleOpen();
              } else {
                setSelectedExtension(filterList[0]);
                localStorage.setItem(
                  "extension",
                  JSON.stringify(filterList[0])
                );
              }
            } else {
              const filterSelectedExtension = res?.data?.data?.filter(
                (item) => item.id == selectedExtension.id
              );
              if (
                !filterSelectedExtension ||
                filterSelectedExtension.length == 0
              ) {
                const filterList = res?.data?.data?.filter(
                  (item) => item.status == 1
                );
                if (!filterList || filterList.length == 0) {
                  handleOpen();
                } else {
                  setSelectedExtension(filterList[0]);
                  localStorage.setItem(
                    "extension",
                    JSON.stringify(filterList[0])
                  );
                }
              }
            }
          }
          localStorage.setItem("listExtensions", JSON.stringify(res.data.data));

          // if (localStorage.getItem("currEx")) {
          //   const selectItem = res?.data?.data?.filter(
          //     (item) => item.id == localStorage.getItem("currEx")
          //   );
          //   if (selectItem[0]?.status == 1) {
          //     setSelectedExtension(selectItem[0]);
          //   } else {
          //     let check = false;
          //     res?.data?.data?.map((item) => {
          //       if (item?.status === 1 && check === false) {
          //         setSelectedExtension(item);
          //         localStorage.setItem("currEx", item.id);
          //         check = true;
          //       }
          //     });
          //   }
          //   if(selectedExtension.status !==1){
          //     handleOpen();
          //   }
          // } else {
          //   let check = false;
          //   res?.data?.data?.map((item) => {
          //     if (item?.status === 1 && check === false) {
          //       setSelectedExtension(item);
          //       localStorage.setItem("currEx", item.id);
          //       check = true;
          //     }
          //   });
          //   if(selectedExtension.status !==1){
          //     handleOpen();
          //   }
          // }
          // let check = false;
          // res?.data?.data?.map((item) => {
          //   if (item?.status === 1 && check === false) {
          //     setSelectedExtension(item);
          //     localStorage.setItem("currEx", item.id);
          //     check = true;
          //   }
          // });
          // setSelectedItem(res?.data?.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProfile = async () => {
    try {
      const res = await getMe();
      if (res.status == 200) {
        if (res.data?.data?.length === 0) {
          handleOpen();
        } else {
          // setSelectedItem(res?.data?.data[0]);
          localStorage.setItem("profile", JSON.stringify(res.data.data));
        }
      }
    } catch (err) {
      console.log(err);
      // throw err;
    }
  };

  const renderButton = (props, ref) => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 50) {
        setVisible(true);
      } else if (scrolled <= 50) {
        setVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisible);
    return (
      <>
        <div
          ref={ref}
          style={{ cursor: "pointer" }}
          className={`${
            !visible ? "showfull" : "showless"
          } d-flex align-items-center`}
        >
          {Cookies?.get("token") ? (
            <>
              {selectedExtension !== undefined ? (
                <>
                  <div>
                    <img
                      {...props}
                      src={
                        selectedExtension?.avartar
                          ? selectedExtension?.avartar
                          : not_found_img
                      }
                      alt="avt"
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="ms-2">
                    {/* <p>{selectedExtension?.name}</p>
                      <p>{selectedExtension?.uid}</p> */}
                    <p className="m-0 extension_text_name">
                      {selectedExtension?.name}
                    </p>
                    <p className="m-0 extension_text">
                      {selectedExtension?.uid
                        ? selectedExtension?.uid
                        : "no data"}
                    </p>
                  </div>
                </>
              ) : (
                <div className="text-center">No active account</div>
              )}
            </>
          ) : (
            <>
              <div style={{ margin: "auto" }}>Chưa đăng nhập</div>
            </>
          )}
        </div>
      </>
    );
  };

  const getVersionExtension = async () => {
    try {
      const res = await sendGet(`/users/get-version?type=1`);
      if (res.status === 200) {
        setCheckVersion(res.data?.data[0]);
        localStorage.setItem("link_download", res.data?.data[0]?.link_download);
        if (
          res.data?.data[0]?.version !== process.env.REACT_APP_EXTENSION_VERSION
        ) {
          handleOpen();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (!selectedExtension || selectedExtension.length == 0) {
  //     setOpen(true);
  //   } else {
  //     setOpen(false);
  //   }
  //   // if (selectedExtension === undefined) {
  //   //   setOpen(true);
  //   // } else {
  //   //   setOpen(false);
  //   // }
  // }, [selectedExtension]);

  useEffect(() => {
    if (Cookies.get("token") !== undefined && location?.pathname !== "/") {
      localStorage?.setItem("currEx", 0);
      getExtension();
      getProfile();
      getVersionExtension();
    }
  }, [Cookies.get("token")]);
  
  useEffect(() => {
    socket.on("get_info", (data) => {
      if(data == "thành công"){
        handleClose();
      }
    });
  }, []);

  return (
    <div className="extensionMenu">
      <Modal
        backdrop="static"
        role="alertdialog"
        keyboard={false}
        open={open}
        style={{ paddingTop: "5rem" }}
        onClose={handleClose}
      >
        <>
          <Modal.Body>
            <div className="text-center" style={{position: "relative"}}>
              <h1>
                <AiOutlineWarning className="text-danger" />
              </h1>
              {checkVersion?.version !==
              process.env.REACT_APP_EXTENSION_VERSION ? (
                <>
                  <h3>Bạn đang sử dụng phiên bản cũ của Extension !</h3>
                  <p>
                    Để cải thiện tính năng và khắc phục những lỗi đang gặp phải
                    chúng tôi đã cập nhật lên phiên bản mới. Hãy cài đặt để có
                    trải nghiệm tốt nhất.
                  </p>
                </>
              ) : (
                <>
                  <h3>Bạn chưa cài đặt Extension ?</h3>
                  <p>
                    Trước khi có thể sử dụng Tools, bạn cần phải <strong>cài đặt</strong> Extension và <strong>đăng nhập</strong> nó bằng tài khoản của bạn để có thể tiếp tục. Chúng tôi rất xin lỗi về vấn đề này...
                  </p>
                </>
              )}
              <p
                className="navigateLogin"
                onClick={() => {
                  navigate("/login");
                  Cookies.remove("token");
                }}
              >
                <small>Bấm vào đây để quay trở lại trang đăng nhập</small>
              </p>
            </div>
          </Modal.Body>
        <div
          style={{display: "flex", gap: "1.5rem", width: "100%", alignItems: "center", justifyContent: "center", marginBottom: "1.5rem"}}
        >
          <img style={{width: "15rem", heigh: "12rem", borderRadius: "5px"}} src={extension1} alt="" />
          <img style={{width: "15rem", heigh: "12rem", borderRadius: "5px"}} src={extension} alt="" />
        </div>
          <Modal.Footer className="d-flex justify-content-center gap-2">
            <a href={checkVersion?.link_download} target="_blank">
              <Button appearance="primary">Tải xuống Extension</Button>
            </a>
          </Modal.Footer>
        </>
      </Modal>
      <div style={{ width: "100%" }} className="">
        {/* <Whisper placement="bottomStart" trigger="click" speaker={renderButton}>
          <IconButton
            appearance="primary"
            icon={<AiFillAlert />}
            placement="left"
          >
            <p className="m-0 extension_text">{selectedItem?.name}</p>
            <p className="m-0 extension_text">{selectedItem?.uid}</p>
          </IconButton>
        </Whisper> */}
        <Dropdown
          renderToggle={renderButton}
          placement="bottomEnd"
          trigger={["click", "hover"]}
          style={{ width: "100%" }}
          disabled={extensionAccounts?.length === 0 ? true : false}
        >
          {extensionAccounts?.map((item, k) => (
            <Dropdown.Item
              eventKey={k}
              key={k}
              onClick={() => {
                if (item?.status === 1) {
                  setSelectedExtension(item);
                  localStorage.setItem("extension", JSON.stringify(item));
                  localStorage.setItem("currEx", item.id);
                  setListPagesAT([]);
                  setListPagesNonAT([]);
                  setAdAccount(null);
                }
              }}
              disabled={item?.status === 0 ? true : false}
            >
              <div className="d-flex gap-2 align-items-center">
                <div>
                  <img
                    src={item?.avartar ? item?.avartar : not_found_img}
                    alt="avt"
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
                <div className="text-start">
                  <p className="m-0 extension_text text-start">
                    <GoDotFill
                      className={`me-1 ${
                        item?.status === 1 ? "text-success" : "text-danger"
                      }`}
                    />
                    {item?.name}
                  </p>
                  <p className="m-0 extension_text text-start">{item?.uid}</p>
                </div>
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown>
      </div>
    </div>
  );
};

export default ExtensionMenu;
