import React from "react";
import AutoPostFbGroup from "../auto-post-fb-group/AutoPostFbGroup";

const AutoCommentFbGroup = () => {
  return (
    <div>
      <AutoPostFbGroup />
    </div>
  );
};

export default AutoCommentFbGroup;
