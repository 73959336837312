import React, { useEffect, useState } from "react";
import {
  sendDelete,
  sendGet,
  sendPost,
  sendPut,
} from "../../../axios/axiosClient";
import { Table } from "react-bootstrap";
import Cookies from "js-cookie";
import {
  Button,
  Form,
  Input,
  InputPicker,
  Message,
  Modal,
  Pagination,
  useToaster,
} from "rsuite";
import "./adminpackage.css";
import { getExtension } from "../../../axios/adminextensionApi";
import { Form as SubForm } from "react-bootstrap";
import { getPackages } from "../../../axios/adminPackages";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
const AdminPackages = () => {
  const [listPackages, setListPackages] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [exType, setExType] = useState(0);
  const [exSource, setExSource] = useState(0);
  const [openCreate, setOpenCreate] = React.useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [deleteId, setDeleteId] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [selectedValue, setSelectedValue] = useState(0);
  const [formValue, setFormValue] = useState();

  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const handleChangeLimit = (e) => {
    setLimit(e);
    setActivePage(1);
  };

  useEffect(() => {
    getListPackages(activePage, limit);
  }, [activePage, limit]);

  const getListPackages = async (page, page_size) => {
    const input = {
      ...(exSource && (exSource == 1 || exSource == 2) && { source: exSource }),
      ...(exType &&
        (exType == 1 || exType == 2 || exType == 3) && { type: exType }),
    };
    try {
      const res = await getPackages(input, page, page_size);
      setListPackages(res.data.data.data);
    } catch (err) {
      throw err;
    }
  };

  const handleUpdate = async () => {
    try {
      const res = await sendPut(
        `/admins/packages/${selectedValue?.id}`,
        selectedValue
      );
      if (res.status === 200) {
        getListPackages(activePage, limit);
        handleClose();
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await sendDelete(
        `/admins/packages/${selectedValue?.id}`,
        selectedValue
      );
      if (res.status === 200) {
        getListPackages(activePage, limit);
        handleCloseDelete();
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getListPackages(activePage, limit);
    }, 500);
    return () => clearTimeout(timer);
  }, [exSource, exType]);

  const priceFormat = (price) => {
    return price?.toLocaleString("vi", { style: "currency", currency: "VND" });
  };

  const limitType = [
    { value: 1, label: "Ngày" },
    { value: 2, label: "Tháng" },
    { value: 3, label: "Năm" },
  ];
  const handleType = (type) => {
    switch (type) {
      case 1:
        return "ngày";
      case 2:
        return "tháng";
      case 3:
        return "năm";
      default:
        break;
    }
  };

  return (
    <div className="admin__tool__container">
      <Modal open={open} onClose={handleClose} backdrop={"static"}>
        <Modal.Header>
          <Modal.Title>Update Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid formValue={selectedValue} onChange={setSelectedValue}>
            <Form.Group controlId="name">
              <Form.ControlLabel>Tên gói</Form.ControlLabel>
              <Form.Control name="name" />
            </Form.Group>
            <Form.Group controlId="money">
              <Form.ControlLabel>Giá</Form.ControlLabel>
              <Form.Control name="money" />
            </Form.Group>
            <Form.Group controlId="limit">
              <Form.ControlLabel>Thời gian</Form.ControlLabel>
              <Form.Control name="limit" />
            </Form.Group>
            <Form.Group controlId="type">
              <Form.ControlLabel>Kiểu thời gian</Form.ControlLabel>
              <Form.Control
                name="type"
                style={{ width: "100%" }}
                accepter={InputPicker}
                data={limitType}
              />
            </Form.Group>
            <Form.Group controlId="source">
              <Form.ControlLabel>Source</Form.ControlLabel>
              <Form.Control
                name="source"
                style={{ width: "100%" }}
                accepter={InputPicker}
                data={[
                  { value: 1, label: "Web" },
                  { value: 2, label: "Extension" },
                ]}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal open={openDelete} onClose={handleCloseDelete} backdrop={"static"}>
        <Modal.Header>
          <Modal.Title>Xoá Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Bạn có chắc chắn muốn xoá package này, hành động này sẽ không thể hoàn
          tác?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseDelete} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-end align-items-center mb-2">
        {/* <Form formValue={formValue} onChange={setFormValue}>
            <Form.Group controlId="name">
              <Form.Control
                name="name"
                style={{ background: "#fff" }}
                placeholder="Search ..."
              />
            </Form.Group>
          </Form> */}
        <SubForm.Select
          style={{ background: "#fff", maxWidth: "200px", marginLeft: "10px" }}
          aria-label="Default select example"
          onChange={(e) => setExSource(e.target.value)}
        >
          <option>Filter source</option>
          <option value={1}>Web</option>
          <option value={2}>Extension</option>
        </SubForm.Select>
        <SubForm.Select
          style={{ background: "#fff", maxWidth: "200px", marginLeft: "10px" }}
          aria-label="Default select example"
          onChange={(e) => setExType(e.target.value)}
          placeholder="Filter thời gian"
        >
          <option>Filter thời gian</option>
          {limitType.map((item, idx) => (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          ))}
        </SubForm.Select>
      </div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Tên gói</th>
            <th>Giá</th>
            <th>Thời hạn</th>
            <th>Source</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {listPackages?.map((item, index) => (
            <tr key={item?.id}>
              <td>{item?.id}</td>
              <td>{item?.name}</td>
              <td>{priceFormat(Number(item?.money))}</td>
              <td>{item?.limit + " " + handleType(item?.type)}</td>
              <td>{item?.source == 1 ? "Web" : "Extension"}</td>

              {/* <td style={{ maxWidth: "20rem" }}>{item?.script}</td> */}
              <th className="d-flex gap-2">
                <Button
                  appearance="primary"
                  onClick={() => {
                    setSelectedValue({ ...item, money: Number(item?.money) });
                    handleOpen();
                  }}
                >
                  Update
                </Button>
                <Button
                  appearance="primary"
                  style={{ background: "red" }}
                  onClick={() => {
                    setDeleteId(item?.id);
                    handleOpenDelete();
                  }}
                >
                  Delete
                </Button>
              </th>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="_pagination">
        {listPackages?.length > 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"xs"}
            prev
            next
            // first
            // last
            ellipsis
            boundaryLinks
            total={listPackages?.length}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={5}
            activePage={activePage}
            onChangePage={setActivePage}
            onChangeLimit={handleChangeLimit}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AdminPackages;
