import React from "react";
import FbOneCardVideoCarousel from "../fb-one-card-video-carousel/FbOneCardVideoCarousel";

const FbCloneVideoPE = () => {
  return (
    <div>
      <FbOneCardVideoCarousel />
    </div>
  );
};

export default FbCloneVideoPE;
