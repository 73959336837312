import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Cookies from "js-cookie";
import {
  Button,
  Form,
  Input,
  Message,
  Modal,
  Pagination,
  useToaster,
} from "rsuite";
import "./adminlog.css";
import { Form as SubForm } from "react-bootstrap";
import {
  createLog,
  delLog,
  getLog,
  updateLog,
} from "../../../axios/adminlogApi";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
const AdminsLog = () => {
  const [listExtenions, setlistExtenions] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [state, setState] = useState("");
  const [updateState, setUpdateState] = useState("1");
  const [method, setMeThod] = useState("1");
  const [openCreate, setOpenCreate] = useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);
  const [selectedValue, setSelectedValue] = useState();
  const [formValue, setFormValue] = useState();
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const handleChangeLimit = (e) => {
    setLimit(e);
    setActivePage(1);
  };

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );
  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);
  const getListLogs = async (page, pageSize) => {
    const input = {};
    try {
      const res = await getLog(input, page, pageSize);
      setlistExtenions(res.data.data.data);
      setTotalItems(res?.data?.data?.total);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getListLogs(activePage, limit);
    }, 500);
    return () => clearTimeout(timer);
  }, [formValue?.name, state, method, activePage, limit]);
  const priceFormat = (price) => {
    return price?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };
  const handleUpdate = async (id) => {
    const input = {
      content: selectedValue.content,
      state: updateState,
    };
    try {
      const res = await updateLog(selectedValue?.id, input);
      if (res.status == 200) {
        getListLogs(activePage, limit);
        handleClose();
      }
    } catch (err) {
      throw err;
    }
  };
  const handleCreate = async () => {
    const input = {
      content: formValue.content,
      state: method,
    };
    try {
      const res = await createLog(input);
      if (res.status == 200) {
        getListLogs(activePage, limit);
        setFormValue();
        handleCloseCreate();
      }
    } catch (err) {
      throw err;
    }
  };
  const handleDelete = async () => {
    try {
      const res = await delLog(selectedValue.id);
      if (res.status == 200) {
        getListLogs(activePage, limit);
        handleCloseDel();
      }
    } catch (err) {
      throw err;
    }
  };
  const setLogState = (id) => {
    let logName;
    switch (id) {
      case 1:
        logName = "New";
        break;
      case 2:
        logName = "Fixed";
        break;
      case 3:
        logName = "Improved";
        break;
      case 4:
        logName = "Cancel";
        break;
      default:
        break;
    }
    return logName;
  };
  return (
    <div className="admin__tool__container">
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{`UPDATE`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={selectedValue} onChange={setSelectedValue} fluid>
            <Form.Group controlId="content">
              <Form.ControlLabel>
                Content
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control rows={5} name="content" accepter={Textarea} />
            </Form.Group>
            <Form.Group controlId="type">
              <Form.ControlLabel>
                State
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <SubForm.Select
                style={
                  {
                    //   background: "#fff",
                    //   maxWidth: "150px",
                    //   marginLeft: "10px",
                  }
                }
                aria-label="Default select example"
                onChange={(e) => setUpdateState(e.target.value)}
                defaultValue={1}
              >
                <option value="1">New</option>
                <option value="2">Fixed</option>
                <option value="3">Improved</option>
                <option value="4">Cancel</option>
              </SubForm.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal open={openDel} onClose={handleCloseDel}>
        <Modal.Header>
          <Modal.Title>{`DELETE`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Bạn có chắc chắn muốn xoá log này!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseDel} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={openCreate}
        onClose={handleCloseCreate}
        // style={{ background: "#F5ECD7" }}
      >
        <Modal.Header>
          <Modal.Title>ADD NEW LOG</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={formValue} onChange={setFormValue} fluid>
            <Form.Group controlId="content">
              <Form.ControlLabel>
                Content
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control rows={5} name="content" accepter={Textarea} />
            </Form.Group>
            <Form.Group controlId="type">
              <Form.ControlLabel>
                State
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <SubForm.Select
                style={
                  {
                    //   background: "#fff",
                    //   maxWidth: "150px",
                    //   marginLeft: "10px",
                  }
                }
                aria-label="Default select example"
                onChange={(e) => setMeThod(e.target.value)}
              >
                <option value="1">New</option>
                <option value="2">Fixed</option>
                <option value="3">Improved</option>
                <option value="4">Cancel</option>
              </SubForm.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCreate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseCreate} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-end">
        <Button
          className="mb-2"
          style={{ width: "150px" }}
          onClick={handleOpenCreate}
        >
          ADD NEW LOG
        </Button>
      </div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nội dung</th>
            <th>Trạng thái</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {listExtenions
            ?.map((item, index) => (
              <tr key={item?.id}>
                <td>{item?.id}</td>
                <td>{item?.content}</td>
                <td>{setLogState(item?.state)}</td>

                <td className="d-flex gap-2">
                  <Button
                    appearance="primary"
                    onClick={() => {
                      setSelectedValue(item);
                      setUpdateState(item?.state);
                      // console.log(item);
                      handleOpen();
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    onClick={() => {
                      setSelectedValue(item);
                      // console.log(item);
                      handleOpenDel();
                    }}
                    appearance="primary"
                    style={{ background: "red" }}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
            ?.splice((activePage - 1) * limit, limit)}
        </tbody>
      </Table>
      <div className="_pagination">
        {listExtenions?.length !== 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"xs"}
            prev
            next
            // first
            // last
            ellipsis
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={5}
            activePage={activePage}
            onChangePage={setActivePage}
            onChangeLimit={setLimit}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default AdminsLog;
