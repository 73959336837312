import React, { useContext, useEffect, useRef, useState } from "react";
import "./fbOneCardVideoCarousel.css";
import ProfileHeading from "../../../../components/profileHeading/ProfileHeading";
import { Button, Form, Message, useToaster } from "rsuite";
import google_image from "../../../../assets/images/demo-chrome.webp";
import PublicHeading from "../../../../components/publishHeading/PublicHeading";
import { AiFillLike } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import CarouselMenu from "../../../../components/carouselMenu/CarouselMenu";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import Cookies from "js-cookie";
import { sendPost } from "../../../../axios/axiosClient";
import { socket } from "../../../../context/socket";
import errImage from "../../../../assets/images/645fbc5da4a21.png";
import { listCardButtons } from "../../../../commons/data/cardButton";
import LinearProgress from "@mui/material/LinearProgress";
import { listIcon } from "../../../../commons/data/icon";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
import { DataContext } from "../../../../context/DataContext";
import { Navigation, Pagination } from "swiper";
import { Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const FbOneCardVideoCarousel = () => {
  const [loaderImage, setLoaderImage] = useState(false);
  const location = useLocation();
  const [listPages, setListPages] = useState([]);
  const inputImage = useRef(null);
  const inputImage2 = useRef(null);
  const [carouselImages, setCarouselImages] = useState([]);
  const [cardForm, setCardForm] = useState();
  const toolType = location.pathname;
  const originLocation =
    window.location.origin === "http://localhost:3000"
      ? "https://amaimmo.net"
      : window.location.origin;
  const inputVideo = useRef(null);
  const [videoUpload, setVideoUpload] = useState();
  const [cardButton, setCardButton] = useState("LIKE_PAGE");
  const { selectedExtension } = useContext(ExtensionContext);
  const [linkUrl, setLinkUrl] = useState("");
  const [selectedAdsAccount, setSelectedAdsAccount] = useState();
  const [selectedPage, setSelectedPage] = useState();
  const [thumbnails, setThumbnails] = useState([null, null]);
  const [title, setTitle] = useState("");
  const [videoId, setVideoId] = useState("");
  const [videoId2, setVideoId2] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [postSuccessfully, setPostSuccessfully] = useState([]);
  const [formValue, setFormValue] = useState({
    title: "",
  });
  const [selectedThumbnail, setSelectedThumbnail] = useState(0);
  const [randomMenu, setRandomMenu] = useState([]);
  const { adAccount } = useContext(DataContext);
  const userInfo = JSON.parse(localStorage?.getItem("profile"));
  const [thumbImage, setThumbImage] = useState([]);
  const [swiperRef, setSwiperRef] = useState(null);
  // const ad_images = message?.data?.ad_image?.map((item) => item?.url);
  // setCarouselImages(ad_images);

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      console.log(message);
      switch (message?.params?.key_script) {
        case "upload_video_carousel":
          if (message?.data?.error_msg || message?.data?.error_code) {
            setTextMessage(message?.data?.error_msg);
          } else {
            const images = message?.data?.thumbnails?.map((i, k) => i?.uri);
            // setThumbImage(images);
            setCarouselImages((old) => [...images, ...old]);
            setVideoUpload(message?.data);
          }
          setLoaderImage(false);
          break;
        case "post_carousel":
          if (message?.data?.success === true || message?.data?.post_id) {
            setTextMessage("Đăng carousel thành công.");
            setPostSuccessfully((old) => [
              ...old,
              `https://www.facebook.com/${message?.data?.post_id}`,
            ]);
          } else {
            // if (message?.data?.error_title === "Đăng bài lỗi!") {
            //   setTextMessage("Đăng carousel thành công.");
            //   setPostSuccessfully((old) => [
            //     ...old,
            //     `https://www.facebook.com/${message?.data?.post_id}`,
            //   ]);
            // } else {
            setPostSuccessfully((old) => [...old, message?.data?.error_msg]);
            setTextMessage(message?.data?.error_msg);
            // }
          }
          setIsPublic(false);
        case "get_ad_account":
          const ad_images = message?.data?.ad_image?.map((item) => item?.url);
          setCarouselImages(ad_images);
        default:
          break;
      }
    });
  }, []);

  // useEffect(() => {
  //   if (thumbImage?.length > 0) {
  //     setCarouselImages((old) => [...thumbImage, ...old]);
  //   }
  // }, [thumbImage]);

  useEffect(() => {
    if (adAccount) {
      const ad_images = adAccount?.ad_image?.map((item) => item?.url);
      setCarouselImages(ad_images);
    }
  }, [adAccount]);

  const handleCheckType = (file, fileType, fileNumber) => {
    // const file = e.target.files[0];
    console.log(file, "input file");
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (fileNumber === "1") {
          const dataURL = e.target.result;
          const isImage = dataURL.startsWith("data:image/");
          const isVideo = dataURL.startsWith("data:video/");
          if (isImage) {
            if (fileType === "image") {
              handleUploadImage(file[0]);
              setLoaderImage(true);
            } else {
              setTextMessage("File gửi lên không đúng định dạng");
            }
          } else if (isVideo) {
            if (fileType === "video") {
              handleUploadVideo(file[0]);
              setLoaderImage(true);
            } else {
              setTextMessage("File gửi lên không đúng định dạng");
            }
          } else {
            setTextMessage("File gửi lên không đúng định dạng");
          }
        } else {
          handleUploadImage(file, "changeThumbnail");
        }
      };
      reader.readAsDataURL(file[0]);
      // reader.readAsDataURL(file);
      // if (typeCheck !== fileType) {
      //   setTextMessage("File gửi lên không đúng định dạng");
      // } else {
      //   handleUploadImage(file);
      // }
    }
  };

  useEffect(() => {
    if (videoUpload !== undefined) {
      const thumbnailUrl = videoUpload?.thumbnails[0]?.uri;
      // console.log(thumbnailUrl);
      const uploadVideoId = videoUpload?.video_id;
      if (selectedThumbnail === 0) {
        // select change thumb1
        // cond1: thumb2=null, cond2: thumb2 exist
        if (thumbnails[1] === null) {
          setThumbnails([thumbnailUrl, null]);
        } else {
          const newThumb = [thumbnailUrl, thumbnails[1]];
          setThumbnails(newThumb);
        }
        setVideoId(uploadVideoId);
      } else if (selectedThumbnail === 1) {
        //change thumb2
        // cond1: thumb1=null, cond2: thumb1 exist
        if (thumbnails[0] === null) {
          setThumbnails([null, thumbnailUrl]);
        } else {
          const newThumb = [thumbnails[0], thumbnailUrl];
          setThumbnails(newThumb);
        }
        setVideoId2(uploadVideoId);
      }
    }
  }, [videoUpload]);

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const handleUploadVideoCarousel = async (e) => {
    if (selectedAdsAccount === undefined) {
      setTextMessage("Kiểm tra lại tài khoản Ad của bạn.");
      return;
    }
    if (
      linkUrl?.trim()?.length === 0 &&
      cardButton !== "NO_BUTTON" &&
      cardButton !== "LIKE_PAGE"
    ) {
      if (typeof e !== "string") {
        setTextMessage("Video tải lên thất bại.");
        return;
      }
      setTextMessage("Bạn cần điền link.");
      return;
    }

    let type = "";
    switch (toolType) {
      case "/fb-clone-video-reel":
        if (
          // linkUrl?.includes("/watch?") ||
          linkUrl?.includes(".watch/") ||
          linkUrl?.includes("/videos/") ||
          linkUrl?.includes("/reel/")
        ) {
          type = "reel&fb_video";
          break;
        } else {
          setTextMessage("Đường dẫn không đúng định dạng cho phép.");
          return;
        }
      case "/tiktok-pe-clone":
        type = "tiktok";
        break;
      case "/fb-clone-video-pe":
        type = "clone";
        break;
      case "/fb-video-carousel":
      case "/fb-videos-carousel":
      case "/fb-one-card-video-carousel":
        type = "video";
        break;
      default:
        break;
    }
    const input = {
      key_script: "upload_video_carousel",
      extension_key: selectedExtension?.key,
      key_tool: "facebook_video_carousel_post",
      params: {
        key_socket: Cookies.get("keySocket"),
        ad_id: selectedAdsAccount,
        link: typeof e === "string" ? e : linkUrl,
        type: type,
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setLoaderImage(true);
      }
    } catch (error) {
      setTextMessage("Có lỗi xảy ra khi đăng bài.");
      console.log(error);
    }
  };

  const handleUploadImage = async (e, type) => {
    const formData = new FormData();
    if (type === "changeThumbnail") {
      Array.from({ length: e.length }, (item, idx) => {
        formData.append(`files[${idx}]`, e[idx]);
      });
    } else {
      formData.append("files[0]", e);
    }
    try {
      const res = await sendPost(`/users/scripts/save-file`, formData);
      if (res.status === 200) {
        if (type === "changeThumbnail") {
          setCarouselImages((old) => [...res.data, ...old]);
        } else {
          setCarouselImages((old) => [res.data[0], ...old]);

          switch (toolType) {
            case "/fb-picture-carousel":
              setThumbnails([res.data[0], null]);
              break;
            default:
              if (selectedThumbnail === 1) {
                const thumb = [thumbnails[0], res.data[0]];
                setThumbnails(thumb);
              } else {
                const thumb = [res.data[0], thumbnails[1]];
                setThumbnails(thumb);
              }
              break;
          }
        }
      }
    } catch (error) {
      setTextMessage("Tải lên Ảnh thất bại");
    }
    setLoaderImage(false);
  };

  const handleUploadVideo = async (e) => {
    const formData = new FormData();
    formData.append("files[0]", e);
    try {
      const res = await sendPost(`/users/scripts/save-file`, formData);
      if (res.status === 200) {
        switch (toolType) {
          case "/fb-video-carousel":
          case "/fb-videos-carousel":
          case "/fb-one-card-video-carousel":
            handleUploadVideoCarousel(res?.data[0]);
            break;
          default:
            if (selectedThumbnail === 1) {
              const thumb = [thumbnails[0], res.data[0]];
              setThumbnails(thumb);
            } else {
              const thumb = [res.data[0], thumbnails[1]];
              setThumbnails(thumb);
            }
            setLoaderImage(false);

            break;
        }
      }
    } catch (error) {
      setTextMessage("Có lỗi xảy ra khi upload video.");
      setLoaderImage(false);
    }
  };

  const postCarousel = async () => {
    if (selectedAdsAccount === undefined || selectedAdsAccount === "") {
      setTextMessage("Kiểm tra tài khoản Ad của bạn.");
      return;
    }
    if (thumbnails[0] === null) {
      setTextMessage("Bạn cần chọn thumbnail trước");
      return;
    }
    if (
      !cardForm?.link &&
      cardButton !== "LIKE_PAGE" &&
      cardButton !== "NO_BUTTON"
    ) {
      setTextMessage("Bạn cần nhập đường dẫn cho carousel");
      return;
    }
    // const urlPattern =
    //   /^(https?:\/\/)?([a-z\d.-]+)\.([a-z]{2,6})([/\w .-]*)*\/?$/i;
    // if (!urlPattern.test(cardForm?.link) && cardButton !== "LIKE_PAGE") {
    //   setTextMessage("Hãy nhập đường dẫn hợp lệ.");
    //   return;
    // }
    let key_tool = "";
    let cardType = "two_card";
    switch (toolType) {
      case "/fb-clone-video-reel":
        key_tool = "facebook_video_carousel_post";
        break;
      case "/tiktok-pe-clone":
        key_tool = "tiktok_to_carousel_post";
        break;
      case "/fb-video-carousel":
        key_tool = "facebook_video_carousel_post";
        break;
      case "/fb-videos-carousel":
        key_tool = "facebook_2_card_video_carousel_post";
        break;
      case "/fb-clone-video-pe":
        key_tool = "clone_carousel_post_pe_clone";
        break;
      case "/fb-picture-carousel":
        key_tool = "picture_carousel_post_pe_post";
        cardType = "one_card";
        break;
      case "/fb-one-card-video-carousel":
        key_tool = "facebook_one_card_video_post";
        cardType = "one_card";
        break;
      case "/fb-one-card-picture-carousel":
        key_tool = "facebook_one_card_link_image_post";
        cardType = "one_card";
        break;
      default:
        break;
    }
    let finalTitle = title;
    if (randomMenu?.includes("text")) {
      let stringLength = 7;
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
      for (let i = 0; i < stringLength; i++) {
        finalTitle += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
    }
    if (randomMenu?.includes("icon")) {
      finalTitle += listIcon[Math.floor(Math.random() * listIcon.length)];
    }
    if (randomMenu?.includes("time")) {
      const today = new Date();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      finalTitle += time;
    }
    const input = {
      key_script: "post_carousel",
      extension_key: selectedExtension?.key,
      key_tool: key_tool,
      params: {
        key_socket: Cookies.get("keySocket"),
        type: cardType,
        pageId: selectedPage,
        title: finalTitle,
        videoId1: videoId,
        videoId2: videoId2,
        thumbnail1:
          thumbnails[0] === null
            ? `${originLocation}/amai-logo.webp`
            : thumbnails[0],
        description1: cardForm?.fDescription,
        thumbnail2:
          thumbnails[1] === null && cardType === "two_card"
            ? `${originLocation}/amai-logo.webp`
            : `${
                userInfo?.promotion === 1
                  ? `${originLocation}/amai-logo.webp`
                  : thumbnails[1]
              }`,
        description2: cardForm?.sDescription,
        button_type: cardButton,
        ad_id: selectedAdsAccount,
        caption: cardForm?.caption,
        // link: "",
        link:
          cardButton === "LIKE_PAGE"
            ? `https://www.facebook.com/${
                cardForm?.fakePageId ? cardForm?.fakePageId : selectedPage
              }`
            : cardForm?.link
            ? cardForm?.link
            : `https://www.facebook.com/${selectedPage}`,
        name1: cardForm?.name1,
        name2: cardForm?.name2,
        fakePageId:
          cardForm?.fakePageId && cardButton === "LIKE_PAGE"
            ? cardForm?.fakePageId
            : selectedPage,
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setIsPublic(true);
      }
    } catch (error) {
      console.log(error);
      setTextMessage("Có lỗi xảy ra khi đăng carousel");
    }
  };

  return (
    <>
      <div className="d-flex" style={{ maxWidth: "100%" }}>
        <div className="col-5">
          <CarouselMenu
            cardButton={cardButton}
            selectedPage={selectedPage}
            listPages={listPages}
            setListPages={setListPages}
            setTitle={setTitle}
            setSelectedPage={setSelectedPage}
            setCardButton={setCardButton}
            setSelectedAdsAccount={setSelectedAdsAccount}
            selectedAdsAccount={selectedAdsAccount}
            randomMenu={randomMenu}
            setRandomMenu={setRandomMenu}
          />
          {isPublic ? (
            <LinearProgress style={{ width: "100%", marginTop: "20px" }} />
          ) : (
            <></>
          )}
          {postSuccessfully ? (
            <div className="card p-3 mt-2 text-center">
              <h5 className="fw-bold">Báo cáo bài đăng</h5>
              <Table responsive bordered style={{ background: "white" }}>
                <thead>
                  <tr>
                    <th>Đường dẫn</th>
                    <th>Trạng thái</th>
                  </tr>
                </thead>
                <tbody>
                  {postSuccessfully?.map((item, idx) => {
                    if (!item.startsWith("https://www.facebook.com")) {
                      return (
                        <tr key={idx}>
                          <td colSpan={2} className="text-danger fw-bold">
                            {item.trim().length > 0 ? item : "Thất bại"}
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={idx}>
                          <td style={{ maxWidth: "15vw" }}>
                            <a
                              href={item}
                              target="_blank"
                              className="group_membernumber blackLink"
                            >
                              {item}
                            </a>
                          </td>
                          <td className="text-success fw-bold">Thành công</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="col-7 ps-3">
          <div className="card p-3">
            <PublicHeading
              isPublic={isPublic}
              selectedPage={selectedPage}
              listPages={listPages}
              setLinkUrl={setLinkUrl}
              postCarousel={postCarousel}
              selectedAdsAccount={selectedAdsAccount}
              handleUploadVideoCarousel={handleUploadVideoCarousel}
              loaderImage={loaderImage}
            />

            <div className="d-flex gap-2">
              {[
                ...Array(
                  toolType === "/fb-video-carousel" ||
                    toolType === "/tiktok-pe-clone" ||
                    toolType === "/fb-videos-carousel" ||
                    toolType === "/fb-clone-video-pe" ||
                    toolType === "/fb-clone-video-reel"
                    ? 2
                    : 1
                ),
              ].map((e, i) => {
                return (
                  <div
                    className={`col mt-3 cardLikePage ${
                      selectedThumbnail === i ? "selectedThumb" : ""
                    }`}
                    key={i}
                    onClick={() => {
                      if (!loaderImage) {
                        setSelectedThumbnail(i);
                      }
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      {(() => {
                        switch (toolType) {
                          case "/fb-clone-video-reel":
                          case "/tiktok-pe-clone":
                          case "/fb-clone-video-pe":
                            break;
                          case "/fb-picture-carousel":
                          case "/fb-one-card-picture-carousel":
                            return (
                              <div className="carousel__uploadVideoButton">
                                <input
                                  style={{ display: "none" }}
                                  ref={inputImage}
                                  type="file"
                                  onChange={(e) => {
                                    handleCheckType(
                                      e.target.files,
                                      "image",
                                      "1"
                                    );
                                  }}
                                />

                                <Button
                                  appearance="primary"
                                  style={{ backgroundColor: "#f2ae14" }}
                                  onClick={() => inputImage.current.click()}
                                >
                                  Tải lên hình ảnh
                                </Button>
                              </div>
                            );
                          case "/fb-video-carousel":
                            if (i === 1) {
                              return (
                                <div className="carousel__uploadVideoButton">
                                  <input
                                    style={{ display: "none" }}
                                    ref={inputImage}
                                    type="file"
                                    onChange={(e) => {
                                      handleCheckType(
                                        e.target.files,
                                        "image",
                                        "1"
                                      );
                                    }}
                                  />

                                  <Button
                                    appearance="primary"
                                    style={{ backgroundColor: "#f2ae14" }}
                                    onClick={() => inputImage.current.click()}
                                    disabled={loaderImage}
                                  >
                                    Tải lên hình ảnh
                                  </Button>
                                </div>
                              );
                            } else {
                              return (
                                <div className="carousel__uploadVideoButton">
                                  <input
                                    style={{ display: "none" }}
                                    ref={inputVideo}
                                    type="file"
                                    onChange={(e) => {
                                      handleCheckType(
                                        e.target.files,
                                        "video",
                                        "1"
                                      );
                                    }}
                                  />
                                  <Button
                                    appearance="primary"
                                    style={{ backgroundColor: "#f2ae14" }}
                                    onClick={() => inputVideo.current.click()}
                                    disabled={loaderImage}
                                  >
                                    {loaderImage
                                      ? "Loading..."
                                      : "Tải lên video"}
                                  </Button>
                                </div>
                              );
                            }
                          default:
                            return (
                              <div className="carousel__uploadVideoButton">
                                <input
                                  style={{ display: "none" }}
                                  ref={inputVideo}
                                  type="file"
                                  onChange={(e) => {
                                    handleCheckType(
                                      e.target.files,
                                      "video",
                                      "1"
                                    );
                                  }}
                                />
                                <Button
                                  appearance="primary"
                                  style={{ backgroundColor: "#f2ae14" }}
                                  onClick={() => inputVideo.current.click()}
                                  disabled={loaderImage}
                                >
                                  {loaderImage ? "Loading..." : "Tải lên video"}
                                </Button>
                              </div>
                            );
                        }
                      })()}
                      {thumbnails[i] === null ? (
                        <img
                          src={google_image}
                          alt=""
                          className={"default-carousel-image"}
                        />
                      ) : (
                        <img
                          src={thumbnails[i]}
                          alt=""
                          className={"carousel-placeholder-image"}
                        />
                      )}
                    </div>
                    <div className="p-2 d-flex justify-content-between">
                      <div className="">
                        {/* <p className="m-0 group_membernumber">
                        {cardForm?.caption}
                      </p> */}
                        <p className="fw-bold m-0">
                          {i === 0
                            ? cardForm?.fDescription
                            : cardForm?.sDescription}
                        </p>
                        <p
                          className="m-0 fw-bold"
                          // className="m-0 group_membernumber"
                        >
                          {i === 0 ? cardForm?.name1 : cardForm?.name2}
                          {/* {cardForm?.name} */}
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <Button
                          appearance="default"
                          className="fw-bold"
                          style={{
                            backgroundColor: "#E4E6EB",
                            display: `${
                              cardButton === "NO_BUTTON" ? "none" : ""
                            }`,
                          }}
                        >
                          {cardButton === "LIKE_PAGE" ? (
                            <AiFillLike className="me-2 mb-1" />
                          ) : (
                            <></>
                          )}
                          {listCardButtons?.map((item, key) => {
                            if (item?.value === cardButton) {
                              return item?.name;
                            }
                          })}
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="card p-3 mt-3">
            <Form fluid formValue={cardForm} onChange={setCardForm}>
              {(() => {
                switch (toolType) {
                  case "video":
                    return (
                      <Form.Group controlId="name-6">
                        <Form.ControlLabel className="group_membernumber">
                          Card title
                        </Form.ControlLabel>
                        <Form.Control name="title" />
                      </Form.Group>
                    );
                  case "picture":
                    return (
                      <>
                        <Form.Group controlId="name-6">
                          <Form.ControlLabel className="group_membernumber">
                            Card title
                          </Form.ControlLabel>
                          <Form.Control name="title" />
                        </Form.Group>
                        <Form.Group controlId="name-6">
                          <Form.ControlLabel className="group_membernumber">
                            Website URL
                          </Form.ControlLabel>
                          <Form.Control name="webUrl" />
                        </Form.Group>
                        <Form.Group controlId="name-6">
                          <Form.ControlLabel className="group_membernumber">
                            Đường dẫn giả (Basic +)
                          </Form.ControlLabel>
                          <Form.Control name="displayLink" />
                        </Form.Group>
                        <Form.Group controlId="name-6">
                          <Form.ControlLabel className="group_membernumber">
                            Mô tả đường dẫn giả (Basic +)
                          </Form.ControlLabel>
                          <Form.Control name="displayDescription" />
                        </Form.Group>
                      </>
                    );
                  case "/fb-clone-video-reel":
                    return (
                      <>
                        <div className="d-flex gap-3">
                          <Form.Group controlId="name-6" className="col">
                            <Form.ControlLabel className="group_membernumber">
                              Card 1 tiêu đề
                            </Form.ControlLabel>
                            <Form.Control
                              name="fDescription"
                              placeholder={`Mô tả đường dẫn`}
                            />
                          </Form.Group>
                          <Form.Group controlId="name-6" className="col">
                            <Form.ControlLabel className="group_membernumber">
                              Card 2 tiêu đề
                            </Form.ControlLabel>
                            <Form.Control
                              name="sDescription"
                              placeholder={`Mô tả đường dẫn`}
                            />
                          </Form.Group>
                        </div>
                      </>
                    );
                  default:
                    break;
                }
              })()}
              {/* {cardButton === "LIKE_PAGE" || cardButton === "NO_BUTTON" ? (
                <></>
              ) : (
                <> */}
              <div className="d-flex gap-3">
                <Form.Group controlId="name1" className="col">
                  <Form.ControlLabel className="group_membernumber">
                    Tên đường dẫn 1:
                  </Form.ControlLabel>
                  <Form.Control name="name1" placeholder="Tiêu đề" />
                </Form.Group>
                <Form.Group controlId="name2" className="col">
                  <Form.ControlLabel className="group_membernumber">
                    Tên đường dẫn 2:
                  </Form.ControlLabel>
                  <Form.Control name="name2" placeholder="Tiêu đề" />
                </Form.Group>
              </div>

              {cardButton === "LIKE_PAGE" ? (
                <div>
                  <Form.Group controlId="link" className="col">
                    <Form.ControlLabel className="group_membernumber">
                      Trang được thích:
                    </Form.ControlLabel>
                    <Form.Control
                      name="fakePageId"
                      placeholder="ID Trang được thích"
                    />
                  </Form.Group>
                </div>
              ) : (
                <div className="d-flex gap-3">
                  <Form.Group controlId="caption" className="col">
                    <Form.ControlLabel className="group_membernumber">
                      Đường dẫn hiển thị:
                    </Form.ControlLabel>
                    <Form.Control name="caption" placeholder="URL" />
                  </Form.Group>
                  <Form.Group controlId="link" className="col">
                    <Form.ControlLabel className="group_membernumber">
                      Đường dẫn:
                      <Form.HelpText tooltip className="m-0 ms-1">
                        Yêu cầu
                      </Form.HelpText>
                    </Form.ControlLabel>
                    <Form.Control name="link" placeholder="URL" />
                  </Form.Group>
                </div>
              )}
              {/* </>
              )} */}
            </Form>
          </div>

          {/*  */}
          {toolType === "/fb-picture-carousel" ? (
            <></>
          ) : (
            <div className="card mt-3 p-3">
              <div>
                <input
                  style={{ display: "none" }}
                  type="file"
                  ref={inputImage2}
                  multiple
                  onChange={(e) => {
                    if (e.target.files) {
                      handleCheckType(e.target.files, "image", "multi");
                    }
                    // handleUploadImage(e.target.files[0], "changeThumb");
                  }}
                />
                <div className="d-flex justify-content-end my-2">
                  <Button
                    appearance="primary"
                    style={{ backgroundColor: "#f2ae14" }}
                    onClick={() => inputImage2.current.click()}
                    disabled={loaderImage || isPublic}
                  >
                    Tải ảnh lên
                  </Button>
                </div>
                <div
                  className={
                    carouselImages?.length > 0 ? "carouselThumbnailList" : ``
                  }
                  // style={{ maxWidth: "100%" }}
                >
                  <Swiper
                    // spaceBetween={50}
                    // slidesPerView={3}
                    style={{ maxWidth: "100%", color: "white" }}
                    onSwiper={setSwiperRef}
                    slidesPerView={3}
                    // centeredSlides={true}
                    spaceBetween={30}
                    pagination={{
                      type: "fraction",
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {carouselImages?.map((i, k) => {
                      if (i) {
                        return (
                          // <div key={k}>
                          <SwiperSlide
                            style={{ maxWidth: "200px", cursor: "pointer" }}
                            key={k}
                          >
                            <div
                              onClick={() => {
                                if (!loaderImage) {
                                  switch (selectedThumbnail) {
                                    case 1:
                                      const newThumb = [thumbnails[0], i];
                                      setThumbnails(newThumb);
                                      break;
                                    case 0:
                                      const newThumb1 = [i, thumbnails[1]];
                                      setThumbnails(newThumb1);
                                      break;
                                    default:
                                      break;
                                  }
                                }
                              }}
                              style={{
                                width: "200px",
                                height: "200px",
                                overflow: "hidden",
                                position: "relative",
                              }}
                            >
                              <img
                                loading="lazy"
                                src={i}
                                alt="image"
                                style={{
                                  objectFit: "cover",
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                            </div>
                          </SwiperSlide>
                          // </div>
                        );
                      }
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FbOneCardVideoCarousel;
