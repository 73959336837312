import React, { useEffect, useState } from "react";
import "./tutorials.css";
import { sendGet } from "../../../axios/axiosClient";
import ReactPlayer from "react-player";
const Tutorials = () => {
  const [listTutorials, setListTutorials] = useState([]);

  const getTutorials = async () => {
    try {
      const res = await sendGet(`/users/tutorials`);
      if (res.status === 200) {
        console.log(res);
        setListTutorials(res?.data?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTutorials();
  }, []);

  return (
    <div>
      <h4 className="fw-bold text-center">Video hướng dẫn sử dụng</h4>
      <div className="home__menuItems">
        {listTutorials?.map((item, key) => (
          <div
            className="tutorial__menuItem"
            style={{
              marginLeft: `${key % 4 === 0 ? "" : "1.33%"}`,
            }}
          >
            <div className="card p-2">
              <ReactPlayer
                url={item?.link}
                style={{
                  maxWidth: "100%",
                  height: "12rem",
                  maxHeight: "14rem",
                }}
                controls
              />
              <p
                className="fw-bold mt-2 multi-line"
                style={
                  {
                    // height: "1.5rem",
                    // maxHeight: "1.5rem",
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                  }
                }
              >
                {item?.title}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tutorials;
