import React, { useContext, useEffect, useRef, useState } from "react";
import "./extractUidFromGroup.css";
import { AiFillCheckCircle, AiOutlineCopy } from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si";
import {
  Button,
  Form,
  IconButton,
  Input,
  Message,
  Pagination,
  useToaster,
  Checkbox,
  CheckboxGroup,
  Modal,
} from "rsuite";
import { Table } from "react-bootstrap";
import { sendPost } from "../../../../axios/axiosClient";
import ProfileHeading from "../../../../components/profileHeading/ProfileHeading";
import { SocketContext } from "../../../../context/socket";
import Cookies from "js-cookie";
import { CSVDownload, CSVLink } from "react-csv";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import LinearProgress from "@mui/material/LinearProgress";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { CheckFunction } from "../../../../commons/function/CheckURL";
import { DataContext } from "../../../../context/DataContext";
import { MdFileDownload } from "react-icons/md";

// import { socket } from "../../../context/socket";
const ExtractUIDFromGroup = () => {
  const { selectedExtension } = useContext(ExtensionContext);
  const location = useLocation();
  const [uidData, setUiddata] = useState([]);
  const [listUID, setListUID] = useState([]);
  const downloadMe = useRef(null);
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [linkGroup, setLinkGroup] = useState();
  const [formValue, setFormValue] = useState();
  const socket = useContext(SocketContext);
  const [buttonType, setButtonType] = useState("extract");
  const toolType =
    location.pathname === "/extract-uid-by-group" ? "group" : "post";
  const [sortingMember, setSortingMember] = useState("asc");
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMsg, setTextMsg] = useState("");
  const [tableType, setTableType] = useState("");
  const [search, setSearch] = useState("");
  const [filterDownload, setFilterDownload] = useState([]);
  const [downloadModal, setDownloadModal] = useState(false);
  const { keepFieldsDownload } = useContext(DataContext);

  const handleChangeLimit = async (num) => {
    setLimit(num);
    setActivePage(1);
  };

  useEffect(() => {
    setActivePage(1);
  }, [search]);

  const handleSorting = (e) => {
    setSortingMember(e);

    if (e === "asc") {
      uidData.sort((a, b) => a.mutual_friends - b.mutual_friends);
    } else {
      uidData.sort((a, b) => b.mutual_friends - a.mutual_friends);
    }
  };

  const message = (
    <Message showIcon type={type} closable>
      {textMsg}
    </Message>
  );

  useEffect(() => {
    if (textMsg !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMsg("");
  }, [textMsg]);

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      // console.log(message, "message");
      setListUID(message?.data);
      if (message?.done_all === true) {
        setButtonType("extract");
        setTextMsg("Kết thúc tìm kiếm");
      }
      switch (message?.params?.key_script) {
        case "get_uids_with_reaction_post":
          setTableType("reaction");
          break;
        case "get_uids_with_comment_post":
          setTableType("comment");
          break;
        default:
          break;
      }
      // switch (message?.params?.key_script) {
      //   case "get_uids_with_comment_post":
      //     setListUID(message?.data);
      //     break;

      //   default:
      //     break;
      // }
    });
  }, []);

  useEffect(() => {
    if (listUID?.length > 0) {
      const data = [...uidData, ...listUID];
      const uniqueArray = data.filter(
        (item, index, self) =>
          index === self.findIndex((obj) => obj.id === item.id)
      );
      setUiddata(uniqueArray);
    }
  }, [listUID]);

  const handleExtract = async (id, extractType) => {
    setUiddata([]);
    setActivePage(1);
    const input = {
      key_script:
        toolType === "group"
          ? "get_member_group"
          : extractType === "comment"
          ? "get_uids_with_comment_post"
          : "get_uids_with_reaction_post",
      extension_key: selectedExtension?.key,
      key_tool:
        toolType === "group"
          ? "extract_uids_from_group"
          : "extract_uids_from_post",
      params: {
        uid: selectedExtension?.uid,
        key_socket: Cookies.get("keySocket"),
        post_id: id,
        groupId: id,
      },
    };
    if (toolType === "group") {
      delete input.params.post_id;
      delete input.params.uid;
    } else {
      delete input.params.groupId;
    }
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setButtonType("stop");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getId = async (type) => {
    if (toolType === "group") {
      if (!formValue?.group_id) {
        setTextMsg("Bạn cần chưa điền đường dẫn/ID nhóm.");
        return;
      }
      setButtonType("stop");
      if (CheckFunction(formValue?.group_id)) {
        axios
          .post("https://uid.bufflike.net/fbid", {
            link: formValue?.group_id,
          })
          .then(function (response) {
            if (response.data.status) {
              handleExtract(response?.data?.fbid);
            } else {
              setTextMsg(response?.data?.message);
              setButtonType("extract");
            }
          })
          .catch(function (error) {
            setTextMsg("Có lỗi xảy ra.");
            setButtonType("extract");
          });
      } else {
        handleExtract(formValue?.group_id);
      }
    } else {
      // toolType === post
      if (!formValue?.post_id) {
        setTextMsg("Bạn cần chưa điền đường dẫn/ID bài viết.");
        return;
      }
      setButtonType("stop");
      if (CheckFunction(formValue?.post_id)) {
        axios
          .post("https://uid.bufflike.net/fbid", {
            link: formValue?.post_id,
          })
          .then(function (response) {
            if (response.data.status) {
              handleExtract(response?.data?.fbid, type);
            } else {
              setTextMsg(response?.data?.message);
              setButtonType("extract");
            }
          })
          .catch(function (error) {
            setTextMsg("Có lỗi xảy ra.");
            setButtonType("extract");
          });
      } else {
        // setIsLoadingData(true);
        handleExtract(formValue?.post_id, type);
      }
    }
  };

  const handleStop = async () => {
    const input = {
      key_script: "force_stop",
      extension_key: selectedExtension?.key,
      key_tool: "force_stop",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setButtonType("extract");
        setTextMsg("Kết thúc tìm kiếm");
      }
    } catch (error) {}
  };

  return (
    <div className="">
      <Modal
        backdrop="static"
        role="alertdialog"
        keyboard={false}
        open={downloadModal}
        style={{ paddingTop: "5rem" }}
        onClose={() => setDownloadModal(false)}
      >
        <Modal.Header>
          <Modal.Title className="fw-bold">
            Chọn các mục bạn muốn tải
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CheckboxGroup
            name="checkbox-group"
            value={filterDownload}
            onChange={(value) => {
              setFilterDownload(value);
            }}
          >
            <Checkbox value="id">ID</Checkbox>
            <Checkbox value="name">Tên</Checkbox>
            <Checkbox value="link">Link</Checkbox>
          </CheckboxGroup>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <CSVLink
            data={uidData
              .map((item) => ({
                ID: item?.id,
                NAME: item?.name,
                LINK: item?.url,
              }))
              ?.map((item) => keepFieldsDownload(item, filterDownload))}
            filename="extract-uid-by-group.csv"
            // style={{ display: "none" }}
            // ref={downloadMe}
            onClick={() => {
              if (!uidData?.length) {
                setTextMsg("Không có dữ liệu để tải về");
                return false; // 👍🏻 You are stopping the handling of component
              }
            }}
          >
            <Button appearance="primary">
              <SiMicrosoftexcel className="me-2" />
              <span>Tải xuống</span>
            </Button>
          </CSVLink>
        </Modal.Footer>
      </Modal>
      <div className="d-flex gap-3">
        <div className="col">
          <div className="card p-3 euidfromgroup__groupInput cardShadow">
            <div className="mb-3">
              <ToolHeading toolType={location.pathname} />
            </div>
            <div className="">
              <Form
                layout="horizontal"
                fluid
                formValue={formValue}
                onChange={setFormValue}
              >
                {toolType === "group" ? (
                  <Form.Group controlId="group_id">
                    <p className="group_membernumber">
                      Đường dẫn hoặc ID nhóm:
                      <a href="https://uid.bufflike.net/" target="_blank">
                        <Form.HelpText tooltip>
                          Tìm ID nhóm theo URL
                        </Form.HelpText>
                      </a>
                    </p>
                    <Form.Control
                      name="group_id"
                      placeholder="Nhập đường dẫn hoặc ID"
                    />
                  </Form.Group>
                ) : (
                  <Form.Group controlId="post_id">
                    <p className="group_membernumber">
                      Đường dẫn hoặc ID bài viết:
                      <a href="https://uid.bufflike.net/" target="_blank">
                        <Form.HelpText tooltip>
                          Tìm ID bài viết theo URL
                        </Form.HelpText>
                      </a>
                    </p>
                    <Form.Control
                      name="post_id"
                      placeholder="Nhập đường dẫn hoặc ID"
                    />
                  </Form.Group>
                )}
              </Form>
              <div className="d-flex gap-3">
                {(() => {
                  switch (toolType) {
                    case "group":
                      return buttonType === "extract" ? (
                        <Button
                          className="euidfromgroup__groupInput__button"
                          style={{
                            // backgroundColor: "rgb(235, 156, 100)",
                          }}
                          onClick={() => getId("comment")}
                          appearance="primary"
                        >
                          BẮT ĐẦU
                        </Button>
                      ) : (
                        <Button
                          className="euidfromgroup__groupInput__button"
                          style={{
                            backgroundColor: "red",
                          }}
                          onClick={handleStop}
                        >
                          DỪNG LẠI
                        </Button>
                      );

                    default:
                      return buttonType === "extract" ? (
                        <>
                          <Button
                            className="euidfromgroup__groupInput__button"
                            style={{
                              backgroundColor: "rgb(235, 156, 100)",
                            }}
                            onClick={() => getId("comment")}
                          >
                            TÌM KIẾM BÌNH LUẬN
                          </Button>
                          <Button
                            className="euidfromgroup__groupInput__button"
                            onClick={() => getId("react")}
                            appearance="primary"
                          >
                            TÌM KIẾM THEO REACT
                          </Button>
                        </>
                      ) : (
                        <Button
                          className="euidfromgroup__groupInput__button"
                          style={{
                            backgroundColor: "red",
                          }}
                          onClick={handleStop}
                        >
                          DỪNG LẠI
                        </Button>
                      );
                  }
                })()}
              </div>
            </div>
          </div>
        </div>

        {/* card 2 */}
        <div
          className="col card p-3 euidfromgroup__groupInput cardShadow"
          style={{ height: "fit-content" }}
        >
          <div style={{ position: "relative", height: "100%", width: "100%" }}>
            <div className="d-flex justify-content-between align-items-center">
              <Button
                appearance="primary"
                style={{ padding: "0.2rem 0.4rem" }}
                onClick={() => {
                  setDownloadModal(true);
                }}
              >
                <MdFileDownload style={{ fontSize: 21 }} />
              </Button>
              <div>
                <Input
                  placeholder={"Tìm kiếm"}
                  value={search}
                  onChange={setSearch}
                ></Input>
              </div>
            </div>
            <div className="mt-3">
              <Table responsive>
                <thead>
                  <tr className="group_membernumber">
                    <th>#</th>
                    <th style={{ width: "40%" }}>UID</th>
                    <th>Tên</th>
                    {tableType === "reaction" ? (
                      <th className="oneLine">
                        Bạn chung{" "}
                        {sortingMember === "asc" ? (
                          <BiSortUp
                            className="sortingNumber"
                            onClick={() => {
                              handleSorting("des");
                            }}
                          />
                        ) : (
                          <BiSortDown
                            className="sortingNumber"
                            onClick={() => {
                              handleSorting("asc");
                            }}
                          />
                        )}
                      </th>
                    ) : (
                      <></>
                    )}
                  </tr>
                  {buttonType !== "extract" ? (
                    <tr>
                      <th colSpan={10}>
                        <LinearProgress style={{ width: "100%" }} />
                      </th>
                    </tr>
                  ) : (
                    <></>
                  )}
                </thead>
                <tbody>
                  {uidData
                    ?.filter((item) =>
                      item?.name.toLowerCase().includes(search.toLowerCase())
                    )
                    ?.map((item, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>
                          {item?.id}{" "}
                          <IconButton
                            className="ms-1"
                            icon={<AiOutlineCopy />}
                            // color="blue"
                            appearance="subtle"
                            circle
                            onClick={() => {
                              navigator.clipboard.writeText(item?.id);
                            }}
                          />
                        </td>
                        <td>
                          <a
                            style={{ textDecoration: "none" }}
                            href={
                              toolType === "post"
                                ? item?.url
                                : `https://www.facebook.com/${item?.id}`
                            }
                            target="_blank"
                          >
                            <div className="d-flex gap-2 align-items-center">
                              <div>
                                <img
                                  src={
                                    toolType === "post"
                                      ? item?.image
                                      : item?.url
                                  }
                                  alt="avatar"
                                  className="toolHeader__avatar"
                                />
                              </div>
                              <div>
                                <p className="euidfromgroup__groupInput__name">
                                  {item?.name}
                                </p>
                              </div>
                            </div>
                          </a>
                        </td>
                        {tableType === "reaction" ? (
                          <td>{item?.mutual_friends}</td>
                        ) : (
                          <></>
                        )}
                        {/* <td>
                          <a href={`https://www.facebook.com/${item?.id}`}>
                            https://www.facebook.com/{item?.id}
                          </a>
                        </td> */}
                      </tr>
                    ))
                    ?.splice((activePage - 1) * limit, limit)}
                </tbody>
              </Table>
            </div>
            {/* <div className="_pagination"> */}
            {uidData?.length !== 0 ? (
              <Pagination
                layout={["limit", "-", "pager"]}
                size={"xs"}
                prev
                next
                // first
                // last
                ellipsis
                boundaryLinks
                total={
                  uidData?.filter((item) =>
                    item?.name.toLowerCase().includes(search.toLowerCase())
                  )?.length
                }
                limit={limit}
                limitOptions={[10, 30, 50]}
                maxButtons={5}
                activePage={activePage}
                onChangePage={setActivePage}
                onChangeLimit={handleChangeLimit}
              />
            ) : (
              <></>
            )}
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* <div>
        <ToolFooter />
      </div> */}
    </div>
  );
};

export default ExtractUIDFromGroup;
