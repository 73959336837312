import React, { useContext, useEffect, useState } from "react";
import "./createMbPages.css";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputPicker,
  Loader,
  Message,
  useToaster,
} from "rsuite";
import { socket } from "../../../../context/socket";
import Cookies from "js-cookie";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import { sendPost } from "../../../../axios/axiosClient";
import { Badge, Form as bsForm } from "react-bootstrap";
import LinearProgress from "@mui/material/LinearProgress";
import { AiFillCheckCircle } from "react-icons/ai";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
let createPageResult = [];
let createPageQuantity = 1;
const CreateMbPages = () => {
  const [formValue, setFormValue] = useState({
    delay: 60,
    limit: 10,
  });
  const { selectedExtension } = useContext(ExtensionContext);
  const [intervalId, setIntervalId] = useState(null);
  const [pageNames, setPageNames] = useState("");
  const [pageBios, setPageBios] = useState("");
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [isCreate, setIsCreate] = useState(false);
  const [isRandomEmoji, setIsRandomEmoji] = useState(false);
  const [isRandomString, setIsRandomString] = useState(false);
  const [resultList, setResultList] = useState([]);
  const listIcon = [
    "🙂",
    "🙂",
    "😀",
    "😄",
    "😆",
    "😅",
    "😂",
    "🤣",
    "😊",
    "😌",
    "😉",
    "😏",
    "😍",
    "😘",
    "😗",
    "😙",
    "😚",
    "🤗",
    "💙",
    "😺",
    "😸",
    "😹",
    "😻",
    "😼",
    "😽",
    "🙀",
    "🌺",
    "🔰",
    "♥️",
    "❤️",
    "💛",
    "💚",
    "💙",
    "💜",
    "🖤",
    "💖",
    "💝",
    "💔",
    "❣️",
    "💕",
    "💞",
    "💓",
    "💗",
    "💘",
    "💟",
    "💌",
    "💋",
    "👄",
    "🌙",
    "💫",
    "⭐",
    "🌟",
    "✨",
    "⚡",
    "🔥",
    "☄️",
    "💥",
    "🌞",
    "☀️",
    "💧",
    "💦",
    "🌊",
    "🌼",
    "🌸",
    "🌺",
    "🏵️",
    "🌻",
    "🌷",
    "🌹",
    "🥀",
    "💐",
  ];
  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const limitPageCreatingData = [...Array(100)]?.map((_, i) => ({
    label: (i + 1) * 10,
    value: (i + 1) * 10,
  }));

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      console.log(message);
      switch (message?.params?.key_script) {
        case "get_list_page":
          break;
        case "create_page":
          if (message?.data?.error) {
            setTextMessage(message?.data?.error);
            // console.log("createPageQuantity", createPageQuantity <= 1);
            if (createPageQuantity <= 1) {
              setIsCreate(false);
              // setTextMessage("Kết thúc tạo trang");
            } else {
              --createPageQuantity;
            }
          } else {
            createPageResult.push(message);
            setResultList((prevState) => [...prevState, message]);
            if (
              createPageQuantity !== 0 &&
              createPageResult.length == createPageQuantity
            ) {
              setTextMessage("Tạo trang thành công");
              setIsCreate(false);
            }
          }

          // if (message?.done_all === true) {
          //   setTextMessage("Tạo trang thành công");
          // }
          // setIsCreate(false);

          break;
        default:
          break;
      }
    });
  }, []);

  const handleStopCreate = () => {
    // if (isCreate) {
    clearInterval(intervalId);
    setIsCreate(false);
    setTextMessage("Dừng tạo trang.");
    // }
  };

  const handleCreatePage = async () => {
    // console.log(formValue?.limit, "limit create page");
    // console.log(formValue?.delay, "delay time");
    // return;
    if (isRandomString) {
      setIsCreate(true);
      createPageResult = [];
      createPageQuantity = Number(formValue?.limit);
      let b = 1;
      let limit = Number(formValue?.limit);
      // if (pageNames?.trim()?.split("\n")?.length < formValue?.limit) {
      //   limit = pageNames?.split("\n")?.length;
      // } else {
      //   limit = formValue?.limit;
      // }

      createPage(pageNames?.trim()?.split("\n")[0]);
      if (limit > 1) {
        const id = setInterval(() => {
          if (b < limit) {
            if (b >= pageNames?.trim()?.split("\n")?.length) {
              createPage("");
              b++;
            } else {
              createPage(pageNames?.trim()?.split("\n")[b]);
              b++;
            }
          } else {
            clearInterval(id);
          }
        }, formValue?.delay * 1000);
        setIntervalId(id);
      }
    } else {
      if (pageNames?.trim().split("\n")[0] === "") {
        setTextMessage("Bạn cần nhập tên trang muốn tạo");
      } else {
        createPageResult = [];
        setIsCreate(true);
        let b = 1;
        let limit = 0;
        if (
          pageNames?.trim()?.split("\n")?.length <= Number(formValue?.limit)
        ) {
          limit = pageNames?.trim()?.split("\n")?.length;
        } else {
          limit = Number(formValue?.limit);
        }
        createPageQuantity = limit;
        createPage(pageNames?.trim()?.split("\n")[0]);
        if (limit > 1) {
          const id = setInterval(() => {
            if (b < limit) {
              createPage(pageNames?.trim()?.split("\n")[b]);
              b++;
            } else {
              clearInterval(id);
            }
          }, formValue?.delay * 1000);
          // console.log(id, "interval id");
          setIntervalId(id);
        }
      }
    }
  };

  const createPage = async (pageName) => {
    // if (!isCreate) {
    //   return;
    // }
    let finalPageName = pageName + " ";
    if (isRandomString) {
      let stringLength = 7;
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < stringLength; i++) {
        finalPageName += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
    }

    const input = {
      key_script: "create_page",
      extension_key: selectedExtension?.key,
      key_tool: "auto_create_multiple_facebook_pages",
      params: {
        key_socket: Cookies.get("keySocket"),
        page_bio: pageBios,
        page_names: [finalPageName?.trim()],
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        // handleRemoveString(pageName);
        // setIsCreate(true);
      }
    } catch (error) {
      setTextMessage("Có lỗi xảy ra trong quá tình tạo trang.");
      // setIsCreate(false);
    }
  };

  const handleRemoveString = (string) => {
    const text = string;
    setPageNames(text?.replace(string, ""));
  };

  return (
    <div className="d-flex gap-3">
      <div className="col">
        <div className=" card p-3">
          <div className="mb-3">
            <ToolHeading toolType={window.location.pathname} />
          </div>
          <Form fluid formValue={formValue} onChange={setFormValue}>
            <div className="d-flex gap-3">
              <Form.Group controlId="limit" className="col">
                <Form.ControlLabel className="group_membernumber">
                  Số trang tối đa được tạo :
                </Form.ControlLabel>

                <Form.Control name="limit" type="number" />
              </Form.Group>
              <Form.Group controlId="delay" className="col">
                <Form.ControlLabel className="group_membernumber">
                  Delays (giây) :
                </Form.ControlLabel>
                <Form.Control name="delay" type="number" />
              </Form.Group>
            </div>
            <Form.Group>
              {/* {isCreate ? (
                <Button
                  // className="fw-bold"
                  style={{ width: "100%" }}
                  appearance="primary"
                >
                  <Loader />
                </Button>
              ) : (
                <Button
                  className="fw-bold"
                  style={{ width: "100%" }}
                  appearance="primary"
                  color="green"
                  onClick={handleCreatePage}
                >
                  START
                </Button>
              )} */}
              <Button
                className="fw-bold"
                style={{
                  width: "100%",
                  backgroundColor: `${isCreate ? "red" : ""}`,
                }}
                appearance="primary"
                onClick={() => {
                  if (isCreate) {
                    handleStopCreate();
                  } else {
                    handleCreatePage();
                  }
                }}
              >
                {isCreate ? "DỪNG LẠI" : "BẮT ĐẦU"}
              </Button>
            </Form.Group>
          </Form>
          {isCreate ? (
            <LinearProgress
              style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
            />
          ) : (
            <></>
          )}
        </div>
        {resultList?.length > 0 ? (
          <div className=" card p-3 mt-3">
            <p className="group_membernumber">Kết quả :</p>
            <div>
              {resultList?.map((item, key) => (
                <div
                  key={key}
                  className="d-flex justify-content-between pb-1 border-bottom"
                >
                  <div className="d-flex gap-2 align-items-center">
                    <AiFillCheckCircle
                      className="fs-4"
                      style={{ color: "#60A5FA" }}
                    />
                    <a
                      className="list-result"
                      href={`https://www.facebook.com/${item?.data?.page_id}`}
                      target="_blank"
                    >
                      {item?.params.page_names[0]}
                    </a>
                  </div>
                  <div>
                    {item?.data ? (
                      <>
                        {item?.data?.status == "created" ? (
                          <Badge bg="success">{"Đã tạo"}</Badge>
                        ) : (
                          <Badge bg="danger">{"Lỗi"}</Badge>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="col card p-3">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <p className="group_membernumber m-0">Tên trang (500) :</p>
          {/* <Button appearance="primary">Get new name list</Button> */}

          <div className="d-flex gap-3 justify-content-end">
            {/* <Checkbox
              className="group_membernumber"
              onChange={(e, check) => {
                if (check) {
                  setIsRandomEmoji(true);
                } else {
                  setIsRandomEmoji(false);
                }
              }}
            >
              Kí tự ngẫu nhiên
            </Checkbox> */}
            <Checkbox
              className="group_membernumber"
              onChange={(e, check) => {
                if (check) {
                  setIsRandomString(true);
                } else {
                  setIsRandomString(false);
                }
              }}
            >
              Nội dung ngẫu nhiên
            </Checkbox>
          </div>
        </div>
        <Input
          as="textarea"
          rows={8}
          value={pageNames}
          onChange={setPageNames}
          placeholder="Tên trang, mỗi trang 1 dòng"
        />
        <div className="d-flex justify-content-between align-items-center mt-2">
          <p className="group_membernumber m-0 mb-2">
            Giới thiệu trang (Basic +):
          </p>
        </div>
        <Input
          as="textarea"
          rows={5}
          onChange={setPageBios}
          value={pageBios}
          placeholder="Giới thiệu trang"
        />
      </div>
    </div>
  );
};

export default CreateMbPages;
