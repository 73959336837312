import { sendGet, sendPost, sendPut } from "./axiosClient";

export const getOrder = (data, page, page_size) =>
  sendGet(`/admins/orders?page_size=${page_size}&page=${page}`, data);
export const getOrderOverall = (data) =>
  sendGet("/admins/orders/overall", data);
export const setOrder = (data) =>
  sendPost("/admins/orders/donate-package", data);
export const updateOrder = (id, data) =>
  sendPut(`/admins/orders/update-state/${id}`, data);
