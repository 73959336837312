import React, { useContext, useEffect, useState } from "react";
import "./login.css";
import {
  Button,
  ButtonToolbar,
  Checkbox,
  Divider,
  Form,
  Input,
  Loader,
  Message,
  useToaster,
} from "rsuite";
// import Button as ButtonB from "bootstrap";
import { Button as ButtonB } from "react-bootstrap";
import { sendGet, sendPost } from "../../axios/axiosClient";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { SidebarContextProvider } from "../../context/SidebarContext";
import { register } from "../../axios/authApi";
import {
  encryptData,
  getAppKey,
  setAppKey,
  socket,
} from "../../context/socket";
import meeting_img from "../../assets/images/login/meeting.svg";
import logo from "../../assets/images/logo/logo-transparent-background.png";
import google_logo from "../../assets/images/login/google-logo.png";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

const Login = () => {
  const { haveSidebar, setHaveSidebar, haveFooter, setHaveFooter } = useContext(
    SidebarContextProvider
  );
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState();
  const [type, setType] = React.useState("info");
  const [placement, setPlacement] = React.useState("topCenter");
  const [messageText, setMessageText] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const toaster = useToaster();
  const [signUpState, setSignUpState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const message = (
    <Message showIcon type={type} closable>
      {messageText}
    </Message>
  );

  useEffect(() => {
    setHaveSidebar(false);
    localStorage?.clear();
    if (window.location.pathname.includes("admin")) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  });
  useEffect(() => {
    const a = window.location.pathname;
    const b = a.split("/");
    const c = b.indexOf("login");
    const d = b[c + 1];
    if (d && d.length !== 0) {
      setSignUpState(true);
      const e = { ...formValue, ref: d };
      setFormValue(e);
    }
  }, []);
  useEffect(() => {
    if (messageText !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setMessageText("");
  }, [messageText]);

  const handleAdminLogin = async () => {
    try {
      const res = await sendPost(`/admins/auth/login`, formValue);
      if (res.status === 200 || res.status === 201) {
        Cookies.set("token", res.data.data.original.data.token);
        Cookies.set("admin", 1);
        setMessageText("Đăng nhập thành công");
        // console.log(res.data.data.original.data.token);
        navigate("/admin-tools");
        setHaveSidebar(true);
        setHaveFooter(true);
      }
    } catch (error) {
      //   console.log(error?.response?.data?.error_msg);
      setMessageText(error?.response?.data?.error_msg);
    }
  };

  const handleLogin = async () => {
    try {
      formValue.socket_key = Cookies.get("clientId");
      const res = await sendPost(`/users/auth/login`, formValue);
      if (res.status === 200 || res.status === 201) {
        console.log();
        if (res.data.data.token) {
          Cookies.set("token", res.data.data.token);
          Cookies.remove("admin");
          setMessageText("Đăng nhập thành công");
          setHaveSidebar(true);
          setHaveFooter(true);
          navigate("/home");
        } else {
          if (res?.data?.data) {
            setMessageText(res?.data?.data);
          } else {
            setMessageText(
              "Tài khoản chưa verify. Hãy kiểm tra mail, verify tài khoản và đăng nhập lại."
            );
          }
        }
      }
    } catch (error) {
      //   console.log(error?.response?.data?.error_msg);
      if (error?.response?.data?.msg_error) {
        setMessageText(error?.response?.data?.msg_error);
      } else {
        setMessageText(error?.response?.data?.error_msg);
      }
    }
  };
  const handleRegister = async () => {
    setIsLoading(true);
    const input = {
      name: formValue?.name,
      email: formValue?.email,
      password: formValue?.password,
      ...(formValue?.ref &&
        formValue?.ref.length !== 0 && { ref: formValue?.ref }),
    };
    try {
      const res = await register(input);
      if (res.status == 200) {
        console.log(res.data);
        if (res?.data?.data) {
          setMessageText(res?.data?.data);
        } else {
          setMessageText("Hãy kiểm tra mail của bạn.");
        }
        setSignUpState(false);
        setIsLoading(false);
      }
    } catch (err) {
      // throw err;
      setIsLoading(false);
      setMessageText(err?.response?.data?.error_msg);
    }
    // console.log(formValue.ref)
  };

  const handleLoginGoogle = async () => {
    try {
      const res = await sendGet(`/users/auth/login/google`);
      if (res.status === 200) {
        window.location.href = res?.data?.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="loginCard">
        <div className="d-flex">
        <div
            className="col-12 col-lg-5 loginForm px-5"
            style={{ paddingTop: `${signUpState ? "5vh" : "8vh"}` }}
          >
            <div className="d-flex align-items-center gap-2">
              <img
                src={logo}
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
              <h3 className="fw-bold m-0">Amai MMo Tools</h3>
            </div>
            {signUpState ? (
              <>
                <div className="mt-4 mb-3">
                  <p className="fw-bold">
                    Chào mừng bạn đến với Amai MMo Tools
                  </p>
                  <ButtonB
                    className="btn btn-primary"
                    style={{ width: "100%" }}
                    onClick={handleLoginGoogle}
                  >
                    <img
                      src={google_logo}
                      alt=""
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "5px",
                      }}
                      className="me-2"
                    />
                    Đăng ký bằng Google
                  </ButtonB>
                </div>
                <Divider className="my-5">
                  hoặc đăng ký qua form dưới đây
                </Divider>
              </>
            ) : (
              <>
                <div className="mt-4 mb-3">
                  <ButtonB
                    className="btn btn-primary"
                    style={{
                      width: "100%",
                      backgroundColor: "#24BCD6",
                      borderColor: "#24BCD6",
                    }}
                    onClick={handleLoginGoogle}
                  >
                    {/* <span className="d-flex gap-2 align-items-center"> */}
                    <img
                      src={google_logo}
                      alt=""
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "5px",
                      }}
                      className="me-2"
                    />
                    Đăng nhập bằng Google
                    {/* </span> */}
                  </ButtonB>
                  {/* <GoogleOAuthProvider clientId="723861206102-14jidc0e3ptkhf5kgoe2p302b9ojniiu.apps.googleusercontent.com">
                    <GoogleLogin
                      // clientId="723861206102-14jidc0e3ptkhf5kgoe2p302b9ojniiu.apps.googleusercontent.com"
                      // buttonText="Login with Google"
                      onSuccess={responseGoogle}
                      onError={failureGoogle}
                      // same-origin-allow-popups
                      // cookiePolicy={"single_host_origin"}
                    />
                  </GoogleOAuthProvider> */}
                </div>
                <p className="mt-3">
                  Bạn có thể dùng ngay tài khoản Google để đăng nhập trực tiếp
                  Amai MMo Tools (không cần tạo mới tài khoản Amai MMo Tools)
                </p>
                <Divider className="my-5">hoặc</Divider>
              </>
            )}

            <Form fluid formValue={formValue} onChange={setFormValue}>
              {signUpState ? (
                <Form.Group controlId="name" className="mb-2">
                  <Form.ControlLabel>
                    Tên<Form.HelpText tooltip>Yêu cầu</Form.HelpText>
                  </Form.ControlLabel>
                  <Form.Control name="name" type="name" placeholder="Tên" />
                </Form.Group>
              ) : (
                <></>
              )}
              <Form.Group controlId="email" className="mb-2">
                <Form.ControlLabel>
                  Email<Form.HelpText tooltip>Yêu cầu</Form.HelpText>
                </Form.ControlLabel>
                <Form.Control name="email" type="email" placeholder="Email" />
              </Form.Group>
              <Form.Group controlId="password" className="mb-2">
                <Form.ControlLabel>
                  Mật khẩu<Form.HelpText tooltip>Yêu cầu</Form.HelpText>
                </Form.ControlLabel>
                <Form.Control
                  name="password"
                  type="password"
                  autoComplete="off"
                  placeholder="Mật khẩu"
                />
              </Form.Group>
              {signUpState ? (
                <Form.Group controlId="ref" className="mb-2">
                  <Form.ControlLabel>Mã giới thiệu</Form.ControlLabel>
                  <Form.Control
                    name="ref"
                    type="ref"
                    autoComplete="off"
                    placeholder="Mã giới thiệu"
                  />
                </Form.Group>
              ) : (
                <></>
              )}
              <Form.Group>
                <div className="d-flex justify-content-between align-items-center">
                  <Checkbox>
                    <small>Ghi nhớ đăng nhập</small>
                  </Checkbox>
                  <Link
                    to={window?.location.origin + `/forgot-password`}
                    style={{ textDecoration: "none" }}
                  >
                    <small>Quên mật khẩu?</small>
                  </Link>
                </div>
                <div className="">
                  <ButtonB
                    // appearance="primary"
                    className="fw-bold fs-6 btn btn-success"
                    style={{
                      width: "100%",
                      backgroundColor: "#45bf86",
                      borderColor: "#45bf86",
                    }}
                    onClick={() => {
                      if (isLoading) {
                        return;
                      }
                      if (signUpState) {
                        handleRegister();
                      } else {
                        if (isAdmin) {
                          handleAdminLogin();
                        } else {
                          handleLogin();
                        }
                      }
                    }}
                  >
                    {isLoading ? (
                      <Loader />
                    ) : signUpState ? (
                      "ĐĂNG KÝ"
                    ) : (
                      "ĐĂNG NHẬP"
                    )}
                  </ButtonB>
                  {/* <Button
                    // appearance="primary"
                    className="fw-bold fs-6"
                    style={{ width: "45%" }}
                    onClick={() => {
                      setSignUpState(!signUpState);
                    }}
                  >
                    {signUpState ? "BẠN ĐÃ CÓ TÀI KHOẢN" : "ĐĂNG KÝ"}
                  </Button> */}
                </div>
                <div className="mt-2">
                  {signUpState ? (
                    <>
                      <p className="text-center mb-0">
                        Bạn đã có tài khoản?
                        <span
                          className="ms-2 text-primary"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSignUpState(!signUpState);
                          }}
                        >
                          Đăng nhập tại đây
                        </span>
                      </p>
                    </>
                  ) : (
                    <>
                      <div className="d-flex justify-content-between">
                        <div>
                          <p className="my-1">
                            <small>Bạn muốn tạo tài khoản Amai MMo Tools</small>
                          </p>
                          <p
                            className="text-primary my-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSignUpState(!signUpState);
                            }}
                          >
                            Tạo tài khoản mới
                          </p>
                        </div>
                        {/* <div className="text-end">
                          <p className="my-1">
                            <small>Bạn chưa xác thực tài khoản?</small>
                          </p>
                          <Link
                            className="text-primary my-1"
                            style={{ cursor: "pointer" }}
                            to={"/verify"}
                          >
                            Xác thực tài khoản
                          </Link>
                        </div> */}
                      </div>
                    </>
                  )}
                </div>
              </Form.Group>
            </Form>
            {/* <div className="card card_login p-4">
          
        </div> */}
          </div>
          <div className="col-12 col-lg-7 d-none d-lg-block loginText">
            <div className="loginDescription">
              <p className="fw-bold h1" style={{ fontSize: "5rem" }}>
                80%
              </p>
              <p>
                Các cuộc thảo luận về thương hiệu của bạn xảy ra bên ngoài kênh
                riêng của bạn.
              </p>
              <p>
                Cùng Amai MMo Tools khám phá những dữ liệu internet bạn chưa
                biết tới.
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <img src={meeting_img} alt="" className="loginDescriptionImage" />
            </div>
          </div>
      
        </div>
      </div>
    </div>
  );
};

export default Login;
