import React, { useContext, useEffect } from "react";
import "./makeMeAdmin.css";
import ProfileHeading from "../../../../components/profileHeading/ProfileHeading";
import { Button, InputPicker, Input, Toggle } from "rsuite";
import Form from "react-bootstrap/Form";
import { Table } from "react-bootstrap";
import avatar_image from "../../../../assets/images/645fbc5da4a21.png";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import { socket } from "../../../../context/socket";
import Cookies from "js-cookie";
import { sendPost } from "../../../../axios/axiosClient";
const MakeMeAdmin = () => {
  const selectPicker = [
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 3,
      label: 3,
    },
    {
      value: 4,
      label: 4,
    },
    {
      value: 5,
      label: 5,
    },
  ];

  const { selectedExtension } = useContext(ExtensionContext);

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      console.log(message);
    });
  }, []);

  useEffect(() => {
    if (selectedExtension?.id !== Number(localStorage?.getItem("currEx"))) {
      getPublicGroup();
      return;
    }
    if (selectedExtension !== undefined) {
      getPublicGroup();
    }
  }, [selectedExtension]);

  const getPublicGroup = async () => {
    const input = {
      key_script: "scan_group",
      extension_key: selectedExtension?.key,
      key_tool: "claim_groups_as_admin",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="card p-3">
        <div className="d-flex">
          <div className="col-8 pe-4">
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td>1</td>
                  <td>
                    <div className="d-flex gap-3">
                      <div className="d-flex align-items-center">
                        <img
                          src={avatar_image}
                          alt="avatar"
                          className="toolHeader__avatar"
                        />
                      </div>
                      <div>
                        <p className="group_membernumber fw-bold m-0">
                          this is name
                        </p>
                        <p className="group_membernumber m-0">
                          0912371261829322
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>x</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="col-4">
            <ProfileHeading />
            <div className="mt-2">
              <Form.Group>
                <Form.Label>Threads :</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  {selectPicker?.map((item) => (
                    <option value={item?.value} key={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Delays (minute) :</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  {selectPicker?.map((item) => (
                    <option value={item?.value} key={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Button
                appearance="primary"
                color="orange"
                className="mt-2"
                style={{ width: "100%" }}
              >
                Start Claim
              </Button>
              <Input className="mt-2" as="textarea" rows={8} />
              <div className="d-flex justify-content-between mt-2">
                <span>Anonymous :</span>
                <Toggle />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakeMeAdmin;
