import { createContext } from "react";
import { io } from "socket.io-client";
import encrypt from "../commons/encrypt";
import Cookies from "js-cookie";

export const socket = io(process.env.REACT_APP_API_SERVICE_URL);
export const SocketContext = createContext();

export function getAppKey() {
  return window.localStorage.getItem("app-key") || encrypt.generateKey();
}
export function setAppKey(key) {
  return window.localStorage.setItem("app-key", key);
}

export function encryptData(data) {
  if (!!process.env.REACT_APP_ENCRYPT_API_ENABLE) {
    return data;
  } else {
    return { content: encrypt.encode(JSON.stringify(data)) };
  }
}
export function decryptData(data) {
  let dataContent = null;
  if (!!process.env.REACT_APP_ENCRYPT_API_ENABLE) {
    return data;
  } else {
    try {
      dataContent = JSON.parse(
        (data.content && encrypt.decode(data.content)) || "{}"
      );
    } catch (e) {
      console.log(e);
    }
    return dataContent;
  }
}

socket.on("connect", function () {
  console.log("connected");
  console.log(socket.id);
  Cookies.set("clientId", socket.id);
});
socket.on("info", function () {
  console.log("info");
});

socket.on("get_info", function () {
  // if (window.location.pathname === "/") {
  //   return;
  // }
  // console.log("hi");
  const key = getAppKey();
  Cookies.set("keySocket", key);
  setAppKey(key);
  socket.emit("info", encryptData({ key: key, type: 3 }));
});

export const TYPES = {
  BROWSER: 1,
  USER: 2,
};

//    start_group_scan_new_reaction(data) => {
//         const item = decryptData(data)
//         this.updateListScan(item.group_id, { ...this.listScan[item.group_id], ...{ status: 'Đang tiến hành', posts: {}, percent: 0 }})
//         this.groupCollapse = item.group_id
//         this.keyStatusScan = randomString()
//       },
