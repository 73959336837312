import React, { useEffect, useState } from "react";
import { sendGet, sendPost, sendPut } from "../../../axios/axiosClient";
import { Table } from "react-bootstrap";
import Cookies from "js-cookie";
import {
  Button,
  Form,
  Input,
  Message,
  Modal,
  Pagination,
  useToaster,
} from "rsuite";
import "./adminExtension.css";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

const AdminExtension = () => {
  const [listScripts, setListScripts] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openCreate, setOpenCreate] = React.useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [selectedValue, setSelectedValue] = useState();
  const [formValue, setFormValue] = useState();
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const getScript = async (page) => {
    try {
      let config = {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      };
      const res = await sendGet(
        `/admins/scripts?page_size=${limit}&page=${page}`,
        config
      );
      if (res.status === 200) {
        // console.log(res.data.data);
        setListScripts(res.data?.data?.data);
        setTotalItems(res.data?.data?.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getScript(activePage);
  }, [limit, activePage]);

  const handleChangeLimit = (e) => {
    setLimit(e);
    setActivePage(1);
  };

  const handleUpdate = async () => {
    try {
      const input = {
        script: selectedValue?.script,
        key: selectedValue?.key,
        description: selectedValue?.description,
      };
      console.log(selectedValue);
      const res = await sendPost(`/admins/scripts/${selectedValue?.id}`, input);
      if (res.status === 200) {
        console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreate = async () => {
    console.log(formValue);
    try {
      const res = await sendPost(`/admins/scripts`, formValue);
      if (res.status === 200) {
        console.log(res.data);
        setTextMessage("Add new script !!!");
        getScript();
        handleCloseCreate();
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
      //   console.log(error);
    }
  };

  return (
    <div className="admin__tool__container">
      {/* create script modal */}
      <Modal
        open={openCreate}
        onClose={handleCloseCreate}
        // style={{ background: "#F5ECD7" }}
      >
        <Modal.Header>
          <Modal.Title>CREATE NEW CRIPT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={formValue} onChange={setFormValue} fluid>
            <Form.Group controlId="key">
              <Form.ControlLabel>
                Key
                <Form.HelpText tooltip>Key is required</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="key" />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.ControlLabel>Description</Form.ControlLabel>
              <Form.Control name="description" />
            </Form.Group>

            <Form.Group controlId="script">
              <Form.ControlLabel>Script</Form.ControlLabel>
              <Form.Control rows={5} name="script" accepter={Textarea} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCreate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseCreate} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>

      {/* update modal */}
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{`UPDATE`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={selectedValue} onChange={setSelectedValue} fluid>
            <Form.Group controlId="key">
              <Form.ControlLabel>
                Key
                <Form.HelpText tooltip>Key is required</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="key" />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.ControlLabel>Description</Form.ControlLabel>
              <Form.Control name="description" />
            </Form.Group>

            <Form.Group controlId="script">
              <Form.ControlLabel>Script</Form.ControlLabel>
              <Form.Control rows={5} name="script" accepter={Textarea} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-end mb-2">
        <Button onClick={handleOpenCreate}>ADD NEW SCRIPT</Button>
      </div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Key</th>
            <th>Description</th>
            {/* <th>Script</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {listScripts?.map((item, index) => (
            <tr key={item?.id}>
              <td>{index + 1}</td>
              <td>{item?.key}</td>
              <td>{item?.description}</td>
              {/* <td style={{ maxWidth: "20rem" }}>{item?.script}</td> */}
              <th className="d-flex gap-2">
                <Button
                  appearance="primary"
                  onClick={() => {
                    setSelectedValue(item);
                    // console.log(item);
                    handleOpen();
                  }}
                >
                  Update
                </Button>
                <Button appearance="primary" style={{ background: "red" }}>
                  Delete
                </Button>
              </th>
            </tr>
          ))}
        </tbody>
      </Table>
      <div>
        {totalItems > 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"xs"}
            prev
            next
            ellipsis
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={5}
            activePage={activePage}
            onChangePage={setActivePage}
            onChangeLimit={handleChangeLimit}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AdminExtension;
