import React from "react";
import "./baoMat.css";

const BaoMat = () => {
  return (
    <div className="card p-2">
      <div className="text-center">
        <h3 className="fw-bold">Chính sách bảo mật</h3>
      </div>
      <div className="">
        <p style={{ fontStyle: "italic" }}>
          AMAI Agency hiểu rằng Quý khách quan tâm đến việc những thông tin của
          Quý khách sẽ được sử dụng và chia sẻ như thế nào. Chúng tôi rất coi
          trọng sự tin tưởng của Quý khách, vì vậy chúng tôi sẽ sử dụng những
          thông tin mà Quý khách cung cấp một cách cẩn thận và hợp lý.
        </p>
        <p style={{ fontStyle: "italic" }}>
          Dưới đây là chính sách bảo mật thông tin của AMAI Agency .Với việc
          truy cập vào website và các các sản phẩm liên quan của AMAI Agency,
          Quý khách đã đồng ý với mọi điểm trong chính sách bảo mật này.
        </p>
      </div>
      <div className="dieuKhoanBody">
        <div>
          <h5>1. Mục đích và phạm vi thu thập thông tin</h5>
          <p>
            Thông tin mà chúng tôi thu thập được từ khách hàng giúp chúng tôi có
            thể cải thiện trải nghiệm của Quý khách trên
            https://amaiagency.com/. Dưới đây là những loại thông tin mà chúng
            tôi thu thập:
          </p>
          <ul>
            <li>
              Thông tin do Quý khách cung cấp: Khi Quý khách thực hiện giao dịch
              hoặc đăng ký/ đăng nhập tại https://amaiagency.com/, tùy từng thời
              điểm, Quý khách phải cung cấp một số thông tin cần thiết nhằm giúp
              chúng tôi xác định phần thanh toán và giao hàng chính xác cho
              người nhận. Chúng tôi thu thập tất cả những thông tin mà Quý khách
              đăng tải hoặc các thao tác mà Quý khách thực hiện trên website
              https://amaiagency.com/. Quý khách có thể lựa chọn không cung cấp
              cho chúng tôi một số thông tin nhất định.
            </li>
            <li>
              Thông tin tự động: Chúng tôi ghi nhận và lưu trữ một số loại thông
              tin trong tất cả những lần Quý khách tương tác với
              https://amaiagency.com/. Ví dụ, chúng tôi sử dụng “cookies” giống
              như nhiều website khác để ghi nhận một số loại thông tin khi trình
              duyệt web của Quý khách truy cập vào https://amaiagency.com/ hoặc
              các quảng cáo và các nội dung khác được hiển thị trên
              https://amaiagency.com/ hoặc về https://amaiagency.com/ trên các
              website khác.
            </li>
            <li>
              Thiết bị di động: Khi Quý khách tải và sử dụng các ứng dụng cung
              cấp bởi https://amaiagency.com/, chúng tôi có thể cung cấp cho Quý
              khách các dịch vụ tiện ích dựa vào địa điểm của Quý khách như
              quảng cáo, đề xuất các địa điểm tổ chức sự kiện hoặc chương trình
              xung quanh. Nếu như Quý khách cảm thấy phiền và không muốn tiếp
              tục sử dụng các dịch vụ này thì hoàn toàn có thể tắt đi.
            </li>
            <li>
              E-mail: Để giúp chúng tôi sử dụng email hiệu quả hơn, chúng tôi
              thường nhận được xác nhận khi Quý khách mở email từ
              https://amaiagency.com/ nếu máy tính của Quý khách hỗ trợ tính
              năng này.
            </li>
            <li>
              Thông tin từ các nguồn khác: Chúng tôi có thể ghi nhận các thông
              tin về Quý khách từ nhiều nguồn khác và bổ sung chúng vào thông
              tin tài khoản của chúng tôi.
            </li>
          </ul>
        </div>
        <div>
          <h5>2. Phạm vi sử dụng thông tin</h5>
          <p>
            Thông tin về khách hàng là một phần dữ liệu quan trọng để
            https://amaiagency.com/ có thể khai thác và cải thiện trải nghiệm
            của khách hàng. Hoạt động kinh doanh của https://amaiagency.com/
            không bao gồm việc bán các thông tin đó cho bên thứ ba. Chúng tôi
            chỉ cung cấp thông tin khách hàng cho các bên được liệt kê dưới đây
            hoặc cho bên thứ ba nhằm đảm bảo quyền lợi của khách hàng theo những
            cam kết bảo mật của https://amaiagency.com/.
          </p>
          <ul>
            <li>
              Các đối tác liên kết mà https://amaiagency.com/ không kiểm soát:
              Chúng tôi liên kết chặt chẽ với rất nhiều đối tác. Một trong những
              đối tác mà Quý khách có thể thấy là các đơn vị vận chuyển. Nói
              cách khác, khi một bên thứ ba liên quan với các giao dịch của Quý
              khách, chúng tôi sẽ chia sẻ những thông tin khách hàng liên quan
              đến giao dịch đó cho bên thứ ba tương ứng.
            </li>
            <li>
              Nhà cung cấp dịch vụ độc lập: Chúng tôi sử dụng những tổ chức và
              cá nhân khác để thay mặt https://amaiagency.com/ thực hiện một số
              chức năng như cung cấp dịch vụ thanh toán qua thẻ… Họ có thể tiếp
              cận những thông tin cá nhân cần thiết để hoàn thành công việc của
              họ, nhưng không được quyền sử dụng các thông tin cá nhân đó để
              phục vụ cho mục đích khác.
            </li>
            <li>
              Thông tin quảng cáo, khuyến mãi: Tùy theo chương trình hợp tác với
              các đối tác, https://amaiagency.com/ có thể thay mặt các đối tác
              liên kết gửi các thông tin quảng cáo, khuyến mãi đến một số nhóm
              khách hàng đặc biệt. Tuy nhiên, chúng tôi không cung cấp tên và
              địa chỉ của Quý khách cho đối tác. Nếu Quý khách không muốn nhận
              các thông tin này, Quý khách vui lòng chọn Unsubscribe ở cuối
              email mà Quý khách nhận được.
            </li>
            <li>
              Bảo vệ https://amaiagency.com/ và các đối tác khác: Chúng tôi sẽ
              cung cấp các thông tin tài khoản và các thông tin cá nhân trong
              trường hợp chúng tôi tin rằng sự cung cấp thông tin này không vi
              phạm pháp luật; bị bắt buộc hoặc phù hợp với các Thỏa Thỏa thuận
              sử dụng và các thỏa thuận khác; hoặc bảo vệ quyền, tài sản, và sự
              an toàn của https://amaiagency.com/, các khách hàng khác và các
              đối tượng khác. Sự cung cấp này có thể bao gồm việc trao đổi thông
              tin với các công ty, tổ chức nhằm chống lại những gian lận và giảm
              thiểu rủi ro. Tuy nhiên, sự cung cấp này không bao gồm việc bán,
              cho thuê, chia sẻ, hoặc công bố các thông tin cá nhân vì mục đích
              thương mại.
            </li>
            <li>
              Với sự đồng ý của Quý khách: Một số trường hợp khác với những
              trường hợp được đề cập ở trên, Quý khách sẽ nhận được thông báo về
              việc thông tin của Quý khách có thể được chia sẻ cho bên thứ ba và
              Quý khách có quyền lựa chọn cho phép sự chia sẻ đó hay không.
            </li>
          </ul>
        </div>
        <div>
          <h5>3. Thời gian lưu trữ thông tin</h5>
          <p>
            Thông tin đăng ký của Khách hàng sẽ được lưu trữ tại
            https://amaiagency.com/ trong thời gian 24 tháng, theo quy định Pháp
            luật Việt Nam.
          </p>
        </div>
        <div>
          <h5>4. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân</h5>
          <p>Website: https://amaiagency.com/</p>
          <p>Địa chỉ: 186 Nguyễn Xiển, Thanh Xuân, Hà Nội</p>
        </div>
        <div>
          <h5>
            5. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ
            liệu cá nhân của mình.
          </h5>
          <p>
            – Quý khách có quyền truy cập vào các thông tin về tài khoản của Quý
            khách và sự tương tác của Quý khách với https://amaiagency.com/ với
            mục đích xem, chỉnh sửa và cập nhật thông tin.
          </p>
          <p>
            – Như đề cập ở trên, Quý khách luôn luôn có sự lựa chọn về việc có
            cung cấp thông tin cho chúng tôi không, ngay cả trong trường hợp
            những thông tin đấy là cần thiết để https://amaiagency.com/ xử lý
            đơn hàng của Quý khách như Tài khoản của tôi, Đơn hàng của tôi.
          </p>
          <p>
            – Quý khách có thể thay đổi phần Lựa chọn thông tin của Quý khách để
            ưu tiên những thông tin mà Quý khách muốn nhận.
          </p>
          <p>
            – Nếu Quý khách không muốn nhận email từ chúng tôi, Quý khách có thể
            chọn Unsubscribe ở cuối email mà chúng tôi gửi Quý khách.
          </p>
          <p>
            – Tính năng Trợ Giúp (Help) ở hầu hết các trình duyệt đều cho phép
            Quý khách ngăn trình duyệt của Quý khách chấp nhận cookies mới,
            thông báo cho Quý khách biết khi trình duyệt của Quý khách nhận
            cookies mới hoặc khóa chức năng nhận cookies. Bên cạnh đó, Quý khách
            cũng có thể khóa hoặc xóa các dữ liệu tương tự được sử dụng bởi các
            tính năng bổ sung của trình duyệt (add-on) bằng cách thay đổi cài
            đặt của các tính năng bổ sung hoặc truy cập vào website của nhà cung
            cấp tính năng đó. Do cookies giúp Quý khách tận dụng được rất nhiều
            tính năng đặc biệt mà https://amaiagency.com/ thiết kế cho Quý
            khách, nên chúng tôi đề nghị Quý khách cho phép trình duyệt của Quý
            khách nhận cookies.
          </p>
        </div>
        <div>
          <h5>Cam kết bảo mật thông tin cá nhân khách hàng</h5>
          <p>
            – Khi Quý khách gửi thông tin cá nhân của Quý khách cho chúng tôi,
            Quý khách đã đồng ý với các điều khoản mà chúng tôi đã nêu ở trên,
            https://amaiagency.com/ cam kết bảo mật thông tin cá nhân của khách
            hàng bằng mọi cách thức có thể. Chúng tôi sẽ sử dụng nhiều công nghệ
            bảo mật thông tin khác nhau nhằm bảo vệ thông tin này không bị truy
            lục, sử dụng hoặc tiết lộ ngoài ý muốn.
          </p>
          <p>
            – Tuy nhiên do hạn chế về mặt kỹ thuật, không một dữ liệu nào có thể
            được truyền trên đường truyền internet mà có thể được bảo mật 100%.
            Do vậy, chúng tôi không thể đưa ra một cam kết chắc chắn rằng thông
            tin Quý khách cung cấp cho chúng tôi sẽ được bảo mật một cách tuyệt
            đối an toàn, và chúng tôi không thể chịu trách nhiệm trong trường
            hợp có sự truy cập trái phép thông tin cá nhân của Quý khách như các
            trường hợp Quý khách tự ý chia sẻ thông tin với người khác….
          </p>
          <p>
            – Vì vậy, https://amaiagency.com/ cũng khuyến cáo Quý khách nên bảo
            mật các thông tin liên quan đến mật khẩu truy xuất của Quý khách và
            không nên chia sẻ với bất kỳ người nào khác. Nếu sử dụng máy tính
            chung nhiều người, Quý khách nên đăng xuất, hoặc thoát hết tất cả
            cửa sổ Website đang mở.
          </p>
          <p>
            – Đối với thông tin liên quan đến thẻ tín dụng thanh toán: chúng tôi
            chỉ cung cấp 4 chữ số cuối của số thẻ tín dụng của Quý khách khi xác
            nhận đơn hàng. Tất nhiên, chúng tôi sẽ cung cấp đầy đủ số thẻ tín
            dụng của Quý khách cho công ty thẻ tín dụng phù hợp trong quá trình
            xử lý đơn hàng.
          </p>
        </div>
        <div>
          <h5>Điều kiện sử dụng, thông báo, và sửa đổi</h5>
          <p>
            – Khi Quý khách truy cập vào https://amaiagency.com/ , sự truy cập
            của Quý khách và bất kỳ vấn đề phát sinh nào liên quan đến sự bảo
            mật đều tuân theo các điều khoản trong Chính sách bảo mật này và
            Thỏa thuận sử dụng bao gồm giới hạn thiệt hại, cách giải quyết các
            vấn đề phát sinh, và việc áp dụng luật Việt Nam.
          </p>
          <p>
            – Nếu Quý khách có bất kỳ câu hỏi nào liên quan đến sự bảo mật tại
            https://amaiagency.com/ , vui lòng liên lạc với chúng tôi. Do chúng
            tôi liên tục cải thiện dịch vụ và sản phẩm để phục vụ Quý khách tốt
            hơn, nên Chính sách bảo mật và Thỏa thuận sử dụng sẽ thường xuyên
            được thay đổi và cập nhật. Chúng tôi có thể email thông báo cho Quý
            khách về những thay đổi trong những chính sách.
          </p>
          <p>
            – Tuy nhiên, chúng tôi cũng khuyến khích Quý khách thường xuyên theo
            dõi trên website của https://amaiagency.com/ để cập nhật với những
            thay đổi trong chính sách của chúng tôi. Trừ trường hợp có văn bản
            với nội dung khác, Chính sách bảo mật hiện tại của chúng tôi được áp
            dụng cho tất cả những thông tin mà chúng tôi có về Quý khách và tài
            khoản của Quý khách.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BaoMat;
