import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Form,
  IconButton,
  Input,
  InputGroup,
  Loader,
  Message,
  Pagination,
  useToaster,
  Modal,
} from "rsuite";
import { GrGroup } from "react-icons/gr";
import { BsFire } from "react-icons/bs";
import { FaRegStopCircle } from "react-icons/fa";
import { PiMicrosoftExcelLogoDuotone } from "react-icons/pi";
import { AiOutlineCheck, AiOutlineClose, AiOutlineCopy } from "react-icons/ai";
import { Tab } from "bootstrap";
import { Table } from "react-bootstrap";
import Cookies from "js-cookie";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import { sendPost } from "../../../../axios/axiosClient";
import { socket } from "../../../../context/socket";
import { CSVLink } from "react-csv";
import "./getGroupsFromUid.css";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import LinearProgress from "@mui/material/LinearProgress";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
import axios from "axios";
import { CheckFunction } from "../../../../commons/function/CheckURL";
import { DataContext } from "../../../../context/DataContext";
import { MdFileDownload } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";

const GetGroupsFromUid = () => {
  const { selectedExtension } = useContext(ExtensionContext);
  const [uid, setUid] = useState("");
  const [listGroups, setListGroups] = useState([]);
  // const [accessToken, setAccessToken] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [isStop, setIsStop] = useState(true);
  const [isUnsetData, setIsUnsetData] = useState(false);
  const [filterDownload, setFilterDownload] = useState([]);
  const [downloadModal, setDownloadModal] = useState(false);
  const { keepFieldsDownload } = useContext(DataContext);
  const [filterPosts, setFilterPosts] = useState([
    "CAN_POST_AFTER_APPROVAL",
    "CAN_POST_WITHOUT_APPROVAL",
  ]);

  useEffect(() => {
    setActivePage(1);
  }, [search]);

  const [sortingMember, setSortingMember] = useState("asc");

  const handleSorting = (e) => {
    setSortingMember(e);

    if (e === "asc") {
      listGroups.sort(
        (a, b) =>
          convertMemberNumber(a.group_member_profiles) -
          convertMemberNumber(b.group_member_profiles)
      );
    } else {
      listGroups.sort(
        (a, b) =>
          convertMemberNumber(b.group_member_profiles) -
          convertMemberNumber(a.group_member_profiles)
      );
    }
  };

  const convertMemberNumber = (memberString) => {
    let value;
    if (memberString.includes("members")) {
      value = memberString.replace(" members", "").trim();
    } else if (memberString.includes("member")) {
      value = memberString.replace("member", "").trim();
    } else if (memberString.includes("thành viên")) {
      value = memberString.replace("thành viên", "").trim();
    }
    if (value.includes(",")) {
      value = value.replace(",", ".");
    }
    if (value.endsWith("K")) {
      return parseFloat(value.replace("K", "")) * 1000;
    } else if (value.endsWith("M")) {
      return parseFloat(value.replace("M", "")) * 1000000;
    } else if (value.endsWith("nghìn")) {
      return parseFloat(value.replace("nghìn", "")) * 1000;
    } else if (value.endsWith("triệu")) {
      return parseFloat(value.replace("triệu", "")) * 1000000;
    } else {
      return value;
    }
  };

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  useEffect(() => {
    let data = [];
    socket.on("do_task_response", (message) => {
      // console.log(message);
      console.log(message, "message");

      switch (message?.params?.key_script) {
        case "scan_group_from_friend":
          if (message?.data?.length > 0 && typeof message.data === "object") {
            // data = message?.data?.map((item) => ({
            //   id: item?.id,
            //   name: item?.name,
            //   viewer_post_status: item?.viewer_post_status,
            //   visibility: item?.visibility,
            //   member_number: item?.group_member_profiles?.count,
            // }));
            data = [...data, ...message?.data];

            setListGroups(data);
          }
          if (message?.data?.error_msg) {
            setTextMessage(message?.data?.error_msg);
          }
          if (message?.done_all === true) {
            setIsLoadingData(false);
            setIsStop(true);
          }
          break;
        case "force_stop":
          setIsStop(true);
          break;
        default:
          break;
      }
      //
    });
  }, [isUnsetData]);

  useEffect(() => {
    setActivePage(1);
  }, [limit]);

  const getPublicGroup = async (id) => {
    if (uid.trim()?.length === 0) {
      setTextMessage("Bạn chưa điền UID");
      return;
    }
    setListGroups([]);
    const input = {
      key_script: "scan_group_from_friend",
      extension_key: selectedExtension?.key,
      key_tool: "get_public_groups_from_any_fb_profile",
      params: {
        key_socket: Cookies.get("keySocket"),
        findUid: id,
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
      }
    } catch (error) {
      console.log(error);
      setIsLoadingData(false);
    }
  };

  const getUID = async () => {
    setIsStop(false);
    setIsUnsetData(!isUnsetData);
    if (uid.trim() === "") {
      setTextMessage("Bạn chưa nhập đường dẫn.");
      return;
    }
    if (CheckFunction(uid)) {
      setIsLoadingData(true);
      axios
        .post("https://uid.bufflike.net/fbid", {
          link: uid,
        })
        .then(function (response) {
          if (response.data.status) {
            getPublicGroup(response?.data?.fbid);
          } else {
            setIsLoadingData(false);
            setTextMessage(response?.data?.message);
          }
        })
        .catch(function (error) {
          setTextMessage("Có lỗi xảy ra.");
          setIsLoadingData(false);
        });
    } else {
      setIsLoadingData(true);
      getPublicGroup(uid);
    }
  };
  const handleStop = async () => {
    const input = {
      key_script: "force_stop",
      extension_key: selectedExtension?.key,
      key_tool: "force_stop",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
      }
    } catch (error) {}
  };

  // useEffect(() => {
  //   if (selectedExtension !== undefined) {
  //     getAccessToken();
  //   }
  // }, [selectedExtension]);

  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <Modal
        backdrop="static"
        role="alertdialog"
        keyboard={false}
        open={downloadModal}
        style={{ paddingTop: "5rem" }}
        onClose={() => setDownloadModal(false)}
      >
        <Modal.Header>
          <Modal.Title className="fw-bold">
            Chọn các mục bạn muốn tải
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CheckboxGroup
            name="checkbox-group"
            value={filterDownload}
            onChange={(value) => {
              setFilterDownload(value);
            }}
          >
            <Checkbox value="id">ID</Checkbox>
            <Checkbox value="name">Tên</Checkbox>
            <Checkbox value="privacy_info">Trạng thái</Checkbox>
            <Checkbox value="group_member_profiles">Thành viên</Checkbox>
          </CheckboxGroup>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <CSVLink
            data={listGroups
              ?.map((item) => ({
                ID: item?.id,
                NAME: item?.name,
                PRIVACY_INFO: item?.privacy_info,
                GROUP_MEMBER_PROFILES: item?.group_member_profiles,
              }))
              ?.map((item) => keepFieldsDownload(item, filterDownload))}
            filename="List_Group.csv"
            onClick={() => {
              if (!listGroups?.length) {
                setTextMessage("Không có dữ liệu để tải về");
                return false; // 👍🏻 You are stopping the handling of component
              }
            }}
          >
            <Button appearance="primary">
              <SiMicrosoftexcel className="me-2" />
              <span>Tải xuống</span>
            </Button>
          </CSVLink>
        </Modal.Footer>
      </Modal>
      <div className="card p-3">
        <div className="mb-3">
          <ToolHeading toolType={window.location.pathname} />
        </div>
        <div className="d-flex gap-2">
          <InputGroup>
            <InputGroup.Addon>
              <a href="https://uid.bufflike.net/" target="_blank">
                <Form.HelpText tooltip className="m-0">
                  Click vào đây để lấy ID
                </Form.HelpText>
              </a>
            </InputGroup.Addon>
            <Input
              placeholder="Đường dẫn hoặc UID người dùng"
              onChange={setUid}
            />
          </InputGroup>

          {isStop === true ? (
            <Button
              // disabled={isLoadingData}
              appearance="primary"
              onClick={getUID}
              // onClick={getPublicGroup}
            >
              <BsFire className="fs-5 me-2" />
              <span className="fw-bold">TÌM KIẾM</span>
            </Button>
          ) : (
            <Button
              appearance="primary"
              onClick={handleStop}
              style={{ background: "red" }}
            >
              <FaRegStopCircle className="fs-5 me-2" />
              <span className="fw-bold">DỪNG LẠI</span>
            </Button>
          )}
        </div>
        <div className="mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2">
              {/* <Button appearance="primary" color="green">
                <PiMicrosoftExcelLogoDuotone />
                <span>EXPORT</span>
              </Button> */}
              <Button
                appearance="primary"
                style={{ padding: "0.2rem 0.4rem" }}
                onClick={() => {
                  setDownloadModal(true);
                }}
              >
                <MdFileDownload style={{ fontSize: 21 }} />
              </Button>
            </div>
            <div style={{ fontSize: ".85rem" }} className="fw-bold">
              Tổng groups: {listGroups?.length}
            </div>
            <div className="d-flex gap-2">
              {/* <CheckboxGroup
                inline
                name="checkboxList"
                value={filterPosts}
                onChange={(value) => {
                  // console.log(value, "onChange");
                  setFilterPosts(value);
                  setActivePage(1);
                }}
                style={{ fontSize: ".8rem", whiteSpace: "nowrap" }}
              >
                <Checkbox value={"CAN_POST_AFTER_APPROVAL"}>
                  Đăng bài sau khi được duyệt
                </Checkbox>
                <Checkbox value={"CAN_POST_WITHOUT_APPROVAL"}>
                  Đăng bài không cần duyệt
                </Checkbox>
              </CheckboxGroup> */}
              <Input
                placeholder="Tìm kiếm..."
                value={search}
                onChange={setSearch}
              />
            </div>
          </div>
          {/* function */}
          <div className="mt-3">
            <div></div>
            <Table responsive>
              <thead>
                <tr className="group_membernumber oneLine">
                  <th style={{ width: "3rem" }}>#</th>
                  <th>UID</th>
                  <th style={{ width: "40%" }}>Tên</th>

                  <th>Nhóm công khai</th>
                  <th>
                    Số lượng thành viên{" "}
                    {sortingMember === "asc" ? (
                      <BiSortUp
                        className="sortingNumber"
                        onClick={() => {
                          handleSorting("des");
                        }}
                      />
                    ) : (
                      <BiSortDown
                        className="sortingNumber"
                        onClick={() => {
                          handleSorting("asc");
                        }}
                      />
                    )}
                  </th>
                </tr>
                {!isStop ? (
                  <tr>
                    <th colSpan={10}>
                      <LinearProgress style={{ width: "100%" }} />
                    </th>
                  </tr>
                ) : (
                  <></>
                )}
              </thead>
              <tbody>
                {listGroups
                  // ?.filter((item) =>
                  //   filterPosts?.some(
                  //     (status) => status === item?.viewer_post_status
                  //   )
                  // )
                  ?.filter((item) =>
                    item?.name.toLowerCase().includes(search.toLowerCase())
                  )
                  ?.map((item, key) => (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>{item?.id}</td>
                      <td>
                        <a
                          href={`https://www.facebook.com/${item?.id}`}
                          target="_blank"
                          className="blackLink"
                        >
                          {item?.name}
                        </a>
                      </td>

                      <td>
                        {item?.privacy_info === "Public group" ? (
                          <AiOutlineCheck className="text-success" />
                        ) : (
                          <AiOutlineClose className="text-danger" />
                        )}
                      </td>
                      <td>{item?.group_member_profiles}</td>
                    </tr>
                  ))
                  ?.splice((activePage - 1) * limit, limit)}
              </tbody>
            </Table>
            {listGroups?.length > 0 ? (
              <Pagination
                layout={["limit", "-", "pager"]}
                size={"xs"}
                prev
                next
                // first
                // last
                ellipsis
                boundaryLinks
                total={
                  listGroups
                    // ?.filter((item) =>
                    //   filterPosts?.some(
                    //     (status) => status === item?.viewer_post_status
                    //   )
                    // )
                    ?.filter((item) =>
                      item?.name.toLowerCase().includes(search.toLowerCase())
                    )?.length
                }
                limit={limit}
                limitOptions={[10, 30, 50, 100, 500]}
                maxButtons={5}
                activePage={activePage}
                onChangePage={setActivePage}
                onChangeLimit={setLimit}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetGroupsFromUid;
