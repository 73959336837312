import React, { useContext, useEffect, useState } from "react";
import {
  AiOutlineLike,
  AiOutlineSearch,
  AiOutlineShareAlt,
  AiOutlineUpload,
} from "react-icons/ai";
import {
  Checkbox,
  DateRangePicker,
  IconButton,
  Input,
  InputGroup,
  Button,
  Message,
  useToaster,
  InputPicker,
  Form,
  Loader,
  CheckboxGroup,
  RadioGroup,
  Radio,
  ButtonToolbar,
  Pagination,
} from "rsuite";
import { socket } from "../../../../context/socket";
import { sendPost } from "../../../../axios/axiosClient";
import Cookies from "js-cookie";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import "./pagePostsAnalyzer.css";
import { Form as bForm } from "react-bootstrap";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { HiPhotograph } from "react-icons/hi";
import { BiCommentDetail, BiSolidPhotoAlbum } from "react-icons/bi";
import moment from "moment";
import SelectedPageNotFromAd from "../../../../components/selectedPage/SelectedPageNotFromAd";
import SelectedPage from "../../../../components/selectedPage/SelectedPage";
import LinearProgress from "@mui/material/LinearProgress";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
import { DataContext } from "../../../../context/DataContext";
import { useLocation } from "react-router-dom";
import { listIcon } from "../../../../commons/data/icon";
import no_image from "../../../../assets/images/default_thumbnail.png";
import axios from "axios";
import { CheckFunction } from "../../../../commons/function/CheckURL";
const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
let resultClone = [];
let resultCloneCount = 1;
const PagePostsAnalyzer = () => {
  // const [toolsTitle, setToolsTitle] = useState('Page Analytics Facebooks');
  const { selectedExtension } = useContext(ExtensionContext);

  const { setListPagesNonAT, listPagesNonAT, isSendScript } =
    useContext(DataContext);
  const [pageId, setPageId] = useState("");
  const location = useLocation();
  const toolType = location.pathname;
  const [pagePosts, setPagePosts] = useState([]);
  const [posts, setPosts] = useState([]);
  const current = new Date();
  const [timer, setTimer] = useState([
    new Date().setMonth(current.getMonth() - 1),
    current,
  ]);
  const [checkGroups, setCheckGroups] = useState([]);
  const [listPages, setListPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState();
  const [isLoadingPosts, setIsLoadingPosts] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [filterPosts, setFilterPosts] = useState([]);
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [postingId, setPostingId] = useState("");
  const [postingList, setPostingList] = useState([]);
  const [isRandomString, setIsRandomString] = useState(false);
  const [checkRandom, setCheckRandom] = useState([]);
  const [isErr, setIsErr] = useState(false);
  const [intervalId, setIntervalId] = useState();
  const [formClone, setFormClone] = useState({
    delay: 60,
    addingText: "",
  });

  const filterValue = [
    {
      value: "created_time",
      label: "Time",
    },
    {
      value: "like_count",
      label: "Like",
    },
    {
      value: "share_count",
      label: "Share",
    },
    {
      value: "comment",
      label: "Comment",
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState([]);

  useEffect(() => {
    if (postingId !== "") {
      setPostingList((old) => [...old, postingId]);
    }
  }, [postingId]);

  useEffect(() => {
    setSelectedFilter([sortColumn, sortOrder]);
    switch (sortOrder) {
      case "asc":
        pagePosts.sort((a, b) => a[sortColumn] - b[sortColumn]);
        break;
      case "des":
        pagePosts.sort((a, b) => b[sortColumn] - a[sortColumn]);
        break;
      default:
        break;
    }
  }, [sortColumn, sortOrder]);

  // useEffect(() => {
  //   console.log(checkGroups);
  // }, [checkGroups]);

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      switch (message?.params?.key_script) {
        case "get_list_post_in_page":
          if (message?.error) {
            setTextMessage(message?.error);
          } else if (message?.data?.length > 0) {
            setPosts(message?.data);
          }
          if (message?.done_all) {
            setIsLoadingPosts(false);
          }
          break;
        // case "get_pages_is_admin":
        //   const pages = message?.data?.page?.map((item) => ({
        //     value: item?.id,
        //     label: item?.name,
        //   }));
        //   if (pages?.length !== 0) {
        //     setListPagesNonAT(message?.data?.page);
        //     setListPages(pages);
        //     setSelectedPage(pages[0]?.value);
        //   }
        //   break;
        case "get_all_pages_is_admin":
          if (message?.data?.length > 0) {
            setListPagesNonAT(message?.data);
            setListPages(message?.data);
            setSelectedPage(message?.data[0]?.id);
          } else {
            if (message?.data?.err) {
              setIsErr(true);
            }
            setSelectedPage("");
          }
          break;
        case "post_to_page":
          if (message?.data?.status === "done") {
            setPostingId(message?.data?.post_id);
            resultClone.push(message);
            if (resultCloneCount <= 1) {
              setIsCloning(false);
            }
            if (
              resultCloneCount !== 0 &&
              resultClone.length == resultCloneCount
            ) {
              setTextMessage("Đăng bài viết thành công !!!");
              // setIsCloning(false);
            }
          } else {
            setTextMessage("Đăng thất bại.");
            // setIsCloning(false);
          }
          resultCloneCount--;

          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    if (posts?.length > 0) {
      setPagePosts((old) => [...old, ...posts]);
    }
  }, [posts]);

  const getPagePost = async (id) => {
    setPagePosts([]);
    setCheckGroups([]);

    const input = {
      key_script: "get_list_post_in_page",
      extension_key: selectedExtension?.key,
      key_tool: "page_posts_analyzer_viral_finder",
      params: {
        key_socket: Cookies.get("keySocket"),
        pageId: id,
        since: moment(timer[0])?.format("X"),
        until: moment(timer[1])?.format("X"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setIsLoadingPosts(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getId = async () => {
    // https://www.facebook.com/TheNewMentor
    if (timer?.length !== 2) {
      setTextMessage("Bạn chưa chọn thời gian");
      return;
    } else if (pageId.trim() === "") {
      setTextMessage("Bạn chưa điền liên kết/ID trang.");
      return;
    }
    setIsLoadingPosts(true);
    if (CheckFunction(pageId.trim())) {
      axios
        .post("https://uid.bufflike.net/fbid", {
          link: pageId,
        })
        .then(function (response) {
          if (response.data.status) {
            getPagePost(response?.data?.fbid);
          } else {
            setTextMessage(response?.data?.message);
            setIsLoadingPosts(false);
          }
        })
        .catch(function (error) {
          setTextMessage("Có lỗi xảy ra.");
          setIsLoadingPosts(false);
        });
    } else {
      getPagePost(pageId.trim());
    }
  };

  const clonePost = async (content, image) => {
    let stringLength = 7;
    let randomString = content + "\n" + formClone?.addingText + "\n";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    if (formClone?.randomCheckbox?.includes("text")) {
      for (let i = 0; i < stringLength; i++) {
        randomString += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
    }
    if (formClone?.randomCheckbox?.includes("icon")) {
      randomString +=
        " " + listIcon[Math.floor(Math.random() * listIcon.length)];
    }
    if (formClone?.randomCheckbox?.includes("time")) {
      const today = new Date();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      randomString += " " + time;
    }
    let input = {};
    // if (formClone?.randomCheckbox?.includes('text')) {
    input = {
      key_script: "post_to_page",
      extension_key: selectedExtension?.key,
      key_tool: "page_posts_deep_clone",
      params: {
        key_socket: Cookies.get("keySocket"),
        // page_id: pageId,
        page_id: selectedPage,
        content: randomString?.trim(),
        urlImage: image,
        ranges: [],
      },
    };
    // }
    // else {
    //   input = {
    //     key_script: "post_to_page",
    //     extension_key: selectedExtension?.key,
    //     key_tool: "page_posts_deep_clone",
    //     params: {
    //       key_socket: Cookies.get("keySocket"),
    //       // page_id: pageId,
    //       page_id: selectedPage,
    //       content:
    //         formClone?.addingText !== ""
    //           ? `${content + "\n" + formClone?.addingText}`
    //           : content,
    //       urlImage: image,
    //       ranges: [],
    //     },
    //   };
    // }

    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setIsCloning(true);
      }
      // console.log(input);
      // setIsCloning(false);
    } catch (error) {
      console.log(error);
      setTextMessage("Có lỗi xảy ra.");
      setIsCloning(false);
    }
  };

  const handleClonePost = () => {
    setIsCloning(true);
    let data = [];
    checkGroups?.map((item) => {
      data.push(pagePosts?.find((x) => x.id === item));
    });
    let b = 0;
    resultClone = [];
    resultCloneCount = data?.length;
    if (b < data?.length) {
      let image = [];
      image = checkPost(data[b]);
      clonePost(data[b]?.content, image);
      ++b;
      const id = setInterval(() => {
        if (b < data?.length) {
          image = checkPost(data[b]);
          clonePost(data[b]?.content, image);
          ++b;
        } else {
          setIsCloning(false);
          clearInterval(id);
        }
      }, formClone?.delay * 1000);
      setIntervalId(id);
    } else {
      setIsCloning(false);
      setTextMessage("Bạn cần chọn bài viết để đăng.");
    }
  };

  const handleStop = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    setIsCloning(false);
    setTextMessage("Dừng đăng bài viết");
  };

  const checkPost = (e) => {
    switch (e?.attachments[0]?.type) {
      case "photo":
        return [e?.picture];
      case "album":
        return e?.attachments[0]?.image?.map((item) => item?.src);
      case "video_autoplay":
        return [e?.picture];
      default:
        break;
    }
  };

  const postType = (severity) => {
    switch (severity) {
      case "photo":
        return <HiPhotograph />;
      case "album":
        return <BiSolidPhotoAlbum />;
      case "video_autoplay":
        return <BsFillCameraVideoFill />;
      default:
        return null;
    }
  };

  const getUserPage = async () => {
    let keyscript = "";
    let keytool = "";
    if (toolType === "/page-posts-clone") {
      keyscript = "get_all_pages_is_admin";
      keytool = "page_posts_deep_clone";
    }
    // else if (toolType === "/page-posts-analyzer") {
    //   keyscript = "get_pages_is_admin";
    //   keytool = "get_friend";
    // }
    const input = {
      key_script: keyscript,
      extension_key: selectedExtension?.key,
      key_tool: keytool,
      params: {
        key_socket: Cookies.get("keySocket"),
        // page_id: pageId,
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
  };

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  useEffect(() => {
    if (listPagesNonAT?.length > 0) {
      setListPages(listPagesNonAT);
      setSelectedPage(listPagesNonAT[0]?.id);
    } else if (
      selectedExtension?.key &&
      toolType !== "/page-posts-analyzer" &&
      isSendScript
    ) {
      getUserPage();
    }
  }, [selectedExtension]);

  return (
    <div
      style={{ width: "100%", margin: "0 auto" }}
      className={`${toolType === "/page-posts-clone" ? "d-flex gap-4" : ""}`}
    >
      <div className={`${toolType === "/page-posts-clone" ? "col-9" : ""}`}>
        <div className={`card p-3 text-center`}>
          <ToolHeading toolType={window.location.pathname} />
          <div className="d-flex gap-3 justify-content-center mt-4">
            <DateRangePicker
              onChange={setTimer}
              value={timer}
              format="dd-MM-yyy"
            />
            <InputGroup style={{ width: "60%" }}>
              <Input
                placeholder="Điền liên kết/ID trang"
                onChange={setPageId}
              />

              {isLoadingPosts ? (
                <InputGroup.Button appearance="primary">
                  <Loader />
                </InputGroup.Button>
              ) : (
                <InputGroup.Button appearance="primary" onClick={getId}>
                  <AiOutlineSearch />
                </InputGroup.Button>
              )}
            </InputGroup>
            <a href="https://uid.bufflike.net/" target="_blank">
              <Form.HelpText tooltip className="mb-2">
                Tìm ID của bạn
              </Form.HelpText>
            </a>
          </div>
        </div>
        {/*  */}
        <div className="card p-3 mt-3">
          {pagePosts?.length === 0 ? (
            <></>
          ) : (
            <div className="d-flex justify-content-center">
              {toolType === "/page-posts-clone" ? (
                <>
                  {/* <div
                    className="d-flex justify-content-between"
                    style={{ width: "100%" }}
                  >
                    <div style={{ width: "30%" }}>
                      <SelectedPageNotFromAd
                        listPages={listPages}
                        setSelectedPage={setSelectedPage}
                        selectedPage={selectedPage}
                      />
                    </div>
                    <div className="mt-2 d-grid justify-content-center">
                      <div className="d-flex"></div>
                      
                    </div>
                  </div> */}
                </>
              ) : (
                <>
                  <div>
                    <h5>
                      Tên trang:
                      <a
                        href={`https://www.facebook.com/${pagePosts[0]?.page?.id}`}
                        target="_blank"
                        className="ppa__pageTitle ms-2 fs-4"
                      >
                        {pagePosts[0]?.page?.name}
                      </a>
                    </h5>
                  </div>
                </>
              )}
            </div>
          )}
          <div className="d-flex justify-content-between">
            <div>
              <p className="my-2">
                Kết quả: <span className="fw-bold">{pagePosts?.length}</span>
              </p>
              <div className="d-flex gap-2 align-items-center">
                <span className="oneLine group_membernumber">
                  Sắp xếp theo:
                </span>
                <bForm.Select
                  aria-label="Default select example"
                  size="sm"
                  onChange={(e) => setSortColumn(e.target.value)}
                  style={{ width: "10rem" }}
                >
                  <option value={""}>Chọn giá trị</option>
                  {filterValue?.map((item, key) => (
                    <option value={item?.value} key={key}>
                      {item?.label}
                    </option>
                  ))}
                </bForm.Select>
                <bForm.Select
                  aria-label="Default select example"
                  size="sm"
                  onChange={(e) => setSortOrder(e.target.value)}
                >
                  <option value={""}>Thứ tự</option>
                  <option value={"asc"}>Tăng dần</option>
                  <option value={"des"}>Giảm dần</option>
                </bForm.Select>
              </div>
            </div>
            {toolType === "/page-posts-clone" ? (
              <div className="d-flex">
                <Checkbox
                  onChange={(e, check) => {
                    if (check) {
                      const listId = pagePosts?.map((item) => item?.id);
                      setCheckGroups(listId);
                    } else {
                      setCheckGroups([]);
                    }
                  }}
                  className="group_membernumber"
                >
                  Chọn tất cả bài viết
                </Checkbox>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="d-flex flex-wrap">
            {isLoadingPosts ? (
              <LinearProgress
                style={{
                  width: "100%",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />
            ) : (
              <></>
            )}
            {isCloning ? (
              <LinearProgress
                style={{
                  width: "100%",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />
            ) : (
              <></>
            )}
            {pagePosts?.map((item, key) => (
              <div
                className={`${
                  toolType === "/page-posts-clone" ? "col-4" : "col-3"
                } p-3`}
                key={key}
              >
                <div className="card p-3 ppa__pagePostBody">
                  <div
                    className="ppa__pagePost"
                    style={{ position: "relative" }}
                  >
                    <a
                      href={`https://www.facebook.com/${item?.id}`}
                      target="_blank"
                    >
                      <img
                        src={item?.picture ? item?.picture : no_image}
                        alt="x"
                        className="pageImage"
                      />
                    </a>
                    {toolType === "/page-posts-clone" ? (
                      <Checkbox
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          background: "white",
                          borderRadius: "5px",
                        }}
                        checked={checkGroups?.includes(item?.id)}
                        value={item?.id}
                        onChange={(e, check) => {
                          if (check) {
                            setCheckGroups((current) => [...current, e]);
                          } else {
                            setCheckGroups((prevState) =>
                              prevState.filter((prevItem) => prevItem !== e)
                            );
                          }
                          // console.log(e, "e");
                          // console.log(check, "check");
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="justify-content-between">
                    <div className="d-flex justify-content-between">
                      <div>{postType(item?.attachments[0]?.type)}</div>
                      <div>
                        <span style={{ fontSize: ".85rem" }}>
                          {moment
                            ?.unix(item?.created_time)
                            .format("MM/DD/YYYY")}
                        </span>
                      </div>
                    </div>
                    <div className="mt-2 d-flex justify-content-between ppa_pagePostStats">
                      <div>
                        <AiOutlineLike className="mb-1 fs-5" />
                        <span className="ppa_statsNumber">
                          {item?.like_count}
                        </span>
                      </div>
                      <div>
                        <a
                          href={`https://www.facebook.com/${item?.id}`}
                          target="_blank"
                          className="text-dark"
                          style={{ textDecoration: "none" }}
                        >
                          <BiCommentDetail className="me-1" />
                          <span className="ppa_statsNumber">
                            {item?.comment}
                          </span>
                        </a>
                      </div>
                      <div>
                        <AiOutlineShareAlt className="mb-1 fs-5" />
                        <span className="ppa_statsNumber">
                          {item?.share_count}
                        </span>
                      </div>
                    </div>

                    <Input
                      className="mt-2"
                      as={"textarea"}
                      defaultValue={item?.content}
                      onChange={(e) => {
                        const updatedState = pagePosts?.map((todo, index) => {
                          if (todo.id === item?.id) {
                            todo.content = e;
                          }
                          return todo;
                        });
                        setPagePosts(updatedState);
                      }}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {toolType === "/page-posts-clone" ? (
        <div className="col-3 ">
          <div className="p-2 card">
            <div>
              {/* <p className="fs-6 text-center">CLONE MENU</p> */}
              <SelectedPageNotFromAd
                listPages={listPages}
                setSelectedPage={setSelectedPage}
                selectedPage={selectedPage}
                err={isErr}
              />
              <div className="my-2">
                <Form fluid formValue={formClone} onChange={setFormClone}>
                  <Form.Group controlId="delay">
                    <Form.ControlLabel className="group_membernumber">
                      Delay:
                    </Form.ControlLabel>
                    <Form.Control name="delay" type="number" />
                    {/* <Form.HelpText>Required</Form.HelpText> */}
                  </Form.Group>
                  <Form.Group controlId="addingText">
                    <Form.ControlLabel className="group_membernumber">
                      Thêm nội dung vào cuối bài viết:
                      {/* <Form.HelpText>Addition</Form.HelpText> */}
                    </Form.ControlLabel>
                    <Form.Control
                      name="addingText"
                      rows={5}
                      accepter={Textarea}
                    />
                    <Form.Control
                      name="randomCheckbox"
                      accepter={CheckboxGroup}
                      inline
                      className="d-grid"
                    >
                      <Checkbox value="text" className="group_membernumber">
                        Nội dung ngẫu nhiên
                      </Checkbox>
                      <Checkbox value="icon" className="group_membernumber">
                        Biểu tượng ngẫu nhiên
                      </Checkbox>
                      <Checkbox value="time" className="group_membernumber">
                        Thời gian
                      </Checkbox>
                    </Form.Control>
                  </Form.Group>
                  {/* <div className="d-flex align-items-start justify-content-end random__text group_membernumber"> */}
                  {/* <Checkbox
                      onChange={(e, check) => {
                        if (check) {
                          setIsRandomString(true);
                        } else {
                          setIsRandomString(false);
                        }
                      }}
                    />
                    <span className="ms-2">Nội dung ngẫu nhiên</span> */}
                  {/* <Form.Group controlId="randomCheckbox"> */}

                  {/* </Form.Group> */}

                  {/* </div> */}
                  <Form.Group>
                    <ButtonToolbar>
                      {isCloning ? (
                        <Button
                          style={{ width: "100%", background: "red" }}
                          appearance="primary"
                          onClick={handleStop}
                        >
                          DỪNG LẠI
                        </Button>
                      ) : (
                        <>
                          <Button
                            onClick={handleClonePost}
                            style={{ width: "100%" }}
                            appearance="primary"
                          >
                            ĐĂNG BÀI
                          </Button>
                        </>
                      )}
                    </ButtonToolbar>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
          {postingList?.length > 0 ? (
            <div className="card p-2 mt-2">
              <p className="fw-bold text-center">Kết quả</p>

              <div>
                {postingList?.map((item) => (
                  <p key={item}>
                    <a
                      href={`https://www.facebook.com/${item}`}
                      target="_blank"
                      className="blackLink group_membernumber"
                    >{`https://www.facebook.com/${item}`}</a>
                  </p>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PagePostsAnalyzer;
