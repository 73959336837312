import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendPost } from "../../../axios/axiosClient";
import { Message, useToaster } from "rsuite";
import ForgotPassword from "../../forgot-password/ForgotPassword";

const Verify = () => {
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);
  // /users/auth/login/facebook/callback

  const sendApi = async (input) => {
    try {
      const res = await sendPost(`/users/auth/verify-mail`, input);
      if (res.status === 200 || res.status === 201) {
        setTextMessage("Verify tài khoản thành công.");
        const timer = setTimeout(() => {
          navigate("/login");
        }, 1);
        return () => clearTimeout(timer);
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
      navigate("/verify");
    }
  };

  useEffect(() => {
    const paramsObject = {};
    for (const [key, value] of params.entries()) {
      paramsObject[key] = value;
    }
    if (params?.size > 0) {
      sendApi(paramsObject);
    }
  }, []);

  return <div>{params?.size > 0 ? <></> : <ForgotPassword />}</div>;
};

export default Verify;
