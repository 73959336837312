import React from "react";
import GetGroupByHashTag from "../get-group-by-hashtag/GetGroupByHashTag";

const GetGidInfo = () => {
  return (
    <div>
      <GetGroupByHashTag />
    </div>
  );
};

export default GetGidInfo;
