import React, { useState, useEffect } from "react";
import "./package.css";
import { getListBank, getListPackage } from "../../../axios/packageApi";
import { BiSolidPaperPlane, BiSolidTimeFive } from "react-icons/bi";
import { BsTrainFreightFrontFill } from "react-icons/bs";
import { BiSolidRocket } from "react-icons/bi";
import { RiVipDiamondFill } from "react-icons/ri";
import PaymentModal from "../../../components/paymentModal/PaymentModal";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { sendGet } from "../../../axios/axiosClient";
import { Message, useToaster } from "rsuite";
const Package = () => {
  const [listPackage, setListPackage] = useState([]);
  const navigate = useNavigate();
  const handleGetlistPackage = async () => {
    try {
      const res = await getListPackage();
      setListPackage(res.data.data);
    } catch (err) {
      throw err;
    }
  };
  const [modal, setModal] = useState(false);
  const [packageId, setPackageId] = useState(0);
  const [listBank, setListBank] = useState([]);
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [discount, setDiscount] = useState(0);
  const [remainDay, setRemainDay] = useState(0);
  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);
  const handleClose = () => {
    setModal(false);
  };

  const priceFormat = (price) => {
    return price?.toLocaleString("vi", { style: "currency", currency: "VND" });
    // return price?.toLocaleString("en-US", {
    //   style: "currency",
    //   currency: "USD",
    // });
  };
  const handlegetListBank = async (id) => {
    try {
      const res = await getListBank();
      if (res.status == 200) {
        setListBank(res.data.data);
      }
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    if (Cookies?.get("token")) {
      handlegetListBank();
      handleGetlistPackage();
    } else {
      navigate("/login");
    }
  }, []);

  const checkType = (type) => {
    switch (type) {
      case 1:
        return "Ngày";
      case 2:
        return "Tháng";
      case 3:
        return "Năm";
      default:
        break;
    }
  };

  const getHeadingGradient = (id) => {
    switch (id) {
      case 1:
        return "linear-gradient(45deg, rgb(255, 95, 109), rgb(255, 195, 113))";
      case 2:
        return "linear-gradient(45deg, rgb(47, 244, 253), rgb(56, 164, 255))";
      case 3:
        return "linear-gradient(45deg, rgb(55, 166, 250), rgb(170, 18, 210))";
      case 4:
        return "linear-gradient(45deg, rgb(0, 160, 199), rgb(0, 60, 252))";
      case 5:
        return "linear-gradient(45deg, rgb(46, 244, 255), rgb(139, 220, 147))";
      case 6:
        return "linear-gradient(45deg, rgb(75, 121, 161), rgb(40, 62, 81))";
      default:
        break;
    }
  };

  const getMoneyToPay = async (id) => {
    try {
      const res = await sendGet(`/users/order/money-to-pay/${id}`);
      if (res.status === 200) {
        setDiscount(res.data.data.deducted_money);
        setRemainDay(res.data.data.remain_day);
        setModal(true);
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
      setModal(false);
    }
  };

  return (
    <>
      {modal ? (
        <PaymentModal
          packageList={listPackage}
          listBank={listBank}
          onSubmit={handleClose}
          packageId={packageId}
          discount={discount}
          remainDay={remainDay}
        />
      ) : (
        <></>
      )}
      <div className="card p-3 text-center container">
        <h4>LỰA CHỌN GÓI THANH TOÁN PHÙ HỢP NHẤT VỚI BẠN</h4>
        <div className="">
          <span className="fw-bold text-danger">
            Đối với các Tool có mục VIP bạn cần nâng cấp tài khoản lên VIP thì
            mới có thể sử dụng
          </span>
        </div>
        <div className="d-flex flex-wrap gap-3 items-container justify-content-center">
          {listPackage?.map((item, key) => (
            <div
              key={key}
              onClick={() => {
                // setModal(true);
                setPackageId(item?.id);
                getMoneyToPay(item?.id);
              }}
              // className="table-price"
              className="priceCard"
            >
              <div
                className="text-center cardHeading"
                // style={{
                //   background: `linear-gradient(45deg, rgb(255, 95, 109), rgb(255, 195, 113))`,
                // }}
                style={{ background: `${getHeadingGradient(item?.id)}` }}
              >
                <p className="title py-5">
                  Gói {item?.name} ( {item?.limit} {checkType(item?.type)} )
                </p>
              </div>
              <span className="text-center priceTag">
                {priceFormat(Number(item?.money))}
              </span>
              <div className="px-3">
                <p className="text-center mb-2 text-primary">Quyền lợi</p>
                <p className="text-start m-0">
                  <small className="">
                    <RiVipDiamondFill className="me-2 text-primary" />
                    Sử dụng toàn bộ chức năng của hệ thống
                  </small>
                </p>
                <p className="text-start m-0">
                  <small>
                    <BiSolidTimeFive className="text-primary me-2" />{" "}
                    {item?.limit} {checkType(item?.type)} sử dụng
                  </small>
                </p>
              </div>
            </div>
          ))}

          {/* <div
            onClick={() => {
              setModal(true);
              setPackageId(2);
            }}
            className="table-price selected"
          >
            <div className="pic-item">
              <BsTrainFreightFrontFill size={120} />
            </div>
            <span className="best-choice">KHUYÊN DÙNG</span>
            <div className="description">
              <h1 className="title">{listPackage[1]?.limit} THÁNG</h1>
              <span className="price">
                $ {priceFormat(listPackage[1]?.money)}
              </span>
              <button>Nâng cấp ngay</button>
            </div>
          </div> */}
          {/* <div
            onClick={() => {
              setModal(true);
              setPackageId(3);
            }}
            className="table-price"
          >
            <div className="pic-item">
              <BiSolidRocket size={120} />
            </div>
            <div className="description">
              <h1 className="title">{listPackage[2]?.limit} NĂM</h1>
              <span className="price">
                $ {priceFormat(listPackage[2]?.money)}
              </span>
              <button>Nâng cấp ngay</button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Package;
