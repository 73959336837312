import React from "react";
import FbOneCardVideoCarousel from "../fb-one-card-video-carousel/FbOneCardVideoCarousel";
const FbOneCardPictureCarousel = () => {
  return (
    <div>
      <FbOneCardVideoCarousel />
    </div>
  );
};

export default FbOneCardPictureCarousel;
