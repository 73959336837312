import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Cookies from "js-cookie";
import {
  Button,
  Form,
  Input,
  Message,
  Modal,
  Pagination,
  useToaster,
} from "rsuite";
import "./adminorders.css";
import { Form as SubForm } from "react-bootstrap";
import { getOrder, updateOrder } from "../../../axios/orderApi";
import moment from "moment/moment";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
const AdminOrders = () => {
  const [listExtenions, setlistExtenions] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [state, setState] = useState("");
  const [updateState, setUpdateState] = useState("created");
  const [method, setMeThod] = useState(0);
  const [openCreate, setOpenCreate] = React.useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [selectedValue, setSelectedValue] = useState();
  const [formValue, setFormValue] = useState();
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );
  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);
  const getListOrders = async (page, page_size) => {
    const input = {
      ...(formValue?.name &&
        formValue?.name?.length !== 0 && { search: formValue?.name }),
      ...(state && state.length !== 0 && { state: state }),
      ...(method &&
        (method == 1 || method == 2 || method == 3) && {
          payment_method: method,
        }),
      // status: 1,
    };
    try {
      const res = await getOrder(input, page, page_size);
      if (res.status === 200) {
        setlistExtenions(res.data?.data?.data);
        setTotalItems(res?.data?.data?.total);
      }
    } catch (err) {
      throw err;
    }
  };

  const handleChangeLimit = (e) => {
    setLimit(e);
    setActivePage(1);
  };

  // useEffect(() => {
  //   getListOrders(activePage, limit);
  // }, [limit, activePage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getListOrders(activePage, limit);
    }, 500);
    return () => clearTimeout(timer);
  }, [formValue?.name, state, method, limit, activePage]);

  const priceFormat = (price) => {
    return price?.toLocaleString("vi", { style: "currency", currency: "VND" });
  };
  const handleUpdate = async (id) => {
    const input = {
      state: updateState,
    };
    try {
      const res = await updateOrder(selectedValue?.id, input);
      if (res.status == 200) {
        setTextMessage("Cập nhật thành công.");
        getListOrders(activePage, limit);
        handleClose();
      }
    } catch (err) {
      setTextMessage(err?.response?.data?.error_msg);

      // throw err;
    }
  };
  const setNamePackage = (id) => {
    let packageName;
    switch (id) {
      case 1:
        packageName = "Web - 1 Month";
        break;
      case 2:
        packageName = "Web - 6 Months";
        break;
      case 3:
        packageName = "Web - 1 Year";
        break;
      case 4:
        packageName = "Extension - 1 Month";
        break;
      case 5:
        packageName = "Extension - 6 Months";
        break;
      case 6:
        packageName = "Extension - 1 Year";
        break;
      default:
        break;
    }
    return packageName;
  };
  return (
    <div className="admin__tool__container">
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{`UPDATE`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={selectedValue} onChange={setSelectedValue} fluid>
            <Form.Group controlId="key">
              <Form.ControlLabel>
                State:
                <SubForm.Select
                  style={{
                    marginTop: "5px",
                    // background: "#fff",
                    // maxWidth: "150px",
                    // marginLeft: "10px",
                  }}
                  aria-label="Default select example"
                  onChange={(e) => setUpdateState(e.target.value)}
                  defaultValue={updateState}
                >
                  <option value="created">Created</option>
                  <option value="approved">Approved</option>
                  <option value="failed">Failed</option>
                  <option value="expired">Expired</option>
                  <option value="canceled">Canceled</option>
                  <option value="completed">Completed</option>
                  <option value="pending">Pending</option>
                  <option value="refunded">Refunded</option>
                  <option value="partially refunded">Partially Refunded</option>
                </SubForm.Select>
              </Form.ControlLabel>
              {/* <Form.Control name="key" /> */}
            </Form.Group>
            <Form.Group controlId="script">
              <Form.ControlLabel>Description:</Form.ControlLabel>
              <Form.Control rows={5} name="script" accepter={Textarea} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-end align-items-center mb-2">
        <Form formValue={formValue} onChange={setFormValue}>
          <Form.Group controlId="name">
            <Form.Control
              name="name"
              style={{ background: "#fff" }}
              placeholder="Tìm kiếm ..."
            />
          </Form.Group>
        </Form>
        <SubForm.Select
          style={{ background: "#fff", maxWidth: "150px", marginLeft: "10px" }}
          aria-label="Default select example"
          onChange={(e) => setMeThod(e.target.value)}
        >
          <option>Method</option>
          <option value={1}>VnPay</option>
          <option value={2}>PayPal</option>
          <option value={3}>Auto</option>
        </SubForm.Select>
        <SubForm.Select
          style={{ background: "#fff", maxWidth: "150px", marginLeft: "10px" }}
          aria-label="Default select example"
          onChange={(e) => setState(e.target.value)}
        >
          <option value="">State</option>
          <option value="created">Created</option>
          <option value="approved">Approved</option>
          <option value="failed">Failed</option>
          <option value="expired">Expired</option>
          <option value="canceled">Canceled</option>
          <option value="completed">Completed</option>
          <option value="pending">Pending</option>
          <option value="refunded">Refunded</option>
          <option value="partially refunded">Partially Refunded</option>
        </SubForm.Select>
      </div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>User</th>
            <th>Email</th>
            <th>Gói</th>
            <th>Giá</th>
            <th>Phương thức thanh toán</th>
            <th>Trạng thái</th>
            <th>Ngày tạo</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {totalItems ? (
            listExtenions?.map((item, index) => (
              <tr key={item?.id}>
                <td>{index + 1}</td>
                <td>
                  <span className="ms-2">{item?.name}</span>
                </td>
                <td>{item?.email}</td>
                <td>{item?.package_name}</td>
                <td>{priceFormat(Number(item?.money))}</td>
                <td>
                  {(item?.payment_method == 1 && "VNPay") ||
                    (item?.payment_method == 2 && "PayPal") ||
                    "Auto"}
                </td>
                {/* <td style={{ maxWidth: "20rem" }}>{item?.script}</td> */}
                <td>{item?.state}</td>
                <td>{moment(item?.created_at).format("DD/MM/YYYY, h:mm A")}</td>
                <td className="d-flex gap-2">
                  <Button
                    appearance="primary"
                    onClick={() => {
                      setSelectedValue(item);
                      setUpdateState(item.state);
                      // console.log(item);
                      handleOpen();
                    }}
                  >
                    Update State
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={9} className="text-center">
                Không có dữ liệu
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {totalItems > 0 ? (
        <Pagination
          layout={["limit", "-", "pager"]}
          size={"xs"}
          prev
          next
          // first
          // last
          ellipsis
          boundaryLinks
          total={totalItems}
          limit={limit}
          limitOptions={[10, 30, 50]}
          maxButtons={5}
          activePage={activePage}
          onChangePage={setActivePage}
          onChangeLimit={handleChangeLimit}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AdminOrders;
