import React, { useContext, useEffect, useState } from "react";
import "./sidebar.css";
import logo_image from "../../../assets/images/logo/logo-website2.png";
// import logo_image from "../../../assets/images/logo-website.webp";
import avatar_image from "../../../assets/images/645fbc5da4a21.png";
import avatar_image2 from "../../../assets/images/logo/default-user-mmo.jpg";
import { Button, Dropdown, IconButton, Tooltip, Whisper } from "rsuite";
import { SidebarContextProvider } from "../../../context/SidebarContext";
import { useLocation, useNavigate } from "react-router-dom";
import { toolCategories } from "../../../commons/data/toolCategory";
import Cookies from "js-cookie";
import adminSidebar, {
  adminCategory,
} from "../../../commons/data/adminSidebar";
import { MdUpdate } from "react-icons/md";
import { GoCrossReference } from "react-icons/go";
import { FcExpired } from "react-icons/fc";
import { RiVipDiamondFill, RiVipFill } from "react-icons/ri";
import { BiLogOut, BiUserCircle } from "react-icons/bi";
import moment from "moment/moment";
import { FiUser } from "react-icons/fi";
import { IoIosLogOut } from "react-icons/io";
const renderIconButton = (props, ref) => {
  return (
    <IconButton
      {...props}
      ref={ref}
      icon={<img src={avatar_image2} alt="" className="toolHeader__avatar" />}
      circle
      size="xs"
      // onClick={() => setHave2ndSidebar(false)}
      // color="gray"
      appearance="default"
    />
  );
};

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    have2ndSidebar,
    setHave2ndSidebar,
    selectedCategory,
    setSelectedCategory,
    setSelectedTool,
  } = useContext(SidebarContextProvider);
  const userInfo = JSON.parse(localStorage?.getItem("profile"));
  // console.log(JSON.parse(userInfo));

  const handleLogout = () => {
    Cookies.remove("token");
    // localStorage.removeItem("token");
    if (Cookies.get("admin") !== undefined) {
      Cookies.remove("admin");
    }
    navigate("/login");
  };

  useEffect(() => {
    setSelectedTool(location.pathname);
    if (Cookies.get("admin") === "1") {
      setSelectedCategory(
        adminSidebar.find((item) => item.path === location.pathname)?.category
      );
    } else {
      toolCategories.map((item, idx) => {
        if (item.tools.find((x) => x.path === location.pathname)) {
          setSelectedCategory(idx);
        }
      });
    }
  }, [location]);

  return (
    <div className="sidebar d-flex flex-column justify-content-between align-items-center py-4">
      <div>
        <img
          src={logo_image}
          alt="avatar"
          className="toolHeader__avatar"
          onClick={() => {
            navigate("/home");
            if (Cookies?.get("admin") === "1") {
              setSelectedCategory("application");
            } else {
              setSelectedCategory(0);
            }
          }}
        />
      </div>
      <div
        className={`d-flex flex-column ${
          Cookies?.get("admin") == 1 ? "gap-4" : "gap-4"
        }`}
      >
        {(() => {
          switch (Cookies?.get("admin")) {
            case "1":
              return adminCategory?.map((item, index) => (
                <Button
                  size="sm"
                  onClick={() => {
                    setSelectedCategory(item?.key);
                  }}
                  style={{
                    color: `${
                      selectedCategory === item?.key ? "black" : "white"
                    }`,
                  }}
                  key={index}
                  appearance={
                    selectedCategory === item?.key ? "default" : "subtle"
                  }
                >
                  {/* <Whisper
                        placement="rightStart"
                        controlId="control-id-hover"
                        trigger="hover"
                        // followCursor
                        speaker={<Tooltip>{item?.name}</Tooltip>}
                      > */}
                  {item?.icon}
                  {/* </Whisper> */}
                </Button>
              ));
            default:
              return toolCategories?.map((category, index) => (
                <Button
                  size="sm"
                  onClick={() => {
                    if (index === selectedCategory && have2ndSidebar === true) {
                      setHave2ndSidebar(true);
                    } else {
                      setHave2ndSidebar(true);
                      setSelectedCategory(index);
                    }
                  }}
                  style={{
                    color: `${index === selectedCategory ? "black" : "white"}`,
                  }}
                  key={index}
                  appearance={index === selectedCategory ? "default" : "subtle"}
                >
                  <Whisper
                    placement="rightStart"
                    // controlId="control-id-hover"
                    trigger="hover"
                    // followCursor
                    speaker={<Tooltip>{category?.title}</Tooltip>}
                  >
                    {category?.image}
                  </Whisper>
                </Button>
              ));
          }
        })()}
      </div>
      <div>
        {Cookies.get("admin") === "1" ? (
          <Whisper
            placement="rightStart"
            // controlId="control-id-hover"
            trigger="hover"
            // followCursor
            speaker={<Tooltip>Đăng xuất</Tooltip>}
          >
            <IconButton
              icon={<IoIosLogOut />}
              circle
              size="lg"
              onClick={handleLogout}
            />
          </Whisper>
        ) : (
          <Dropdown
            renderToggle={renderIconButton}
            placement="rightEnd"
            trigger="click"
            // onMouseDown={() => setHave2ndSidebar(false)}
            // style={{'width': 'fit-content !important'}}
            className="dropdown-sidebar"
          >
            {userInfo ? (
              <div className="sidebarMenu">
                <p className="oneLine px-3 mb-1 mt-2">
                  {/* <BiUserCircle className="fs-5 mb-1 me-2" /> */}
                  <img
                    src={avatar_image2}
                    alt=""
                    className="toolHeader__avatar me-2"
                  />
                  {userInfo?.email}
                </p>
                <p className="oneLine px-3 my-1">
                  Số dư: {userInfo?.coin ? userInfo?.coin : "0"} coin
                </p>
                <p className="oneLine px-3 my-1">
                  {/* <FcExpired className="fs-5 mb-1 me-2" /> */}
                  Gói:{" "}
                  {userInfo?.name_package ? userInfo?.name_package : "Free"}
                </p>
                <p className="oneLine px-3 my-1">
                  Ngày hết hạn:{" "}
                  {userInfo?.expired_date !== "Chưa mua gói"
                    ? moment(userInfo?.expired_date).format("DD/MM/YYYY")
                    : "Vĩnh viễn"}
                </p>
                <hr className="ms-2" />
              </div>
            ) : (
              // </Dropdown.Item>
              <></>
            )}
            <Dropdown.Item
              onClick={() => {
                navigate("/info");
              }}
              style={{ textTransform: "uppercase" }}
            >
              <FiUser className="fs-5 mb-1 me-2" />
              THÔNG TIN TÀI KHOẢN
            </Dropdown.Item>{" "}
            <Dropdown.Item
              onClick={() => {
                // setSelectedCategory(10);
                // setHave2ndSidebar(true);
                navigate("/logs");
              }}
              style={{ textTransform: "uppercase" }}
              title="Account manager"
            >
              <MdUpdate className="fs-5 mb-1 me-2" />
              LỊCH SỬ CẬP NHẬT
            </Dropdown.Item>{" "}
            {/* <div className="px-2 text-dark">account.test@gmail.com</div> */}
            <Dropdown.Item
              onClick={() => {
                navigate("/ref");
              }}
              style={{ textTransform: "uppercase" }}
              title="Account manager"
            >
              <GoCrossReference className="fs-5 mb-1 me-2" />
              GIỚI THIỆU
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                navigate("/package");
              }}
            >
              <RiVipFill className="fs-5 mb-1 me-2" />
              NÂNG CẤP GÓI
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>
              <BiLogOut className="fs-5 mb-1 me-2" />
              {Cookies?.get("token") ? "ĐĂNG XUẤT" : "ĐĂNG NHẬP"}
            </Dropdown.Item>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
