import React from "react";
import "./facebookCloneVideoReel.css";
import FbOneCardVideoCarousel from "../../fake-content-card/fb-one-card-video-carousel/FbOneCardVideoCarousel";
const FacebookCloneVideoReel = () => {
  return (
    <div>
      <FbOneCardVideoCarousel />
    </div>
  );
};

export default FacebookCloneVideoReel;
