import React, { createContext, useContext, useEffect, useState } from "react";
import { ExtensionContext } from "./ExtensionContext";
import { sendPost } from "../axios/axiosClient";
import Cookies from "js-cookie";
import { socket } from "./socket";

export const DataContext = createContext({});

const DataContextProvider = ({ children }) => {
  const [isSendScript, setIsSendScript] = useState(true);
  const { selectedExtension } = useContext(ExtensionContext);
  // const [listGroupsData, setListGroupsData] = useState([]);
  const [listPagesNonAT, setListPagesNonAT] = useState([]);
  const [listPagesAT, setListPagesAT] = useState([]);
  const [adAccount, setAdAccount] = useState();

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      // console.log(message);
      switch (message?.params?.key_script) {
        // case "get_list_group":
        //   if (message?.data) {
        //     setListGroupsData(message?.data);
        //   }
        //   break;
        case "get_all_pages_is_admin":
          if (message?.data) {
            setListPagesNonAT(message?.data);
          }
          break;
        case "get_pages_is_admin":
          if (message?.data?.page) {
            setListPagesAT(message?.data?.page);
          }
          break;
        case "get_ad_account":
          if (message?.data) {
            setAdAccount(message?.data);
          }
          break;
        default:
          break;
      }
      setIsSendScript(true);
    });
  }, []);

  const getListGroup = async () => {
    const input = {
      key_script: "get_list_group",
      extension_key: selectedExtension?.key,
      key_tool: "auto_post_to_facebook_groups",
      params: {
        key_socket: Cookies.get("keySocket"),
        uid: selectedExtension?.uid,
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
  };

  const getListPageNonAT = async () => {
    const input = {
      key_script: "get_all_pages_is_admin",
      extension_key: selectedExtension?.key,
      key_tool: "high_speed_page_posts_remover",
      params: {
        key_socket: Cookies.get("keySocket"),
        uid: selectedExtension?.uid,
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
  };
  const getListPageAT = async () => {
    const input = {
      key_script: "get_pages_is_admin",
      extension_key: selectedExtension?.key,
      key_tool: "get_friend",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
  };

  const getListAdsAccount = async () => {
    // console.log(uid);
    const input = {
      key_script: "get_ad_account",
      extension_key: selectedExtension?.key,
      key_tool: "clone_fb_reels_&_video_to_carousel",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
  };
  const keepFieldsDownload = (obj, fields) => {
    return fields.reduce((acc, field) => {
      if (obj[field.toUpperCase()] !== undefined) {
        acc[field.toUpperCase()] = obj[field.toUpperCase()];
      }
      return acc;
    }, {});
  };

  useEffect(() => {
    if (selectedExtension?.key) {
      // getListGroup();
      // getListPageNonAT();
      // getListPageAT();
      // getListAdsAccount();
    }
  }, [selectedExtension]);

  useEffect(() => {
    if (!Cookies?.get("token")) {
      setAdAccount(undefined);
      setListPagesAT([]);
      setListPagesNonAT([]);
    }
  }, [Cookies.get("token")]);

  return (
    <DataContext.Provider
      value={{
        // listGroupsData, setListGroupsData,
        listPagesNonAT,
        setListPagesNonAT,
        listPagesAT,
        setListPagesAT,
        adAccount,
        setAdAccount,
        isSendScript,
        keepFieldsDownload
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContextProvider;
