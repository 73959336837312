import React, { useContext, useEffect, useState } from "react";
import ProfileHeading from "../../../../components/profileHeading/ProfileHeading";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  Input,
  Loader,
  Message,
  Pagination,
  Toggle,
  Form as rForm,
  useToaster,
} from "rsuite";
import Form from "react-bootstrap/Form";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import { socket } from "../../../../context/socket";
import Cookies from "js-cookie";
import { sendPost } from "../../../../axios/axiosClient";
import SelectedPage from "../../../../components/selectedPage/SelectedPage";
import LinearProgress from "@mui/material/LinearProgress";
import { AiFillCheckCircle } from "react-icons/ai";
import { Badge } from "react-bootstrap";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
import { DataContext } from "../../../../context/DataContext";

let totalShare = 0;
const FbShareToPages = () => {
  const { setListPagesAT, listPagesAT, isSendScript } = useContext(DataContext);
  const [search, setSearch] = useState("");
  const { selectedExtension } = useContext(ExtensionContext);
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const toolType = window.location.pathname;
  const [listPages, setListPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState();
  const [delays, setDelays] = useState(10);
  const [postLink, setPostLink] = useState("");
  const [content, setContent] = useState("");
  const [isSharing, setIsSharing] = useState(false);
  const [checkedPage, setCheckedPage] = useState([]);
  const [isRandomEmoji, setIsRandomEmoji] = useState(false);
  const [isRandomString, setIsRandomString] = useState(false);
  const [isLoadingListPage, setIsLoadingListPage] = useState(true);
  const [checkboxRandom, setCheckboxRandom] = useState([]);
  const [intervalId, setIntervalId] = useState();

  const current = new Date();
  const [resultList, setResultList] = useState([]);
  const regex = /https?:\/\/[^\s/$.?#].[^\s]*/gi;
  const listIcon = [
    "🙂",
    "🙂",
    "😀",
    "😄",
    "😆",
    "😅",
    "😂",
    "🤣",
    "😊",
    "😌",
    "😉",
    "😏",
    "😍",
    "😘",
    "😗",
    "😙",
    "😚",
    "🤗",
    "💙",
    "😺",
    "😸",
    "😹",
    "😻",
    "😼",
    "😽",
    "🙀",
    "🌺",
    "🔰",
    "♥️",
    "❤️",
    "💛",
    "💚",
    "💙",
    "💜",
    "🖤",
    "💖",
    "💝",
    "💔",
    "❣️",
    "💕",
    "💞",
    "💓",
    "💗",
    "💘",
    "💟",
    "💌",
    "💋",
    "👄",
    "🌙",
    "💫",
    "⭐",
    "🌟",
    "✨",
    "⚡",
    "🔥",
    "☄️",
    "💥",
    "🌞",
    "☀️",
    "💧",
    "💦",
    "🌊",
    "🌼",
    "🌸",
    "🌺",
    "🏵️",
    "🌻",
    "🌷",
    "🌹",
    "🥀",
    "💐",
  ];

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      // console.log(message);
      switch (message?.params?.key_script) {
        // case "get_all_pages_is_admin":
        //   setListPages(message?.data);
        //   setSelectedPage(message?.data[0]?.id);
        //   break;
        case "get_pages_is_admin":
          setListPages(message?.data?.page);
          setListPagesAT(message?.data?.page);
          setIsLoadingListPage(false);
          break;
        case "share_post_to_pages":
          if (message?.data !== false) {
            setResultList((prev) => [...prev, message]);
            setTextMessage("Chia sẻ bài viết thành công !!!");
          } else {
            setTextMessage("Chia sẻ thất bại");
          }
          if (totalShare <= 1) {
            setIsSharing(false);
          }
          --totalShare;
          break;
        default:
          break;
      }
    });
  }, []);

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  // const getListPage = async () => {
  //   const input = {
  //     key_script: "get_all_pages_is_admin",
  //     extension_key: selectedExtension?.key,
  //     key_tool: "high_speed_page_posts_remover",
  //     params: {
  //       key_socket: Cookies.get("keySocket"),
  //     },
  //   };
  //   try {
  //     const res = await sendPost(`/users/scripts/send`, input);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getListPageAccessToken = async () => {
    const input = {
      key_script: "get_pages_is_admin",
      extension_key: selectedExtension?.key,
      key_tool: "get_friend",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setIsLoadingListPage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShareToPage = async () => {
    if (checkedPage?.length >= 1) {
    } else {
      setTextMessage("Bạn cần chọn page để share.");
      return;
    }
    if (!postLink) {
      setTextMessage("Bạn cần nhập link bài viết muốn share");
      return;
    } else {
      if (!postLink.match(regex)) {
        setTextMessage("Đường dẫn bài viết không hợp lệ");
        return;
      }
      const limit = checkedPage?.length;
      totalShare = checkedPage?.length;
      sharePost(checkedPage[0]);
      if (limit > 0) {
        if (1 < limit) {
          let b = 1;
          const id = setInterval(() => {
            if (b < limit) {
              sharePost(checkedPage[b]);
              ++b;
            } else {
              clearInterval(id);
            }
          }, delays * 1000);
          setIntervalId(id);
        }
      } else {
        setTextMessage("Bạn cần chọn page để share.");
        return;
      }
    }
  };

  const sharePost = async (pageId) => {
    let randomString = content + "\n";
    if (checkboxRandom?.includes("text")) {
      let stringLength = 7;
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
      for (let i = 0; i < stringLength; i++) {
        randomString += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
    }
    if (checkboxRandom?.includes("icon")) {
      randomString +=
        " " + listIcon[Math.floor(Math.random() * listIcon.length)];
    }

    if (checkboxRandom?.includes("time")) {
      const today = new Date();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      randomString += " " + time;
    }
    const input = {
      key_script: "share_post_to_pages",
      extension_key: selectedExtension?.key,
      key_tool: "share_link_to_multiple_pages",
      params: {
        key_socket: Cookies.get("keySocket"),
        page_id: pageId,
        content: randomString?.trim(),
        access_token: listPages?.find((item) => item?.id === pageId)
          ?.access_token,
        link: postLink,
      },
    };
    console.log(input);
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setIsSharing(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (listPages?.length > 0) {
      setIsLoadingListPage(false);
    }
  }, [listPages]);

  useEffect(() => {
    if (listPagesAT?.length > 0) {
      setListPages(listPagesAT);
    } else if (selectedExtension?.key && isSendScript) {
      getListPageAccessToken();
    }
    // getListPage();
  }, [selectedExtension]);
  return (
    <div className="d-flex gap-3">
      <div className="col-7">
        <div className="card p-3">
          <div className="mb-3">
            <ToolHeading toolType={window.location.pathname} />
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <p className="m-0 group_membernumber">Trang Facebook:</p>
            </div>
            <div className="d-flex gap-3">
              <Checkbox
                onChange={(e, check) => {
                  if (check) {
                    setCheckedPage(listPages?.map((item) => item?.id));
                  } else {
                    setCheckedPage([]);
                  }
                }}
                className="group_membernumber oneLine"
              >
                Chọn tất cả
              </Checkbox>
              <Input
                placeholder="Tìm kiếm..."
                value={search}
                onChange={setSearch}
              />
            </div>
          </div>
          {isLoadingListPage ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : (
            <>
              {listPages?.length > 0 ? (
                <>
                  {listPages
                    ?.filter((item) =>
                      item?.name.toLowerCase().includes(search.toLowerCase())
                    )
                    ?.map((item, key) => (
                      <div
                        key={key}
                        className="d-flex align-items-center gap-3 mb-2"
                      >
                        <Checkbox
                          value={item?.id}
                          checked={checkedPage?.includes(item?.id)}
                          onChange={(e, check) => {
                            if (check) {
                              setCheckedPage((current) => [...current, e]);
                            } else {
                              setCheckedPage((prevState) =>
                                prevState.filter((prevItem) => prevItem !== e)
                              );
                            }
                          }}
                        />
                        <img
                          src={item?.picture?.data?.url}
                          alt="avatar_image"
                          className="toolHeader__avatar"
                        />
                        <div>
                          <a
                            href={`https://www.facebook.com/${item?.id}`}
                            target="_blank"
                            className="blackLink"
                          >
                            <p className="fw-bold group_membernumber m-0 text-start">
                              {item?.name}
                            </p>
                          </a>
                          <p className="group_membernumber m-0">{item?.id}</p>
                        </div>
                      </div>
                    ))
                    ?.splice((activePage - 1) * limit, limit)}
                </>
              ) : (
                <div className="text-center">no data</div>
              )}
            </>
          )}
          {listPages?.length !== 0 ? (
            <Pagination
              layout={["limit", "-", "pager"]}
              size={"xs"}
              prev
              next
              ellipsis
              boundaryLinks
              total={listPages?.length}
              limit={limit}
              limitOptions={[10, 20, 30]}
              maxButtons={5}
              activePage={activePage}
              onChangePage={setActivePage}
              onChangeLimit={setLimit}
            />
          ) : (
            <></>
          )}
        </div>
        {resultList.length !== 0 ? (
          <div className=" card p-3 mt-3">
            <p className="group_membernumber">Kết quả :</p>
            <div>
              {resultList?.map((item, key) => (
                <div
                  key={key}
                  className="d-flex justify-content-between pb-1 border-bottom"
                >
                  <div className="d-flex gap-2 align-items-center">
                    <AiFillCheckCircle
                      className="fs-4"
                      style={{ color: "#60A5FA" }}
                    />
                    <a
                      className="list-result"
                      href={`https://www.facebook.com/${
                        item?.data?.id?.split("_")[1]
                      }`}
                      target="_blank"
                    >
                      {`https://www.facebook.com/${
                        item?.data?.id?.split("_")[1]
                      }`}
                    </a>
                  </div>
                  {/* <div>
                  {item?.data ? (
                    <>
                      {item?.data?.status == "success" ? (
                        <Badge bg="success">{item?.data?.status}</Badge>
                      ) : (
                        <Badge bg="danger">{item?.data?.status}</Badge>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div> */}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="col-5">
        <div className="card p-3">
          <p className="group_membernumber">
            URL Bài viết:
            {/* <a href="https://uid.bufflike.net/" target="_blank">
              <rForm.HelpText tooltip>Find your post ID</rForm.HelpText>
            </a> */}
          </p>
          <Input
            value={postLink}
            onChange={setPostLink}
            placeholder="URL bài viết"
          />
          <div className="d-flex justify-content-between ">
            <span className="group_membernumber mt-2">Nội dung:</span>
            {/* <div className="d-flex gap-2">
              <div className="d-flex gap-1 align-items-center">
                <Checkbox
                  onChange={(e, check) => {
                    setIsRandomEmoji(check);
                  }}
                >
                  <span className="group_membernumber">
                    Biểu tượng ngẫu nhiên
                  </span>
                </Checkbox>
              </div>
              <div className="d-flex gap-1 align-items-center">
                <Checkbox
                  onChange={(e, check) => {
                    setIsRandomString(check);
                  }}
                >
                  <span className="group_membernumber">
                    Nội dung ngẫu nhiên
                  </span>
                </Checkbox>
              </div>
            </div> */}
            <CheckboxGroup
              inline
              name="checkboxList"
              value={checkboxRandom}
              onChange={(value) => {
                // console.log(value, 'onChange');
                setCheckboxRandom(value);
              }}
            >
              <Checkbox value="text">Nội dung ngẫu nhiên</Checkbox>
              <Checkbox value="icon">Biểu tượng ngẫu nhiên</Checkbox>
              <Checkbox value="time">Thời gian</Checkbox>
            </CheckboxGroup>
          </div>
          <Input
            as="textarea"
            rows={5}
            placeholder="Nội dung"
            onChange={setContent}
            value={content}
          />
          {/* <div className="d-flex justify-content-between mt-3">
            <p className="group_membernumber">Schedule</p>
            <Toggle />
          </div>
          <DatePicker format="yyyy-MM-dd HH:mm" defaultValue={current} /> */}
          <p className="mt-3 group_membernumber">Delay (giây):</p>
          <Input type="number" value={delays} onChange={setDelays} />

          <Button
            disabled={isSharing}
            appearance="primary"
            color="green"
            className="fw-bold mt-2"
            onClick={() => {
              handleShareToPage();
            }}
          >
            ĐĂNG BÀI
          </Button>
          {isSharing ? (
            <LinearProgress
              style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default FbShareToPages;
