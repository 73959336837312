import React, { useEffect, useState } from "react";
import { Dropdown, Loader } from "rsuite";
import "./selectedPage.css";
import avatar_img from "../../assets/images/645fbc5da4a21.png";
import Select from "react-select";
const SelectedPage = ({ listPages, selectedPage, setSelectedPage }) => {
  const [selectedValue, setSelectedValue] = useState();
  const [options, setOptions] = useState([]);
  useEffect(() => {
    let arr = [];
    if (listPages.length > 0) {
      arr = listPages?.map((item) => ({
        label: (
          <div className="d-flex gap-3 align-items-center">
            <img
              src={item?.picture?.data?.url}
              alt="page-avatar"
              className="select-item-image"
            />
            <div>
              <p className="fw-bold group_membernumber m-0">{item?.name}</p>
              <p className="group_membernumber m-0">{item?.id}</p>
            </div>
          </div>
        ),
        value: item?.name,
      }));
    }else{
      arr = [{
        label: (
          <div className="d-flex gap-3 align-items-center">
            <Loader />
          </div>
        ),
        value: '',
      }];
    }
    setOptions(arr);
    setSelectedValue(arr[0]);
  }, [listPages]);

  return (
    <>
      <Select
        onChange={(e) => {
          setSelectedPage(listPages.find((item) => item.name === e.value).id);
          setSelectedValue(e);
        }}
        value={selectedValue}
        options={options}
      />
      {/* <Dropdown
      style={{ width: "100%" }}
      className="pageDropdown"
      title={
        selectedPage !== undefined ? (
          <>
            {selectedPage !== "" && listPages?.length > 0 ? (
              <div className="d-flex align-items-center gap-3">
                <img
                  src={
                    listPages?.find((item) => item?.id === selectedPage)
                      ?.picture?.data?.url
                  }
                  alt="avatar_image"
                  className="toolHeader__avatar"
                />
                <div className="text-start">
                  <p className="fw-bold group_membernumber m-0">
                    {listPages?.find((item) => item?.id === selectedPage)?.name}
                  </p>
                  <p className="group_membernumber m-0">{selectedPage}</p>
                </div>
              </div>
            ) : (
              <div className="text-center">no data</div>
            )}
          </>
        ) : (
          <Loader />
        )
      }
    >
      {listPages?.map((item, key) => (
        <Dropdown.Item
          eventKey={key}
          key={key}
          onClick={() => setSelectedPage(item?.id)}
        >
          <div className="d-flex align-items-center gap-3">
            <img
              src={
                item?.picture?.data?.url ? item?.picture?.data?.url : avatar_img
              }
              alt="avatar_image"
              className="toolHeader__avatar"
            />
            <div>
              <p className="fw-bold group_membernumber m-0">{item?.name}</p>
              <p className="group_membernumber m-0">{item?.id}</p>
            </div>
          </div>
        </Dropdown.Item>
      ))}
    </Dropdown> */}
    </>
  );
};

export default SelectedPage;
