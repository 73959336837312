import React, { createContext, useEffect, useState } from "react";
import { sendGet, sendPost } from "../axios/axiosClient";
import Cookies from "js-cookie";
import { socket } from "./socket";
import { getMe } from "../axios/authApi";
import { toolCategories } from "../commons/data/toolCategory";
import { useLocation } from "react-router-dom";

export const ExtensionContext = createContext({});

const ExtensionContextProvider = ({ children }) => {
  const [selectedExtension, setSelectedExtension] = useState({});
  // JSON.parse(localStorage.getItem("extension")) ||
  const [listTools, setListTools] = useState([]);
  const [webTools, setWebTools] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const getListTool = async () => {
    const input = {
      source: 1,
    };
    try {
      const res = await sendGet(`/users/extensions/get-tool`, input);
      if (res.status === 200) {
        // console.log(res);
        setListTools(res?.data?.data);
      }
    } catch (error) {}
  };

  const getWebTool = async () => {
    const input = {
      source: 1,
    };
    try {
      const res = await sendGet(`/users/list-tool`, input);
      if (res.status === 200) {
        // setWebTools(res?.data?.data);
        let mergeArray = [];
        toolCategories?.map((cate) => {
          cate?.tools?.map((tool) => {
            const matchingItem = res?.data?.data?.find(
              (item) => item?.key === tool?.key
            );
            mergeArray?.push({
              path: tool?.path,
              ...matchingItem,
            });
          });
        });
        setWebTools(mergeArray);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoadingData(false);
  };

  // useEffect(() => {
  //   localStorage.setItem("extension", JSON.stringify(selectedExtension));
  // }, [selectedExtension]);

  useEffect(() => {
    // console.log('get token');
    if (window.location.pathname !== "/") {
      getWebTool();
      if (!Cookies?.get("admin") && Cookies?.get("token")) {
        getListTool();
      } else {
      }
    }
  }, [Cookies?.get("token")]);

  return (
    <ExtensionContext.Provider
      value={{
        selectedExtension,
        setSelectedExtension,
        listTools,
        isLoadingData,
        webTools,
      }}
    >
      {children}
    </ExtensionContext.Provider>
  );
};

export default ExtensionContextProvider;
