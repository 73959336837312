import React, { useContext, useEffect, useState } from "react";
import "./secondSidebar.css";
import { SidebarContextProvider } from "../../../context/SidebarContext";
import { toolCategories } from "../../../commons/data/toolCategory";
import { Link } from "react-router-dom";
import { ExtensionContext } from "../../../context/ExtensionContext";
import { Badge, Divider } from "rsuite";
import Cookies from "js-cookie";
import { sendPost } from "../../../axios/axiosClient";
import { socket } from "../../../context/socket";
import adminSidebar, {
  adminCategory,
} from "../../../commons/data/adminSidebar";
const SecondSidebar = () => {
  const {
    have2ndSidebar,
    selectedCategory,
    setSelectedCategory,
    selectedTool,
    setSelectedTool,
  } = useContext(SidebarContextProvider);
  const { selectedExtension } = useContext(ExtensionContext);
  const { listTools, webTools } = useContext(ExtensionContext);

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      switch (message?.params?.key_script) {
        case "force_stop":
          // setDoTask(false);
          break;
        default:
          break;
      }
    });
  }, []);

  const handleStop = async () => {
    if (selectedExtension !== undefined) {
      const input = {
        key_script: "force_stop",
        extension_key: selectedExtension?.key,
        key_tool: "force_stop",
        params: {
          key_socket: Cookies.get("keySocket"),
        },
      };
      try {
        const res = await sendPost(`/users/scripts/send`, input);
        if (res.status === 200) {
        }
      } catch (error) {}
    }
  };

  const checkItem = (key, path) => {
    if (selectedCategory === 0) {
      return true;
    }
    if (key === "up_to_reels") {
      return false;
    }
    for (let obj of listTools) {
      if (obj.key === key) {
        return true;
      }
    }
    return false;
  };

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: false,
  //     },
  //     "google_translate_element"
  //   );
  // };
  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  return (
    <div
      className={`${
        have2ndSidebar ? "secondSidebar" : "hideSecondSidebar"
      } d-flex flex-column`}
    >
      <div className="px-2 mb-0">
        <div className="secondSidebar__categoryName d-flex align-items-center gap-2 px-2">
          {Cookies?.get("admin") === "1" ? (
            <span className="fs-5 text-white">
              {
                adminCategory?.find((item) => item?.key === selectedCategory)
                  ?.name
              }
            </span>
          ) : (
            <>
              <span>{toolCategories[selectedCategory]?.image}</span>
              <span className="fs-5 text-white">
                {toolCategories[selectedCategory]?.title}
              </span>
            </>
          )}
        </div>
      </div>
      <br />
      <ul className="mt-3 ps-0">
        {Cookies?.get("admin") === "1"
          ? adminSidebar?.map((item, key) => {
              if (item?.category === selectedCategory) {
                return (
                  <li
                    key={key}
                    className={`secondSidebar__toolName px-2 ${
                      selectedTool === item?.path ? "selectedTool" : ""
                    }`}
                  >
                    <Link
                      to={item?.path}
                      className={`secondSidebar__toolLink d-flex gap-2 align-items-center `}
                    >
                      {item?.name}
                    </Link>
                  </li>
                );
              }
            })
          : toolCategories[selectedCategory]?.tools?.map((tool, key) => (
              <li
                key={key}
                className={`secondSidebar__toolName px-2 ${
                  selectedTool === tool?.path ? "selectedTool" : ""
                }`}
              >
                {checkItem(tool?.key, tool?.path) ? (
                  <Link
                    to={tool?.path}
                    className={`secondSidebar__toolLink d-flex gap-2 align-items-center `}
                    onClick={() => {
                      if (selectedCategory !== 0) {
                        handleStop();
                      }
                    }}
                  >
                    {tool?.title}
                  </Link>
                ) : (
                  <Link
                    to={Cookies?.get("token") ? `/package` : "/login"}
                    className={`secondSidebar__toolLink d-flex gap-2 align-items-center ${
                      selectedTool === tool?.path ? "fw-bold" : ""
                    }`}
                    onClick={() => {
                      if (selectedCategory !== 0) {
                        handleStop();
                      }
                    }}
                  >
                    {Cookies?.get("token") ? (
                      <Badge content="VIP">{tool?.title}</Badge>
                    ) : (
                      tool?.title
                    )}
                  </Link>
                )}
              </li>
            ))}
      </ul>
      {/* <div id="google_translate_element"></div> */}
    </div>
  );
};

export default SecondSidebar;
