import React from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { Button, Modal } from "rsuite";

const MobileNoti = () => {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      keyboard={false}
      backdrop={"static"}
      style={{
        marginTop: "13vh",
        width: "100vw",
      }}
    >
      <Modal.Header closeButton={false}>
        <Modal.Title className="text-center">
          <AiOutlineWarning className="text-danger mb-1 text-center fw-bold fs-1" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">
          Hiện tại Website chưa hỗ trợ cho điện thoại. Hãy sử dụng thiết bị khác
          (laptop, máy tính cá nhân).
        </p>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleClose} appearance="primary">
          Ok
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Huỷ
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default MobileNoti;
