import React from "react";
import {
  Form,
  ButtonToolbar,
  Button,
  Input,
  Checkbox,
  CheckboxGroup,
  Toggle,
} from "rsuite";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

// const Checkbox = React.forwardRef((props, ref) => (
//   <Checkbox {...props} ref={ref}></Checkbox>
// ));

const UpToReels = () => {
  return (
    <div className="d-flex">
      <div className="col-5 card p-3">
        <Form fluid>
          <Form.Group controlId="text_editor">
            <Form.ControlLabel className="group_membernumber">
              Bulk text editor :
            </Form.ControlLabel>
            <Form.Control rows={5} name="text_editor" accepter={Textarea} />
          </Form.Group>
          <Form.Group controlId="limit">
            <Form.ControlLabel className="group_membernumber">
              Limit ( recommended 20 ):
            </Form.ControlLabel>
            <Form.Control name="limit" type="number" />
          </Form.Group>
          <Form.Group controlId="delay">
            <Form.ControlLabel className="group_membernumber">
              Upload delay ( + for low spec pc ) :
            </Form.ControlLabel>
            <Form.Control name="delay" type="number" />
          </Form.Group>
          <Form.Group controlId="checkbox">
            {/* <Form.ControlLabel>Password</Form.ControlLabel> */}
            <Form.Control name="checkbox" accepter={CheckboxGroup} inline>
              <Checkbox value="checkbox" className="group_membernumber">
                Reels title from file name
              </Checkbox>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="checkbox" className="d-flex ">
            <Form.ControlLabel className="group_membernumber">
              Advanced Bulk schedule
            </Form.ControlLabel>
            <Form.Control name="checkbox" accepter={CheckboxGroup} inline>
              <Toggle />
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <ButtonToolbar>
              <Button appearance="primary" style={{ width: "100%" }}>
                RUN BULK
              </Button>
            </ButtonToolbar>
          </Form.Group>
        </Form>
      </div>
      <div className="col-7 card p-3 ms-3">
        <div className="">Kết quả:</div>
      </div>
    </div>
  );
};

export default UpToReels;
