export const listIcon = [
  "🙂",
  "🙂",
  "😀",
  "😄",
  "😆",
  "😅",
  "😂",
  "🤣",
  "😊",
  "😌",
  "😉",
  "😏",
  "😍",
  "😘",
  "😗",
  "😙",
  "😚",
  "🤗",
  "💙",
  "😺",
  "😸",
  "😹",
  "😻",
  "😼",
  "😽",
  "🙀",
  "🌺",
  "🔰",
  "♥️",
  "❤️",
  "💛",
  "💚",
  "💙",
  "💜",
  "🖤",
  "💖",
  "💝",
  "💔",
  "❣️",
  "💕",
  "💞",
  "💓",
  "💗",
  "💘",
  "💟",
  "💌",
  "💋",
  "👄",
  "🌙",
  "💫",
  "⭐",
  "🌟",
  "✨",
  "⚡",
  "🔥",
  "☄️",
  "💥",
  "🌞",
  "☀️",
  "💧",
  "💦",
  "🌊",
  "🌼",
  "🌸",
  "🌺",
  "🏵️",
  "🌻",
  "🌷",
  "🌹",
  "🥀",
  "💐",
];
