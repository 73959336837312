import CreateMbPages from "../../pages/user/page/create-bm-pages/CreateMbPages";
import PageProfilesToGroup from "../../pages/user/page/page-profiles-to-groups/PageProfilesToGroup";
import FacebookCloneVideoReel from "../../pages/user/carousel-post/fb-clone-video-reel/FacebookCloneVideoReel";
import FbVideoCarousel from "../../pages/user/carousel-post/fb-video-carousel/FbVideoCarousel";
import FbVideosCarousel from "../../pages/user/carousel-post/fb-videos-carousel/FbVideosCarousel";
import GroupBulkSearch from "../../pages/user/explore/group-bulk-search/GroupBulkSearch";
import GroupPostsRemover from "../../pages/user/group/group-posts-remover/GroupPostsRemover";
import MakeMeAdmin from "../../pages/user/group/make-me-admin/MakeMeAdmin";
import PEOneCardv2 from "../../pages/user/fake-content-card/pe-one-card-v2/PEOneCardv2";
import TikTokPEClone from "../../pages/user/carousel-post/tiktok-pe-clone/TikTokPEClone";
import UpToReels from "../../pages/user/reels/up-to-reels/UpToReels";
import AutoCommentFbGroup from "../../pages/user/carousel-post/auto-comment-fb-group/AutoCommentFbGroup";
import V3GroupsShare from "../../pages/user/group/v3-groups-share/V3GroupsShare";
import AutoJoinFbGroup from "../../pages/user/group/auto-join-fb-group/AutoJoinFbGroup";
import AutoPostFbGroup from "../../pages/user/carousel-post/auto-post-fb-group/AutoPostFbGroup";
import ExtractGidFromSharedPost from "../../pages/user/explore/extract-gid-from-shared-post/ExtractGidFromSharedPost";
import ExtractUIDFromGroup from "../../pages/user/explore/extract-uid-from-group/ExtractUIDFromGroup";
import ExtractUidFromPost from "../../pages/user/explore/extract-uid-from-post/ExtractUidFromPost";
import FbOneCardPictureCarousel from "../../pages/user/fake-content-card/fb-one-card-picture-carousel/FbOneCardPictureCarousel";
import FbOneCardVideoCarousel from "../../pages/user/fake-content-card/fb-one-card-video-carousel/FbOneCardVideoCarousel";
import FbShareToPages from "../../pages/user/page/fb-share-to-pages/FbShareToPages";
import GetGroupByHashTag from "../../pages/user/explore/get-group-by-hashtag/GetGroupByHashTag";
import PeOneCardBulk from "../../pages/user/fake-content-card/pe-one-card-bulk/PeOneCardBulk";
import PagePostsAnalyzer from "../../pages/user/page/page-posts-analyze/PagePostsAnalyzer";
import FbCloneVideoPE from "../../pages/user/fake-content-card/fb-clone-video-pe/FbCloneVideoPE";
import PagePostsClone from "../../pages/user/page/page-posts-clone/PagePostsClone";
import FbPictureCarousel from "../../pages/user/carousel-post/fb-picture-carousel/FbPictureCarousel";
import GetGroupsFromUid from "../../pages/user/explore/get-groups-from-uid/GetGroupsFromUid";
import GroupPostsDelete from "../../pages/user/group/group-posts-delete/GroupPostsDelete";
import GetGidInfo from "../../pages/user/explore/get-gid-info/GetGidInfo";
import PagePostsRemover from "../../pages/user/page/page-posts-remover/PagePostsRemover";
import GroupPostsAnalyze from "../../pages/user/group/group-posts-analyze/GroupPostsAnalyze";
import Login from "../../pages/Login/Login";
import AdminLogin from "../../pages/admin/admin-login/AdminLogin";
import AdminTools from "../../pages/admin/admin-tools/AdminTools";
import AdminExtension from "../../pages/admin/admin-scripts/AdminExtension";
import ErrorPage from "../../components/errorPage/ErrorPage";
import AdminExtensions from "../../pages/admin/admin-extensions/AdminExtensions";
import AdminPackages from "../../pages/admin/admin-packages/AdminPackages";
import AdminUsers from "../../pages/admin/admin-users/AdminUsers";
import AdminOrders from "../../pages/admin/admin-orders/AdminOrders";
import AdminWithdraw from "../../pages/admin/admin-withdraw/AdminWithdraw";
import AdminBank from "../../pages/admin/admin-banks/AdminBank";
import AdminVersions from "../../pages/admin/admin-versions/AdminVersions";
import AdminRef from "../../pages/admin/admin-refs/AdminRef";
import AdminHistoryTools from "../../pages/admin/admin-history-tools/AdminHistoryTools";
import AdminsLog from "../../pages/admin/admin-logs/AdminsLog";
import Ref from "../../pages/user/ref/Ref";
import ChangeLog from "../../pages/user/change-log/ChangeLog";
import Package from "../../pages/user/package/Package";
import DieuKhoan from "../../pages/user/dieu-khoan-su-dung/DieuKhoan";
import BaoMat from "../../pages/user/quy-dinh-bao-mat/BaoMat";
import AdminGuide from "../../pages/admin/admin-guide/AdminGuide";
import Tutorials from "../../pages/user/tutorials/Tutorials";
import Notify from "../../pages/user/notify/Notify";
import AdminNotify from "../../pages/admin/admin-notify/AdminNotify";
import GetCallBackGoogle from "../../pages/user/get-callback-google/GetCallBackGoogle";
import ForgotPassword from "../../pages/forgot-password/ForgotPassword";
import Verify from "../../pages/user/verify/Verify";
import ChangePassword from "../../pages/user/change-password/ChangePassword";
import Info from "../../pages/user/info/Info";
import AdminBanner from "../../pages/admin/admin-banner/AdminBanner";
import Home from "../../pages/home/Home";

export const routers = [
  {
    path: "dieu-khoan-su-dung",
    element: <DieuKhoan />,
  },
  {
    path: "get-callback-google",
    element: <GetCallBackGoogle />,
  },
  {
    path: "quy-dinh-bao-mat",
    element: <BaoMat />,
  },
  {
    path: "ref",
    element: <Ref />,
  },
  {
    path: "package",
    element: <Package />,
  },
  {
    path: "logs",
    element: <ChangeLog />,
  },
  {
    path: "extract-uid-by-group",
    element: <ExtractUIDFromGroup />,
  },
  {
    path: "extract-uid-by-post",
    element: <ExtractUidFromPost />,
  },
  {
    path: "auto-post-fb-group",
    element: <AutoPostFbGroup />,
  },
  {
    path: "auto-join-fb-group",
    element: <AutoJoinFbGroup />,
  },
  {
    path: "extract-gid-from-shared-post",
    element: <ExtractGidFromSharedPost />,
  },
  {
    path: "fb-one-card-video-carousel",
    element: <FbOneCardVideoCarousel />,
  },
  {
    path: "fb-share-to-pages",
    element: <FbShareToPages />,
  },
  {
    path: "fb-one-card-picture-carousel",
    element: <FbOneCardPictureCarousel />,
  },
  {
    path: "get-group-by-hashtag",
    element: <GetGroupByHashTag />,
  },
  {
    path: "create-bm-pages",
    element: <CreateMbPages />,
  },

  {
    path: "page-profiles-to-groups",
    element: <PageProfilesToGroup />,
  },
  {
    path: "v3-groups-share",
    element: <V3GroupsShare />,
  },
  {
    path: "auto-comment-fb-group",
    element: <AutoCommentFbGroup />,
  },
  {
    path: "fb-video-carousel",
    element: <FbVideoCarousel />,
  },
  {
    path: "fb-clone-video-reel",
    element: <FacebookCloneVideoReel />,
  },
  {
    path: "make-me-admin",
    element: <MakeMeAdmin />,
  },
  {
    path: "tiktok-pe-clone",
    element: <TikTokPEClone />,
  },
  {
    path: "group-bulk-search",
    element: <GroupBulkSearch />,
  },
  {
    path: "group-posts-remover",
    element: <GroupPostsRemover />,
  },
  {
    path: "fb-videos-carousel",
    element: <FbVideosCarousel />,
  },
  {
    path: "up-to-reels",
    element: <UpToReels />,
  },
  {
    path: "pe-one-card-v2",
    element: <PEOneCardv2 />,
  },
  {
    path: "pe-one-card-bulk",
    element: <PeOneCardBulk />,
  },
  {
    path: "page-posts-analyzer",
    element: <PagePostsAnalyzer />,
  },
  {
    path: "group-posts-analyze",
    element: <GroupPostsAnalyze />,
  },
  {
    path: "fb-clone-video-pe",
    element: <FbCloneVideoPE />,
  },
  {
    path: "tutorials",
    element: <Tutorials />,
  },
  {
    path: "notify",
    element: <Notify />,
  },
  {
    path: "page-posts-clone",
    element: <PagePostsClone />,
  },
  {
    path: "fb-picture-carousel",
    element: <FbPictureCarousel />,
  },
  {
    path: "get-groups-from-uid",
    element: <GetGroupsFromUid />,
  },
  {
    path: "group-posts-delete",
    element: <GroupPostsDelete />,
  },
  {
    path: "get-gid-info",
    element: <GetGidInfo />,
  },
  {
    path: "page-posts-remover",
    element: <PagePostsRemover />,
  },
  {
    path: "login/*",
    element: <Login />,
  },
  {
    path: "admin/login",
    element: <AdminLogin />,
  },
  {
    path: "login/admin",
    element: <AdminLogin />,
  },
  {
    path: "admin-tools",
    element: <AdminTools />,
  },
  {
    path: "admin-extensions",
    element: <AdminExtensions />,
  },
  {
    path: "admin-script",
    element: <AdminExtension />,
  },
  {
    path: "admin-packages",
    element: <AdminPackages />,
  },
  {
    path: "admin-users",
    element: <AdminUsers />,
  },
  {
    path: "admin-orders",
    element: <AdminOrders />,
  },
  {
    path: "admin-withdraw",
    element: <AdminWithdraw />,
  },
  {
    path: "admin-bank",
    element: <AdminBank />,
  },
  {
    path: "admin-version",
    element: <AdminVersions />,
  },
  {
    path: "admin-history-tool",
    element: <AdminHistoryTools />,
  },
  {
    path: "admin-ref",
    element: <AdminRef />,
  },
  {
    path: "admin-changelog",
    element: <AdminsLog />,
  },
  {
    path: "admin-guide",
    element: <AdminGuide />,
  },
  {
    path: "admin-notify",
    element: <AdminNotify />,
  },
  {
    path: "admin-banner",
    element: <AdminBanner />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "change-password",
    element: <ChangePassword />,
  },
  {
    path: "verify",
    element: <Verify />,
  },
  {
    path: "info",
    element: <Info />,
  },
  {
    path: "home",
    element: <Home />,
  },
];
