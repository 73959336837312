import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sendGet } from "../../../axios/axiosClient";
import Cookies from "js-cookie";
import { Message, useToaster } from "rsuite";
const GetCallBackGoogle = () => {
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  // /users/auth/login/facebook/callback

  const sendCallback = async (input) => {
    try {
      const res = await sendGet(`/users/auth/login/google/callback`, input);
      if (res.status === 200) {
        // console.log(res.data);
        if (res?.data?.data?.token_pass) {
          navigate(
            `/change-password?token=${res?.data?.data?.token_pass}&email=${res?.data?.data?.email}`
          );
        } else {
          Cookies?.set("token", res?.data?.data?.token);
          navigate("/home");
        }
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
      const timer = setTimeout(() => {
        navigate("/login");
      }, 1);
    }
  };

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  useEffect(() => {
    const paramsObject = {};
    for (const [key, value] of params.entries()) {
      paramsObject[key] = value;
    }
    sendCallback(paramsObject);
  }, []);
  return <div></div>;
};

export default GetCallBackGoogle;
