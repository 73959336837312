export const CheckFunction = (input) => {
  const urlRegex = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/;
  if (urlRegex.test(input)) {
    // Thử tạo một đối tượng URL từ chuỗi
    try {
      new URL(input);
      // Nếu không có lỗi, trả về true
      return true;
    } catch (error) {
      // Nếu có lỗi, trả về false
      return false;
    }
  } else {
    // Nếu chuỗi không khớp với biểu thức chính quy, trả về false
    return false;
  }
};
