import React from "react";
import "./dieuKhoan.css";

const DieuKhoan = () => {
  return (
    <div className="card p-2">
      <div className="text-center">
        <h3 className="fw-bold">Điều khoản sử dụng</h3>
      </div>
      <div className="dieuKhoanBody">
        <div>
          <h5>1. QUY ĐỊNH CHUNG</h5>
          <p>
            Điều khoản Sử dụng quy định các điều khoản ràng buộc bạn khi sử dụng
            các Dịch vụ. Thuật ngữ “Bạn” và/hoặc “Người sử dụng” sau đây được
            gọi chung để chỉ tới những người sử dụng các Dịch vụ. Vui lòng
            nghiên cứu kỹ và lưu lại một bản Điều khoản Sử dụng này.
          </p>
          <p>
            Bằng việc ghé thăm website https://amaiagency.com/ và sử dụng các
            Dịch vụ, Bạn đồng ý bị ràng buộc với Điều khoản Sử dụng này, Chính
            sách Quảng cáo và Chính sách Bảo mật của chúng tôi.
          </p>
          <p>
            Chúng tôi thực hiện Điều khoản Sử dụng này, Chính sách Quảng cáo và
            Chính sách bảo mật theo quy định của pháp luật hiện hành và không
            nội dung nào trong các tài liệu trên cản trở quyền của chúng tôi
            tuân thủ các quy định hoặc yêu cầu của chính phủ, tòa án, cơ quan
            thi hành án liên quan đến việc Bạn sử dụng các Dịch vụ hoặc thông
            tin do chúng tôi nhận được hoặc thu được từ việc sử dụng các Dịch vụ
            của Bạn.
          </p>
        </div>
        <div>
          <h5>2. TỪ CHỐI BẢO ĐẢM</h5>
          <p>
            Website https://amaiagency.com/ và các Dịch vụ được cung cấp dựa
            trên nguyên tắc không bảo hành, trên cơ sở dịch vụ và tính năng sẵn
            có mà Chúng tôi không đảm bảo rằng các tính năng trên website
            https://amaiagency.com/ hoặc các Dịch vụ sẽ đáp ứng các yêu cầu của
            Bạn hoặc đảm bảo rằng sự vận hành các phần mềm hoặc các Dịch vụ sẽ
            không bị gián đoạn hoặc bị lỗi.
          </p>
          <p>
            Chúng tôi không chịu trách nhiệm đối với bất kỳ sự mất mát dữ liệu,
            tổn thất lợi ích nào hoặc các vấn đề khác liên quan đến việc truy
            cập website https://amaiagency.com/ và sử dụng các Dịch vụ, bao gồm
            nhưng không giới hạn đến tất cả các thông tin, dữ liệu, văn bản,
            phần mềm, âm nhạc, âm thanh, hình ảnh, đồ họa, video, thông điệp
            hoặc các nguyên liệu khác (“Nội dung”) mà Bạn có thể lưu giữ, đưa
            lên, hoặc truyền tải thông qua Dịch vụ. Bạn đồng ý sử dụng các Dịch
            vụ với tất cả sự rủi ro.
          </p>
        </div>
        <div>
          <h5>3. THAY ĐỔI ĐIỀU KHOẢN SỬ DỤNG</h5>
          <p>
            Chúng tôi giữ quyền thay đổi và/hoặc sửa đổi mà không cần báo trước
            bất kỳ điều khoản nào trong Điều khoản Sử dụng này tùy từng thời
            điểm. Những sự thay đổi và/hoặc sửa đổi như vậy sẽ có hiệu lực ngay
            khi đăng lên website https://amaiagency.com/. Nếu Bạn tiếp tục truy
            cập https://amaiagency.com/ hoặc sử dụng các Dịch vụ sau khi các
            thay đổi và/hoặc sửa đổi được đăng lên, Bạn đã chấp nhận và đồng ý
            với các thay đổi và/hoặc sửa đổi đó.
          </p>
        </div>
        <div>
          <h5>4. QUY ĐỊNH SỬ DỤNG DỊCH VỤ</h5>
          <p>
            Khi sử dụng Dịch vụ của chúng tôi, bên cạnh việc phải ký hợp đồng
            dịch vụ theo mẫu do chúng tôi ban hành, Bạn còn bị ràng buộc phải
            chấp thuận và tuân thủ các điều kiện quy định dưới đây :
          </p>
          <p>– Thanh toán phí dịch vụ theo thỏa thuận</p>
          <p>– Tuân thủ quy định của pháp luật</p>
          <p>
            – Các tranh chấp xảy ra nếu có do hai bên thỏa thuận giải quyết trên
            cơ sở hợp tác và thỏa thuận.
          </p>
        </div>
        <div>
          <h5>
            5. QUY ĐỊNH VỀ ĐĂNG KÝ, SỬ DỤNG TÀI KHOẢN VÀ THANH TOÁN PHÍ DỊCH VỤ
          </h5>
          <p>
            Chúng tôi cung cấp các Dịch vụ cho Bạn hoàn toàn thông qua hệ thống
            trực tuyến của Website https://amaiagency.com/ từ khi Bạn bắt đầu
            đăng ký tài khoản, lựa chọn dịch vụ cũng như tiến hành thanh toán
            phí dịch vụ mà Bạn sử dụng Dịch vụ của Chúng tôi.
          </p>
          <p>
            Bạn xác nhận và đồng ý rằng Bạn đã nghiên cứu kỹ càng và sẽ tuân thủ
            đầy đủ các quy định về đăng ký tài khoản, phương thức tính phí và
            thanh toán chi phí được đăng trên Website https://amaiagency.com/
            của chúng tôi (“Quy định về Đăng ký và Sử dụng Dịch vụ”). Bằng việc
            Bạn xác nhận hoàn thành thủ tục đăng ký tài khoản trên website
            https://amaiagency.com/, Bạn đã chấp nhận bị ràng buộc thực hiện đối
            với các Quy định về Đăng ký và Sử dụng Dịch vụ như vậy khi Bạn sử
            dụng các Dịch vụ.
          </p>
          <p>
            Chúng tôi luôn thay đổi và/hoặc sửa đổi nội dung Điều khoản Sử dụng
            này cũng như các Quy định về Đăng ký và Sử dụng Dịch vụ với mục đích
            hoàn thiện tốt nhất nội dung các quy định và đáp ứng tối đa sự tiện
            dụng và hiệu quả khi Bạn sử dụng các Dịch vụ cũng như đảm bảo nâng
            cao lợi ích của Chúng tôi khi cung cấp các Dịch vụ.
          </p>
          <p>
            Chúng tôi giữ quyền nhưng không có nghĩa vụ thông báo tới Bạn đối
            với bất kỳ sự thay đổi và/hoặc sửa đổi các Quy định về Đăng ký và Sử
            dụng Dịch vụ. Những sự thay đổi và/hoặc sửa đổi như vậy sẽ có hiệu
            lực ngay khi đăng lên Website https://amaiagency.com/. Nếu Bạn tiếp
            tục sử dụng các Dịch vụ sau khi các thay đổi và/hoặc sửa đổi được
            đăng lên, Bạn đã chấp nhận và đồng ý với các thay đổi và/hoặc sửa
            đổi đó. Chúng tôi khuyến nghị Bạn thường xuyên kiểm tra trên Website
            https://amaiagency.com/ và liên hệ với nhân viên hỗ trợ của Chúng
            tôi để có được bản cập nhật các Quy định về Đăng ký và Sử dụng Dịch
            vụ mới nhất.
          </p>
        </div>
        <div>
          <h5>6. GIỚI HẠN TRÁCH NHIỆM</h5>
          <p>
            Bạn xác nhận và đồng ý rằng chúng tôi chỉ sẵn sàng cung cấp các Dịch
            vụ nếu Bạn đồng ý giới hạn trách nhiệm của chúng tôi đối với Bạn và
            các bên thứ ba. Bạn đồng ý rằng Bạn chịu trách nhiệm bồi thường duy
            nhất và toàn bộ đối với bất kỳ khiếu nại, khiếu kiện nào tới chúng
            tôi liên quan đến bất kỳ sự vi phạm quy định sử dụng nào do việc Bạn
            sử dụng các Dịch vụ hoặc ngừng sử dụng Dịch vụ của chúng tôi.
          </p>
          <p>
            Bạn đồng ý tự chịu trách nhiệm với các nội dung và thông tin cung
            cấp cho chúng tôi
          </p>
          <p>
            Bạn đồng ý bảo vệ, bồi thường và giữ cho chúng tôi và các công ty
            liên kết của chúng tôi và mỗi nhân viên, giám đốc, người lao động,
            đại lý, đại diện, người cung cấp thông tin và bên cấp phép của chúng
            tôi không bị tổn hại bởi bất kỳ khiếu nại, khiếu kiện, chi phí, tổn
            thất, thiệt hại, phán quyết của tòa án và phí tổn nào, bao gồm nhưng
            không giới hạn phí luật sư, phí bồi thường thiệt hại, chi phí tố
            tụng, lãi chậm trả liên quan đến hoặc phát sinh từ bất kỳ khiếu nại,
            mâu thuẫn, tranh chấp, thủ tục tố tụng pháp lý tại Tòa án hoặc các
            tổ chức Trọng tài, tổ chức Hòa giải, các quyết định của cơ quan Nhà
            nước có thẩm quyền … liên quan đến hoặc phát sinh từ các sản phẩm và
            dịch vụ. Chúng tôi giữ quyền, bằng chi phí của mình, đảm nhận hoàn
            toàn việc bảo vệ và kiểm soát (nhưng không có trách nhiệm) đối với
            bất kỳ vấn đề nào phát sinh như vậy tùy thuộc vào sự bồi thường của
            Bạn.
          </p>
        </div>
        <div>
          <h5>
            7. BẢO VỆ BẢN QUYỀN VÀ QUYỀN SỞ HỮU TRÍ TUỆ KHÁC VỚI CÁC SẢN PHẨM VÀ
            DỊCH VỤ CỦA CHÚNG TÔI
          </h5>
          <p>
            Các Dịch vụ, và tất cả các phần mềm cần thiết, nguyên liệu cấu thành
            của Dịch vụ và khai thác, triển khai Dịch vụ, và bao gồm cả các
            quyền sở hữu trí tuệ đối với các Dịch vụ và sản phẩm của chúng tôi,
            và tất cả các phần mềm cần thiết, nguyên liệu cấu thành của Dịch vụ
            (“Sở hữu Trí tuệ”), được bảo vệ bởi bản quyền, quyền về thương hiệu,
            nhãn dịch vụ, hoặc quyền tài sản khác do chúng tôi sở hữu hoặc được
            sở hữu bởi bên thứ ba nào mà đã cấp quyền sở hữu trí tuệ cho chúng
            tôi. Bạn không được quyền sử dụng bất kỳ tên thương mại, nhãn hiệu
            của hàng hóa và dịch vụ, biểu tượng, tên miền và các hình thức nhận
            diện hàng hóa, dịch vụ đặc trưng nào khác của chúng tôi vào mục đích
            thương mại trừ khi Bạn được sự chấp thuận bằng văn bản của chúng
            tôi, hoặc việc sử dụng của Bạn gây ảnh hưởng, cản trở, tác động xấu
            tới việc hoạt động bình thường của các sản phẩm và dịch vụ, uy tín
            của chúng tôi.
          </p>
          <p>
            Chúng tôi tôn trọng quyền sở hữu trí tuệ của người khác và yêu cầu
            Người sử dụng các Dịch vụ cũng như vậy. Bạn không được tải lên, gắn
            vào, đăng lên, truyền đi hoặc bằng cách khác tạo sẵn bất kỳ nguyên
            liệu cấu thành nào gây ảnh hưởng đến bất kỳ quyền sở hữu trí tuệ về
            bản quyền, thương hiệu, bí mật thương mại hoặc quyền tài sản khác
            của bất kỳ tổ chức hoặc cá nhân nào. Chúng tôi có quyền chấm dứt
            đường truy cập vào các Dịch vụ hoặc bất kỳ dịch vụ nào khác do chúng
            tôi cung cấp đối với người bị nghi ngờ xâm phạm.
          </p>
        </div>
        <div>
          <h5>8. ĐIỀU KHOẢN KHÁC</h5>
          <p className="fw-bold" style={{ fontStyle: "italic" }}>
            Luật điều chỉnh
          </p>
          <p>
            Điều khoản Sử dụng này được điều chỉnh bởi và giải thích phù hợp với
            pháp luật Việt Nam. Nếu bất kỳ điều khoản nào trong Điều khoản Sử
            dụng này không hợp pháp, vô hiệu hoặc vì bất kỳ lý do nào không thể
            thực hiện được, thì điều khoản đó sẽ được xem là tách rời khỏi Điều
            khoản Sử dụng này và không ảnh hưởng đến hiệu lực và tính thực thi
            của bất kỳ điều khoản còn lại nào của Điều khoản Sử dụng này.
          </p>
          <p className="fw-bold" style={{ fontStyle: "italic" }}>
            Chấm dứt
          </p>
          <p>
            Điều khoản Sử dụng này có hiệu lực cho đến khi bị chấm dứt bởi Bạn
            hoặc bởi chúng tôi theo các trường hợp sau đây:
          </p>
          <p>
            Chấm dứt bởi Bạn: Bạn có thể chấm dứt Điều khoản Sử dụng này bằng
            cách không sử dụng các Dịch vụ nữa.
          </p>
          <p>
            Chấm dứt bởi chúng tôi: Điều khoản Sử dụng này chấm dứt ngay lập tức
            mà chúng tôi không cần phải thông báo trước nếu (i) theo sự quyết
            định của chúng tôi, Bạn không tuân thủ bất kỳ điều khoản nào của
            Điều khoản Sử dụng này hoặc (ii) Pháp luật hoặc quyết định của chính
            phủ, tòa án, cơ quan thi hành án yêu cầu chúng tôi chấm dứt Điều
            khoản Sử dụng.
          </p>
          <p className="fw-bold" style={{ fontStyle: "italic" }}>
            Liên hệ
          </p>
          <p>
            Nếu Bạn có bất kỳ câu hỏi nào về Điều khoản Sử dụng này, về hoạt
            động của các Dịch vụ, hoặc sự kết nối của Bạn với chúng tôi, vui
            lòng liên hệ với chúng tôi qua email: support@amaiagency.com. Tất cả
            các vấn đề hoặc mâu thuẫn sẽ được giải quyết nhanh chóng và hợp lý
          </p>
        </div>
      </div>
    </div>
  );
};

export default DieuKhoan;
