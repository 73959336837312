import React, { useContext, useEffect, useState } from "react";
import {
  AiFillCamera,
  AiFillLike,
  AiOutlineCloudUpload,
  AiOutlineShareAlt,
} from "react-icons/ai";
import { BsFillShareFill, BsFire } from "react-icons/bs";
import { BiCommentDetail } from "react-icons/bi";
import { HiOutlineUserGroup } from "react-icons/hi";
import "./groupPostsAnalyze.css";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Form,
  IconButton,
  Input,
  InputGroup,
  Message,
  Radio,
  RadioGroup,
  useToaster,
} from "rsuite";
import { Form as bForm } from "react-bootstrap";
import avatar_image from "../../../../assets/images/645fbc5da4a21.png";
import moment from "moment/moment";
import google_img from "../../../../assets/images/card_google.webp";
import { socket } from "../../../../context/socket";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import Cookies from "js-cookie";
import { sendPost } from "../../../../axios/axiosClient";
import no_image from "../../../../assets/images/default_thumbnail.png";
import LinearProgress from "@mui/material/LinearProgress";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
import axios from "axios";
import { CheckFunction } from "../../../../commons/function/CheckURL";
const GroupPostsAnalyze = () => {
  const [isAnalyze, setIsAnalyzer] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [posts, setPosts] = useState([]);
  const [listPosts, setListPosts] = useState([]);
  const { selectedExtension } = useContext(ExtensionContext);
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMsg, setTextMsg] = useState("");
  const [formValue, setFormValue] = useState({
    sorting: "",
  });
  const [sortOrder, setSortOrder] = useState("");
  const [sortProp, setSortProp] = useState("");
  const [sortState, setSortState] = useState([]);
  const [limitPosts, setLimitPosts] = useState(100);
  const [limitChange, setLimitChange] = useState(0);

  // post_number
  const sortPropData = [
    {
      value: "creation_time",
      label: "Thời gian",
    },
    {
      value: "like_count",
      label: "Thích",
    },
    {
      value: "comment_count",
      label: "Bình luận",
    },
    {
      value: "share_count",
      label: "Chia sẻ",
    },
  ];

  const message = (
    <Message showIcon type={type} closable>
      {textMsg}
    </Message>
  );

  useEffect(() => {
    if (textMsg !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMsg("");
  }, [textMsg]);

  useEffect(() => {
    // console.log("run twice?");
    socket.on("do_task_response", (message) => {
      // console.log(message, "message");
      switch (message?.params?.key_script) {
        case "get_post_from_group":
          if (message?.data?.length > 0) {
            setPosts(message?.data);
          }
          if (message?.done_all === true) {
            setIsAnalyzer(false);
          }
          break;
        case "force_stop":
          setIsAnalyzer(false);
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    if (posts?.length > 0) {
      setListPosts((prevArray) => [...prevArray, ...posts]);
    }
  }, [posts]);

  const getID = async () => {
    if (groupId.trim() === "") {
      setTextMsg("Bạn chưa nhập đường dẫn.");
      return;
    }
    setIsAnalyzer(true);

    if (CheckFunction(groupId.trim())) {
      axios
        .post("https://uid.bufflike.net/fbid", {
          link: groupId,
        })
        .then(function (response) {
          if (response.data.status) {
            getPostFromGroup(response?.data?.fbid);
          } else {
            setTextMsg(response?.data?.message);
          }
        })
        .catch(function (error) {
          setTextMsg("Có lỗi xảy ra.");
          setIsAnalyzer(false);
        });
    } else {
      getPostFromGroup(groupId.trim());
    }
  };

  const getPostFromGroup = async (id) => {
    setListPosts([]);
    const input = {
      key_script: "get_post_from_group",
      extension_key: selectedExtension?.key,
      key_tool: "public_group_posts_analyzer_(viral_finder)",
      params: {
        key_socket: Cookies.get("keySocket"),
        groupId: id,
        post_number: Number(limitPosts),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      // setIsAnalyzer(true);
    } catch (error) {
      console.log(error);
      setIsAnalyzer(false);
    }
  };

  const handleStop = async () => {
    const input = {
      key_script: "force_stop",
      extension_key: selectedExtension?.key,
      key_tool: "force_stop",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSortState([sortOrder, sortProp]);
    switch (sortOrder) {
      case "asc":
        listPosts.sort((a, b) => a[sortProp] - b[sortProp]);
        break;
      case "des":
        listPosts.sort((a, b) => b[sortProp] - a[sortProp]);
        break;
      default:
        break;
    }
  }, [sortOrder, sortProp]);

  return (
    <div>
      <div className="card p-3">
        <div className="mb-3">
          <ToolHeading toolType={window.location.pathname} />
        </div>
        <div className="d-flex gap-3">
          <InputGroup>
            <InputGroup.Addon>
              <a href="https://uid.bufflike.net/" target="_blank">
                <Form.HelpText tooltip className="m-0">
                  Click vào đây để lấy ID
                </Form.HelpText>
              </a>
            </InputGroup.Addon>
            <Input
              placeholder="Nhập đường dẫn hoặc ID nhóm"
              onChange={setGroupId}
            />
          </InputGroup>
          <InputGroup style={{ width: "15%" }}>
            <InputGroup.Addon>
              <Form.HelpText tooltip className="m-0">
                Số lượng bài viết
              </Form.HelpText>
            </InputGroup.Addon>
            <Input
              placeholder="Limit"
              onChange={setLimitPosts}
              value={limitPosts}
            />
          </InputGroup>
          {!isAnalyze ? (
            <Button
              appearance="primary"
              color="orange"
              onClick={getID}
              // onClick={getPostFromGroup}
            >
              <span className="mx-2">BẮT ĐẦU</span>
            </Button>
          ) : (
            <Button
              appearance="primary"
              // color="orange"
              style={{ background: "red" }}
              onClick={() => {
                handleStop();
              }}
            >
              <span>
                <BsFire className="me-2 mb-1 fs-6" />
                DỪNG LẠI
              </span>
            </Button>
          )}
        </div>
        <div>
          <div className="mt-3">
            <Form
              className="d-flex justify-content-between"
              formValue={formValue}
              onChange={setFormValue}
            >
              {/* <Form.Group controlId="checkbox">
                <Form.ControlLabel className="group_membernumber fw-bold">
                  Filter by content type:
                </Form.ControlLabel>
                <Form.Control
                  name="checkbox"
                  accepter={CheckboxGroup}
                  inline
                  className="group_membernumber"
                >
                  <Checkbox value="Photo">Photo</Checkbox>
                  <Checkbox value="Video">Video</Checkbox>
                  <Checkbox value="Text">Text</Checkbox>
                  <Checkbox value="Shared">Shared</Checkbox>
                </Form.Control>
              </Form.Group> */}

              <Form.Group
                controlId="radio"
                className="d-flex align-items-center gap-2"
              >
                <Form.ControlLabel className="fw-bold group_membernumber oneLine">
                  Sắp xếp:
                </Form.ControlLabel>
                <bForm.Select
                  aria-label="Default select example"
                  size="sm"
                  onChange={(e) => setSortProp(e.target.value)}
                  style={{ width: "10rem" }}
                >
                  <option>Chọn giá trị</option>
                  {sortPropData?.map((item) => (
                    <option value={item?.value}>{item?.label}</option>
                  ))}
                </bForm.Select>
                <bForm.Select
                  aria-label="Default select example"
                  size="sm"
                  onChange={(e) => setSortOrder(e.target.value)}
                >
                  <option>Thứ tự</option>
                  <option value={"asc"}>Tăng dần</option>
                  <option value={"des"}>Giảm dần</option>
                </bForm.Select>
              </Form.Group>
            </Form>

            <div>
              <div className="d-flex flex-wrap mt-3">
                {isAnalyze ? (
                  <LinearProgress
                    style={{ width: "100%", marginBottom: "10px" }}
                  />
                ) : (
                  <></>
                )}
                {listPosts?.slice(0, limitPosts).map((item, index) => (
                  <div className="col-3 mb-3" key={index}>
                    <div className="card p-3" style={{ width: "95%" }}>
                      <div
                        className="mb-2 ppa__pagePost"
                        style={{ borderRadius: "5px", overflow: "hidden" }}
                      >
                        <a
                          href={`https://www.facebook.com/${item?.story_id}`}
                          target="_blank"
                          className="blackLink"
                        >
                          <img
                            src={
                              item?.attachments[0]?.media?.image?.uri ===
                              undefined
                                ? no_image
                                : item?.attachments[0]?.media?.image?.uri
                            }
                            alt="google_img"
                            className="pageImage"
                          />
                        </a>
                      </div>
                      <div className="d-flex justify-content-between">
                        <AiFillCamera />
                        <span className="group_membernumber">
                          {new Date(
                            item?.creation_time * 1000
                          ).toLocaleString()}
                        </span>
                      </div>
                      <Input
                        as={"textarea"}
                        rows={5}
                        className="my-2"
                        value={item?.content?.text}
                        readOnly
                      />
                      <div className="d-flex justify-content-between">
                        <span>
                          <AiFillLike className="mb-1 fs-5" />
                          <span>{item?.like_count}</span>
                        </span>
                        <span>
                          <BiCommentDetail /> {item?.comment_count}
                        </span>
                        <span>
                          <AiOutlineShareAlt className="mb-1 fs-5" />
                          <span>{item?.share_count}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupPostsAnalyze;
