import React, { useState, useEffect } from "react";
import "./changelog.css";
import { getUserLog } from "../../../axios/userlogApi";
import { Timeline } from "rsuite";
import { AiFillCheckCircle } from "react-icons/ai";
const ChangeLog = () => {
  const [listChangeLog, setListChangeLog] = useState([]);
  const handleGetlistLog = async () => {
    try {
      const res = await getUserLog();
      setListChangeLog(res.data.data.data);
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    handleGetlistLog();
  }, []);
  const formatDate = (date) => {
    // const dateObject = new Date(date);
    // const hours = dateObject.getUTCHours();
    // const minutes = dateObject.getUTCMinutes();
    // const day = dateObject.getUTCDate();
    // const month = dateObject.getUTCMonth() + 1; // Add 1 because getUTCMonth() returns values from 0 to 11
    // const year = dateObject.getUTCFullYear();

    // const formattedDate = `${hours}:${minutes}  ${day} / ${month} / ${year}`;
    // return formattedDate;
    const dateObject = new Date(date);

    const utcTime = dateObject.getTime();

    const vietnamTimezoneOffset = 7 * 60 * 60 * 1000;

    const localTimeInVietnam = new Date(utcTime + vietnamTimezoneOffset);

    const formattedDate = localTimeInVietnam.toLocaleString("en-US", {
      timeZone: "Asia/Ho_Chi_Minh",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };
  return (
    <div className="log__container" style={{ width: "100%", margin: "0 auto" }}>
      <div className="card p-3 text-center">
          <h4 className="mt-3">ChangeLogs</h4>
        <div className=" mt-4">
          <Timeline className="custom-timeline">
            {listChangeLog.map((item) => (
              <Timeline.Item
                key={item.id}
                dot={
                  <AiFillCheckCircle
                    size={20}
                    color="#15b215"
                    style={{ fontSize: "16px" }}
                  />
                }
              >
                <p className="fw-bold ms-3">{formatDate(item?.created_at)}</p>
                <p className="ms-4">{item?.content}</p>
              </Timeline.Item>
            ))}
          </Timeline>
        </div>
      </div>
    </div>
  );
};

export default ChangeLog;
