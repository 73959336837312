import React, { useContext, useEffect, useState } from "react";
import "./forgotPassword.css";
import { Form, Input, Loader, Message, useToaster } from "rsuite";
import { Button } from "react-bootstrap";
import { sendPost } from "../../axios/axiosClient";
import meeting_img from "../../assets/images/logo/logo-transparent-background.png";
import logo from "../../assets/images/logo/logo-website2.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SidebarContextProvider } from "../../context/SidebarContext";
import Cookies from "js-cookie";

const ForgotPassword = () => {
  const { haveSidebar, setHaveSidebar, haveFooter, setHaveFooter } = useContext(
    SidebarContextProvider
  );
  const [email, setEmail] = useState("");
  const [isSendEmail, setIsSendEmail] = useState(false);
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [sendEmailMsg, setSendEmailMsg] = useState("");
  const params = new URLSearchParams(window.location.search);
  const location = useLocation();
  const navigate = useNavigate();
  const [isResetPassword, setIsGetPassword] = useState(
    params?.size > 0 ? true : false
  );
  const [isLoading, setIsLoading] = useState(false);

  const [input, setInput] = useState({});
  const [password, setPassword] = useState("");
  const [rePassword, setRepassword] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const handleForgotPassword = async () => {
    if (email?.trim() === "") {
      setTextMessage("Hãy điền Email");
      return;
    }
    setIsLoading(true);
    const input = {
      email: email?.trim(),
    };
    try {
      const res = await sendPost(`/users/auth/forgot-password`, input);
      if (res.status === 200) {
        setTextMessage(res?.data?.msg);
        setSendEmailMsg(res?.data?.msg);
        setIsSendEmail(true);
      }
    } catch (error) {
      //   console.log(error);
      setTextMessage(error?.response?.data?.error_msg);
    }
    setIsLoading(false);
  };

  const handleSendVerifyEmail = async () => {
    if (email?.trim() === "") {
      setTextMessage("Hãy điền Email");
      return;
    }
    setIsLoading(true);
    const input = {
      email: email?.trim(),
    };
    try {
      const res = await sendPost(`/users/auth/send-verify`, input);
      if (res.status === 200) {
        setTextMessage(res?.data?.msg);
        setSendEmailMsg(res?.data?.msg);
        setIsSendEmail(true);
      }
    } catch (error) {
      //   console.log(error);
      setTextMessage(error?.response?.data?.error_msg);
    }
    setIsLoading(false);
  };

  const handleChangePassword = async () => {
    if (password?.trim() === "" || rePassword?.trim() === "") {
      setTextMessage("Chưa nhập mật khẩu.");
      return;
    }
    if (password !== rePassword) {
      setTextMessage("Mật khẩu bạn nhập không trùng nhau");
      return;
    }
    const form = {
      ...input,
      password: password,
    };
    try {
      const res = await sendPost(`/users/auth/reset-password`, form);
      if (res.status === 200 || res.status === 201) {
        Cookies.set("token", res.data.data.token);
        setHaveSidebar(true);
        setHaveFooter(true);
        navigate("/home");
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  useEffect(() => {
    // console.log(params?.size, "params");
    // console.log(location, "location");
    const paramsObject = {};
    for (const [key, value] of params.entries()) {
      paramsObject[key] = value;
    }
    if (location?.pathname !== "/verify" && params?.size > 0) {
      setInput(paramsObject);
    }
  }, []);

  return (
    <div className="loginCard">
      <div className="d-flex">
        <div className="col-12 col-lg-8 d-none d-lg-block loginText">
          <div className="loginDescription">
            <p className="fw-bold h1" style={{ fontSize: "5rem" }}>
              80%
            </p>
            <p>
              Các cuộc thảo luận về thương hiệu của bạn xảy ra bên ngoài kênh
              riêng của bạn.
            </p>
            <p>
              Cùng Amai MMo Tools khám phá những dữ liệu internet bạn chưa biết
              tới.
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <img src={meeting_img} alt="" className="loginDescriptionImage" />
          </div>
        </div>
        <div className="col-12 col-lg-5 loginForm px-5">
          <div
            className="d-flex align-items-center gap-2"
            style={{ paddingTop: "20vh" }}
          >
            <img src={logo} alt="" style={{ width: "50px", height: "50px" }} />
            <h3 className="fw-bold m-0">Amai MMo Tools</h3>
          </div>

          <h4 className="text-left fw-bold my-3">
            {isResetPassword
              ? `${
                  location?.pathname === "/change-password"
                    ? "Cài đặt mật khẩu"
                    : "Đặt lại mật khẩu"
                }`
              : `${
                  location?.pathname === "/verify"
                    ? "Gửi lại email xác thực"
                    : "Khôi phục mật khẩu"
                }`}
          </h4>
          {isResetPassword ? (
            <>
              {location?.pathname === "/change-password" ? (
                <p>
                  Để thuận tiện trong việc sử dụng, bạn nên có mật khẩu để có
                  thể sử dụng trong hệ thống của chúng tôi.
                </p>
              ) : (
                ""
              )}
              <Input
                placeholder="Mật khẩu"
                type="password"
                value={password}
                onChange={setPassword}
              />
              <Input
                placeholder="Nhập lại mật khẩu"
                value={rePassword}
                onChange={setRepassword}
                type="password"
                className="mt-3"
              />
              <Button
                className="mt-3 btn btn-primary fw-bold"
                style={{ width: "100%" }}
                onClick={handleChangePassword}
              >
                {location?.pathname === "/change-password"
                  ? "Đặt mật khẩu"
                  : "Đặt lại mật khẩu"}
              </Button>
            </>
          ) : (
            <>
              {isSendEmail ? (
                <>
                  <p>{sendEmailMsg}</p>
                </>
              ) : (
                <>
                  <Input
                    placeholder="Nhập email của bạn"
                    value={email}
                    onChange={setEmail}
                  />
                  {location?.pathname === "/verify" ? (
                    <Button
                      className="mt-3 btn btn-primary fw-bold"
                      style={{ width: "100%" }}
                      disabled={isSendEmail}
                      onClick={() => {
                        if (!isLoading) {
                          handleSendVerifyEmail();
                        }
                      }}
                    >
                      {isLoading ? <Loader /> : "Gửi Email"}
                    </Button>
                  ) : (
                    <Button
                      className="mt-3 btn btn-primary fw-bold"
                      style={{ width: "100%" }}
                      disabled={isSendEmail}
                      onClick={() => {
                        if (!isLoading) {
                          handleForgotPassword();
                        }
                      }}
                    >
                      {isLoading ? <Loader /> : "Lấy lại mật khẩu"}
                    </Button>
                  )}
                </>
              )}
            </>
          )}
          <div className="mt-3">
            <Link to={"/login"}>Quay lại đăng nhập</Link>
          </div>

          {/* <div className="card card_login p-4">
          
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
