import React, { useContext, useEffect, useState } from "react";
import "./paymentmodal.css";
import PaypalImg from "../../assets/images/logo/paypal.svg";
import VnPay from "../../assets/images/logo/vnpay.svg";
import VisaImg from "../../assets/images/logo/visa.svg";
import TpBankImg from "../../assets/images/logo/tpBank.svg";
import { useCopyToClipboard } from "react-use";
import { AiOutlineClose } from "react-icons/ai";
import MomoImg from "../../assets/images/logo/momo.svg";
import { createOrder } from "../../axios/packageApi";
const PaymentModal = ({
  packageList,
  packageId,
  onSubmit,
  listBank,
  discount,
  remainDay,
}) => {
  const [state, copyToClipBoard] = useCopyToClipboard();
  const [bankInfo, setBankInfo] = useState(false);
  const [bankCode, setBankCode] = useState("");
  const [method, setMethod] = useState("bank");
  const handleChange = (e) => {
    setMethod(e.currentTarget.value);
  };
  const handleClose = async () => {
    onSubmit();
  };
  const priceFormat = (price) => {
    return price?.toLocaleString("vi", { style: "currency", currency: "VND" });
    // return price?.toLocaleString("en-US", {
    //   style: "currency",
    //   currency: "USD",
    // });
  };
  const handlePayment = async (id) => {
    let input = {};
    if (method == "paypal") {
      input = {
        package_id: packageId,
        payment_method: 2,
      };
    } else if (method == "vnpay") {
      input = {
        package_id: packageId,
        payment_method: 1,
      };
    } else if (method == "bank") {
      input = {
        package_id: packageId,
        payment_method: 3,
      };
    }
    try {
      const res = await createOrder(input);
      if (method == "paypal" || method == "vnpay") {
        if (res.status == 200) {
          window.open(res.data?.data?.url_payment);
        }
      } else {
        if (res.status == 200) {
          setBankInfo(true);
          setBankCode(res.data?.data?.code_auto);
        }
      }
    } catch (err) {
      throw err;
    }
  };

  const checkType = (type) => {
    switch (type) {
      case 1:
        return "Ngày";
      case 2:
        return "Tháng";
      case 3:
        return "Năm";
      default:
        break;
    }
  };

  useEffect(() => {
    setBankInfo(false);
    if (bankCode.length !== 0 && method == "bank") {
      setBankInfo(true);
    }
  }, [method]);
  return (
    <div className="p__modal">
      <div className="p__modal__wrapper">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleClose();
          }}
          className="p__modal__closebutton text-dark"
        >
          <AiOutlineClose />
        </div>
        <div className="p__modal__container">
          <div className="p__modal__body">
            <div className="row d-flex justify-content-between">
              <div className="col-4">
                <div className="border-bottom pb-2">PAYMENT METHOD</div>
                <div className="mt-4">
                  {/* <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        name="method"
                        value="paypal"
                        onChange={handleChange}
                        checked={method == "paypal"}
                      />
                      <div className="ms-2">PayPal</div>
                    </div>
                    <img src={PaypalImg} alt="" />
                  </div> */}
                  <div className="mt-2">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex">
                        <input
                          type="radio"
                          name="method"
                          value="bank"
                          onChange={handleChange}
                          checked={method == "bank"}
                        />
                        <div className="ms-2">Bank Transfer</div>
                      </div>
                      <img src={VisaImg} alt="" />
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex">
                        <input
                          type="radio"
                          name="method"
                          value="vnpay"
                          onChange={handleChange}
                          checked={method == "vnpay"}
                        />
                        <div className="ms-2">
                          <span style={{ textDecoration: "line-through" }}>
                            VNPAY
                          </span>{" "}
                          <small className="text-danger">Bảo trì</small>
                        </div>
                      </div>
                      <img src={VnPay} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-7">
                <div className="border-bottom pb-2">PAYMENT INFORMATION</div>
                <div className="mt-4 fw-bold d-flex justify-content-between align-items-center">
                  <div>Gói:</div>
                  <div>
                    {/* {(packageId == 1 && "1") ||
                      (packageId == 2 && "6") ||
                      (packageId == 3 && "12")}{" "}
                    Tháng */}
                    {packageList.find((item) => item.id == packageId)?.limit}{" "}
                    {checkType(
                      packageList.find((item) => item.id == packageId)?.type
                    )}
                  </div>
                </div>
                <div className="mt-2 fw-bold d-flex justify-content-between align-items-center">
                  <div>Số ngày còn lại:</div>
                  <div>
                    <span className="">{remainDay} Ngày</span>
                  </div>
                </div>
                {discount ? (
                  <div className="mt-2 fw-bold d-flex justify-content-between align-items-center">
                    <div>Giảm giá:</div>
                    <div>
                      <span className="">{priceFormat(Number(discount))}</span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="mt-2 border-bottom pb-2 fw-bold d-flex justify-content-between align-items-center">
                  <div>Giá tiền:</div>
                  <div>
                    {discount ? (
                      <span className="moneyAfterDiscount">
                        {priceFormat(
                          Number(
                            packageList.filter(
                              (item) => item.id == packageId
                            )[0]?.money
                          )
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                    {priceFormat(
                      Number(
                        packageList.filter((item) => item.id == packageId)[0]
                          ?.money - discount
                      )
                    )}
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (method === "vnpay") {
                    } else {
                      handlePayment();
                    }
                  }}
                  className="mt-3 confirm__btn"
                >
                  {method === "vnpay" ? "Đang bảo trì" : "Xác nhận"}
                </div>
                {(method == "vnpay" && (
                  <div className="mt-2 payment__info">
                    Lưu ý: Sau khi ấn xác nhận bạn sẽ được chuyển tiếp sang
                    trang thanh toán của VNPAY.
                    <p className="text-danger">
                      Hệ thống có thể sẽ mất một vài phút sau khi thanh toán để
                      ghi nhận thông tin của bạn.
                    </p>
                  </div>
                )) ||
                  (method == "paypal" && (
                    <div className="mt-2 payment__info">
                      Lưu ý: Sau khi ấn xác nhận bạn sẽ được chuyển tiếp sang
                      trang thanh toán của PayPal.
                      <p className="text-danger">
                        Hệ thống có thể sẽ mất một vài phút sau khi thanh toán
                        để ghi nhận thông tin của bạn.
                      </p>
                    </div>
                  )) || (
                    <div className="mt-2 payment__info">
                      Lưu ý: Sau khi ấn xác nhận bạn sẽ nhận được thông tin ngân
                      hàng và nội dung chuyển khoản.
                      <p className="text-danger">
                        Hệ thống có thể sẽ mất một vài phút sau khi thanh toán
                        để ghi nhận thông tin của bạn.
                      </p>
                    </div>
                  )}
                {bankInfo ? (
                  <>
                    <div className="bank__info mt-3 border-top pt-3">
                      <div className="mb-3">
                        Nội dung chuyển khoản:{" "}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            copyToClipBoard(bankCode);
                          }}
                          className="text-success ms-4 fw-bold"
                        >
                          {bankCode}
                        </span>
                      </div>
                      <div className="d-flex">
                        <img src={TpBankImg} alt="" />
                        <div className="ms-4  w-100">
                          <div className="d-flex justify-content-between">
                            <div className="col-4">Ngân hàng:</div>
                            <div className="fw-bold ">
                              {listBank ? listBank[0]?.bank : <></>}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="col-4">STK:</div>
                            <div className="fw-bold ">
                              {listBank ? listBank[0]?.bank_code : <></>}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="col-4">Chủ tài khoản:</div>
                            <div className="fw-bold">
                              {listBank ? listBank[0]?.fullname : <></>}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="col-4">Chi nhánh:</div>
                            <div className="text-end">
                              {listBank ? listBank[0]?.bank_branch : <></>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bank__info  mt-3 border-top pt-3">
                      <div className="d-flex">
                        <img src={MomoImg} alt="" />
                        <div className="ms-4 w-100">
                          <div className="d-flex justify-content-between">
                            <div className="col-4">Ngân hàng:</div>
                            <div className="fw-bold ">
                              {listBank ? listBank[1]?.bank : <></>}
                            </div>
                          </div>
                          <div className="d-flex mt-2 justify-content-between">
                            <div className="col-4">STK:</div>
                            <div className="fw-bold ">
                              {listBank ? listBank[1]?.bank_code : <></>}
                            </div>
                          </div>
                          <div className="d-flex mt-2 justify-content-between">
                            <div className="col-4">Chủ tài khoản:</div>
                            <div className="fw-bold">
                              {listBank ? listBank[1]?.fullname : <></>}
                            </div>
                          </div>
                          <div className="d-flex mt-2 justify-content-between align-items-center">
                            <div className="col-4">Chi nhánh:</div>
                            <div className="text-end">
                              {listBank ? listBank[1]?.bank_branch : <></>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
