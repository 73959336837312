import Cookies from "js-cookie";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import adminSidebar from "../../commons/data/adminSidebar";
import { toolCategories } from "../../commons/data/toolCategory";
import Card from "../../components/card/Card";
import { ExtensionContext } from "../../context/ExtensionContext";
import { SidebarContextProvider } from "../../context/SidebarContext";
import "./home.css";
const Home = () => {
  const { selectedCategory, setHaveSidebar, setHaveFooter } = useContext(
    SidebarContextProvider
  );
  const { listTools, isLoadingData, webTools } = useContext(ExtensionContext);
  const navigate = useNavigate();
  useEffect(() => {
    setHaveFooter(true);
    setHaveSidebar(true);
  }, []);

  const checkItem = (key) => {
    for (let obj of listTools) {
      if (obj.key === key) {
        return true;
      }
    }
    return false;
  };

  return (
    <div>
      <div
      //  className="home__menuItems"
      >
        {Cookies?.get("admin") ? (
          <div className="mt-5">
            <h5 className="fw-bold d-flex align-items-center gap-2 categoryHeading">
              Admin Category
            </h5>
            <div className="home__menuItems">
              {adminSidebar?.map((item, key) => (
                <div
                  key={key}
                  className="home__menuItem"
                  style={{
                    marginLeft: `${key % 5 === 0 ? "" : "1.25%"}`,
                  }}
                >
                  <Card title={item?.name} path={item?.path} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <>
            {isLoadingData ? (
              <div className="mt-5 d-flex justify-content-center">
                <Loader />
              </div>
            ) : (
              <>
                {toolCategories?.map((category, k) => {
                  if (k !== 0) {
                    return (
                      <div key={k} className="mt-5">
                        <h5 className="fw-bold d-flex align-items-center gap-2 categoryHeading">
                          {category?.image} {category?.title}
                        </h5>
                        <div className="home__menuItems">
                          {category?.tools?.map((tool, key) => (
                            <div
                              key={key}
                              className="home__menuItem"
                              style={{
                                marginLeft: `${key % 5 === 0 ? "" : "1.25%"}`,
                              }}
                            >
                              {checkItem(tool?.key) ? (
                                <Card
                                  title={tool?.title}
                                  // image={tool?.image}
                                  image={tool?.icon}
                                  description={
                                    webTools.find(
                                      ({ key }) => key === tool?.key
                                    )?.description
                                  }
                                  path={tool?.path}
                                  keyTool={tool?.key}
                                />
                              ) : (
                                <Card
                                  title={tool?.title}
                                  image={tool?.icon}
                                  description={
                                    webTools.find(
                                      ({ key }) => key === tool?.key
                                    )?.description
                                  }
                                  path={"/package"}
                                  key={tool?.key}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  }
                })}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
