import React, { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  CheckboxGroup,
  Input,
  InputPicker,
  Form,
  DatePicker,
  DateRangePicker,
  Button,
  useToaster,
  Message,
  Loader,
  Modal,
} from "rsuite";
import "./groupPostsDelete.css";
import no_image from "../../../../assets/images/default_thumbnail.png";
import { Form as bsForm } from "react-bootstrap";
import { socket } from "../../../../context/socket";
import Cookies from "js-cookie";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import { sendPost } from "../../../../axios/axiosClient";
import moment from "moment/moment";
import { BiCommentDetail } from "react-icons/bi";
import { AiFillDelete, AiOutlineLike, AiOutlineShareAlt } from "react-icons/ai";
import SelectedPageNotFromAd from "../../../../components/selectedPage/SelectedPageNotFromAd";
import LinearProgress from "@mui/material/LinearProgress";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
import { DataContext } from "../../../../context/DataContext";

const GroupPostsDelete = () => {
  const { selectedExtension } = useContext(ExtensionContext);
  const {
    setListPagesAT,
    setListPagesNonAT,
    listPagesAT,
    listPagesNonAT,
    isSendScript,
  } = useContext(DataContext);
  const [isSendScriptState, setIsSendScriptState] = useState(false);
  const toolType = window.location.pathname;
  const [listPages, setListPages] = useState([]);
  const [pageId, setPageId] = useState();
  // const [listPagesAT, setListPagesAT] = useState([]);
  // const [listPagesNonAT, setListPagesNonAT] = useState([]);
  const current = new Date();
  const [timer, setTimer] = useState([
    new Date().setMonth(current.getMonth() - 1),
    current,
  ]);
  const [posts, setPosts] = useState([]);
  const [listPosts, setListPosts] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isDeletePost, setIsDeletePost] = useState(false);
  const [removePosts, setRemovePosts] = useState([]);
  const [checkGroups, setCheckGroups] = useState([]);
  const [isError, setIrError] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e) => {
    if (listPosts?.length > 0) {
      setDeleteAll(true);
      setOpen(true);
      if (e === "select") {
        if (checkGroups?.length > 0) {
          setDeleteAll(false);
          setOpen(true);
        } else {
          setTextMessage("Không có bài viết nào được chọn");
          setOpen(false);
        }
      } else if (e === "all") {
        setDeleteAll(true);
        setOpen(true);
      }
    } else {
      setTextMessage("Không có bài viết");
    }
  };
  const handleClose = () => setOpen(false);
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  useEffect(() => {
    // console.log("run twice?");
    socket.on("do_task_response", (message) => {
      // socket.off("do_task_response").on("do_task_response", (message) => {
      switch (message?.params?.key_script) {
        case "get_all_pages_is_admin":
          if (message?.data) {
            // setListPages(message?.data);
            setListPagesNonAT(message?.data);
            // setPageId(message?.data[0]?.id);
          }
          break;
        case "get_pages_is_admin":
          // console.log(message, "page token");
          setListPagesAT(message?.data?.page);
          if (message?.data?.page.length === undefined) {
            setIrError(true);
          } else {
            setIrError(false);
          }
          break;
        case "get_list_post_in_page":
          console.log(message);
          if (message?.error) {
            setTextMessage(message?.error);
          } else if (message?.data?.length > 0) {
            setPosts(message?.data);
          }
          if (message?.done_all) {
            setIsLoadingData(false);
          }
          break;
        case "delete_post_in_fanpage":
          if (message?.data?.status === "deleted") {
            setTextMessage("Xoá bài viết thành công !!!");
            setRemovePosts(message?.params?.story_ids);
            setCheckGroups([]);
          } else {
            setTextMessage("Xoá thất bại.");
          }
          handleClose();
          setIsDeletePost(false);
          break;
        default:
          break;
      }
      // setUiddata([...uidData, ...message?.data]);
    });
  }, []);

  useEffect(() => {
    if (listPagesAT?.length && listPagesNonAT?.length) {
      const finalArr = listPagesAT?.map((item1) => {
        return {
          id_non_at: listPagesNonAT?.find(
            (item2) => item2?.name === item1?.name
          )?.id,
          id: item1?.id,
          url: item1?.picture?.data?.url,
          name: item1?.name,
        };
      });
      setListPages(finalArr);
      if (finalArr[0]?.id !== undefined) {
        setPageId(finalArr[0]?.id);
      } else {
        setPageId("");
      }
    } else {
      setListPages([]);
      if (isSendScriptState) {
        setPageId("");
      }
    }
  }, [listPagesAT, listPagesNonAT]);

  useEffect(() => {
    if (posts?.length > 0) {
      setListPosts((old) => [...old, ...posts]);
    }
  }, [posts]);

  useEffect(() => {
    if (removePosts?.length > 0) {
      const newCheckGroups = checkGroups?.filter(
        (item) => !removePosts.includes(item?.id)
      );
      const newList = listPosts.filter(
        (item) => !removePosts.includes(item?.id?.split("_")[1])
      );
      setListPosts(newList);
      setCheckGroups(newCheckGroups);
    }
    // setListPages(filteredArray);
  }, [removePosts]);

  const getListPostInPage = async () => {
    setListPosts([]);
    setCheckGroups([]);
    if (timer?.length !== 2) {
      setTextMessage("Bạn cần chọn ngày");
      return;
    }
    if (pageId === "") {
      setTextMessage("Bạn cần nhập page ID");
      return;
    }
    const input = {
      key_script: "get_list_post_in_page",
      extension_key: selectedExtension?.key,
      key_tool: "high_speed_page_posts_remover",
      params: {
        key_socket: Cookies.get("keySocket"),
        pageId: pageId,
        since: moment(timer[0]).format("X"),
        until: moment(timer[1]).format("X"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setIsLoadingData(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletePost = async () => {
    const id_non_at = listPages?.find((item) => item?.id === pageId)?.id_non_at;

    let storyIds = [];
    if (deleteAll) {
      storyIds = listPosts?.map((item) => {
        return item?.id?.split("_")[1];
      });
    } else {
      storyIds = checkGroups?.map((item) => {
        return item?.split("_")[1];
      });
    }
    const input = {
      key_script: "delete_post_in_fanpage",
      extension_key: selectedExtension?.key,
      key_tool: "high_speed_page_posts_remover",
      params: {
        key_socket: Cookies.get("keySocket"),
        page_id: id_non_at,
        story_ids: storyIds,
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setIsDeletePost(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getListPage = async () => {
    const input = {
      key_script: "get_all_pages_is_admin",
      extension_key: selectedExtension?.key,
      key_tool: "high_speed_page_posts_remover",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
    setIsSendScriptState(true);
  };

  const getListPageAT = async () => {
    const input = {
      key_script: "get_pages_is_admin",
      extension_key: selectedExtension?.key,
      key_tool: "get_friend",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
    setIsSendScriptState(true);
  };

  useEffect(() => {
    if (selectedExtension !== undefined) {
      if (!listPagesNonAT?.length) {
        getListPage();
      }
      if (!listPagesAT?.length) {
        getListPageAT();
      }
    }
  }, [selectedExtension]);

  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <Modal
        backdrop={"static"}
        keyboard={false}
        open={open}
        onClose={handleClose}
        style={{ top: "13%" }}
      >
        <Modal.Header>
          <Modal.Title className="fw-bold">Xoá bài viết</Modal.Title>
        </Modal.Header>
        <Modal.Body>Ban có chắc chắn muốn xoá bài viết này</Modal.Body>
        <Modal.Footer>
          {isDeletePost ? (
            <Button appearance="primary">
              <Loader />
            </Button>
          ) : (
            <>
              <Button onClick={handleDeletePost} appearance="primary">
                Ok
              </Button>
              <Button onClick={handleClose} appearance="subtle">
                Huỷ
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <div className="mt-3 card p-3" style={{ background: "antiquewhite" }}>
        <div className="mb-3">
          <ToolHeading toolType={window.location.pathname} />
        </div>
        <Form>
          {/* <Form.Group controlId="checkbox">
              <Form.ControlLabel className="group_membernumber fw-bold">
                Filter by content type:
              </Form.ControlLabel>
              <Form.Control
                name="checkbox"
                accepter={CheckboxGroup}
                inline
                className="group_membernumber"
              >
                {toolType === "/group-posts-delete" ? (
                  <>
                    <Checkbox value="photo">Photo</Checkbox>
                    <Checkbox value="link">Link</Checkbox>
                    <Checkbox value="mediaSet">Media Set</Checkbox>
                    <Checkbox value="video">Video</Checkbox>
                    <Checkbox value="text">Text</Checkbox>
                    <Checkbox value="other">Other</Checkbox>
                  </>
                ) : (
                  <>
                    <Checkbox value="photo">Photo</Checkbox>
                    <Checkbox value="link">Link / Share</Checkbox>
                    <Checkbox value="mediaSet">Media Set/Album</Checkbox>
                    <Checkbox value="video">Video / Reels</Checkbox>
                    <Checkbox value="text">Text</Checkbox>
                    <Checkbox value="other">Other</Checkbox>
                  </>
                )}
              </Form.Control>
            </Form.Group> */}
          <div style={{ height: "100%" }}>
            <SelectedPageNotFromAd
              listPages={listPages}
              selectedPage={pageId}
              setSelectedPage={setPageId}
              err={isError}
            />
          </div>
          <div className="d-flex my-3 align-items-center justify-content-center gap-2">
            <Form.Group
              controlId="checkbox"
              className="d-flex gap-2 m-0 align-items-center"
            >
              <Form.ControlLabel className="group_membernumber">
                Ngày:
              </Form.ControlLabel>
              <Form.Control
                name="checkbox"
                accepter={DateRangePicker}
                onChange={setTimer}
                value={timer}
                format="dd-MM-yyyy"
                // onChange={(e) => console.log(e)}
                // defaultValue={current}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="submit" className="text-center">
              <Button
                disabled={isLoadingData}
                appearance="primary"
                color="orange"
                className="fw-bold"
                style={{ width: "10rem" }}
                onClick={getListPostInPage}
              >
                BẮT ĐẦU
                {/* <span>{isLoadingData ? <Loader /> : "START SCAN"}</span> */}
              </Button>
            </Form.Group>
          </div>
        </Form>
      </div>
      <div className="mt-3 card p-3">
        <div className="d-flex justify-content-end gap-2">
          <Button
            onClick={() => handleOpen("select")}
            style={{ backgroundColor: "#833500", color: "white" }}
          >
            <AiFillDelete className="mb-1 me-1" /> XOÁ BÀI VIẾT
          </Button>
          <Button
            onClick={() => handleOpen("all")}
            style={{ backgroundColor: "#5f5f5f", color: "white" }}
          >
            <AiFillDelete className="mb-1 me-1" /> XOÁ TẤT CẢ
          </Button>
        </div>
        <div className="d-flex flex-wrap">
          {isLoadingData ? (
            <LinearProgress
              style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
            />
          ) : (
            <></>
          )}
          {isDeletePost ? (
            <LinearProgress
              style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
            />
          ) : (
            <></>
          )}
          {listPosts?.map((item, key) => (
            <div
              key={key}
              className="col-3 mt-3 px-2"
              style={{ position: "relative" }}
            >
              <div style={{ backgroundColor: "white" }}>
                <Checkbox
                  style={{
                    position: "absolute",
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                  checked={checkGroups?.includes(item?.id) ? true : false}
                  value={item?.id}
                  onChange={(e, check) => {
                    if (check) {
                      setCheckGroups((current) => [...current, e]);
                    } else {
                      setCheckGroups((prevState) =>
                        prevState.filter((prevItem) => prevItem !== e)
                      );
                    }
                  }}
                />
              </div>
              <div
                className="mb-2 ppa__pagePost"
                style={{ borderRadius: "5px", overflow: "hidden" }}
              >
                <a
                  href={`https://www.facebook.com/${item?.id}`}
                  target="_blank"
                >
                  <img
                    src={item?.picture === undefined ? no_image : item?.picture}
                    alt="google_img"
                    className="pageImage"
                  />
                </a>
              </div>
              <div className="d-flex justify-content-between">
                <span>
                  <AiOutlineLike className="mb-1 me-1" />
                  {item?.like_count}
                </span>

                <span>
                  <a
                    href={`https://www.facebook.com/${item?.id}`}
                    target="_blank"
                    className="text-dark"
                    style={{ textDecoration: "none" }}
                  >
                    <BiCommentDetail className="me-1 mb-1" />
                    <span>{item?.comment}</span>
                  </a>
                </span>
                <span>
                  <AiOutlineShareAlt className="me-1 mb-1" />
                  {item?.share_count}
                </span>
              </div>

              <p className="text-center m-0">
                <span style={{ fontSize: "0.8rem" }}>
                  {moment(item?.created_time * 1000)?.format("LLL")}
                </span>
              </p>
              <Input as={"textarea"} rows={5} value={item?.content}></Input>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GroupPostsDelete;
