import React, { useEffect, useState } from "react";
import { sendGet, sendPost, sendPut } from "../../../axios/axiosClient";
import Cookies from "js-cookie";
import { Table } from "react-bootstrap";
import {
  Button,
  ButtonToolbar,
  Form,
  Input,
  InputPicker,
  Message,
  Modal,
  Pagination,
  TagPicker,
  Toggle,
  useToaster,
} from "rsuite";
import "./adminTools.css";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

const AdminTools = () => {
  const [listTools, setListTools] = useState([]);
  const [listScript, setListScripts] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [openCreate, setOpenCreate] = React.useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);
  const [formUpdate, setFormUpdate] = useState();
  const [formCreate, setFormCreate] = useState();
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const typeData = [
    {
      value: 1,
      label: "Free",
    },
    {
      value: 2,
      label: "Pay fees",
    },
  ];

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMsg, setTextMsg] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMsg}
    </Message>
  );

  useEffect(() => {
    if (textMsg !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMsg("");
  }, [textMsg]);

  const getListTools = async (page) => {
    try {
      let config = {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      };
      const res = await sendGet(
        `/admins/tools?page_size=${limit}&page=${page}`,
        config
      );
      if (res.status === 200) {
        setListTools(res.data.data.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const getListScript = async () => {
    try {
      let config = {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      };
      const res = await sendGet(`/admins/scripts?page_size=1000`, config);
      if (res.status === 200) {
        console.log(res.data.data);
        const listData = res.data?.data?.data?.map((item) => ({
          label: item?.key,
          value: item?.id,
        }));
        setListScripts(listData);
        setTotalItems(res?.data?.data?.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreate = async () => {
    const input = {
      ...formCreate,
      status: formCreate?.status === true ? 1 : 0,
    };
    try {
      const res = await sendPost(`/admins/tools/`, input);
      if (res.status === 200) {
        // console.log(res.data);
        getListTools();
        handleCloseCreate();
        setTextMsg("Create new tool successfully !!");
      }
    } catch (error) {
      setTextMsg(error?.response?.data?.error_msg);
      // console.log(error?.response?.data?.error_msg);
    }
  };

  const handleUpdate = async () => {
    delete formUpdate?.created_at;
    delete formUpdate?.deleted_at;
    delete formUpdate?.updated_at;
    const input = {
      ...formUpdate,
      script_ids: formUpdate.scripts,
      status: formCreate?.status === true || formCreate?.status === 1 ? 1 : 0,
    };
    delete input?.id;
    try {
      const res = await sendPut(`/admins/tools/${formUpdate?.id}`, input);
      if (res.status === 200) {
        getListTools();
        handleCloseUpdate();
        setTextMsg("update tool successfully !!");
      }
    } catch (error) {
      setTextMsg(error?.response?.data?.error_msg);
    }
    // console.log(input);
  };

  useEffect(() => {
    getListScript();
  }, []);

  useEffect(() => {
    getListTools(activePage);
  }, [activePage, limit]);

  const handleChangeLimit = (e) => {
    setLimit(e);
    setActivePage(1);
  };

  // useEffect(() => {
  //   getListTools(activePage);
  // }, [activePage]);

  return (
    <div className="admin__tool__container">
      <Modal open={openCreate} onClose={handleCloseCreate}>
        <Modal.Header>
          <Modal.Title>CREATE NEW TOOL</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-2">
          <Form fluid formValue={formCreate} onChange={setFormCreate}>
            <Form.Group controlId="key">
              <Form.ControlLabel>Key</Form.ControlLabel>
              <Form.Control name="key" />
            </Form.Group>
            <Form.Group controlId="name">
              <Form.ControlLabel>Name</Form.ControlLabel>
              <Form.Control name="name" />
            </Form.Group>
            <Form.Group controlId="script_ids">
              <Form.ControlLabel>Script</Form.ControlLabel>
              <Form.Control
                name="script_ids"
                accepter={TagPicker}
                data={listScript}
              />
            </Form.Group>
            <div className="d-flex">
              <Form.Group controlId="type" className="col-8">
                <Form.ControlLabel>Type</Form.ControlLabel>
                <Form.Control
                  name="type"
                  accepter={InputPicker}
                  data={typeData}
                />
              </Form.Group>
              <Form.Group controlId="status" className="col-4">
                <Form.ControlLabel>Status</Form.ControlLabel>
                <Form.Control name="status" accepter={Toggle} />
              </Form.Group>
            </div>
            <Form.Group controlId="description">
              <Form.ControlLabel>Description</Form.ControlLabel>
              <Form.Control rows={5} name="description" accepter={Textarea} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCreate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseCreate} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal open={openUpdate} onClose={handleCloseUpdate}>
        <Modal.Header>
          <Modal.Title>UPDATE TOOL</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-2">
          <Form fluid formValue={formUpdate} onChange={setFormUpdate}>
            <Form.Group controlId="key">
              <Form.ControlLabel>Key</Form.ControlLabel>
              <Form.Control name="key" />
            </Form.Group>
            <Form.Group controlId="name">
              <Form.ControlLabel>Name</Form.ControlLabel>
              <Form.Control name="name" />
            </Form.Group>
            <Form.Group controlId="scripts">
              <Form.ControlLabel>Script</Form.ControlLabel>
              <Form.Control
                name="scripts"
                accepter={TagPicker}
                data={listScript}
                // value={formUpdate?.script_ids}
              />
            </Form.Group>
            <div className="d-flex">
              <Form.Group controlId="type" className="col-8">
                <Form.ControlLabel>Type</Form.ControlLabel>
                <Form.Control
                  name="type"
                  accepter={InputPicker}
                  data={typeData}
                />
              </Form.Group>
              <Form.Group controlId="status" className="col-4">
                <Form.ControlLabel>Status</Form.ControlLabel>
                <Form.Control name="status" accepter={Toggle} />
              </Form.Group>
            </div>
            <Form.Group controlId="description">
              <Form.ControlLabel>Description</Form.ControlLabel>
              <Form.Control rows={5} name="description" accepter={Textarea} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseUpdate} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-end mb-2">
        <Button onClick={handleOpenCreate}>ADD NEW TOOL</Button>
      </div>
      <Table responsive striped bordered>
        <thead>
          <tr>
            <th>#</th>
            <th>Key</th>
            <th>Name</th>
            <th>Script</th>
            <th>Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {listTools?.map((item, key) => (
            <tr key={key}>
              <td>{key + 1 + (activePage - 1) * limit}</td>
              <td>{item?.key}</td>
              <td>{item?.name}</td>
              <td>
                {item?.scripts?.map((script, index) => (
                  <div key={index}>{script?.key}</div>
                  // <>{`${script?.key}, `}</>
                ))}
              </td>
              <td>{item?.type == 1 ? "Web" : "Extension"}</td>
              <td>
                <div className="d-flex gap-2">
                  <Button
                    appearance="primary"
                    onClick={() => {
                      let scripts = item?.scripts?.map((script) => script?.id);

                      setFormUpdate({ ...item, scripts: scripts });
                      handleOpenUpdate();
                    }}
                  >
                    Update
                  </Button>
                  <Button appearance="primary" style={{ background: "red" }}>
                    Delete
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {totalItems > 0 ? (
        <Pagination
          layout={["limit", "-", "pager"]}
          size={"xs"}
          prev
          next
          ellipsis
          boundaryLinks
          total={totalItems}
          limit={limit}
          limitOptions={[10, 30, 50]}
          maxButtons={5}
          activePage={activePage}
          onChangePage={setActivePage}
          onChangeLimit={handleChangeLimit}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AdminTools;
