import React from "react";
import {
  AiFillAppstore,
  AiFillNotification,
  AiFillTool,
  AiOutlineNotification,
} from "react-icons/ai";
import { AiFillBank } from "react-icons/ai";
import { BiSolidExtension } from "react-icons/bi";
import { BiExtension } from "react-icons/bi";
import { BiLogoJavascript } from "react-icons/bi";
import { LuPackageSearch } from "react-icons/lu";
import { BiUserCircle } from "react-icons/bi";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaMoneyBillAlt } from "react-icons/fa";
import { TbVersionsFilled } from "react-icons/tb";
import { BsFillMenuButtonWideFill, BsFillShareFill } from "react-icons/bs";
import { FaHistory } from "react-icons/fa";
import { FiMap } from "react-icons/fi";
import { BsReverseListColumnsReverse } from "react-icons/bs";
import { PiFlagBannerBold } from "react-icons/pi";
import { MdWork } from "react-icons/md";
import { FaCircleUser } from "react-icons/fa6";

const adminSidebar = [
  {
    name: "Admin Scripts",
    icon: <BiLogoJavascript />,
    path: "/admin-script",
    category: "application",
  },
  {
    name: "Admin Tools",
    icon: <AiFillTool />,
    path: "/admin-tools",
    category: "application",
  },
  {
    name: "Admin Extensions",
    icon: <BiExtension />,
    path: "/admin-extensions",
    category: "user",
  },
  {
    name: "Admin Packages",
    icon: <LuPackageSearch />,
    path: "/admin-packages",
    category: "application",
  },
  {
    name: "Admin Users",
    icon: <BiUserCircle />,
    path: "/admin-users",
    category: "user",
  },
  {
    name: "Admin Orders",
    icon: <FaMoneyBillAlt />,
    path: "/admin-orders",
    category: "user",
  },
  {
    name: "Admin Withdraw",
    icon: <BiMoneyWithdraw />,
    path: "/admin-withdraw",
    category: "user",
  },
  {
    name: "Admin Bank",
    icon: <AiFillBank />,
    path: "/admin-bank",
    category: "application",
  },
  {
    name: "Admin Version",
    icon: <TbVersionsFilled />,
    path: "/admin-version",
    category: "application",
  },
  {
    name: "Admin Ref",
    icon: <BsFillShareFill />,
    path: "admin-ref",
    category: "user",
  },
  {
    name: "Admin History Tool",
    icon: <FaHistory />,
    path: "/admin-history-tool",
    category: "user",
  },
  {
    name: "Admin ChangeLog",
    icon: <BsReverseListColumnsReverse />,
    path: "/admin-changelog",
    category: "application",
  },
  {
    name: "Admin Guide",
    icon: <FiMap />,
    path: "/admin-guide",
    category: "application",
  },
  {
    name: "Admin Notify",
    icon: <AiFillNotification />,
    path: "/admin-notify",
    category: "application",
  },
  {
    name: "Admin Banner",
    icon: <PiFlagBannerBold />,
    path: "/admin-banner",
    category: "application",
  },
];

export const adminCategory = [
  {
    name: "Ứng dụng",
    key: "application",
    icon: <AiFillAppstore />,
  },
  {
    name: "Người dùng",
    key: "user",
    icon: <FaCircleUser />,
  },
];

export default adminSidebar;
