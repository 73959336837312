import React from "react";
import GroupPostsDelete from "../../group/group-posts-delete/GroupPostsDelete";

const PagePostsRemover = () => {
  return (
    <div>
      <GroupPostsDelete />
    </div>
  );
};

export default PagePostsRemover;
