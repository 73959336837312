import React, { useContext, useEffect, useState } from "react";
import "./autoJoinFbGroup.css";
import {
  Input,
  InputGroup,
  Form,
  Button,
  useToaster,
  Message,
  Loader,
} from "rsuite";
import avatar_image from "../../../../assets/images/645fbc5da4a21.png";
import { socket } from "../../../../context/socket";
import { AiFillCheckCircle } from "react-icons/ai";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import { sendPost } from "../../../../axios/axiosClient";
import Cookies from "js-cookie";
import { Badge } from "react-bootstrap";
import LinearProgress from "@mui/material/LinearProgress";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

// const InputGroup = React.forwardRef((props, ref) => (
//   <Input {...props} as="textarea" ref={ref} />
// ));
let joinGroupResult = [];
let quantiTyGroupJoin = 1;
let comparejoinGroup = [];
const AutoJoinFbGroup = () => {
  const [formData, setFormData] = useState({
    delay: 30,
    limit: 50,
    answer: "",
  });
  const [listAnswer, setListAnswer] = useState([]);
  const [answer, setAnswer] = useState("");
  const [results, setResults] = useState([]);
  const { selectedExtension } = useContext(ExtensionContext);
  const [group, setGroup] = useState("");
  const [listGroup, setListGroup] = useState([]);
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [intervalId, setIntervalId] = useState();
  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      console.log(message);
      switch (message?.params?.key_script) {
        case "join_to_group":
          // if (message?.data?.msg === "Thành công") {
          //   setGroup(message?.data?.id);
          //   setTextMessage("Tham gia group thành công !!!");
          // }
          const newItem = {
            url: comparejoinGroup[comparejoinGroup.length - 1]?.url,
            loading: false,
            status: message?.data?.msg,
          };
          setListGroup((prevState) => {
            // prevState[prevState.length - 1] = newItem;
            // return prevState;
            const item = prevState.find((item) => item.url == newItem.url);
            if (item) {
              return prevState.map((item) => {
                if (item.url == newItem.url) {
                  return newItem;
                }
                return item;
              });
            } else {
              return prevState;
            }
          });
          joinGroupResult.push(message);
          if (
            quantiTyGroupJoin !== 0 &&
            joinGroupResult.length == quantiTyGroupJoin
          ) {
            setIsLoading(false);
          }
          break;
        default:
          break;
      }
    });
  }, []);

  // useEffect(() => {
  //   if (group !== "") {
  //     setListGroup((old) => [...old, group]);
  //   }
  // }, [group]);

  const joinGroup = async (groupId) => {
    const input = {
      key_script: "join_to_group",
      extension_key: selectedExtension?.key,
      key_tool: "auto_join_to_facebook_groups",
      params: {
        key_socket: Cookies.get("keySocket"),
        groupIds: [groupId],
        answer: formData?.answer,
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      comparejoinGroup.push({
        url: groupId,
        loading: true,
      });
      if (res.status === 200) {
        setListGroup((prevState) => [
          ...prevState,
          {
            url: groupId,
            loading: true,
          },
        ]);
        console.log("script run !!!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleJoinGroup = async () => {
    if (!formData?.listgroup) {
      setTextMessage("Bạn cần điền ID nhóm");
      return;
    }
    let b = 1;
    let limit = 0;
    if (
      formData?.listgroup
        ?.trim()
        ?.split("\n")
        ?.filter((item) => item.length !== 0)?.length > formData?.limit
    ) {
      limit = formData?.limit;
    } else {
      limit = formData?.listgroup
        ?.trim()
        ?.split("\n")
        ?.filter((item) => item.length !== 0)?.length;
    }
    quantiTyGroupJoin = limit;
    joinGroupResult = [];
    comparejoinGroup = [];
    setListGroup([]);
    setIsLoading(true);
    joinGroup(formData?.listgroup?.trim()?.split("\n")[0]);
    if (limit > 1) {
      const id = setInterval(() => {
        if (b < limit) {
          joinGroup(formData?.listgroup?.trim()?.split("\n")[b]);
          b++;
        } else {
          clearInterval(id);
        }
      }, Number(formData?.delay) * 1000);
      setIntervalId(id);
    }
  };

  const handleStopJoin = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    setIsLoading(false);
  };

  const handleAdd = () => {
    setListAnswer([...listAnswer, answer]);
    setAnswer("");
  };

  return (
    <>
      <div className="d-flex gap-3">
        <div className="card p-3 col-7">
          <div className="mb-3">
            <ToolHeading toolType={window.location.pathname} />
          </div>
          <Form fluid formValue={formData} onChange={setFormData}>
            <Form.Group controlId="listgroup">
              <Form.ControlLabel className="group_membernumber">
                Danh sách nhóm (ID):
                <a
                  href="https://uid.bufflike.net/"
                  target="_blank"
                  className="ms-2"
                >
                  <Form.HelpText tooltip className="m-0">
                    Click vào đây để lấy ID
                  </Form.HelpText>
                </a>
              </Form.ControlLabel>
              <Form.Control
                rows={8}
                name="listgroup"
                placeholder="ID nhóm, mỗi nhóm 1 dòng"
                accepter={Textarea}
              />
            </Form.Group>
            <div className="d-flex gap-3">
              <div className="col">
                {/* <Form.Group controlId="number">
                <Form.ControlLabel className="autojoin__label">
                  THREAD ( RECOMMENDED 1 ):
                </Form.ControlLabel>
                <Form.Control
                  name="thread"
                  type="number"
                  min={1}
                  defaultValue={1}
                />
              </Form.Group> */}
                <Form.Group controlId="delay" className="mb-3">
                  <Form.ControlLabel className="group_membernumber">
                    Delay ( giây ):
                  </Form.ControlLabel>
                  <Form.Control name="delay" type="number" />
                </Form.Group>
                <Form.Group controlId="limit">
                  <Form.ControlLabel className="group_membernumber">
                    Tối đa:
                  </Form.ControlLabel>
                  <Form.Control name="limit" type="number" />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group controlId="answer">
                  <Form.ControlLabel className="group_membernumber">
                    Thêm câu trả lời:
                  </Form.ControlLabel>
                  <Form.Control
                    rows={5}
                    name="answer"
                    accepter={Textarea}
                    placeholder="Nhập câu trả lời..."
                  />
                  {/* <InputGroup inside>
                  <Form.Control
                    value={answer}
                    onChange={(e) => setAnswer(e)}
                    name="answer"
                  />
                  <InputGroup.Button onClick={handleAdd}>+</InputGroup.Button>
                </InputGroup> */}
                </Form.Group>
              </div>
            </div>
          </Form>
          {isLoading ? (
            <Button
              className="mt-2 text-white fw-bold"
              style={{ background: "red" }}
              onClick={handleStopJoin}
              appearance="primary"
            >
              STOP
            </Button>
          ) : (
            <Button
              className="mt-2 text-white fw-bold"
              // style={{ background: "#141821" }}
              onClick={handleJoinGroup}
              appearance="primary"
            >
              THAM GIA
            </Button>
          )}
          {/* <Button
            disabled={isLoading}
            className="mt-2 text-white fw-bold"
            // style={{ background: "#141821" }}
            onClick={handleJoinGroup}
            appearance="primary"
          >
            THAM GIA
          </Button> */}
          {isLoading ? (
            <LinearProgress
              style={{ width: "100%", marginBottom: "10px", marginTop: "10px" }}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="card p-3 col-5">
          <h5 className="fw-bold text-center">Kết quả</h5>
          <div>
            {listGroup?.map((item, key) => (
              <div
                key={key}
                className="d-flex justify-content-between pb-1 border-bottom"
              >
                <div className="d-flex gap-2 align-items-center">
                  {item?.loading ? (
                    <Loader />
                  ) : (
                    <AiFillCheckCircle
                      className="fs-4"
                      style={{ color: "#60A5FA" }}
                    />
                  )}
                  <a
                    className="list-result"
                    href={`https://www.facebook.com/${item?.url}`}
                    target="_blank"
                  >
                    {item?.url}
                  </a>
                </div>
                <div>
                  {item?.status ? (
                    <>
                      {item?.status == "Thành công" ? (
                        <Badge bg="success">{item?.status}</Badge>
                      ) : (
                        <Badge bg="danger">{item?.status}</Badge>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoJoinFbGroup;
