import React, { useContext, useEffect } from "react";
import "./errorPage.css";
import { SidebarContextProvider } from "../../context/SidebarContext";

const ErrorPage = () => {
  const { setHave2ndSidebar, setHaveSidebar, setHaveFooter } = useContext(
    SidebarContextProvider
  );

  useEffect(() => {
    setHave2ndSidebar(false);
    setHaveSidebar(false);
    setHaveFooter(false);
  });
  return <div className="errorPage"></div>;
};

export default ErrorPage;
