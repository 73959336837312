import Cookies from "js-cookie";
import React, { useContext, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useMedia } from "react-use";
import { routers } from "../../../commons/data/router";
import { SidebarContextProvider } from "../../../context/SidebarContext";
import LandingPage from "../../../pages/landing-page/LandingPage";
import ExtensionMenu from "../extensionMenu/ExtensionMenu";
import ToolFooter from "../footer/ToolFooter";
import MobileNoti from "../mobile-noti/MobileNoti";
import Report from "../report/Report";
import SecondSidebar from "../secondSidebar/SecondSidebar";
import Sidebar from "../sidebar/Sidebar";
import "./wrapper.css";
const Wrapper = () => {
  const {
    have2ndSidebar,
    setHave2ndSidebar,
    haveSidebar,
    setHaveSidebar,
    haveFooter,
    setHaveFooter,
  } = useContext(SidebarContextProvider);
  const location = useLocation();
  const navigate = useNavigate();
  const isWide = useMedia("(max-width: 768px)");

  const noLogin = [
    "",
    "home",
    "admin/login",
    "admin",
    "login",
    "forgot-password",
    "change-password",
    "verify",
    "register",
    "dieu-khoan-su-dung",
    "quy-dinh-bao-mat",
    "tutorials",
    "notify",
    "get-callback-google",
  ];

  const noLoginWithSidebar = ["tutorials", "notify", "home"];

  useEffect(() => {
    // console.log(location.pathname?.split("/")[1]);
    if (noLogin?.includes(location.pathname?.substring(1))) {
      if (noLoginWithSidebar?.includes(location.pathname?.substring(1))) {
        setHaveSidebar(true);
        setHave2ndSidebar(true);
        setHaveFooter(true);
      } else {
        setHaveSidebar(false);
        setHave2ndSidebar(false);
        setHaveFooter(false);
      }
    } else {
      if (!Cookies?.get("token")) {
        switch (location.pathname) {
          case "/home":
            break;
          case "/":
            break;
          default:
            navigate("/login");
            break;
        }
      }
      setHaveSidebar(true);
      setHave2ndSidebar(true);
      setHaveFooter(true);
    }
    // console.log(location.pathname, "pathLocation")
  }, [location.pathname]);

  return isWide &&
    (location.pathname !== "/" &&
    location.pathname !== "/login" &&
    location.pathname !== "/forgot-password") ? (
    <MobileNoti />
  ) : (
    <>
      {haveSidebar ? (
        <>
          <Sidebar />
          {Cookies.get("admin") === undefined ? <ExtensionMenu /> : <></>}
          <SecondSidebar />
          <Report />
        </>
      ) : (
        <></>
      )}
      <div
        className={`home__body ${
          noLogin?.includes(location.pathname?.substring(1)) &&
          !noLoginWithSidebar?.includes(location.pathname?.substring(1))
            ? "noLogin"
            : ""
        } ${location.pathname === "/" ? "landing" : ""}`}
      >
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {routers?.map((router, key) => (
            <Route key={key} path={router?.path} element={router?.element} />
          ))}
        </Routes>
      </div>
      {haveFooter ? <ToolFooter /> : <></>}
    </>
  );
};

export default Wrapper;
