import React from "react";
import ForgotPassword from "../../forgot-password/ForgotPassword";

const ChangePassword = () => {
  return (
    <div>
      <ForgotPassword />
    </div>
  );
};

export default ChangePassword;
