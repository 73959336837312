import React, { useContext, useEffect, useState } from "react";
import "./groupPostsRemover.css";
import { Form } from "react-bootstrap";
import {
  Button,
  Modal,
  Checkbox,
  CheckboxGroup,
  Input,
  Loader,
  Message,
  useToaster,
} from "rsuite";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import { sendPost } from "../../../../axios/axiosClient";
import Cookies from "js-cookie";
import { socket } from "../../../../context/socket";
import no_image from "../../../../assets/images/default_thumbnail.png";

import {
  AiOutlineLike,
  AiOutlineLink,
  AiOutlineShareAlt,
} from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import LinearProgress from "@mui/material/LinearProgress";
import SelectedGroup from "../../../../components/selectedGroup/SelectedGroup";
import { BiCommentDetail } from "react-icons/bi";
import moment from "moment";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";

const GroupPostsRemover = () => {
  const { selectedExtension } = useContext(ExtensionContext);
  const [listGroups, setListGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [check1, setCheck1] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [listPosts, setListPosts] = useState([]);
  const [listPost, setListPost] = useState([]);
  const [removePosts, setRemovePosts] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [delay, setDelay] = useState(10);
  const [likeFilter, setLikeFilter] = useState(100);
  const [limitPosts, setLimitPosts] = useState(100);
  const [filterData, setFilterData] = useState([]);
  const [getGroupErr, setGetGroupErr] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isDeletePost, setIsDeletePost] = useState(false);
  const handleOpen = (e) => {
    if (listPosts?.length > 0) {
      if (e === "select") {
        if (removePosts?.length > 0) {
          setDeleteAll(false);
          setOpen(true);
        } else {
          setTextMessage("Bạn chưa chọn bài viết.");
          setOpen(false);
        }
      } else if (e === "all") {
        setDeleteAll(true);
        setOpen(true);
      }
    } else {
      setTextMessage("Bạn cần tải bài viết trước.");
    }
  };
  const handleClose = () => setOpen(false);

  const checkbox = [
    {
      label: "Photo",
      value: "photo",
    },
    {
      label: "Link",
      value: "link",
    },
    {
      label: "Media set",
      value: "media set",
    },
    {
      label: "Video",
      value: "video",
    },
    {
      label: "Text",
      value: "text",
    },
    {
      label: "Other",
      value: "other",
    },
  ];

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  useEffect(() => {
    // console.log("run twice?");
    socket.on("do_task_response", (message) => {
      console.log(message);
      // socket.off("do_task_response").on("do_task_response", (message) => {
      switch (message?.params?.key_script) {
        case "get_list_group_is_admin":
          if (message?.data?.length > 0) {
            setListGroups((old) => [...old, ...message?.data]);
            setSelectedGroup(message?.data[0]);
          } else {
            if (message?.data?.err) {
              setGetGroupErr(true);
              setTextMessage("Có lỗi xảy ra khi lấy danh sách nhóm");
            } else {
              setGetGroupErr(false);
              setSelectedGroup(`no_group`);
              setTextMessage("Bạn không có nhóm nào !!");
            }
          }
          break;
        case "get_post_from_group":
          setListPosts((old) => [...old, ...message?.data]);
          // setListPost(message?.data);
          break;
        case "delete_post_in_group":
          if (message?.done_all === true) {
            const idToRemove = message?.data.map((item) => {
              if (item?.status === 1) {
                setTextMessage("Xoá bài viết thành công!!");
                return item.id;
              } else {
                setTextMessage(
                  `Xoá bài viết https://www.facebook.com/${item.id} thất bại !!`
                );
              }
            });
            // setFilterData((prevState) => {
            //   const filterItem = prevState.filter(
            //     (item) => !idToRemove.has(item.story_id)
            //   );
            //   return filterItem;
            // });
            setFilterData(idToRemove);
          } else {
            setTextMessage("Xoá thất bại.");
          }
          handleClose();
          setLoadingDelete(false);
          break;
        default:
          break;
      }

      setLoadingData(false);
    });
  }, []);

  useEffect(() => {
    if (filterData?.length > 0 && listPosts?.length > 0) {
      const newListPost = listPosts?.filter(
        (item) => !filterData.includes(item?.story_id)
      );
      console.log(filterData, "filterData");
      console.log(listPosts, "list posts");
      setListPosts(newListPost);
    }
  }, [filterData]);

  const getListGroup = async () => {
    const input = {
      key_script: "get_list_group_is_admin",
      extension_key: selectedExtension?.key,
      key_tool: "emergency_group_posts_remover",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
  };

  const getListPost = async () => {
    setListPosts([]);
    setRemovePosts([]);
    setLoadingData(true);
    const input = {
      key_script: "get_post_from_group",
      extension_key: selectedExtension?.key,
      key_tool: "emergency_group_posts_remover",
      params: {
        key_socket: Cookies.get("keySocket"),
        groupId: selectedGroup?.id,
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setLoadingData(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemovePosts = async () => {
    // console.log(deleteAll
    //   ? listPosts?.map((item) => item?.story_id)
    //   : removePosts, 'remove item id')
    // return;

    if (removePosts?.length > 0 || deleteAll) {
      const input = {
        key_script: "delete_post_in_group",
        extension_key: selectedExtension?.key,
        key_tool: "emergency_group_posts_remover",
        params: {
          key_socket: Cookies.get("keySocket"),
          groupId: selectedGroup?.id,
          // userPost
          storyIds: deleteAll
            ? listPosts?.map((item) => item?.story_id)
            : removePosts,
        },
      };
      try {
        const res = await sendPost(`/users/scripts/send`, input);
        if (res.status === 200) {
          setLoadingDelete(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setTextMessage("Chọn bài viết để xoá.");
    }
  };

  useEffect(() => {
    if (selectedExtension?.id !== Number(localStorage?.getItem("currEx"))) {
      getListGroup();
      return;
    }
    if (selectedExtension !== undefined) {
      getListGroup();
    }
  }, [selectedExtension]);

  const handleStop = async () => {
    const input = {
      key_script: "force_stop",
      extension_key: selectedExtension?.key,
      key_tool: "force_stop",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (selectedGroup !== undefined) {
  //     setLoadingData(true);
  //     getListPost(selectedGroup);
  //   }
  // }, [selectedGroup]);

  // useEffect(() => {
  //   if (listPosts?.length > 0) {
  //     const filterData = listPosts?.filter(
  //       (item, key) => item?.like_count < likeFilter
  //     );
  //     setFilterData(filterData);
  //   }
  // }, [likeFilter, listPosts]);

  // useEffect(() => {
  //   if (listPosts?.length > 0) {
  //     const filterData = listPosts?.filter((item, key) => key < limitPosts);
  //     setFilterData(filterData);
  //   }
  // }, [limitPosts, listPosts]);

  return (
    <div>
      <Modal
        backdrop={"static"}
        keyboard={false}
        open={open}
        onClose={handleClose}
        style={{ top: "13%" }}
      >
        <Modal.Header>
          <Modal.Title className="fw-bold">Xoá bài viết</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteAll
            ? "Bạn có chắc chắn muốn xoá tất cả các bài viết?"
            : "Bạn có chắc chắn muốn xoá bài viết này ?"}
        </Modal.Body>
        <Modal.Footer>
          {loadingDelete ? (
            <Button appearance="primary">
              <Loader />
            </Button>
          ) : (
            <>
              <Button onClick={handleRemovePosts} appearance="primary">
                Ok
              </Button>
              <Button onClick={handleClose} appearance="subtle">
                Huỷ
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      <div>
        <div className="card p-3">
          <div className="mb-3">
            <ToolHeading toolType={window.location.pathname} />
          </div>
          <div>
            <div>
              <Form.Group className="col">
                <Form.Label className="group_membernumber">Nhóm :</Form.Label>
                <SelectedGroup
                  listGroups={listGroups}
                  selectedGroup={selectedGroup}
                  setSelectedGroup={setSelectedGroup}
                  err={getGroupErr}
                />
              </Form.Group>
            </div>
            <div className="d-flex gap-2 mt-2">
              {/* <Form.Group className="col">
                <Form.Label className="group_membernumber">
                  Delay (giây) :
                </Form.Label>
                <Input value={delay} onChange={setDelay} />
              </Form.Group> */}
              <Form.Group className="col">
                <Form.Label className="group_membernumber">
                  Số lượng bài viết :
                </Form.Label>
                <Input value={limitPosts} onChange={setLimitPosts} />
              </Form.Group>
              {/* <Form.Group className="col">
                <Form.Label className="group_membernumber">
                  Like less than :
                </Form.Label>
                <Input value={likeFilter} onChange={setLikeFilter} />
              </Form.Group> */}
            </div>

            <div className="d-flex mt-2 justify-content-center">
              {/* <div>
                <span className="group_membernumber fw-bold">Filter:</span>
                {checkbox?.map((item, key) => (
                  <Checkbox className="group_membernumber" key={key}>
                    {item?.label}
                  </Checkbox>
                ))}
              </div> */}

              <div className="col-3">
                <Button
                  appearance="primary"
                  color="orange"
                  className="fw-bold"
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (
                      selectedGroup &&
                      selectedGroup !== `YOU DON'T HAVE ANY GROUP`
                    ) {
                      getListPost();
                    } else {
                      setTextMessage("You need select a group");
                    }
                  }}
                >
                  BẮT ĐẦU
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="card p-3">
          {loadingData ? (
            <div className="d-flex justify-content-center">
              {/* <Loader /> */}
              <LinearProgress style={{ width: "100%" }} />
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-end">
                {loadingDelete ? (
                  <LinearProgress style={{ width: "100%" }} />
                ) : (
                  <div className="d-flex gap-2">
                    <Button
                      onClick={() => handleOpen("select")}
                      style={{ backgroundColor: "#833500", color: "white" }}
                    >
                      XOÁ BÀI VIẾT
                    </Button>
                    <Button
                      onClick={() => handleOpen("all")}
                      appearance="primary"
                      style={{ backgroundColor: "#5f5f5f", color: "white" }}
                    >
                      XOÁ TẤT CẢ BÀI VIẾT
                    </Button>
                  </div>
                )}
              </div>
              <div className="d-flex flex-wrap">
                {listPosts?.map((item, key) => (
                  <div
                    key={key}
                    className="col-3 mt-3 px-2"
                    style={{ position: "relative" }}
                  >
                    <div style={{ backgroundColor: "white" }}>
                      <Checkbox
                        style={{
                          position: "absolute",
                          backgroundColor: "white",
                          borderRadius: "4px",
                        }}
                        checked={
                          removePosts?.includes(item?.story_id) ? true : false
                        }
                        value={item?.story_id}
                        onChange={(e, check) => {
                          if (check) {
                            setRemovePosts((current) => [...current, e]);
                          } else {
                            setRemovePosts((prevState) =>
                              prevState.filter((prevItem) => prevItem !== e)
                            );
                          }
                        }}
                      />
                    </div>
                    <div
                      className="mb-2 ppa__pagePost"
                      style={{ borderRadius: "5px", overflow: "hidden" }}
                    >
                      <a
                        href={`https://www.facebook.com/${item?.story_id}`}
                        target="_blank"
                      >
                        <img
                          src={
                            item?.attachments[0]?.media?.image?.uri ===
                            undefined
                              ? no_image
                              : item?.attachments[0]?.media?.image?.uri
                          }
                          alt="google_img"
                          className="pageImage"
                        />
                      </a>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>
                        <AiOutlineLike className="mb-1 me-1" />
                        {item?.like_count}
                      </span>

                      <span>
                        <a
                          href={`https://www.facebook.com/${item?.id}`}
                          target="_blank"
                          className="text-dark"
                          style={{ textDecoration: "none" }}
                        >
                          <BiCommentDetail className="me-1 mb-1" />
                          <span>{item?.comment_count}</span>
                        </a>
                      </span>
                      <span>
                        <AiOutlineShareAlt className="me-1 mb-1" />
                        {item?.share_count}
                      </span>
                    </div>

                    <p className="text-center m-0">
                      <span style={{ fontSize: "0.8rem" }}>
                        {moment(item?.creation_time * 1000)?.format("LLL")}
                      </span>
                    </p>
                    <Input
                      as={"textarea"}
                      rows={5}
                      value={item?.content?.text}
                    ></Input>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupPostsRemover;
