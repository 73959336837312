import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Cookies from "js-cookie";
import {
  Button,
  Form,
  Input,
  Message,
  Modal,
  Pagination,
  useToaster,
} from "rsuite";
import "./adminbank.css";
import { Form as SubForm } from "react-bootstrap";
import { getOrder, updateOrder } from "../../../axios/orderApi";
import {
  createBank,
  delBank,
  getBank,
  updateBank,
} from "../../../axios/bankApi";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
const AdminBank = () => {
  const [listExtenions, setlistExtenions] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [state, setState] = useState("");
  const [updateState, setUpdateState] = useState("created");
  const [method, setMeThod] = useState("bank");
  const [openCreate, setOpenCreate] = useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);
  const [selectedValue, setSelectedValue] = useState();
  const [formValue, setFormValue] = useState();
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    setActivePage(1);
  }, [limit]);

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );
  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);
  const getListBanks = async () => {
    const input = {
      //   ...(formValue?.name &&
      //     formValue?.name?.length !== 0 && { search: formValue?.name }),
      //   ...(state && state.length !== 0 && { state: state }),
      //   ...(method &&
      //     (method == 1 || method == 2 || method == 3) && {
      //       payment_method: method,
      //     }),
      // status: 1,
    };
    try {
      const res = await getBank(input);
      setlistExtenions(res.data.data);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getListBanks();
    }, 500);
    return () => clearTimeout(timer);
  }, [formValue?.name, state, method]);
  const priceFormat = (price) => {
    return price?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };
  const handleUpdate = async (id) => {
    const input = {
      fullname: selectedValue.fullname,
      bank: selectedValue.bank,
      bank_code: selectedValue.bank_code,
      type: selectedValue.type,
      bank_branch: selectedValue.bank_branch,
    };
    try {
      const res = await updateBank(selectedValue?.id, input);
      if (res.status == 200) {
        getListBanks();
        handleClose();
      }
    } catch (err) {
      throw err;
    }
  };
  const setNamePackage = (id) => {
    let packageName;
    switch (id) {
      case 1:
        packageName = "Web - 1 Month";
        break;
      case 2:
        packageName = "Web - 6 Months";
        break;
      case 3:
        packageName = "Web - 1 Year";
        break;
      case 4:
        packageName = "Extension - 1 Month";
        break;
      case 5:
        packageName = "Extension - 6 Months";
        break;
      case 6:
        packageName = "Extension - 1 Year";
        break;
      default:
        break;
    }
    return packageName;
  };
  const handleCreate = async () => {
    const input = {
      fullname: formValue.fullname,
      bank: formValue.bank,
      bank_code: formValue.bank_code,
      type: method,
      bank_branch: formValue.bank_branch,
    };
    try {
      const res = await createBank(input);
      if (res.status == 200) {
        getListBanks();
        setFormValue();
        handleCloseCreate();
      }
    } catch (err) {
      throw err;
    }
  };
  const handleDelete = async () => {
    try {
      const res = await delBank(selectedValue.id);
      if (res.status == 200) {
        getListBanks();
        handleCloseDel();
      }
    } catch (err) {
      throw err;
    }
  };
  return (
    <div className="admin__tool__container">
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{`UPDATE`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={selectedValue} onChange={setSelectedValue} fluid>
            <Form.Group controlId="fullname">
              <Form.ControlLabel>Tên tài khoản</Form.ControlLabel>
              <Form.Control name="fullname" />
            </Form.Group>
            <Form.Group controlId="bank_code">
              <Form.ControlLabel>Số tài khoản</Form.ControlLabel>
              <Form.Control name="bank_code" />
            </Form.Group>
            <Form.Group controlId="bank">
              <Form.ControlLabel>Ngân hàng</Form.ControlLabel>
              <Form.Control name="bank" />
            </Form.Group>
            <Form.Group controlId="type">
              <Form.ControlLabel>Loại</Form.ControlLabel>
              <Form.Control name="type" />
            </Form.Group>
            <Form.Group controlId="bank_branch">
              <Form.ControlLabel>Chi nhánh</Form.ControlLabel>
              <Form.Control name="bank_branch" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal open={openDel} onClose={handleCloseDel}>
        <Modal.Header>
          <Modal.Title>{`Xoá ngân hàng`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Bạn có chắc chắn muốn xoá ngân hàng này!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseDel} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={openCreate}
        onClose={handleCloseCreate}
        // style={{ background: "#F5ECD7" }}
      >
        <Modal.Header>
          <Modal.Title>Thêm ngân hàng</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={formValue} onChange={setFormValue} fluid>
            <Form.Group controlId="fullname">
              <Form.ControlLabel>
                Tên tài khoản
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>

              <Form.Control name="fullname" />
            </Form.Group>
            <Form.Group controlId="bank_code">
              <Form.ControlLabel>
                Số tài khoản
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="bank_code" />
            </Form.Group>
            <Form.Group controlId="bank">
              <Form.ControlLabel>
                Ngân hàng
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="bank" />
            </Form.Group>
            <Form.Group controlId="type">
              <Form.ControlLabel>
                Loại
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <SubForm.Select
                style={
                  {
                    //   background: "#fff",
                    //   maxWidth: "150px",
                    //   marginLeft: "10px",
                  }
                }
                aria-label="Default select example"
                onChange={(e) => setMeThod(e.target.value)}
              >
                <option value="bank">Bank</option>
                <option value="momo">Momo</option>
              </SubForm.Select>
            </Form.Group>
            <Form.Group controlId="bank_branch">
              <Form.ControlLabel>
                Chi nhánh
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="bank_branch" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCreate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseCreate} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-end">
        <Button
          className="mb-2"
          style={{ width: "150px" }}
          onClick={handleOpenCreate}
        >
          Thêm ngân hàng
        </Button>
      </div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Tên tài khoản</th>
            <th>Số tài khoản</th>
            <th>Ngân hàng</th>
            <th>Chi nhánh</th>
            <th>Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {listExtenions
            ?.map((item, index) => (
              <tr key={item?.id}>
                <td>{item?.id}</td>
                <td>{item?.fullname}</td>
                <td>{item?.bank_code}</td>
                <td>{item?.bank}</td>
                <td>{item?.bank_branch}</td>
                {/* <td style={{ maxWidth: "20rem" }}>{item?.script}</td> */}
                <td>{item?.type}</td>
                <td className="d-flex gap-2">
                  <Button
                    appearance="primary"
                    onClick={() => {
                      setSelectedValue(item);
                      // console.log(item);
                      handleOpen();
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    onClick={() => {
                      setSelectedValue(item);
                      // console.log(item);
                      handleOpenDel();
                    }}
                    appearance="primary"
                    style={{ background: "red" }}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
            ?.splice((activePage - 1) * limit, limit)}
        </tbody>
      </Table>
      {listExtenions?.length > 0 ? (
        <Pagination
          layout={["limit", "-", "pager"]}
          size={"xs"}
          prev
          next
          // first
          // last
          ellipsis
          boundaryLinks
          total={listExtenions?.length}
          limit={limit}
          limitOptions={[10, 30, 50]}
          maxButtons={5}
          activePage={activePage}
          onChangePage={setActivePage}
          onChangeLimit={setLimit}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AdminBank;
