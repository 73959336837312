import React, { useContext, useEffect, useState } from "react";
import { ExtensionContext } from "../../context/ExtensionContext";
import { toolCategories } from "../../commons/data/toolCategory";
import "./toolHeading.css";
const ToolHeading = ({ toolType }) => {
  const { webTools } = useContext(ExtensionContext);
  const [tool, setTool] = useState({});

  const handleToolTitle = () => {
    let keyFound;
    toolCategories?.map((item) => {
      item?.tools?.map((toolItem) => {
        if (toolItem?.path === toolType) {
          keyFound = toolItem?.key;
        }
      });
    });
    const tun = webTools?.find((key) => key?.key === keyFound);
    setTool(tun);
  };

  useEffect(() => {
    if (toolType && webTools?.length > 0) {
      handleToolTitle();
    }
  }, [toolType, webTools]);

  return (
    <div className="text-center toolHeading">
      <h4 className="fw-bold">{tool?.name}</h4>
      <p className="toolDescription m-0">{tool?.description}</p>
    </div>
  );
};

export default ToolHeading;
