import React, { useContext, useEffect, useState } from "react";
import { Checkbox, CheckboxGroup, Input, InputPicker } from "rsuite";
import { Form } from "react-bootstrap";
import "./carouselMenu.css";
import { ExtensionContext } from "../../context/ExtensionContext";
import { socket } from "../../context/socket";
import Cookies from "js-cookie";
import { sendPost } from "../../axios/axiosClient";
import { listCardButtons } from "../../commons/data/cardButton";
import SelectedPage from "../selectedPage/SelectedPage";
import ToolHeading from "../toolHeading/ToolHeading";
import { DataContext } from "../../context/DataContext";
import SelectAdAccounts from "../select-ad-accounts/SelectAdAccounts";
import Select from "react-select";

const CarouselMenu = ({
  cardButton,
  selectedPage,
  setSelectedPage,
  setCardButton,
  selectedAdsAccount,
  setSelectedAdsAccount,
  setTitle,
  listPages,
  setListPages,
  randomMenu,
  setRandomMenu,
}) => {
  const [listAdsAccount, setListAdsAccount] = useState([]);
  const { adAccount, setAdAccount, isSendScript } = useContext(DataContext);
  // const [listPages, setListPages] = useState([]);
  const current = new Date();
  const { selectedExtension } = useContext(ExtensionContext);

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      // console.log(message);
      switch (message?.params?.key_script) {
        case "get_ad_account":
          if (message?.data !== undefined) {
            setAdAccount(message?.data);
            if (message?.data?.ad_account?.length > 0) {
              setListAdsAccount(message?.data?.ad_account);
              let check = 0;
              message?.data?.ad_account?.map((item) => {
                if (item?.account_status !== 2) {
                  setSelectedAdsAccount(item?.id);
                  check = 1;
                  return;
                }
              });
              if (check === 0) {
                setSelectedAdsAccount("");
              }
            }
            if (message?.data?.page?.length > 0) {
              setListPages(message?.data?.page);
              setSelectedPage(message?.data?.page[0]?.id);
            } else {
              setSelectedPage("");
              setListPages([]);
            }
          }
          break;

        default:
          break;
      }
    });
  }, []);

  const getListAdsAccount = async () => {
    // console.log(uid);
    setSelectedAdsAccount(undefined);
    setSelectedPage(undefined);
    const input = {
      key_script: "get_ad_account",
      extension_key: selectedExtension?.key,
      key_tool: "clone_fb_reels_&_video_to_carousel",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
  };

  const getListFacebookPages = async () => {
    // console.log(uid);
    const input = {
      key_script: "get_list_page",
      extension_key: selectedExtension?.key,
      key_tool: "clone_fb_reels_&_video_to_carousel",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // console.log(Number(localStorage?.getItem("currEx")), "curr exten id");
    if (!adAccount) {
      getListAdsAccount();
      return;
    } else {
      if (adAccount?.ad_account?.length > 0) {
        setListAdsAccount(adAccount?.ad_account);
        let check = 0;
        adAccount?.ad_account?.map((item) => {
          if (item?.account_status !== 2) {
            setSelectedAdsAccount(item?.id);
            check = 1;
            return;
          }
        });
        if (check === 0) {
          setSelectedAdsAccount("");
        }
      }
      if (adAccount?.page?.length > 0) {
        setListPages(adAccount?.page);
        setSelectedPage(adAccount?.page[0]?.id);
      } else {
        setSelectedPage("");
        setListPages([]);
      }
    }
  }, [selectedExtension]);

  const filterOptions = (candidate, input) => {
    if (input) {
      return candidate.value === listPages[0].name;
    }
    return true;
  };

  return (
    <div>
      <div className="card p-3">
        {/* <ProfileHeading /> */}
        <div className="mb-3">
          <ToolHeading toolType={window.location.pathname} />
        </div>
        <p className="group_membernumber">Trang Facebook:</p>

        <SelectedPage
          listPages={listPages}
          setSelectedPage={setSelectedPage}
          selectedPage={selectedPage}
        />

        <p className="mt-3 group_membernumber">Tài khoản Ad:</p>

        <SelectAdAccounts
          setSelectedAdsAccount={setSelectedAdsAccount}
          selectedAdsAccount={selectedAdsAccount}
          listAdsAccount={listAdsAccount}
        />
      </div>
      <div className="card mt-3 p-3">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <p className="group_membernumber m-0">Nội dung bài đăng:</p>
          </div>
          <div className="group_membernumber">
            <CheckboxGroup
              name="checkboxList"
              value={randomMenu}
              onChange={setRandomMenu}
              inline
            >
              <Checkbox value="text">Nội dung ngẫu nhiên</Checkbox>
              <Checkbox value="icon">Biểu tượng ngẫu nhiên</Checkbox>
              <Checkbox value="time">Thời gian</Checkbox>
            </CheckboxGroup>
          </div>
        </div>
        <Input
          as="textarea"
          rows={5}
          onChange={setTitle}
          placeholder="Nội dung"
        />
        <p className="mt-2 group_membernumber">Nút bấm:</p>
        <Select
          onChange={(e) => setCardButton(e.value)}
          isSearchable={false}
          value={{
            label: listCardButtons.find((item) => item.value === cardButton)
              .name,
            value: listCardButtons.find((item) => item.value === cardButton)
              .value,
          }}
          options={listCardButtons.map((item) => ({
            label: item?.name,
            value: item?.value,
          }))}
        />
        {/* <InputPicker
          size="sm"
          data={listCardButtons.map((item, idx) => ({
            label: item?.name,
            value: item?.value,
          }))}
          onChange={setCardButton}
        /> */}
        {/* <div className="d-flex justify-content-between my-3">
          <span className="mt-2 group_membernumber">Schedule</span>
          <Toggle />
        </div>
        <DatePicker format="yyyy-MM-dd HH:mm" defaultValue={current} /> */}
      </div>
    </div>
  );
};

export default CarouselMenu;
