import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Cookies from "js-cookie";
import {
  Button,
  Form,
  Input,
  InputPicker,
  Message,
  Modal,
  Pagination,
  useToaster,
} from "rsuite";
import "./adminversion.css";
import { Form as SubForm } from "react-bootstrap";
import { getOrder, updateOrder } from "../../../axios/orderApi";
import {
  createBank,
  delBank,
  getBank,
  updateBank,
} from "../../../axios/bankApi";
import {
  createVersion,
  delVersion,
  getVersion,
  updateVersion,
} from "../../../axios/versionApi";
const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
const AdminVersions = () => {
  const [listExtenions, setlistExtenions] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [state, setState] = useState("");
  const [status, setStatus] = useState(1);
  const [updateState, setUpdateState] = useState("created");
  const [method, setMeThod] = useState(1);
  const [openCreate, setOpenCreate] = useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);
  const [selectedValue, setSelectedValue] = useState();
  const [formValue, setFormValue] = useState();
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const handleChangeLimit = (e) => {
    setLimit(e);
    setActivePage(1);
  };

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );
  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);
  const getListVersions = async (page, pageSize) => {
    const input = {
      //   ...(formValue?.name &&
      //     formValue?.name?.length !== 0 && { search: formValue?.name }),
      //   ...(state && state.length !== 0 && { state: state }),
      //   ...(method &&
      //     (method == 1 || method == 2 || method == 3) && {
      //       payment_method: method,
      //     }),
      // status: 1,
    };
    try {
      const res = await getVersion(input, page, pageSize);
      setlistExtenions(res.data.data.data);
      setTotalItems(res.data?.data?.total);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getListVersions(activePage, limit);
    }, 500);
    return () => clearTimeout(timer);
  }, [formValue?.name, state, method, activePage, limit]);

  const handleUpdate = async () => {
    try {
      const res = await updateVersion(selectedValue?.id, selectedValue);
      if (res.status == 200) {
        getListVersions(activePage, limit);
        handleClose();
      }
    } catch (err) {
      throw err;
    }
  };
  const setNamePackage = (id) => {
    let packageName;
    switch (id) {
      case 1:
        packageName = "Web - 1 Month";
        break;
      case 2:
        packageName = "Web - 6 Months";
        break;
      case 3:
        packageName = "Web - 1 Year";
        break;
      case 4:
        packageName = "Extension - 1 Month";
        break;
      case 5:
        packageName = "Extension - 6 Months";
        break;
      case 6:
        packageName = "Extension - 1 Year";
        break;
      default:
        break;
    }
    return packageName;
  };
  const handleCreate = async () => {
    const input = {
      version: formValue.version,
      link_download: formValue.linkDownload,
      status: status,
      type: method,
    };
    try {
      const res = await createVersion(input);
      if (res.status == 200) {
        setTextMessage("Cập nhật thành công.");
        getListVersions(activePage, limit);
        setFormValue();
        handleCloseCreate();
      }
    } catch (err) {
      setTextMessage(err?.response?.data?.error_msg);
    }
  };
  const handleDelete = async () => {
    try {
      const res = await delVersion(selectedValue.id);
      if (res.status == 200) {
        setTextMessage("Xoá version thành công.");
        getListVersions(activePage, limit);
        handleCloseDel();
      }
    } catch (err) {
      setTextMessage(err?.response?.data?.error_msg);
    }
  };
  return (
    <div className="admin__tool__container">
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{`UPDATE`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={selectedValue} onChange={setSelectedValue} fluid>
            <Form.Group controlId="version">
              <Form.ControlLabel>
                Version
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="version" />
            </Form.Group>
            <Form.Group controlId="link_download">
              <Form.ControlLabel>
                Link Download
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="link_download" />
            </Form.Group>
            <Form.Group controlId="status">
              <Form.ControlLabel>
                Status
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control
                name="status"
                style={{
                  width: "100%",
                }}
                accepter={InputPicker}
                data={[
                  { label: "UnActive", value: 0 },
                  { label: "Active", value: 1 },
                ]}
              />
            </Form.Group>
            <Form.Group controlId="type">
              <Form.ControlLabel>
                Loại
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control
                name="type"
                style={{
                  width: "100%",
                }}
                accepter={InputPicker}
                data={[
                  { label: "Web", value: 1 },
                  { label: "Extension", value: 2 },
                ]}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal open={openDel} onClose={handleCloseDel}>
        <Modal.Header>
          <Modal.Title>{`DELETE`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Bạn có chắc chắn muốn xoá version này!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseDel} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={openCreate}
        onClose={handleCloseCreate}
        // style={{ background: "#F5ECD7" }}
      >
        <Modal.Header>
          <Modal.Title>ADD NEW VERSION</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={formValue} onChange={setFormValue} fluid>
            <Form.Group controlId="version">
              <Form.ControlLabel>
                Version
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="version" />
            </Form.Group>
            <Form.Group controlId="linkDownload">
              <Form.ControlLabel>
                Link Download
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="linkDownload" />
            </Form.Group>
            <Form.Group controlId="status">
              <Form.ControlLabel>
                Status
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <SubForm.Select
                style={
                  {
                    //   background: "#fff",
                    //   maxWidth: "150px",
                    //   marginLeft: "10px",
                  }
                }
                aria-label="Default select example"
                onChange={(e) => setStatus(e.target.value)}
                defaultValue={1}
              >
                <option value={0}>UnActive</option>
                <option value={1}>Active</option>
              </SubForm.Select>
            </Form.Group>
            <Form.Group controlId="type">
              <Form.ControlLabel>
                Loại
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <SubForm.Select
                style={
                  {
                    //   background: "#fff",
                    //   maxWidth: "150px",
                    //   marginLeft: "10px",
                  }
                }
                aria-label="Default select example"
                onChange={(e) => setMeThod(e.target.value)}
              >
                <option value={1}>Web</option>
                <option value={2}>Extension</option>
              </SubForm.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCreate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseCreate} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-end">
        <Button
          className="mb-2"
          style={{ width: "150px" }}
          onClick={handleOpenCreate}
        >
          ADD NEW VERSION
        </Button>
      </div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Version</th>
            <th>Link Download</th>
            <th>Status</th>
            <th>Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {listExtenions?.map((item, index) => (
            <tr key={item?.id}>
              <td>{item?.id}</td>
              <td>{item?.version}</td>
              <td>
                <a
                  href={item?.link_download}
                  target="_blank"
                  className="text-decoration-none text-dark"
                >
                  {item?.link_download}
                </a>
              </td>
              <td>{item?.status == 0 ? "UnActive" : "Active"}</td>
              {/* <td style={{ maxWidth: "20rem" }}>{item?.script}</td> */}
              <td>{item?.type == 1 ? "Web" : "Extension"}</td>
              <td className="d-flex gap-2">
                <Button
                  appearance="primary"
                  onClick={() => {
                    setSelectedValue(item);
                    // console.log(item);
                    handleOpen();
                  }}
                >
                  Update
                </Button>
                <Button
                  onClick={() => {
                    setSelectedValue(item);
                    // console.log(item);
                    handleOpenDel();
                  }}
                  appearance="primary"
                  style={{ background: "red" }}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="_pagination">
        {totalItems > 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"xs"}
            prev
            next
            // first
            // last
            ellipsis
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={5}
            activePage={activePage}
            onChangePage={setActivePage}
            onChangeLimit={handleChangeLimit}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default AdminVersions;
