import React, { useEffect, useState } from "react";
import { sendGet, sendPost, sendPut } from "../../../axios/axiosClient";
import { Table } from "react-bootstrap";
import Cookies from "js-cookie";
import {
  Button,
  Form,
  Input,
  Message,
  Modal,
  Pagination,
  useToaster,
} from "rsuite";
import "./adminwithdraw.css";
import { getExtension } from "../../../axios/adminextensionApi";
import { Form as SubForm } from "react-bootstrap";
import { getPackages } from "../../../axios/adminPackages";
import { getWithdraw, updateWithdraw } from "../../../axios/withdrawApi";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
const AdminWithdraw = () => {
  const [listPackages, setListPackages] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [status, setStatus] = useState(2);
  const [exType, setExType] = useState(0);
  const [exSource, setExSource] = useState(0);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [updateState, setUpdateState] = useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [selectedValue, setSelectedValue] = useState();
  const [formValue, setFormValue] = useState();
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const getListWithdraw = async (page, pageSize) => {
    const input = {
      ...(formValue?.name &&
        formValue?.name?.length !== 0 && { search: formValue?.name }),
      ...(status && (status == 1 || status == 0) && { status: status }),
    };
    try {
      const res = await getWithdraw(input, page, pageSize);
      if (res.status === 200) {
        setListPackages(res.data?.data?.data);
        setTotalItems(res.data?.data?.total);
      }
    } catch (err) {
      throw err;
    }
  };

  const handleUpdate = async () => {
    try {
      const input = {
        status: updateState,
      };
      const res = await updateWithdraw(selectedValue.id, input);
      if (res.status === 200) {
        setTextMessage("Thành công");
        handleClose();
        getListWithdraw(activePage, limit);
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const handleChangeLimit = (e) => {
    setLimit(e);
    setActivePage(1);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getListWithdraw(activePage, limit);
    }, 500);
    return () => clearTimeout(timer);
  }, [formValue?.name, status, activePage, limit]);

  const priceFormat = (price) => {
    return price?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  return (
    <div className="admin__tool__container">
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{`UPDATE`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={selectedValue} onChange={setSelectedValue} fluid>
            <Form.Group controlId="type">
              <Form.ControlLabel>
                Status
                <Form.HelpText tooltip>Bắt buộc</Form.HelpText>
              </Form.ControlLabel>
              <SubForm.Select
                style={
                  {
                    //   background: "#fff",
                    //   maxWidth: "150px",
                    //   marginLeft: "10px",
                  }
                }
                aria-label="Default select example"
                onChange={(e) => setUpdateState(e.target.value)}
                defaultValue={1}
              >
                <option value="0">Pending</option>
                <option value="1">Faulty</option>
                <option value="2">Completed</option>
              </SubForm.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-end align-items-center mb-2">
        <Form formValue={formValue} onChange={setFormValue}>
          <Form.Group controlId="name">
            <Form.Control
              name="name"
              style={{ background: "#fff" }}
              placeholder="Tìm kiếm ..."
            />
          </Form.Group>
        </Form>
        <SubForm.Select
          style={{ background: "#fff", maxWidth: "100px", marginLeft: "10px" }}
          aria-label="Default select example"
          onChange={(e) => setStatus(e.target.value)}
        >
          <option>Status</option>
          <option value={1}>Online</option>
          <option value={0}>Offline</option>
        </SubForm.Select>
      </div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>User</th>
            <th>Name</th>
            <th>Money</th>
            <th>Bank</th>
            <th>Bank Code</th>
            {/* <th>Phone</th> */}
            <th>Status</th>
            <th>Note</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {listPackages?.map((item, index) => (
            <tr key={item?.id}>
              <td>{++index}</td>
              <td>{item?.user?.email}</td>
              <td>{item?.fullname}</td>
              <td>{priceFormat(Number(item?.coin))}</td>
              <td>{item?.bank}</td>
              <td>{item?.bank_code}</td>
              {/* <td>{item?.phone}</td> */}
              <td>
                {(item?.status == 0 && "Pending") ||
                  (item?.status == 1 && "Faulty") ||
                  (item?.status == 2 && "Completed")}
              </td>
              <td>{item?.note}</td>
              <td className="d-flex gap-2">
                <Button
                  appearance="primary"
                  onClick={() => {
                    setSelectedValue(item);
                    // console.log(item);
                    handleOpen();
                  }}
                >
                  Update
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="_pagination">
        {totalItems > 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"xs"}
            prev
            next
            // first
            // last
            ellipsis
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={5}
            activePage={activePage}
            onChangePage={setActivePage}
            onChangeLimit={handleChangeLimit}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default AdminWithdraw;
