import React from "react";
import FbOneCardVideoCarousel from "../../fake-content-card/fb-one-card-video-carousel/FbOneCardVideoCarousel";

const FbPictureCarousel = () => {
  return (
    <div>
      <FbOneCardVideoCarousel />
    </div>
  );
};

export default FbPictureCarousel;
