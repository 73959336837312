import React from "react";
import FbVideoCarousel from "../fb-video-carousel/FbVideoCarousel";

const FbVideosCarousel = () => {
  return (
    <div>
      <FbVideoCarousel />
    </div>
  );
};

export default FbVideosCarousel;
