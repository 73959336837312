import React, { useEffect, useState } from "react";
import { Dropdown, Loader } from "rsuite";
import "./selectedGroup.css";
import avatar_img from "../../assets/images/645fbc5da4a21.png";
import Select from "react-select";
const SelectedGroup = ({
  listGroups,
  selectedGroup,
  setSelectedGroup,
  err,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});

  useEffect(() => {
    if (listGroups.length > 0) {
      const arr = listGroups?.map((item) => ({
        label: (
          <div className="d-flex gap-3 align-items-center">
            <img
              src={item?.url}
              alt="page-avatar"
              className="select-item-image"
            />
            <div>
              <p className="fw-bold group_membernumber m-0">{item?.name}</p>
              <p className="group_membernumber m-0">{item?.id}</p>
            </div>
          </div>
        ),
        value: item?.name,
      }));
      setOptions(arr);
      setSelectedValue(arr[0]);
    }
  }, [listGroups]);

  return (
    <>
      <Select
        onChange={(e) => {
          setSelectedGroup(listGroups.find((item) => item.name === e.value));
          setSelectedValue(e);
        }}
        value={selectedValue}
        placeholder="Chọn nhóm..."
        options={options}
      />
      {/* <Dropdown
      style={{ width: "100%" }}
      className="pageDropdown"
      title={
        err ? (
          "GET LIST GROUPS FAIL"
        ) : (
          <>
            {selectedGroup ? (
              selectedGroup !== `no_group` ? (
                <div className="d-flex align-items-center gap-3">
                  <img
                    src={selectedGroup?.url}
                    alt="avatar_image"
                    className="toolHeader__avatar"
                  />
                  <div className="">
                    <p className="fw-bold group_membernumber m-0 text-start">
                      {selectedGroup?.name}
                    </p>
                    <p className="group_membernumber m-0 text-start">
                      {selectedGroup?.id}
                    </p>
                  </div>
                </div>
              ) : (
                `Bạn hiện đang không quản lý nhóm nào`
              )
            ) : (
              <Loader />
            )}
          </>
        )
      }
    >
      {listGroups?.map((item, key) => (
        <Dropdown.Item
          eventKey={key}
          key={key}
          onClick={() => setSelectedGroup(item)}
        >
          <div className="d-flex align-items-center gap-3">
            <img
              src={item?.url ? item?.url : avatar_img}
              alt="avatar_image"
              className="toolHeader__avatar"
            />
            <div>
              <p className="fw-bold group_membernumber m-0 text-start">
                {item?.name}
              </p>
              <p className="group_membernumber m-0 text-start">{item?.id}</p>
            </div>
          </div>
        </Dropdown.Item>
      ))}
    </Dropdown> */}
    </>
  );
};

export default SelectedGroup;
