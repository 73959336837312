import React, { useContext } from "react";
import "./profileHeading.css";
import avatar_image from "../../assets/images/645fbc5da4a21.png";
import { AiFillCheckCircle } from "react-icons/ai";
import { ExtensionContext } from "../../context/ExtensionContext";
const ProfileHeading = () => {
  const { selectedExtension } = useContext(ExtensionContext);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex gap-2 align-items-center">
          <div>
            <img
              src={selectedExtension?.avartar}
              alt="avatar"
              className="toolHeader__avatar"
            />
          </div>
          <div>
            <p className="euidfromgroup__groupInput__name">
              {selectedExtension?.name}
            </p>
            <p className="euidfromgroup__groupInput__id">
              {selectedExtension?.uid}
            </p>
          </div>
        </div>
        <div>
          <AiFillCheckCircle style={{ color: "#4ADE80" }} />
        </div>
      </div>
    </div>
  );
};

export default ProfileHeading;
