import React from "react";
import "./toolFooter.css";
import foot_logo from "../../../assets/images/logo-1.png";
import { Link } from "react-router-dom";
import { BsDot, BsFacebook, BsTelegram, BsTwitter } from "react-icons/bs";
import { Button, Modal } from "rsuite";
import { GrGroup } from "react-icons/gr";
import Cookies from "js-cookie";
import { useMedia } from "react-use";
const ToolFooter = () => {
  const isWide = useMedia({ minWidth: "1050px" });
  return (
    <div
      className="toolFooter"
      style={{
        width: `calc(${
          Cookies?.get("admin") ? "100% -  55px" : "100% - 305px"
        })`,
        left: `${Cookies?.get("admin") ? "55px" : "305px"}`,
      }}
    >
      <div
        className="d-flex justify-content-around align-items-center"
        style={{ gap: "20%" }}
      >
        {/* <div className="d-grid text-center"> */}
        {/* <span className="toolFooter__link oneLine">
          © 2023 Bản quyền thuộc về AMAI Agency
        </span> */}
        <div></div>
        <div className="d-flex gap-3">
          <span className="oneLine">Kênh hỗ trợ:</span>
          <a
            href="https://www.facebook.com/profile.php?id=61550016097785"
            target="_blank"
            className="toolFooter__link"
          >
            <BsFacebook className="fs-5" style={{ color: "#17A9FD" }} />
          </a>
          <a
            href="https://t.me/amaimmo"
            target="_blank"
            className="toolFooter__link"
          >
            <BsTelegram className="fs-5" style={{ color: "#0088CC" }} />
          </a>
          <a
            href="https://www.facebook.com/groups/535296425027335/"
            target="_blank"
            className="toolFooter__link"
          >
            <GrGroup className="fs-5" />
          </a>
        </div>
        <div className="d-flex gap-2">
          <span className="oneLine">
            Bản quyền:{" "}
            <a
              href="https://amaiagency.com/"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              amaiagency.com
            </a>
          </span>
          -
          <span className="oneLine">
            Thợ sửa ống nước:{" "}
            <a
              href="https://hatronghung.com/"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              hatronghung.com
            </a>
          </span>
        </div>
      </div>

      {/* <div className="d-grid text-center">
        <div>
          <img src={foot_logo} alt="" className="toolFooter__footerLogo" />
        </div>
        <div className="mt-3">
          <p className="footerThankyouText">
            Thanks you so much, and have a nice day :)
          </p>
          <p className="footerText">
            <Link className="footerText">This is Link1</Link>
            <BsDot className="mx-2" />
            <Link className="footerText">This is Link2</Link>
            <BsDot className="mx-2" />
            <Link className="footerText">This is Link3</Link>
          </p>
          <p className="footerText">Designer AmaiAgency 2023</p>
        </div>
      </div> */}
    </div>
  );
};

export default ToolFooter;
