import React, { useState } from "react";
import "./landingPage.css";
import { Link, useNavigate } from "react-router-dom";
import headerStaticsImage from "../../assets/images/landing-page/stats-mmo.svg";
import {
  BsBookmarkCheckFill,
  BsCheckAll,
  BsFillCalendar2WeekFill,
} from "react-icons/bs";
import { FaLocationDot, FaTiktok } from "react-icons/fa6";
import helping1 from "../../assets/images/landing-page/help1.svg";
import helping2 from "../../assets/images/landing-page/help2.svg";
import helping3 from "../../assets/images/landing-page/help3.svg";
import helping4 from "../../assets/images/landing-page/help4.svg";
import { BiLogoTiktok, BiTrendingUp } from "react-icons/bi";
import { FreeMode, Pagination } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";
import carouselImage from "../../assets/images/landing-page/demo-image.jpg";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Button, Drawer, Rate } from "rsuite";
import sponsor from "../../assets/images/landing-page/company.svg";
import products1 from "../../assets/images/landing-page/products/product1.svg";
import products2 from "../../assets/images/landing-page/products/product2.svg";
import products3 from "../../assets/images/landing-page/products/product3.svg";
import products4 from "../../assets/images/landing-page/products/product4.svg";
import products5 from "../../assets/images/landing-page/products/product5.svg";
import products6 from "../../assets/images/landing-page/products/product6.svg";
import team1 from "../../assets/images/landing-page/team-member/team1.svg";
import team2 from "../../assets/images/landing-page/team-member/team2.svg";
import team3 from "../../assets/images/landing-page/team-member/team3.svg";
import rate1 from "../../assets/images/landing-page/rate-person/rate1.svg";
import rate2 from "../../assets/images/landing-page/rate-person/rate1.svg";
import rate3 from "../../assets/images/landing-page/rate-person/rate1.svg";
import rate4 from "../../assets/images/landing-page/rate-person/rate1.svg";
import footerLogo from "../../assets/images/logo/logo-transparent-background.png";
import checkBoCongThuong from "../../assets/images/landing-page/bo-cong-thuong-check.svg";
import dmcaProtected from "../../assets/images/landing-page/dmca-protected.svg";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineMenu,
  AiOutlineYoutube,
} from "react-icons/ai";
import landingLogo from "../../assets/images/logo/logo-transparent-background.png";
import helpLogo1 from "../../assets/images/landing-page/help-logo-1.svg";
import helpLogo2 from "../../assets/images/landing-page/help-logo-2.svg";
import helpLogo3 from "../../assets/images/landing-page/help-logo-3.svg";
import helpLogo4 from "../../assets/images/landing-page/help-logo-4.svg";
import { useMedia } from "react-use";
import drawerBackground from "../../assets/images/landing-page/section3-background.svg";

const LandingPage = () => {
  const navigate = useNavigate();
  const isWide = useMedia("(max-width: 768px)");
  const [open, setOpen] = React.useState(false);
  const headerMenu = [
    {
      name: "Trang chủ",
      path: "/home",
    },
    {
      name: "Về chúng tôi",
      path: "",
    },
    {
      name: "Dịch vụ",
      path: "",
    },
    {
      name: "Liên hệ với chúng tôi",
      path: "",
    },
    {
      name: "Đăng nhập",
      path: "/login",
    },
  ];

  const section2Content = [
    {
      heading: "Người Mới Bắt Đầu trong Affiliate Marketing và MMO",
      content:
        "Nếu bạn là người mới bắt đầu và muốn tìm hiểu về Affiliate Marketing và MMO, Amai MMO là nơi bạn nên bắt đầu. Chúng tôi cung cấp các tài liệu hướng dẫn dễ hiểu và giúp bạn xây dựng nền tảng kiến thức cơ bản.",
    },
    {
      heading: "Những Người Muốn Tối Ưu Hóa Chiến Lược Tiếp Thị Của Mình",
      content:
        "Cho dù bạn đã có kinh nghiệm trong lĩnh vực này, Amai MMO vẫn có giá trị cho bạn. Chúng tôi cung cấp các công cụ và khóa học giúp bạn tối ưu hóa chiến lược tiếp thị của mình, tăng cường lợi nhuận và đạt được mục tiêu cao hơn.",
    },
    {
      heading: "Phần Mềm Facebook Marketing Miễn Phí",
      content:
        "Sử dụng các công cụ Phần mềm Marketing Facebook miễn phí và Phần mềm Facebook Marketing có thể làm tăng hiệu suất tiếp thị của bạn đáng kể. Amai MMO cung cấp hướng dẫn cụ thể về cách sử dụng chúng một cách hiệu quả.",
    },
    {
      heading: "Những Ai Quan Tâm Đến Tiếp Thị Liên Kết",
      content:
        "Nếu bạn đang tìm cách tạo nguồn thu nhập bằng cách tiếp thị liên kết hoặc muốn hiểu rõ hơn về mô hình này, Amai MMO cung cấp thông tin và chiến lược để bạn có thể thành công trong lĩnh vực này.",
    },
    {
      heading: "Những Người Cần Hỗ Trợ và Cộng Đồng",
      content:
        "Amai MMO không chỉ là một nền tảng học tập, mà còn là một cộng đồng. Bạn sẽ có cơ hội kết nối với các thành viên khác, trao đổi thông tin và học hỏi từ những người có kinh nghiệm. ",
    },
  ];

  const [selectedContent, setSelectedContent] = useState(0);

  const helping = [
    {
      icon: helpLogo1,
      header: "Hiểu Rõ Hơn về Affiliate Marketing và MMO",
      content:
        "Amai MMO sẽ giúp bạn hiểu rõ hơn về các khái niệm cơ bản và phức tạp trong Affiliate Marketing và MMO. Chúng tôi giải thích mọi thứ một cách đơn giản và dễ hiểu để bạn có thể xây dựng nền tảng kiến thức mạnh mẽ.",
      image: helping1,
    },
    {
      icon: helpLogo2,
      header:
        "Sử Dụng Phần Mềm Marketing Facebook Miễn Phí và Phần Mềm Facebook Marketing Một Cách Hiệu Quả",
      content:
        "Amai MMO cung cấp hướng dẫn chi tiết về cách sử dụng các phần mềm tiếp thị Facebook một cách thông minh và hiệu quả. Bạn sẽ biết cách tối ưu hóa quảng cáo, quản lý trang Facebook, và thu hút nhiều khách hàng hơn.",
      image: helping2,
    },
    {
      icon: helpLogo3,
      header: "Tối Ưu Hóa Chiến Lược Tiếp Thị Liên Kết",
      content:
        "Với Amai MMO, bạn sẽ tìm hiểu về cách tạo ra các chiến lược tiếp thị liên kết mạnh mẽ. Chúng tôi chia sẻ các chiến lược đã được chứng minh và kỹ thuật để bạn có thể tối ưu hóa thu nhập từ tiếp thị liên kết.",
      image: helping3,
    },
    {
      icon: helpLogo4,
      header: "Tạo Ra Nguồn Thu Nhập Ổn Định từ Mô Hình MMO",
      content:
        "Amai MMO không chỉ dừng lại ở việc giúp bạn kiếm tiền trực tiếp từ Affiliate Marketing và MMO, mà còn giúp bạn xây dựng mô hình kinh doanh ổn định và bền vững. Bạn sẽ học cách tạo ra nguồn thu nhập liên tục từ mô hình MMO của mình.",
      image: helping4,
    },
  ];

  const products = [
    {
      image: products1,
      header: "Clone Reels vs video thành Carousel",
      content:
        "There is a moment in the life of any aspiring astronomer that it is time to buy that first telescope.",
    },
    {
      image: products2,
      header: "Lấy video Tiktok đăng carousel",
      content:
        "There is a moment in the life of any aspiring astronomer that it is time to buy that first telescope.",
    },
    {
      image: products3,
      header: "Tạo hàng loạt Page profiles",
      content:
        "There is a moment in the life of any aspiring astronomer that it is time to buy that first telescope.",
    },
    {
      image: products4,
      header: "Clone Reels vs video thành Carousel",
      content:
        "There is a moment in the life of any aspiring astronomer that it is time to buy that first telescope.",
    },
    {
      image: products5,
      header: "Lấy video Tiktok đăng carousel",
      content:
        "There is a moment in the life of any aspiring astronomer that it is time to buy that first telescope.",
    },
    {
      image: products6,
      header: "Tạo hàng loạt Page profiles",
      content:
        "There is a moment in the life of any aspiring astronomer that it is time to buy that first telescope.",
    },
  ];

  const teamMember = [
    {
      name: "Developers",
      description:
        "As a business owner or manager, the decision to opt for offshore",
      image: team1,
    },
    {
      name: "Designers",
      description:
        "As a business owner or manager, the decision to opt for offshore",
      image: team2,
    },
    {
      name: "Managers",
      description:
        "As a business owner or manager, the decision to opt for offshore",
      image: team3,
    },
  ];

  const ratingUs = [
    {
      rate: 5,
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit placeat dicta exercitationem natus eligendi rem aliquam doloremque recusandae esse et quibusdam eum, tenetur ab, quidem mollitia dolores maxime consequuntur sequi.",
      name: "Alex Johnson",
      image: rate1,
    },
    {
      rate: 5,
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit placeat dicta exercitationem natus eligendi rem aliquam doloremque recusandae esse et quibusdam eum, tenetur ab, quidem mollitia dolores maxime consequuntur sequi.",
      name: "Alex Johnson",
      image: rate2,
    },
    {
      rate: 5,
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit placeat dicta exercitationem natus eligendi rem aliquam doloremque recusandae esse et quibusdam eum, tenetur ab, quidem mollitia dolores maxime consequuntur sequi.",
      name: "Alex Johnson",
      image: rate3,
    },
    {
      rate: 5,
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit placeat dicta exercitationem natus eligendi rem aliquam doloremque recusandae esse et quibusdam eum, tenetur ab, quidem mollitia dolores maxime consequuntur sequi.",
      name: "Alex Johnson",
      image: rate4,
    },
  ];

  const socialLink = [
    {
      icon: <AiOutlineInstagram />,
      link: "",
    },
    {
      icon: <AiOutlineFacebook />,
      link: "",
    },
    {
      icon: <BiLogoTiktok />,
      link: "",
    },
    {
      icon: <AiOutlineYoutube />,
      link: "",
    },
  ];

  const dichVu = [
    {
      name: "Dịch vụ thiết kế Website",
      path: "",
    },
    {
      name: "Dịch vụ SEO Website",
      path: "",
    },
    {
      name: "Dịch vụ Quảng cáo Google",
      path: "",
    },
    {
      name: "Dịch vụ Quảng cáo Facebook",
      path: "",
    },
    {
      name: "Dịch vụ chăm sóc Fanpage",
      path: "",
    },
  ];

  const khoaHoc = [
    {
      name: "Khóa học Youtube Marketing",
      path: "",
    },
    {
      name: "Khóa học SEO Master",
      path: "",
    },
    {
      name: "Khóa học Google Adsense",
      path: "",
    },
    {
      name: "Khóa học Google Ads Full Stack",
      path: "",
    },
    {
      name: "Khóa học Facebook Marketing",
      path: "",
    },
  ];

  return (
    <div className="landing">
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        style={{ width: "80vw", backgroundImage: `url(${drawerBackground})` }}
      >
        <Drawer.Header>
          <Drawer.Title style={{ fontSize: "2rem" }}>MENU</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          {headerMenu.map((item, key) => (
            <p>
              <Link
                to={item?.path}
                key={key}
                className="landing__section1__header__headerLink"
              >
                {item?.name}
              </Link>
            </p>
          ))}
        </Drawer.Body>
      </Drawer>
      <div className="landing__section1">
        <div className="landing__section1__header">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <img
                src={landingLogo}
                alt="logo"
                className="landing__section1__header__logo"
              />
            </div>
            {isWide ? (
              <Button onClick={() => setOpen(true)} name="menu">
                <AiOutlineMenu />
              </Button>
            ) : (
              <div className="d-flex gap-5">
                {headerMenu.map((item, key) => (
                  // <Link
                  //   to={item?.path}
                  //   key={key}
                  //   className="landing__section1__header__headerLink"
                  // >
                  //   {item?.name}
                  // </Link>
                  <a
                    href={
                      item?.path !== "" ? item?.path : window.location.origin
                    }
                    key={key}
                    className="landing__section1__header__headerLink"
                  >
                    {item?.name}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="landing__section1__body">
          <div className={isWide ? `` : `d-flex`}>
            <div className="col">
              <p className="landing__section1__body__h1">
                Nơi thúc đẩy doanh số bạn vượt trội
              </p>
              <p className="landing__section1__body__detailText">
                Những thay đổi liên tục trong lĩnh vực Affiliate Marketing và
                MMO có thể khiến bạn cảm thấy bối rối. Các phương pháp tiếp thị
                truyền thống không còn hiệu quả như trước, và bạn có thể đã bỏ
                ra nhiều thời gian và tiền bạc mà không thấy kết quả. Ngoài ra,
                việc tìm hiểu và sử dụng các công cụ Phần mềm Marketing Facebook
                và Phần mềm Facebook Marketing có thể đầy rườm rà và tốn thời
                gian.
              </p>
              <div
                className={`d-flex align-items-center ${
                  isWide ? "gap-4 justify-content-center" : "gap-5"
                }`}
              >
                <div
                  className="landing__section1__body__registerbutton"
                  onClick={() => navigate("/login")}
                >
                  Đăng ký
                </div>
                <div
                  className="landing__section1__body__loginbutton"
                  onClick={() => navigate("/login")}
                >
                  Đăng nhập
                </div>
              </div>
            </div>
            <div className="col">
              <img
                src={headerStaticsImage}
                alt="header-image"
                className="headerImage"
              />
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="landing__section2">
        <div
          className={isWide ? `d-grid` : `d-flex flex-wrap`}
          style={{ gap: `${isWide ? "2rem" : "2%"}` }}
        >
          <div className="landing__section2__body">
            <p className="landing__section2__body__header">
              Amai MMO Dành Cho Ai?
            </p>
            <p className="landing__section2__body__contenttext">
              Amai MMO không giới hạn đối tượng, và nó dành cho tất cả mọi người
              có sự quan tâm đến Affiliate Marketing, MMO, Phần mềm Marketing
              Facebook miễn phí, Phần mềm Facebook Marketing, và Tiếp thị liên
              kết.
            </p>
          </div>
          {section2Content?.map((item, key) => (
            <div
              className={`landing__section2__body listSelect ${
                selectedContent === key ? "selected" : ""
              }`}
              key={key}
              onMouseOver={() => setSelectedContent(key)}
            >
              <div
                className={`d-flex align-items-center ${
                  isWide ? "gap-4" : "gap-5"
                }`}
              >
                <div className="landing__section2__body__check d-flex justify-content-center align-items-center">
                  <BsCheckAll />
                </div>
                <p className="landing__section2__body__header1">
                  {item?.heading}
                </p>
              </div>
              <p className="landing__section2__body__contenttext">
                {item?.content}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/*  */}
      <div className="landing__section3">
        <p className="landing__section3__header text-center">
          Amai MMO - Giúp Ích Gì Cho Bạn?
        </p>
        <div className="landing__section3__content">
          {helping?.map((item, key) => (
            <div
              className={`landing__section3__content__item ${
                isWide ? "" : "d-flex"
              } align-items-center ${key % 2 === 1 ? "flex-row-reverse" : ""}`}
              key={key}
            >
              <div className="landing__section3__content__card">
                <div
                  className={"d-flex align-items-center"}
                  style={{ gap: "5vw" }}
                >
                  {/* {item?.icon} */}
                  {isWide ? (
                    ""
                  ) : (
                    <img src={item?.icon} alt="image" className="helpingLogo" />
                  )}
                  <div>
                    <p className="landing__section3__content__card__header">
                      {item?.header}
                    </p>
                    <p className="landing__section3__content__card__textcontent">
                      {item?.content}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <img
                  src={item?.image}
                  alt="content-image"
                  className="landing__section3__content__image"
                />
              </div>
            </div>
          ))}
        </div>
        {/* new application */}
        <div className="landing__section3__newApplication">
          <div>
            <p className="landing__section3__newApplication__header">
              Ứng dụng mới
            </p>
          </div>
          <div>
            <Swiper
              slidesPerView={isWide ? 1 : 4}
              spaceBetween={isWide ? 20 : 100}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination]}
              className="mySwiper"
            >
              {Array.from({ length: 5 }, (_, index) => (
                <SwiperSlide key={index}>
                  <div className="carouselCard">
                    <img
                      src={carouselImage}
                      alt="carousel-image"
                      className="carouselItem"
                    />
                    <p className="carouselCard__header">
                      Tự động đăng bài hàng loạt group facebook
                    </p>
                    <Button
                      appearance="primary"
                      className="carouselCard__button"
                    >
                      Dùng ngay
                    </Button>
                    {/* <div className="carouselCard__button"></div> */}
                  </div>
                </SwiperSlide> // You should set a unique key for each component
              ))}
            </Swiper>
          </div>
        </div>
        <div className="landing__section3__sponsor">
          <img src={sponsor} alt="sponsor" className="sponsorImage" />
        </div>
      </div>

      {/* sec4 */}
      <div className="landing__section4">
        <div>
          <p className="landing__section4__header text-center">
            Sản phẩm của Amai Mmo
          </p>
        </div>
        <div className="landing__section4__body">
          <div
            className={
              isWide ? `d-grid` : `d-flex flex-wrap justify-content-center`
            }
            style={{ gap: "5vw", position: "relative" }}
          >
            {products?.map((item, index) => (
              <div
                key={index}
                className="landing__section4__item"
                style={{
                  // backgroundImage: `url(${item?.image})`,
                  background: `linear-gradient(180deg, rgba(255, 255, 255, 0) , rgba(255, 255, 255, 1) 70%), url(${item?.image}) no-repeat`,
                  backgroundSize: "cover",
                }}
              >
                {/* <div >
                  <img
                    src={item?.image}
                    alt=""
                    className="landing__section4__cardImage"
                  />
                </div> */}
                <div className="landing__section4__item__content">
                  <p className="landing__section4__item__header">
                    {item?.header}
                  </p>
                  <p className="landing__section4__item__description">
                    {item?.content}
                  </p>
                  <Button className="carouselCard__button" appearance="primary">
                    Dùng ngay
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* sec5 */}
      <div className="landing__section5">
        <div
          className={`${
            isWide ? "d-grid gap-5" : "d-flex"
          } justify-content-between`}
          style={{ gap: "3rem" }}
        >
          <div>
            <p className="landing__section5__header">Đội ngũ Amai Mmo</p>
            <p className="landing__section5__headerDescription">
              If you are looking at blank cassettes on the web, you may be very
              confused.
            </p>
            <div className="d-grid" style={{ gap: "3vh" }}>
              {teamMember?.map((item, key) => (
                <div
                  className={`landing__section5__item d-flex ${
                    key % 2 === 1 ? "flex-row-reverse" : ""
                  } justify-content-between align-items-center`}
                  key={key}
                >
                  <div>
                    <p className="landing__section5__itemHeader">
                      {item?.name}
                    </p>
                    <p className="landing__section5__itemDescription">
                      {item?.description}
                    </p>
                  </div>
                  <div>
                    <img
                      src={item?.image}
                      alt="team-member"
                      className="landing__section5__itemImage"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <img
              src="https://bma.vn/wp-content/uploads/2017/04/team1.jpg"
              alt="team"
              style={isWide ? { display: "none" } : { height: "80vh" }}
            />
          </div>
        </div>
      </div>

      {/* section6 */}
      <div className="landing__section6">
        <p className="landing__section6__header text-center">
          Họ nói gì về chúng tôi
        </p>
        <div
          className={
            isWide ? `d-grid` : `d-flex flex-wrap justify-content-between`
          }
        >
          {ratingUs?.map((item, key) => (
            <div key={key} className="landing__section6__item">
              <div
                className="d-flex align-items-center"
                style={{ gap: `${isWide ? "2rem" : "3vw"}` }}
              >
                <div>
                  <img
                    src={item?.image}
                    alt="avatar"
                    style={{ width: isWide ? "3rem" : "7vw" }}
                  />
                </div>
                <div>
                  <Rate defaultValue={item?.rate} readOnly name={item?.name} />
                  <p className="landing__section6__itemComment">
                    {item?.comment}
                  </p>
                  <p className="landing__section6__itemName">{item?.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* footer */}
      <div className="landing__footer">
        <div
          className={isWide ? `d-grid` : `d-flex justify-content-between`}
          style={{ gap: "7vw" }}
        >
          <div className="landing__footer__column">
            <img
              src={footerLogo}
              alt="logo"
              style={{ width: "7vw", marginBottom: "3vh" }}
            />
            <p>CTCP DỊCH VỤ VÀ TRUYỀN THÔNG AMAI</p>
            <p>Số 588 Nguyễn Trãi, Thanh Xuân, Hà Nội</p>
            <p>VP: CT3-2 KĐT Mễ Trì Hạ, Nam Từ Liêm, Hà Nội</p>
            <p>Mã số thuế: 0109141270</p>
            <p className="landing__footer__heading">Liên hệ</p>
            <p>Hotline: 0969171665</p>
            <p className="landing__footer__heading">Hỗ trợ dịch vụ, khoá học</p>
            <p>support@amaiagency.com</p>
          </div>
          <div className="landing__footer__column">
            <p className="landing__footer__heading">Dịch vụ</p>
            {dichVu?.map((item, key) => (
              <p key={key}>
                <a
                  href={item?.path !== "" ? item?.path : window.location.origin}
                  target="_blank"
                >
                  {item?.name}
                </a>
              </p>
            ))}
            <p className="landing__footer__heading">Liên hệ</p>
            <p>contact@amaiagency.com</p>
            <p className="landing__footer__heading">Tuyển dụng</p>
            <p>hr@amaiagency.com</p>
          </div>
          <div className="landing__footer__column">
            <p className="landing__footer__heading">Khoá học</p>
            {khoaHoc?.map((item, key) => (
              <p key={key}>
                <a
                  href={item?.path !== "" ? item?.path : window.location.origin}
                  target="_blank"
                >
                  {item?.name}
                </a>
              </p>
            ))}
            <div className="d-grid" style={{ gap: "1vw" }}>
              <img
                src={dmcaProtected}
                alt="dmca-protected"
                className="checkLegit"
              />
              <img
                src={checkBoCongThuong}
                alt="check-bo-cong-thuong"
                className="checkLegit"
              />
            </div>
          </div>
          <div className="landing__footer__column">
            <p className="landing__footer__heading">Bản đồ</p>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.4218369644996!2d105.77953301068597!3d21.015800788142986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab5533312d45%3A0x48e7917eab3b11f2!2zQ2h1bmcgY8awIENUMy0yIE3hu4UgVHLDrCBI4bqhLCBN4buFIFRyw6wsIFThu6sgTGnDqm0sIEjDoCBO4buZaSwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1694168593528!5m2!1svi!2s"
              style={{
                border: "0",
                width: `${isWide ? "100%" : "20vw"}`,
                height: "25vh",
              }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className="d-flex gap-3 mt-3">
              {socialLink?.map((item, key) => (
                <a
                  href={item?.link}
                  key={key}
                  target="_blank"
                  className="socialLink"
                >
                  {item?.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
