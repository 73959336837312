import React from "react";
import "./report.css";
import {
  Button,
  ButtonToolbar,
  Form,
  IconButton,
  Input,
  InputPicker,
  Message,
  Modal,
  useToaster,
} from "rsuite";
import { Form as bForm } from "react-bootstrap";
import { GoReport } from "react-icons/go";
import { useEffect } from "react";
import { socket } from "../../../context/socket";
import { ExtensionContext } from "../../../context/ExtensionContext";
import { sendGet, sendPost } from "../../../axios/axiosClient";
import { useContext } from "react";
import { useState } from "react";
import { async } from "q";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

const Report = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [reportTools, setReportTools] = useState([]);
  const { selectedExtension, listTools } = useContext(ExtensionContext);
  const [formValue, setFormValue] = useState({
    key_tool: "",
    note: "",
  });

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  //   useEffect(() => {
  //     socket.on("do_task_response", (message) => {
  //       console.log(message);
  //     });
  //   }, []);

  useEffect(() => {
    if (selectedExtension !== undefined) {
      const data = listTools.map((item) => ({
        label: item?.name,
        value: item?.key,
      }));
      setReportTools(data);
    }
  }, [listTools]);

  const handleSubmitReport = async () => {
    if (formValue?.key_tool !== "" && formValue?.note !== "") {
      const input = {
        key_tool: formValue?.key_tool,
        note: formValue?.note,
      };
      // console.log(input);
      try {
        const res = await sendPost(`/users/tool-error`, input);
        if (res.status === 200) {
          //   console.log(res);
          setTextMessage("Gửi báo cáo thành công !!!");
          handleClose();
          setFormValue({
            note: "",
            key_tool: "",
          });
        }
      } catch (error) {
        // console.log(error);
        setTextMessage("Gửi báo cáo thất bại");
      }
    } else {
      setTextMessage("Điền đầy đủ form trước khi gửi");
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} backdrop="static">
        <Modal.Header>
          <Modal.Title className="fw-bold">Báo cáo Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            className="mt-2"
            formValue={formValue}
            onChange={setFormValue}
          >
            <Form.Group controlId="key_tool">
              <Form.ControlLabel className="group_membernumber">
                Tool:
              </Form.ControlLabel>
              <Form.Control
                name="key_tool"
                accepter={InputPicker}
                data={reportTools}
                style={{ width: "100%" }}
              />
              {/* <bForm.Select
                aria-label="Default select example"
                onChange={(e) => {
                  const save = {
                    key_tool: e.target.value,
                    note: formValue?.note,
                  };
                  setFormValue(save);
                }}
              >
                {reportTools?.map((item, key) => (
                  <option value={item?.value} key={key}>
                    {item?.label}
                  </option>
                ))}
              </bForm.Select> */}
            </Form.Group>
            <Form.Group controlId="note">
              <Form.ControlLabel className="group_membernumber">
                Nội dung:
              </Form.ControlLabel>
              <Form.Control name="note" rows={5} accepter={Textarea} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmitReport} appearance="primary">
            Gửi
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="">
        <ButtonToolbar>
          <IconButton
            onClick={handleOpen}
            icon={<GoReport style={{ color: "white" }} />}
            className="reportButton"
            circle
            size="lg"
            style={{ backgroundColor: "#f2ae14", padding: "14px" }}
          />
        </ButtonToolbar>
      </div>
    </>
  );
};

export default Report;
