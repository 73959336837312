import "./App.css";
import "rsuite/dist/rsuite.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/home/Home";
import {
  SidebarContext,
  SidebarContextProvider,
} from "./context/SidebarContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Wrapper from "./components/layout/wrapper/Wrapper";
// import "react-multi-carousel/lib/styles.css";
import { SocketContext, socket } from "./context/socket";
import ExtensionContextProvider from "./context/ExtensionContext";
import "swiper/css";
import DataContextProvider from "./context/DataContext";
function App() {
  // const { have2ndSidebar, setHave2ndSidebar } = useContext(
  //   SidebarContextProvider
  // );

  return (
    <div className="app">
      {/* <SocketContext> */}
      <ExtensionContextProvider>
        <DataContextProvider>
          <SocketContext.Provider value={socket}>
            <SidebarContext>
              <BrowserRouter>
                <Wrapper />
              </BrowserRouter>
            </SidebarContext>
          </SocketContext.Provider>
        </DataContextProvider>
      </ExtensionContextProvider>

      {/* </SocketContext> */}

      {/* <ExtractUIDFromGroup /> */}
    </div>
  );
}

export default App;
