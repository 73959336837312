import React, { useEffect, useState } from "react";
import {
  sendDelete,
  sendGet,
  sendPost,
  sendPut,
} from "../../../axios/axiosClient";
import { Table } from "react-bootstrap";
import "./info.css";
import { Button, Form, Message, Modal, useToaster } from "rsuite";
import { BsDot } from "react-icons/bs";
import moment from "moment";
import no_image from "../../../assets/images/logo/logo-transparent-background.png";

const Info = () => {
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("profile")) || {}
  );
  const [extensions, setExtension] = useState(
    JSON.parse(localStorage.getItem("listExtensions")) || []
  );
  const [linkDownload, setLinkDownload] = useState(
    localStorage.getItem("link_download") || ""
  );
  const [openChangePswd, setOpenChangePswd] = React.useState(false);
  const handleOpenChangePswd = () => setOpenChangePswd(true);
  const handleCloseChangePswd = () => {
    setOpenChangePswd(false);
    setFormChangePswd({
      password: "",
      new_password: "",
      confirm_password: "",
    });
  };
  const [formChangePswd, setFormChangePswd] = useState({
    password: "",
    new_password: "",
    confirm_password: "",
  });
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [deleteId, setDeleteId] = useState(0);
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  useEffect(() => {
    if (!userProfile) {
      getInfo();
    }
    if (extensions?.length === 0) {
      getExtensions();
    }
    if (linkDownload === "") {
      getVersion();
    }
  }, [userProfile]);

  const getInfo = async () => {
    try {
      const res = await sendGet(`/users/info`);
      if (res.status === 200) {
        // console.log(res.data?.data);
        localStorage.setItem("profile", JSON.stringify(res.data.data));
        setUserProfile(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getExtensions = async () => {
    try {
      const res = await sendGet(`/users/extensions`);
      if (res.status === 200) {
        setExtension(res?.data?.data);
        localStorage.setItem("listExtensions", JSON.stringify(res.data.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVersion = async () => {
    try {
      const res = await sendGet(`/users/get-version?type=1`);
      if (res.status === 200) {
        localStorage.setItem("link_download", res.data?.data[0]?.link_download);
        setLinkDownload(res.data?.data[0]?.link_download);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePassword = async () => {
    if (
      formChangePswd?.new_password?.trim() === "" ||
      formChangePswd?.password === ""
    ) {
      setTextMessage("Mật khẩu không thể là khoảng trắng.");
      return;
    }
    if (formChangePswd?.new_password !== formChangePswd?.confirm_password) {
      setTextMessage("Mật khẩu mới bạn nhập không trùng nhau.");
      return;
    }
    const input = {
      password: formChangePswd?.password,
      new_password: formChangePswd?.new_password,
    };
    try {
      const res = await sendPut(`/users/auth/change-password`, input);
      if (res.status === 200) {
        setTextMessage(res.data.msg);
        handleCloseChangePswd();
      }
    } catch (error) {
      console.log(error);
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await sendDelete(`/users/extensions/${deleteId}`);
      if (res.status === 200) {
        setTextMessage("Xoá thành công");
        getExtensions();
        handleCloseDelete();
      }
    } catch (error) {
      console.log(error);
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  return (
    <>
      <Modal
        open={openChangePswd}
        onClose={handleCloseChangePswd}
        backdrop={"static"}
      >
        <Modal.Header>
          <Modal.Title>
            <p className="fw-bold fs-5">Thay đổi mật khẩu</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={formChangePswd} onChange={setFormChangePswd} fluid>
            <Form.Group controlId="password">
              <Form.ControlLabel>Mật khẩu hiện tại</Form.ControlLabel>
              <Form.Control
                name="password"
                type="password"
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.ControlLabel>Mật khẩu mới</Form.ControlLabel>
              <Form.Control
                name="new_password"
                type="password"
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.ControlLabel>Nhập lại mật khẩu</Form.ControlLabel>
              <Form.Control
                name="confirm_password"
                type="password"
                autoComplete="off"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleChangePassword} appearance="primary">
            Đổi mật khẩu
          </Button>
          <Button onClick={handleCloseChangePswd} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal open={openDelete} onClose={handleCloseDelete}>
        <Modal.Header>
          <Modal.Title>
            <p className="fw-bold fs-5">Xác nhận xoá tài khoản Extension</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Bạn có chắc muốn xoá tài khoản extension này, hành động này không thể
          hoàn tác?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary">
            Xoá
          </Button>
          <Button onClick={handleCloseDelete} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-grid infoBody" style={{ width: "70%" }}>
        <div className="card p-2">
          <div className="d-flex gap-3">
            <div className="col-3 text-center">
              <img
                src={userProfile?.avartar ? userProfile?.avartar : no_image}
                alt="user-avatar"
                className="userprofileImage"
              />
              <br />
              <Button
                appearance="primary"
                className="mt-3"
                onClick={handleOpenChangePswd}
              >
                Đổi mật khẩu
              </Button>
            </div>
            <div className="col-9 d-flex gap-3">
              <div>
                <p>Tên người dùng:</p>
                <p>Email:</p>
                <p>Coin:</p>
                <p>Gói sử dụng:</p>
                <p>Thời hạn sử dụng:</p>
                <p>Mã giới thiệu:</p>
              </div>
              <div>
                <p>{userProfile?.name}</p>
                <p>{userProfile?.email}</p>
                <p>{userProfile?.coin ? userProfile?.coin : 0}</p>
                <p>
                  {userProfile?.name_package
                    ? userProfile?.name_package
                    : "Chưa mua gói"}
                </p>
                <p>
                  {userProfile?.expired_date === "Chưa mua gói"
                    ? "Vĩnh viễn"
                    : moment(userProfile?.expired_date).format("DD/MM/YYYY")}
                </p>
                <p>{userProfile?.code_ref}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 card p-2">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span className="fs-5 fw-bold">Tài khoản Extension</span>
            <a
              href={linkDownload}
              target="_blank"
              className="blackLink oneLine"
            >
              <Button
                appearance="primary"
                style={{ backgroundColor: "orange" }}
              >
                Tải phiên bản Extension mới nhất
              </Button>
            </a>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Tài khoản</th>
                <th>Extension key</th>
                <th>Xoá tài khoản</th>
              </tr>
            </thead>
            <tbody>
              {extensions?.map((item, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>
                    <div className="d-flex gap-2">
                      <div className="d-flex align-items-center">
                        <a
                          href={`https://www.facebook.com/${item?.uid}`}
                          target="_blank"
                        >
                          <img
                            src={item?.avartar}
                            alt="avatar"
                            className="infoAvatar"
                          />
                        </a>
                      </div>
                      <div>
                        <p className="m-0">
                          {item?.status === 1 ? (
                            <BsDot className="text-success  fs-4" />
                          ) : (
                            <BsDot className="text-danger  fs-4" />
                          )}
                          {item?.name}
                        </p>
                        <p className="m-0">{item?.uid}</p>
                      </div>
                    </div>
                  </td>
                  <td>{item?.key}</td>
                  <td>
                    <Button
                      appearance="primary"
                      style={{ background: "red" }}
                      onClick={() => {
                        setDeleteId(item?.id);
                        handleOpenDelete();
                      }}
                    >
                      Xoá
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Info;
