import React, { useContext, useEffect, useState } from "react";
import "./peOneCardBulk.css";
import {
  Button,
  ButtonToolbar,
  Form,
  InputPicker,
  Input,
  Checkbox,
  Toggle,
  Modal,
  Message,
  useToaster,
  Loader,
  CheckboxGroup,
} from "rsuite";
import { Table, Form as bsForm } from "react-bootstrap";
import { AiOutlineCopy, AiOutlineLink } from "react-icons/ai";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import { socket } from "../../../../context/socket";
import Cookies from "js-cookie";
import { sendPost } from "../../../../axios/axiosClient";
import { listCardButtons } from "../../../../commons/data/cardButton";
import SelectedPage from "../../../../components/selectedPage/SelectedPage";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
import { DataContext } from "../../../../context/DataContext";
import { LinearProgress } from "@mui/material";
import no_image from "../../../../assets/images/notFound.png";
import { listIcon } from "../../../../commons/data/icon";
import SelectAdAccounts from "../../../../components/select-ad-accounts/SelectAdAccounts";
import Select from "react-select";
const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

let totalPosts = 0;
const PeOneCardBulk = () => {
  const [formValue, setFormValue] = useState({
    threads: 3,
    delay: 10,
    textEditor: "",
  });
  const { adAccount, setAdAccount, isSendScript } = useContext(DataContext);
  const [adAccounts, setAdAccounts] = useState([]);
  const [link, setLink] = useState("");
  const [selectedAdAccount, setSelectedAdAccount] = useState();
  const [selectedCardButton, setSelectedCardButton] = useState("NO_BUTTON");
  const [pages, setPages] = useState([]);
  const [selectPage, setSelectPage] = useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { selectedExtension } = useContext(ExtensionContext);
  const [picture, setPicture] = useState();
  const [listPictures, setListPictures] = useState([]);
  const [posting, setPosting] = useState(false);
  const [listPostingLinks, setListPostingLink] = useState([]);
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [genType, setGenType] = useState("1");
  const [delays, setDelays] = useState(3);
  const [isError, setIsError] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [checkRandom, setCheckRandom] = useState([]);
  const [intervalId, setIntervalId] = useState();

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      console.log(message);
      switch (message?.params?.key_script) {
        case "get_ad_account":
          if (message?.data) {
            setAdAccount(message?.data);
            if (message?.data?.ad_account?.length > 0) {
              setAdAccounts(message?.data?.ad_account);
              let check = 0;
              message?.data?.ad_account?.map((item) => {
                if (item?.account_status !== 2) {
                  setSelectedAdAccount(item?.id);
                  check = 1;
                  return;
                }
              });
              if (check === 0) {
                setSelectedAdAccount("");
              }
            }
            if (message?.data?.page?.length > 0) {
              setSelectPage(message?.data?.page[0]?.id);
              setPages(message?.data?.page);
            } else {
              setSelectPage("");
              setPages([]);
            }
          }

          break;
        case "generate_card_from_url":
          if (
            message?.data?.data &&
            message?.data?.data?.caption !== "www.facebook.com"
            // && message?.data?.data?.link !== ""
          ) {
            setPicture({
              ...message?.data?.data,
              link: message?.params?.link,
            });
            setLink("");
          } else {
            setPicture(null);
            setTextMessage("Không lấy được dữ liệu từ URL.");
          }
          setIsLoadingData(false);
          handleClose();
          break;
        case "post_carousel":
          if (message?.data?.success === true) {
            setTextMessage("Đăng bài viết thành công");
            setListPostingLink((old) => [
              ...old,
              `https://www.facebook.com/${message?.data?.post_id}`,
            ]);
          } else {
            setListPostingLink((old) => [...old, message?.data?.error_msg]);
            // loi dang bai nhung k public
            // setTextMessage(message?.data?.post_id?.message);
            // loi dang bai khi anh k upload dc
            // https://kb.pavietnam.vn/huong-dan-tao-ung-dung-oauth-client-id-google.html
            // if (message?.data?.error_message) {
            //   setTextMessage(message?.data?.error_message);
            // } else {
            //   setTextMessage(message?.data?.success?.message);
            // }
            setTextMessage(message?.data?.error_msg);
          }
          if (totalPosts <= 0) {
            setPosting(false);
          }
          totalPosts--;
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    // console.log(picture);
    if (picture !== undefined && picture !== null) {
      setListPictures((prevItems) => [...prevItems, picture]);
    }
  }, [picture]);

  const handleUploadPost = async () => {
    console.log(listPictures);
    if (listPictures?.length === 0) {
      setTextMessage("Bạn cần tạo bài viết trước khi đăng bài");
      return;
    }
    uploadPost(listPictures[0]);
    let b = 1;
    let totalPosts = listPictures?.length;
    if (totalPosts > 1) {
      const id = setInterval(() => {
        if (b < listPictures?.length) {
          uploadPost(listPictures[b]);
          ++b;
        } else {
          clearInterval(id);
        }
      }, delays * 1000);
      setIntervalId(id);
    }
  };

  const handleStop = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    setPosting(false);
    setTextMessage("Dừng đăng bài");
  };

  const uploadPost = async (pictureItem) => {
    if (selectedAdAccount === "" || !selectedAdAccount) {
      setTextMessage("Kiểm tra lại tài khoản Ad của bạn.");
      return;
    }
    setPosting(true);
    let finalText = formValue?.textEditor?.trim() + " ";
    if (checkRandom?.length > 0) {
      if (checkRandom?.includes("text")) {
        let stringLength = 7;
        let characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
        for (let i = 0; i < stringLength; i++) {
          finalText += characters.charAt(
            Math.floor(Math.random() * characters.length)
          );
        }
      }
      if (checkRandom?.includes("icon")) {
        finalText += listIcon[Math.floor(Math.random() * listIcon.length)];
      }
      if (checkRandom?.includes("time")) {
        const today = new Date();
        const time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        finalText += time;
      }
    }
    const input = {
      key_script: "post_carousel",
      extension_key: selectedExtension?.key,
      key_tool: "bulk_generate_one_card_link",
      params: {
        key_socket: Cookies.get("keySocket"),
        type: "one_card",
        pageId: selectPage,
        title: finalText?.trim(),
        videoId1: "",
        videoId2: "",
        thumbnail1:
          pictureItem?.picture == ""
            ? `${
                window.location.origin === "http://localhost:3000"
                  ? "https://amaimmo.net"
                  : window.location.origin
              }/amai-logo.webp`
            : pictureItem?.picture,
        description1: "",
        thumbnail2: "",
        description2: "",
        button_type: selectedCardButton,
        ad_id: selectedAdAccount,
        caption: formValue?.caption ? formValue?.caption : pictureItem?.caption,
        link: pictureItem?.link,
        fakePageId:
          formValue?.fakePageId && selectedCardButton === "LIKE_PAGE"
            ? formValue?.fakePageId
            : selectPage,
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
  };

  const getAdAccount = async () => {
    // console.log(uid);
    setSelectedAdAccount(undefined);
    setSelectPage(undefined);
    const input = {
      key_script: "get_ad_account",
      extension_key: selectedExtension?.key,
      key_tool: "bulk_generate_one_card_link",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
      setIsError(true);
      setSelectPage("");
    }
  };

  const getLink = async (linkURL) => {
    const input = {
      key_script: "generate_card_from_url",
      extension_key: selectedExtension?.key,
      key_tool: "bulk_generate_one_card_link",
      params: {
        key_socket: Cookies.get("keySocket"),
        link: linkURL,
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
      setIsLoadingData(false);
    }
  };

  const handleGetLink = async () => {
    setListPictures([]);
    setIsLoadingData(true);
    const listLink = link?.split("\n");
    link?.split("\n")?.map((item, key) => {
      getLink(item);
    });
  };

  useEffect(() => {
    if (adAccount) {
      if (adAccount.ad_account?.length > 0) {
        setAdAccounts(adAccount?.ad_account);
        let check = 0;
        adAccount?.ad_account?.map((item) => {
          if (item?.account_status !== 2) {
            setSelectedAdAccount(item?.id);
            check = 1;
            return;
          }
        });
        if (check === 0) {
          setSelectedAdAccount("");
        }
      }
      if (adAccount?.page?.length > 0) {
        setSelectPage(adAccount?.page[0]?.id);
        setPages(adAccount?.page);
      } else {
        setSelectPage("");
        setPages([]);
      }
    } else if (selectedExtension?.key && isSendScript) {
      getAdAccount();
    }
  }, [selectedExtension]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Nhập URL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {genType === "1" ? (
            <Input placeholder="URL" value={link} onChange={setLink} />
          ) : (
            <Input
              as="textarea"
              rows={3}
              placeholder="URL, mỗi URL một dòng"
              value={link}
              onChange={setLink}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {isLoadingData ? (
            <Button>
              <Loader />
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleGetLink();
              }}
              appearance="primary"
            >
              Ok
            </Button>
          )}
          <Button onClick={handleClose} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex">
        <div className="col-4">
          <div className="card p-3">
            <Form fluid formValue={formValue} onChange={setFormValue}>
              <Form.Group controlId="adAccounts">
                <Form.ControlLabel className="group_membernumber">
                  Tài khoản Ad:
                </Form.ControlLabel>
                {/* <bsForm.Select
                  aria-label="Default select example"
                  onChange={(e) => setSelectedAdAccount(e.target.value)}
                >
                  {adAccounts?.map((item, key) => (
                    <option value={item?.id} key={key}>
                      {item?.account_id}
                    </option>
                  ))}
                </bsForm.Select> */}
                <SelectAdAccounts
                  setSelectedAdsAccount={setSelectedAdAccount}
                  selectedAdsAccount={selectedAdAccount}
                  listAdsAccount={adAccounts}
                />
              </Form.Group>
              <Form.Group controlId="adAccounts">
                <Form.ControlLabel className="group_membernumber">
                  Trang:
                </Form.ControlLabel>
                <SelectedPage
                  listPages={pages}
                  selectedPage={selectPage}
                  setSelectedPage={setSelectPage}
                />
              </Form.Group>
              <Form.Group controlId="cardButton">
                <Form.ControlLabel className="group_membernumber">
                  Nút bấm:
                </Form.ControlLabel>
                <Select
                  onChange={(e) => setSelectedCardButton(e.value)}
                  isSearchable={false}
                  value={{
                    label: listCardButtons.find(
                      (item) => item.value === selectedCardButton
                    ).name,
                    value: listCardButtons.find(
                      (item) => item.value === selectedCardButton
                    ).value,
                  }}
                  options={listCardButtons.map((item) => ({
                    label: item?.name,
                    value: item?.value,
                  }))}
                />
                {/* <bsForm.Select
                  aria-label="Default select example"
                  onChange={(e) => setSelectedCardButton(e.target.value)}
                  defaultValue={"NO_BUTTON"}
                >
                  {listCardButtons?.map((item, key) => (
                    <option value={item?.value} key={key}>
                      {item?.name}
                    </option>
                  ))}
                </bsForm.Select> */}
              </Form.Group>
              <Form.Group controlId="adAccounts">
                <Form.ControlLabel className="group_membernumber">
                  ID Trang được thích:
                </Form.ControlLabel>
                <Form.Control
                  name="fakePageId"
                  // value={caption}
                  // onChange={setCaption}
                  placeholder="ID Trang"
                />
              </Form.Group>
              {/* <Form.Group>
                <ButtonToolbar className="d-flex">
                  <Button appearance="primary" className="col">
                    SELECT ALL
                  </Button>
                  <Button appearance="default" className="col">
                    UNSELECT ALL
                  </Button>
                </ButtonToolbar>
              </Form.Group> */}
              <Form.Group controlId="textEditor">
                <Form.ControlLabel className="group_membernumber">
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="oneLine">Nội dung:</span>
                    <CheckboxGroup
                      inline
                      value={checkRandom}
                      onChange={(value) => {
                        setCheckRandom(value);
                      }}
                    >
                      <Checkbox value="text">Ký tự ngẫu nhiên</Checkbox>
                      <Checkbox value="icon">Biểu tượng</Checkbox>
                      <Checkbox value="time">Thời gian</Checkbox>
                    </CheckboxGroup>
                  </div>
                </Form.ControlLabel>
                <Form.Control
                  rows={5}
                  name="textEditor"
                  accepter={Textarea}
                  placeholder="Nội dung bài viết"
                />
              </Form.Group>
              <Form.Group controlId="caption">
                <Form.ControlLabel className="group_membernumber">
                  URL Hiển thị:
                </Form.ControlLabel>
                <Form.Control
                  name="caption"
                  // value={caption}
                  // onChange={setCaption}
                  placeholder="URL"
                />
              </Form.Group>
              {/* <Form.Group controlId="url">
                <Form.ControlLabel className="group_membernumber">
                  Bulk URL :
                </Form.ControlLabel>
                <Form.Control name="url" placeholder="Target URL" />
              </Form.Group> */}
              {/* <Form.Group controlId="threads">
                <Form.ControlLabel className="group_membernumber">
                  Threads ( recommended 3 ):
                </Form.ControlLabel>
                <Form.Control name="threads" type="number" />
              </Form.Group> */}
              <Form.Group controlId="delay">
                <Form.ControlLabel className="group_membernumber">
                  Delay ( giây ) :
                </Form.ControlLabel>
                <Form.Control name="delay" type="number" />
              </Form.Group>
              {/* <Form.Group controlId="checkOriginal">
                <Form.Control
                  name="checkOriginal"
                  accepter={Checkbox}
                  className="group_membernumber"
                >
                  Keep original message from site
                </Form.Control>
              </Form.Group> */}
              {/* <Form.Group
                controlId="toggleAdvanced"
                className="group_membernumber d-flex justify-content-between"
              >
                <span>Advanced Bulk schedule</span>
                <div>
                  <Form.Control
                    name="toggleAdvanced"
                    accepter={Toggle}
                    className="group_membernumber"
                  ></Form.Control>
                </div>
              </Form.Group> */}
              <Form.Group controlId="submit">
                {posting ? (
                  <Button
                    appearance="primary"
                    style={{ width: "100%", backgroundColor: "red" }}
                    onClick={handleStop}
                  >
                    DỪNG LẠI
                  </Button>
                ) : (
                  <Button
                    appearance="primary"
                    color="orange"
                    style={{ width: "100%" }}
                    onClick={handleUploadPost}
                  >
                    ĐĂNG BÀI
                  </Button>
                )}

                {listPostingLinks?.length > 0 ? (
                  <div className="card p-2 mt-3">
                    <h5 className="fw-bold text-center">Báo cáo bài đăng</h5>
                    <Table bordered responsive style={{ background: "white" }}>
                      <thead>
                        <tr>
                          <th>Đường dẫn</th>
                          <th>Trạng thái</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listPostingLinks?.map((item, idx) => {
                          if (!item.startsWith("https://www.facebook.com")) {
                            return (
                              <tr key={idx}>
                                <td
                                  colSpan={2}
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                  className="text-danger fw-bold"
                                >
                                  {item.trim().length > 0 ? item : "Thất bại"}
                                </td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr key={idx}>
                                <td style={{ maxWidth: "15vw" }}>
                                  <a
                                    href={item}
                                    target="_blank"
                                    className="group_membernumber blackLink"
                                  >
                                    {item}
                                  </a>
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                  className="text-success fw-bold"
                                >
                                  Thành công
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Form>
            {posting ? (
              <div className="mt-3">
                <LinearProgress />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="col-8 ps-3">
          {/* heading */}
          <div className="card p-3">
            <div className="mb-3">
              <ToolHeading toolType={window.location.pathname} />
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-2">
                {/* <Button
                  className="fw-bold"
                  appearance="primary"
                  color="green"
                  // onClick={() => setOpen(true)}
                >
                  <AiOutlineCopy className="me-2" />
                  BULK GEN BY EXCEL
                </Button> */}
                <Button
                  className="fw-bold"
                  appearance="primary"
                  style={{ background: "black" }}
                  onClick={() => {
                    setGenType("multi");
                    handleOpen();
                  }}
                >
                  <AiOutlineLink className="me-2" /> TẠO TỪ NHIỀU URL
                </Button>
              </div>
              <div>
                <Button
                  appearance="primary"
                  className="fw-bold"
                  onClick={handleOpen}
                >
                  <AiOutlineLink className="me-2" />
                  TẠO TỪ URL
                </Button>
              </div>
            </div>
          </div>
          {/* end heading */}
          {isLoadingData ? <LinearProgress style={{ width: "100%" }} /> : <></>}
          {listPictures?.length > 0 ? (
            <div className="card mt-3 p-3">
              <div className="d-flex flex-wrap gap-3">
                {listPictures?.map((item, key) => {
                  // if (item?.picture !== "" && item?.message !== "") {
                  return (
                    <div
                      className="card p-3"
                      style={{ width: "32%" }}
                      key={key}
                    >
                      <img
                        src={item?.picture ? item?.picture : no_image}
                        alt="post-image"
                        style={{ width: "100%", height: "10rem" }}
                      />
                      <p
                        className="my-2"
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          width: "100%",
                          color: "gray",
                        }}
                      >
                        {/* {caption} */}
                        {item?.link}
                      </p>
                      <p>{item?.message}</p>
                    </div>
                  );
                  // }
                })}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default PeOneCardBulk;
