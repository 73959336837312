import React, { useEffect, useState } from "react";
import { sendGet } from "../../../axios/axiosClient";
import { Avatar, Input, Loader } from "rsuite";
import moment from "moment";
import like from "../../../assets/images/notify/like.svg";
import haha from "../../../assets/images/notify/haha.svg";
import love from "../../../assets/images/notify/love.svg";
import { AiOutlineLike } from "react-icons/ai";
import { BiCommentDetail } from "react-icons/bi";
import { PiShareFat } from "react-icons/pi";
import Form from "react-bootstrap/Form";
const Notify = () => {
  const [listNotify, setListNotify] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const getListNotify = async () => {
    try {
      const res = await sendGet(`/users/notify`);
      if (res.status === 200) {
        console.log(res);
        setListNotify(res.data?.data?.data);
        setTotalItems(res.data?.data?.total);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoadingData(false);
  };

  useEffect(() => {
    getListNotify();
  }, []);

  return (
    <div>
      <h4 className="fw-bold text-center">Thông báo hệ thống</h4>
      {isLoadingData ? (
        <div className="text-center mt-5">
          <Loader />
        </div>
      ) : (
        <>
          {listNotify?.map((item) => (
            <div className="mt-3 card p-2">
              <div className="d-flex justify-content-between px-4">
                <div className="d-flex align-items-center gap-3">
                  <Avatar
                    src="https://avatars.githubusercontent.com/u/8225666"
                    alt="@SevenOutman"
                    circle
                  />
                  <div>
                    <span className="text-primary fw-bold">Quản trị viên</span>
                    <br />
                    <span>{moment(item?.created_at).format("LLL")}</span>
                  </div>
                </div>
                <div></div>
              </div>
              <div className="mt-5 px-4">
                {/* <div dangerouslySetInnerHTML={sanitizeHTML(item?.content)} /> */}
                <div dangerouslySetInnerHTML={{ __html: item?.content }} />
                {/* {item?.content} */}
                <div className="mt-5 text-center">
                  {JSON?.parse(item?.image)?.map((item, index) => (
                    <img
                      src={item}
                      alt=""
                      className="mb-2"
                      style={{ maxHeight: "15rem" }}
                    />
                  ))}
                </div>
              </div>
              <hr className="my-2" />
              <div className="d-flex gap-1 align-items-center">
                <img
                  src={like}
                  alt=""
                  style={{ height: "20px", width: "20px" }}
                />
                <img
                  src={haha}
                  alt=""
                  style={{ height: "20px", width: "20px" }}
                />
                <img
                  src={love}
                  alt=""
                  style={{ height: "20px", width: "20px" }}
                />
                {Math.floor(Math.random() * (100000 - 10000 + 1)) + 10000}
              </div>
              <hr className="my-2" />
              <div className="d-flex justify-content-around">
                <span className="d-flex justify-aligns-center gap-2">
                  <AiOutlineLike className="fs-5" />
                  <span>Thích</span>
                </span>
                <span className="d-flex justify-aligns-center gap-2">
                  <BiCommentDetail className="fs-5" />
                  <span>Bình luận</span>
                </span>
                <span className="d-flex justify-aligns-center gap-2">
                  <PiShareFat className="fs-5" />
                  <span>Chia sẻ</span>
                </span>
              </div>
              <hr className="my-2" />
              <div className="d-flex align-items-center gap-3">
                <Avatar
                  src="https://avatars.githubusercontent.com/u/12592949"
                  alt="@SevenOutman"
                  circle
                />
                {/* <Form.Control type="text" style={{ borderRadius: "2rem" }} /> */}
                <Input
                  style={{ borderRadius: "2rem" }}
                  placeholder="Viết bình luận..."
                />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Notify;
