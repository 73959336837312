import React, { useEffect, useState } from "react";
import { Dropdown, Loader } from "rsuite";
import "./selectedPage.css";
import avatar_img from "../../assets/images/645fbc5da4a21.png";
import Select from "react-select";
const SelectedPageNotFromAd = ({
  listPages,
  selectedPage,
  setSelectedPage,
  err,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});

  useEffect(() => {
    let arr = [];
    if (listPages.length > 0) {
      arr = listPages?.map((item) => ({
        label: (
          <div className="d-flex gap-3 align-items-center">
            <img
              src={item?.url}
              alt="page-avatar"
              className="select-item-image"
            />
            <div>
              <p className="fw-bold group_membernumber m-0">{item?.name}</p>
              <p className="group_membernumber m-0">{item?.id}</p>
            </div>
          </div>
        ),
        value: item?.name,
      }));
    }else{
      arr = [{
        label: (
          <div className="d-flex gap-3 align-items-center">
            <Loader />
          </div>
        ),
        value: '',
      }];
    }
    setOptions(arr);
    setSelectedValue(arr[0]);
  }, [listPages]);
  // useEffect(() => {

  // }, [listPages, selectedPage]);
  return (
    <>
      <Select
        onChange={(e) => {
          setSelectedPage(listPages.find((item) => item.name === e.value).id);
          setSelectedValue(e);
        }}
        value={selectedValue}
        options={options}
      />
      {/*  <Dropdown
      style={{ width: "100%" }}
      className="pageDropdown"
      title={
        err ? (
          "GET LIST PAGE FAIL"
        ) : (
          <>
            {selectedPage !== undefined ? (
              <>
                {selectedPage === "" && listPages?.length === 0 ? (
                  <div className="d-flex align-items-center">no data</div>
                ) : (
                  <div className="d-flex align-items-center gap-3">
                    <img
                      src={
                        listPages?.find((item) => item?.id === selectedPage)
                          ?.url
                      }
                      alt="avatar_image"
                      className="toolHeader__avatar"
                    />
                    <div>
                      <p className="fw-bold group_membernumber m-0 text-start">
                        {
                          listPages?.find((item) => item?.id === selectedPage)
                            ?.name
                        }
                      </p>
                      <p className="group_membernumber m-0 text-start">
                        {selectedPage}
                      </p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <Loader />
            )}
          </>
        )
      }
    >
      {listPages?.map((item, key) => (
        <Dropdown.Item
          eventKey={key}
          key={key}
          onClick={() => setSelectedPage(item?.id)}
        >
          <div className="d-flex align-items-center gap-3">
            <img
              src={item?.url ? item?.url : avatar_img}
              alt="avatar_image"
              className="toolHeader__avatar"
            />
            <div>
              <p className="fw-bold group_membernumber m-0 text-start">
                {item?.name}
              </p>
              <p className="group_membernumber m-0 text-start">{item?.id}</p>
            </div>
          </div>
        </Dropdown.Item>
      ))}
    </Dropdown> */}
    </>
  );
};

export default SelectedPageNotFromAd;
