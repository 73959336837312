import React from "react";
import "./extractUidFromPost.css";
import ExtractUIDFromGroup from "../extract-uid-from-group/ExtractUIDFromGroup";
const ExtractUidFromPost = () => {
  return (
    <div>
      <ExtractUIDFromGroup />
    </div>
  );
};

export default ExtractUidFromPost;
