import React, { createContext, useEffect, useState } from "react";
import { toolCategories } from "../commons/data/toolCategory";
export const SidebarContextProvider = createContext({});

export const SidebarContext = ({ children }) => {
  const [have2ndSidebar, setHave2ndSidebar] = useState(false);
  const [haveSidebar, setHaveSidebar] = useState(false);
  const [haveFooter, setHaveFooter] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedTool, setSelectedTool] = useState("");

  return (
    <SidebarContextProvider.Provider
      value={{
        have2ndSidebar,
        setHave2ndSidebar,
        selectedCategory,
        setSelectedCategory,
        haveSidebar,
        setHaveSidebar,
        haveFooter,
        setHaveFooter,
        selectedTool,
        setSelectedTool,
      }}
    >
      {children}
    </SidebarContextProvider.Provider>
  );
};

// export default SidebarContext;
