import React, { useEffect, useState } from "react";
import { sendGet, sendPost, sendPut } from "../../../axios/axiosClient";
import { Table } from "react-bootstrap";
import Cookies from "js-cookie";
import {
  Button,
  Form,
  Input,
  InputPicker,
  Message,
  Modal,
  Pagination,
  useToaster,
} from "rsuite";
import "./adminuser.css";
import { Form as SubForm } from "react-bootstrap";
import { getUsers } from "../../../axios/adminUserApi";
import { setOrder } from "../../../axios/orderApi";
import moment from "moment/moment";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

const AdminUsers = () => {
  const [listExtenions, setlistExtenions] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [status, setStatus] = useState(2);
  const [packageId, setPackageId] = useState(1);
  const [typeFilter, setTypeFilter] = useState(0);
  const [openCreate, setOpenCreate] = React.useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [selectedValue, setSelectedValue] = useState();
  const [formValue, setFormValue] = useState();
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [listPackage, setListPackage] = useState([]);

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const getListExtensions = async (page_size, page) => {
    const input = {
      ...(formValue?.name &&
        formValue?.name?.length !== 0 && { search: formValue?.name }),
      ...(status && (status == 1 || status == 0) && { status: status }),
      type: typeFilter,
      // status: 1,
    };
    if (typeFilter === 0 || typeFilter === null) {
      delete input.type;
    }
    try {
      const res = await getUsers(input, page, page_size);
      // if (res.status === 200) {
      setlistExtenions(res.data?.data?.data);
      setTotalItems(res?.data?.data?.total);
      // }
    } catch (err) {
      setTextMessage(err?.response?.data?.error_msg);
    }
  };

  const handleUpdate = async () => {
    try {
      const input = {
        package_id: selectedValue?.package_id,
        user_id: selectedValue?.id,
      };
      const res = await setOrder(input);
      if (res.status === 200) {
        getListExtensions(limit, activePage);
        setTextMessage("Cập nhật thành công.");
        setOpen(false);
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const handleCreate = async () => {
    try {
      const res = await sendPost(`/admins/scripts`, formValue);
      if (res.status === 200) {
        console.log(res.data);
        setTextMessage("Add new script !!!");
        getListExtensions(limit, activePage);
        handleCloseCreate();
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
      //   console.log(error);
    }
  };

  const handleChangeLimit = (e) => {
    setLimit(e);
    setActivePage(1);
  };

  useEffect(() => {
    getListExtensions(limit, activePage);
  }, [limit, activePage, typeFilter]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getListExtensions(limit, activePage);
    }, 500);
    return () => clearTimeout(timer);
  }, [formValue?.name, status]);

  const getPackage = async () => {
    try {
      const res = await sendGet(`/admins/packages?page_size=999`);
      if (res.status === 200) {
        setListPackage(res.data?.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPackage();
  }, []);

  const handleType = (type) => {
    switch (type) {
      case 1:
        return "ngày";
      case 2:
        return "tháng";
      case 3:
        return "năm";

      default:
        break;
    }
  };

  return (
    <div className="admin__tool__container">
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{`UPDATE`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={selectedValue} onChange={setSelectedValue} fluid>
            <Form.Group controlId="package_id">
              <Form.ControlLabel>Package:</Form.ControlLabel>
              <Form.Control
                name="package_id"
                accepter={InputPicker}
                style={{ width: "100%" }}
                data={listPackage
                  .filter((x) => x?.source === selectedValue?.type)
                  ?.map((item) => ({
                    label: `${item?.limit} ${handleType(item?.type)} - ${
                      item?.name
                    }`,
                    value: item?.id,
                  }))}
              />
            </Form.Group>
            <Form.Group controlId="script">
              <Form.ControlLabel>Description:</Form.ControlLabel>
              <Form.Control rows={5} name="script" accepter={Textarea} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-end align-items-center mb-2">
        <InputPicker
          placeholder="Filter type"
          className="me-2"
          style={{
            width: 200,
          }}
          onChange={setTypeFilter}
          data={[
            {
              label: "Web",
              value: 1,
            },
            { label: "Extension", value: 2 },
          ]}
        />
        <Form formValue={formValue} onChange={setFormValue}>
          <Form.Group controlId="name">
            <Form.Control
              name="name"
              style={{ background: "#fff" }}
              placeholder="Tìm kiếm ..."
            />
          </Form.Group>
        </Form>
        <SubForm.Select
          style={{ background: "#fff", maxWidth: "100px", marginLeft: "10px" }}
          aria-label="Default select example"
          onChange={(e) => setStatus(e.target.value)}
        >
          <option>Status</option>
          <option value={1}>Online</option>
          <option value={0}>Offline</option>
        </SubForm.Select>
      </div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>User</th>
            <th>Email</th>
            <th>Gói</th>
            <th>Ngày hết hạn</th>
            <th>Type</th>
            <th>Trạng thái</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {listExtenions?.map((item, index) => (
            <tr key={item?.id}>
              <td>{index + 1}</td>
              <td>
                <img
                  src={item?.avartar}
                  width={30}
                  height={30}
                  style={{ borderRadius: "50%" }}
                  alt=""
                />
                <span className="ms-2">{item?.name}</span>
              </td>
              <td>{item?.email}</td>
              <td>{item?.name_package}</td>
              <td>{item?.expired_date}</td>
              <td>{item?.type == 1 ? "Web" : "Extension"}</td>
              <td>{item?.status == 1 ? "Online" : "Offline"}</td>
              {/* <td>{item?.platform}</td> */}
              {/* <td style={{ maxWidth: "20rem" }}>{item?.script}</td> */}
              <th className="d-flex gap-2">
                <Button
                  appearance="primary"
                  onClick={() => {
                    setSelectedValue(item);
                    // console.log(item);
                    handleOpen();
                  }}
                >
                  Update Package
                </Button>
                {/* <Button appearance="primary" style={{ background: "red" }}>
                  Delete
                </Button> */}
              </th>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="_pagination">
        {totalItems > 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"xs"}
            prev
            next
            // first
            // last
            ellipsis
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={5}
            activePage={activePage}
            onChangePage={setActivePage}
            onChangeLimit={handleChangeLimit}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default AdminUsers;
