import React, { useContext, useEffect, useRef, useState } from "react";
import "./autoJoinFbGroup.css";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Form,
  IconButton,
  Input,
  InputGroup,
  Loader,
  Message,
  Pagination,
  Uploader,
  useToaster,
} from "rsuite";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { AiOutlineClose, AiOutlineLink } from "react-icons/ai";
import { PiChatsBold } from "react-icons/pi";
import { BiImage } from "react-icons/bi";
import { BsCameraVideo } from "react-icons/bs";
import avatar_image from "../../../../assets/images/645fbc5da4a21.png";
import { Table } from "react-bootstrap";
import Cookies from "js-cookie";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import { socket } from "../../../../context/socket";
import { sendPost } from "../../../../axios/axiosClient";
import LinearProgress from "@mui/material/LinearProgress";
import { listIcon } from "../../../../commons/data/icon";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
import { DataContext } from "../../../../context/DataContext";
import { useLocation } from "react-router-dom";
import axios from "axios";

let totalPosts = 0;
const AutoPostFbGroup = () => {
  // const { listGroupsData, setListGroupsData } = useContext(DataContext);
  const [toolType, setToolType] = useState("post");
  const inputVideo = useRef(null);
  const inputImage = useRef(null);
  const [options, setOptions] = useState(2);
  const [images, setImages] = useState([]);
  const [videoUpload, setVideoUpload] = useState();
  const { selectedExtension } = useContext(ExtensionContext);
  const [listGroup, setListGroup] = useState([]);
  const [listGroups, setListGroups] = useState([]);
  const [shareLink, setShareLink] = useState();
  const [content, setContent] = useState([""]);
  const [delay, setDelay] = useState(30);
  const [limit, setLimit] = useState(10);
  const [limitComment, setLimitComment] = useState(5);
  const [activePage, setActivePage] = useState(1);
  const [checkGroups, setCheckGroups] = useState([]);
  const [listGroupPosts, setListGroupPosts] = useState([]);
  // const [listPosts, setListPosts] = useState();
  const [postsData, setPostsData] = useState();
  const [doneAll, setDoneAll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filterGroup, setFilterGroup] = useState([]);
  const [isPosting, setIsPosting] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [postId, setPostId] = useState("");
  const [postIds, setPostIds] = useState([]);
  const [randomMenu, setRandomMenu] = useState([]);
  const [intervalId, setIntervalId] = useState();
  const location = useLocation();
  // const [commentLength, setCommentLength] = useState(listGroupPosts?.length);

  useEffect(() => {
    const groupData = listGroups?.filter((item) =>
      item?.name.toLowerCase().includes(search.toLowerCase())
    );
    setFilterGroup([
      ...new Map(groupData.map((item) => [item["id"], item])).values(),
    ]);
  }, [listGroups, search]);

  useEffect(() => {
    setActivePage(1);
  }, [limit]);

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  useEffect(() => {
    if (window.location.pathname === "/auto-comment-fb-group") {
      setToolType("comment");
      // setOptions(2);
    } else {
      setToolType("post");
    }
  });

  useEffect(() => {
    if (postIds?.length < listGroupPosts?.length) {
      setIsComment(true);
    } else {
      setIsComment(false);
    }
  }, [postIds, postId]);

  const handleStop = async () => {
    const input = {
      key_script: "force_stop",
      extension_key: selectedExtension?.key,
      key_tool: "force_stop",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
      }
    } catch (error) {}
  };

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      console.log(message);
      switch (message?.params?.key_script) {
        case "get_list_group":
          setListGroup(message?.data);
          setIsLoading(false);
          break;
        case "get_post_from_group":
          setPostsData(message);
          break;
        case "comment_post_to_post_of_group":
          if (message?.data?.status === "done") {
            setTextMessage("Comment thành công!");
            setPostIds((old) => [
              ...old,
              {
                status: true,
                link: `https://www.facebook.com/${message?.data?.comment_id}`,
              },
            ]);
          } else {
            setTextMessage("Comment thất bại.");
            setPostIds((old) => [
              ...old,
              {
                status: false,
                link: `https://www.facebook.com/${message?.params?.post_id}`,
              },
            ]);
          }
          if (totalPosts <= 1) {
            setIsComment(false);
          }
          --totalPosts;
          break;
        case "post_to_group":
          if (message?.data?.status === "done") {
            setTextMessage("Đăng bài thành công!");
            setPostIds((old) => [
              ...old,
              {
                status: true,
                link: `https://www.facebook.com/${message?.data?.post_id}`,
              },
            ]);
          } else {
            setTextMessage("Đăng bài thất bại.");
            setPostIds((old) => [
              ...old,
              {
                status: false,
                link: `https://www.facebook.com/${message?.params?.groupId}`,
              },
            ]);
          }
          if (totalPosts <= 1) {
            setIsPosting(false);
          }
          --totalPosts;
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    if (listGroup?.length) {
      setListGroups((old) => [...old, ...listGroup]);
    }
  }, [listGroup]);

  useEffect(() => {
    if (postsData) {
      // set list group post will comment if doesn't match list group done all id below => set post id for comment later
      if (!doneAll?.includes(postsData?.params?.groupId)) {
        // save list data with limited comment
        setListGroupPosts((oldPost) => [
          ...oldPost,
          ...postsData?.data?.slice(0, limitComment),
        ]);
      }
      // if list post return done all true + data > limit post comment in 1 group =>  save group id de check lan sau
      if (
        postsData?.done_all === true ||
        postsData?.data?.length > limitComment
      ) {
        setDoneAll((pre) => [...pre, postsData?.params?.groupId]);
      }
    }
  }, [postsData]);

  useEffect(() => {
    const uniqueDoneAll = [...new Set(doneAll)];
    // check get all item from
    if (
      checkGroups.length === uniqueDoneAll.length &&
      listGroupPosts?.length > 0
    ) {
      // console.log(listGroupPosts);
      totalPosts = listGroupPosts?.length;
      let b = 1;
      commentToGroups(listGroupPosts[0]);
      if (listGroupPosts?.length > 1) {
        const id = setInterval(() => {
          if (b < listGroupPosts?.length) {
            // console.log("check comment", listGroupPosts[b]?.story_id);
            commentToGroups(listGroupPosts[b]);
            ++b;
          } else {
            clearInterval(id);
          }
        }, delay * 1000);
        setIntervalId(id);
      }
    }
  }, [listGroupPosts]);

  const getListGroups = async () => {
    setListGroups([]);
    setIsLoading(true);
    const input = {
      key_script: "get_list_group",
      extension_key: selectedExtension?.key,
      key_tool: "auto_post_to_facebook_groups",
      params: {
        key_socket: Cookies.get("keySocket"),
        uid: selectedExtension?.uid,
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
  };

  const getPostFromGroup = async () => {
    if (!checkGroups?.length) {
      setTextMessage("Bạn cần chọn 1 nhóm.");
      return;
    }
    setIsComment(true);
    let b = 0;
    getPost(checkGroups[b]);
    ++b;
    if (checkGroups?.length > 1) {
      const id = setInterval(() => {
        if (b < checkGroups?.length) {
          getPost(checkGroups[b]);
          ++b;
        } else {
          clearInterval(id);
        }
      }, 10 * 1000);
      setIntervalId(id);
    }
  };

  const getPost = async (id) => {
    const input = {
      key_script: "get_post_from_group",
      extension_key: selectedExtension?.key,
      key_tool: "auto_comment_facebook_groups",
      params: {
        key_socket: Cookies.get("keySocket"),
        // groupId: "410179212361043",
        groupId: id,
        post_number: Number(limitComment),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      setIsComment(false);
      console.log(error);
      setTextMessage("Có lỗi xảy ra khi lấy bài viết trong nhóm");
    }
  };

  const handleUploadImage = async (image) => {
    const formData = new FormData();
    if (Object.values(image).some((item) => item.type.startsWith("video/"))) {
      setTextMessage("Hiện tại chưa hỗ trợ đăng video.");
      return;
    }
    Array.from({ length: image.length }, (item, idx) => {
      formData.append(`files[${idx}]`, image[idx]);
    });
    try {
      const res = await sendPost(`/users/scripts/save-file`, formData);
      if (res.status === 200) {
        // console.log(res?.data[0]);
        if (toolType === "comment") {
          setImages([res?.data[0]]);
        } else {
          setImages((oldImg) => [...oldImg, ...res?.data]);
        }
      }
    } catch (error) {
      setTextMessage("Có lỗi xảy ra khi tải file lên.");
      console.log(error);
    }
  };

  const handleUploadVideo = async (video) => {
    const formData = new FormData();
    formData.append("files[0]", video);
    try {
      const res = await sendPost(`/users/scripts/save-file`, formData);
      if (res.status === 200) {
        // console.log(res?.data[0]);\
        setVideoUpload(res?.data[0]);
      }
    } catch (error) {
      // setTextMessage("video error");
      console.log(error);
    }
  };

  const commentToGroups = async (post) => {
    let finalTitle = content[Math.floor(Math.random() * content.length)] + " ";
    if (randomMenu?.includes("text")) {
      let stringLength = 7;
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
      for (let i = 0; i < stringLength; i++) {
        finalTitle += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
    }
    if (randomMenu?.includes("icon")) {
      finalTitle += " " + listIcon[Math.floor(Math.random() * listIcon.length)];
    }
    if (randomMenu?.includes("time")) {
      const today = new Date();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      finalTitle += " " + time;
    }
    const input = {
      key_script: "comment_post_to_post_of_group",
      extension_key: selectedExtension?.key,
      key_tool: "auto_comment_facebook_groups",
      params: {
        key_socket: Cookies.get("keySocket"),
        post_id: post?.story_id,
        content_comment: finalTitle?.trim(),
        urlImage: images[0] ? images[0] : "",
        ranges: [],
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
      setTextMessage("Có lỗi xảy ra khi bình luận bài viết.");
      --totalPosts;
    }
  };

  const handleContentIsEmpty = () => {
    content?.map((item) => {
      if (item?.trim() !== "") {
        return false;
      }
    });
    return true;
  };

  const handlePostToGroup = async () => {
    if (!checkGroups?.length) {
      setTextMessage("Bạn cần chọn 1 nhóm.");
      return;
    }
    // console.log(handleContentIsEmpty(), "is empty");
    if (randomMenu?.length === 0 && handleContentIsEmpty()) {
      if (images?.length !== 0 || videoUpload !== undefined) {
      } else {
        setTextMessage("Nội dung không thể bỏ trống.");
        return;
      }
    }
    let b = 0;
    totalPosts = checkGroups?.length;
    postToGroup(checkGroups[0]);
    ++b;
    if (checkGroups?.length > 1) {
      const id = setInterval(() => {
        if (b < checkGroups?.length) {
          postToGroup(checkGroups[b]);
          ++b;
        } else {
          clearInterval(id);
        }
      }, delay * 1000);
      setIntervalId(id);
    }
  };

  const postToGroup = async (groupId) => {
    // let media = "";
    // if (options === 2) {
    // } else if (options === 3) {
    //   media = [videoUpload];
    // }
    // let media = images;

    let finalTitle = content[Math.floor(Math.random() * content.length)] + " ";
    if (randomMenu?.includes("text")) {
      let stringLength = 7;
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
      for (let i = 0; i < stringLength; i++) {
        finalTitle += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
    }
    if (randomMenu?.includes("icon")) {
      finalTitle += listIcon[Math.floor(Math.random() * listIcon.length)];
    }
    if (randomMenu?.includes("time")) {
      const today = new Date();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      finalTitle += time;
    }
    const input = {
      key_script: "post_to_group",
      extension_key: selectedExtension?.key,
      key_tool: "auto_post_to_facebook_groups",
      params: {
        key_socket: Cookies.get("keySocket"),
        groupId: groupId,
        urlImage: images?.length === 0 ? [] : images,
        content: finalTitle,
        ranges: [],
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setIsPosting(true);
      }
    } catch (error) {
      console.log(error);
      --totalPosts;
    }
    // });
  };

  const handleStopDelay = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    setIsComment(false);
    setIsPosting(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedExtension?.id !== Number(localStorage?.getItem("currEx"))) {
      getListGroups();
      return;
    }
    if (selectedExtension?.key) {
      getListGroups();
    }
  }, [selectedExtension]);

  const handleCheckURL = async (url) => {
    try {
      axios
        .post("https://uid.bufflike.net/fbid", {
          link: url,
        })
        .then(function (response) {
          if (response.data.status) {
            window.open(url, "_blank", "noopener,noreferrer");
          } else {
            setTextMessage("Bài viết bị xoá bởi admin hoặc bị lỗi");

            // setTextMessage(response?.data?.message);
          }
        })
        .catch(function (error) {
          setTextMessage("Bài viết bị xoá bởi admin hoặc bị lỗi");
        });
    } catch (error) {}
  };

  return (
    <div>
      <div className="d-flex">
        <div className="col-7 pe-3">
          <div className="card p-3 ">
            <div className="mb-3">
              <ToolHeading toolType={window.location.pathname} />
            </div>
            <div>
              <div className="d-flex justify-content-between group_membernumber ">
                <p className="mb-1">Nội dung:</p>
                <CheckboxGroup
                  name="checkboxList"
                  value={randomMenu}
                  onChange={setRandomMenu}
                  inline
                >
                  <Checkbox value="text">Nội dung ngẫu nhiên</Checkbox>
                  <Checkbox value="icon">Biểu tượng ngẫu nhiên</Checkbox>
                  <Checkbox value="time">Thời gian</Checkbox>
                </CheckboxGroup>
              </div>
              {content?.map((item, idx) => (
                <div className="d-flex align-items-center gap-2 mt-2">
                  <Input
                    key={idx}
                    as="textarea"
                    rows={3}
                    placeholder="Nội dung..."
                    onChange={(e) => {
                      setContent(
                        content.map((cnt, i) => {
                          if (i === idx) {
                            return e;
                          } else {
                            return cnt;
                          }
                        })
                      );
                    }}
                    value={item}
                  />
                  <div>
                    <Button
                      appearance="primary"
                      style={{ padding: ".3rem .7rem", backgroundColor: "red" }}
                      onClick={() =>
                        setContent((old) => old.filter((o, i) => i !== idx))
                      }
                    >
                      x
                    </Button>
                  </div>
                </div>
              ))}

              {/* <Input
                as="textarea"
                rows={5}
                placeholder="Nội dung..."
                onChange={setContent}
                value={content}
              /> */}
              <div className="d-flex justify-content-center mt-2">
                <Button
                  appearance="primary"
                  style={{ padding: ".78rem 1rem", borderRadius: "50%" }}
                  className="fs-3"
                  onClick={() => setContent((old) => [...old, ""])}
                >
                  +
                </Button>
              </div>
            </div>

            <div className="my-3">
              {(() => {
                switch (options) {
                  case 1:
                    return (
                      <InputGroup>
                        <InputGroup.Addon style={{ background: "white" }}>
                          <AiOutlineLink />
                        </InputGroup.Addon>
                        <Input
                          placeholder="Đường dẫn chia sẻ"
                          onChange={setShareLink}
                          value={shareLink}
                        />
                        <InputGroup.Button onClick={() => setShareLink("")}>
                          <AiOutlineClose />
                        </InputGroup.Button>
                      </InputGroup>
                    );
                  case 2:
                    return (
                      <div>
                        <input
                          style={{ display: "none" }}
                          ref={inputImage}
                          type="file"
                          multiple={toolType === "post" ? true : false}
                          onChange={(e) => {
                            if (e.target.files[0]) {
                              handleUploadImage(e.target.files);
                            }
                          }}
                        />
                        <div className="d-flex justify-content-between align-items-center">
                          <Button
                            onClick={() => inputImage.current.click()}
                            appearance="primary"
                            style={{ backgroundColor: "#f2ae14" }}
                          >
                            Tải ảnh lên
                          </Button>
                        </div>
                        <div
                          className={`d-flex ${
                            toolType === "comment"
                              ? "justify-content-center"
                              : ""
                          } gap-1 my-2`}
                          style={{
                            flexWrap: "wrap",
                            position: "relative",
                          }}
                        >
                          {images?.map((i, k) => (
                            <div
                              key={k}
                              className={`text-center`}
                              style={{
                                width: "32%",
                                height: "200px",
                                overflow: "hidden",
                                position: "relative",
                              }}
                            >
                              <img
                                src={i}
                                alt=""
                                style={{
                                  margin: "0 auto",
                                  objectFit: "cover",
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                              <IconButton
                                icon={<AiOutlineClose />}
                                circle
                                className="closeButton"
                                onClick={() => {
                                  setImages((oldValues) => {
                                    return oldValues.filter((_, i) => i !== k);
                                  });
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  case 3:
                    return (
                      <>
                        <div>
                          <input
                            style={{ display: "none" }}
                            ref={inputVideo}
                            type="file"
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                handleUploadVideo(e.target.files[0]);
                              }
                            }}
                          />
                          <Button
                            appearance="primary"
                            onClick={() => inputVideo.current.click()}
                          >
                            Tải lên video
                          </Button>
                          <div
                            style={{
                              position: "relative",
                              width: "66%",
                              height: `${videoUpload ? "200px" : ""}`,
                            }}
                          >
                            {videoUpload !== undefined ? (
                              <>
                                <IconButton
                                  icon={<AiOutlineClose />}
                                  circle
                                  className="closeButton"
                                  style={{ zIndex: "99" }}
                                  onClick={() => {
                                    setVideoUpload([]);
                                  }}
                                />
                                <video
                                  src={videoUpload}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "200px",
                                    position: "absolute",
                                    right: "0",
                                  }}
                                />
                                {/* have video */}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  default:
                    break;
                }
              })()}
            </div>
            <div className="d-flex flex-wrap mb-3">
              <div className="col px-1">
                <p className="group_membernumber mb-1">Delay (nên để 30):</p>
                <Input
                  type="number"
                  value={delay}
                  onChange={setDelay}
                  min={1}
                />
              </div>
              {toolType === "comment" ? (
                <div className="col px-1">
                  <p className="group_membernumber mb-1">
                    Số comment tối đa trong mỗi group:
                  </p>
                  <Input
                    type="number"
                    value={limitComment}
                    onChange={setLimitComment}
                    min={1}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            {isComment || isPosting ? (
              <Button
                // disabled={}
                className="fw-bold fs-5"
                appearance="primary"
                // color="orange"
                style={{ backgroundColor: "red" }}
                onClick={handleStopDelay}
              >
                Dừng lại
              </Button>
            ) : (
              <Button
                // disabled={}
                className="fw-bold fs-5"
                appearance="primary"
                color="orange"
                onClick={() => {
                  switch (toolType) {
                    case "post":
                      if (!isPosting) {
                        // postToGroup();
                        handlePostToGroup();
                      }
                      break;
                    case "comment":
                      if (!isComment) {
                        getPostFromGroup();
                      }
                      break;
                    default:
                      break;
                  }
                }}
              >
                {toolType === "comment" ? <>Bình luận</> : <>Đăng bài</>}
              </Button>
            )}

            {isComment ? (
              <LinearProgress
                style={{
                  width: "100%",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />
            ) : (
              <></>
            )}
            {isPosting ? (
              <LinearProgress
                style={{
                  width: "100%",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />
            ) : (
              <></>
            )}
          </div>
          {postIds?.length > 0 ? (
            <div className="card mt-3 p-2">
              <h5 className="fw-bold text-success text-center">Kết quả:</h5>
              {/* {postIds?.map((item, key) => (
                <a
                  key={key}
                  target="_blank"
                  className="blackLink group_membernumber"
                  href={`https://www.facebook.com/${item}`}
                >{`https://www.facebook.com/${item}`}</a>
              ))} */}
              <Table bordered style={{ background: "white" }}>
                <thead>
                  <tr>
                    <th>Trạng thái</th>
                    <th>Đường dẫn</th>
                  </tr>
                </thead>
                <tbody>
                  {postIds?.map((item, idx) => {
                    if (item.status) {
                      return (
                        <tr key={idx}>
                          <td>
                            <span
                              style={{ cursor: "pointer" }}
                              className="group_membernumber blackLink"
                              onClick={() => handleCheckURL(item?.link)}
                            >
                              {item?.link}
                            </span>
                            {/* <a
                              href={item?.link}
                              target="_blank"
                              className="group_membernumber blackLink"
                            >
                              {item?.link}
                            </a> */}
                          </td>
                          <td className="text-success fw-bold">Thành công</td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={idx}>
                          <td>
                            <a
                              href={item?.link}
                              target="_blank"
                              className="group_membernumber blackLink"
                            >
                              {item?.link}
                            </a>
                          </td>
                          <td className="text-danger fw-bold">Thất bại</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="col-5">
          <div className="card p-3">
            <div
              className="d-flex gap-3 justify-content-between pb-2"
              style={{ borderBottom: "2px solid black" }}
            >
              <div>
                <Checkbox
                  disabled={isComment}
                  onChange={(e, check) => {
                    if (check) {
                      setCheckGroups(filterGroup?.map((item) => item?.id));
                    } else {
                      setCheckGroups([]);
                    }
                  }}
                />
                <span className="ms-3">Chọn tất cả</span>
              </div>
              <div className="">
                <Input
                  placeholder="Tìm kiếm group"
                  onChange={setSearch}
                  value={search}
                ></Input>
              </div>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center mt-5">
                <Loader />
              </div>
            ) : (
              <div className="mt-2">
                <Table responsive borderless>
                  <thead className="text-center">
                    <tr>
                      <th>Chọn</th>
                      <th>Nhóm</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterGroup
                      ?.map((item, key) => (
                        <tr key={key}>
                          <td className="text-center">
                            <Checkbox
                              disabled={isComment}
                              value={item?.id}
                              checked={checkGroups?.includes(item?.id)}
                              onChange={(e, check) => {
                                if (check) {
                                  setCheckGroups((current) => [...current, e]);
                                } else {
                                  setCheckGroups((prevState) =>
                                    prevState.filter(
                                      (prevItem) => prevItem !== e
                                    )
                                  );
                                }
                              }}
                            />
                          </td>
                          <td>
                            <div className="d-flex gap-3 align-items-center">
                              <div>
                                <img
                                  src={item?.image}
                                  alt=""
                                  className="toolHeader__avatar"
                                />
                              </div>
                              <div>
                                <p className="m-0 fw-bold">
                                  <a
                                    href={item?.url}
                                    target="_blank"
                                    className="text-dark"
                                    style={{ textDecoration: "none" }}
                                  >
                                    {item?.name}
                                  </a>
                                </p>
                                <p className="m-0 group_membernumber">
                                  {item?.id}
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                      ?.splice((activePage - 1) * limit, limit)}
                  </tbody>
                </Table>
                <div
                // className="_pagination"
                >
                  {listGroups?.length > 0 ? (
                    <Pagination
                      layout={["limit", "-", "pager"]}
                      size={"xs"}
                      prev
                      next
                      // first
                      // last
                      ellipsis
                      boundaryLinks
                      total={filterGroup?.length}
                      limit={limit}
                      // limitOptions={[1, 2, 3]}
                      limitOptions={[10, 30, 50, 100]}
                      maxButtons={5}
                      activePage={activePage}
                      onChangePage={setActivePage}
                      onChangeLimit={setLimit}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoPostFbGroup;
