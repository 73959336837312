import React, { useEffect, useState } from "react";
import { sendGet, sendPost, sendPut } from "../../../axios/axiosClient";
import { Table } from "react-bootstrap";
import Cookies from "js-cookie";
import {
  Button,
  Form,
  Input,
  Message,
  Modal,
  Pagination,
  useToaster,
} from "rsuite";
import "./adminextension.css";
import { getExtension } from "../../../axios/adminextensionApi";
import { Form as SubForm } from "react-bootstrap";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

const AdminExtensions = () => {
  const [listExtenions, setlistExtenions] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [status, setStatus] = useState(2);
  const [openCreate, setOpenCreate] = React.useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [selectedValue, setSelectedValue] = useState();
  const [formValue, setFormValue] = useState();

  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);
  const getListExtensions = async (page, page_size) => {
    const input = {
      ...(formValue?.name &&
        formValue?.name?.length !== 0 && { search: formValue?.name }),
      ...(status && (status == 1 || status == 0) && { status: status }),
      // status: 1,
    };
    try {
      const res = await getExtension(input, page, page_size);
      if (res.status === 200) {
        console.log(res.data?.data?.total);
        setlistExtenions(res.data?.data?.data);
        setTotalItems(res.data?.data?.total);
      }
    } catch (err) {
      throw err;
    }
  };

  const handleChangeLimit = (e) => {
    setLimit(e);
    setActivePage(1);
  };

  // useEffect(() => {
  //   getListExtensions(activePage, limit);
  // }, [activePage, limit]);

  const handleUpdate = async () => {
    try {
      const input = {
        script: selectedValue?.script,
        key: selectedValue?.key,
        description: selectedValue?.description,
      };
      console.log(selectedValue);
      const res = await sendPut(`/admins/scripts/${selectedValue?.id}`, input);
      if (res.status === 200) {
        console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreate = async () => {
    console.log(formValue);
    try {
      const res = await sendPost(`/admins/scripts`, formValue);
      if (res.status === 200) {
        console.log(res.data);
        setTextMessage("Add new script !!!");
        getListExtensions(activePage, limit);
        handleCloseCreate();
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
      //   console.log(error);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      getListExtensions(activePage, limit);
    }, 500);
    return () => clearTimeout(timer);
  }, [formValue?.name, status, activePage, limit]);

  return (
    <div className="admin__tool__container">
      <div className="d-flex justify-content-end align-items-center mb-2">
        <Form formValue={formValue} onChange={setFormValue}>
          <Form.Group controlId="name">
            <Form.Control
              name="name"
              style={{ background: "#fff" }}
              placeholder="Tìm kiếm ..."
            />
          </Form.Group>
        </Form>
        <SubForm.Select
          style={{ background: "#fff", maxWidth: "100px", marginLeft: "10px" }}
          aria-label="Default select example"
          onChange={(e) => setStatus(e.target.value)}
        >
          <option>Status</option>
          <option value={1}>Online</option>
          <option value={0}>Offline</option>
        </SubForm.Select>
      </div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>User</th>
            <th>Key</th>
            <th>Type</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {listExtenions?.map((item, index) => (
            <tr key={item?.id}>
              <td>{index + 1}</td>
              <td>
                <img
                  src={item?.avartar}
                  width={30}
                  height={30}
                  style={{ borderRadius: "50%" }}
                  alt=""
                />
                <span className="ms-2">{item?.name}</span>
              </td>
              <td>{item?.key}</td>
              <td>{item?.type == 1 ? "Web" : "Extension"}</td>
              <td>{item?.status == 1 ? "Online" : "Offline"}</td>

              {/* <td style={{ maxWidth: "20rem" }}>{item?.script}</td> */}
              <th className="d-flex gap-2">
                <Button
                  appearance="primary"
                  onClick={() => {
                    setSelectedValue(item);
                    // console.log(item);
                    handleOpen();
                  }}
                >
                  Update
                </Button>
                <Button appearance="primary" style={{ background: "red" }}>
                  Delete
                </Button>
              </th>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* <div className="_pagination"> */}
      {totalItems > 0 ? (
        <Pagination
          layout={["limit", "-", "pager"]}
          size={"xs"}
          prev
          next
          // first
          // last
          ellipsis
          boundaryLinks
          total={totalItems}
          limit={limit}
          limitOptions={[10, 30, 50]}
          maxButtons={5}
          activePage={activePage}
          onChangePage={setActivePage}
          onChangeLimit={handleChangeLimit}
        />
      ) : (
        <></>
      )}
      {/* </div> */}
    </div>
  );
};

export default AdminExtensions;
