import React, { useEffect, useRef, useState } from "react";
import {
  sendDelete,
  sendGet,
  sendPost,
  sendPut,
} from "../../../axios/axiosClient";
import {
  Button,
  Form,
  Input,
  Message,
  Modal,
  Toggle,
  useToaster,
} from "rsuite";
import { Table } from "react-bootstrap";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} rows={8} />
));

const AdminNotify = () => {
  const [listNotify, setListNotify] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [deleteItem, setDeleteItem] = useState();
  const [formCreate, setFormCreate] = useState({
    content: "",
  });
  const [formUpdate, setFormUpdate] = useState({});
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const [image, setImage] = useState([]);
  const inputImage = useRef(null);
  const updateImage = useRef(null);

  const getListNotify = async () => {
    try {
      const res = await sendGet(`/admins/notify`);
      if (res.status === 200) {
        console.log(res);
        setListNotify(res?.data?.data?.data);
        setTotalItems(res?.data?.data?.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateNotify = async () => {
    if (formCreate?.content?.trim() === "") {
      setTextMessage("Nội dung không thể bỏ trống");
      return;
    }
    const formData = new FormData();
    image?.map((item) => {
      formData.append("image[]", item);
    });
    formData.append("content", formCreate?.content);
    formData.append("status", 1);
    try {
      const res = await sendPost(`/admins/notify`, formData);
      if (res.status === 200) {
        setTextMessage("Tạo mới notify thành công.");
        getListNotify();
        setFormCreate({
          content: "",
        });
        handleClose();
      }
    } catch (error) {
      setTextMessage("Có lỗi xảy ra.");
      console.log(error);
    }
  };

  const handleUpdate = async () => {
    // const formData = new FormData();
    // JSON.parse(formUpdate?.image)?.map((item) => {
    //   formData.append("image[]", item);
    // });
    // formData.append("content", formUpdate?.content);
    // formData.append("status", 1);
    const input = {
      status: formUpdate?.status === true || formUpdate?.status === 1 ? 1 : 0,
    };
    try {
      const res = await sendPut(`/admins/notify/${formUpdate?.id}`, input);
      if (res.status === 200) {
        setTextMessage("Cập nhật notify thành công.");
        getListNotify();
        // setImage([]);
        handleCloseUpdate();
        // console.log(res);
      }
    } catch (error) {
      setTextMessage("Có lỗi xảy ra khi cập nhật.");
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await sendDelete(`/admins/notify/${deleteItem?.id}`);
      if (res.status === 200) {
        setTextMessage("Xoá notify thành công.");
        getListNotify();
        handleCloseDelete();
      }
    } catch (error) {
      setTextMessage("Có lỗi xảy ra khi xoá.");
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(image);
  }, [image]);

  useEffect(() => {
    getListNotify();
  }, []);

  return (
    <div>
      {/* create */}
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Thêm thông báo</Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-2">
          <Form formValue={formCreate} onChange={setFormCreate} fluid>
            <Form.Group controlId="content">
              <Form.ControlLabel>
                Nội dung <Form.HelpText tooltip>Yêu cầu</Form.HelpText>
              </Form.ControlLabel>
              {/* <Form.Control name="content" accepter={Textarea} /> */}
              <ReactQuill
                value={formCreate?.content}
                onChange={(e) => {
                  setFormCreate({ ...formCreate, content: e });
                }}
              />
            </Form.Group>
            <Form.Group>
              <input
                style={{ display: "none" }}
                ref={inputImage}
                type="file"
                onChange={(e) => {
                  if (e.target.files[0]) {
                    // setImage(e.target.files[0]);
                    setImage((old) => [...old, e.target.files[0]]);
                  }
                }}
              />
              <Button
                onClick={() => inputImage.current.click()}
                appearance="primary"
                style={{ backgroundColor: "#f2ae14" }}
              >
                Tải ảnh lên
              </Button>
              <div className="d-flex flex-wrap">
                {image?.map((item, key) => (
                  <img
                    key={key}
                    src={URL.createObjectURL(item)}
                    alt=""
                    style={{
                      width: "49%",
                      objectFit: "cover",
                      height: "10rem",
                      marginLeft: `${key % 2 === 1 ? "2%" : ""}`,
                    }}
                    className="mt-2"
                  />
                ))}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCreateNotify} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Huỷ bỏ
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  */}
      <Modal open={openUpdate} onClose={handleCloseUpdate}>
        <Modal.Header>
          <Modal.Title>Cập nhật thông báo #{formUpdate?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={formUpdate} onChange={setFormUpdate} fluid>
            <Form.Group controlId="content">
              <Form.ControlLabel>
                Nội dung <Form.HelpText tooltip>Yêu cầu</Form.HelpText>
              </Form.ControlLabel>
              {/* <Form.Control name="content" accepter={Textarea} readOnly /> */}
              <ReactQuill
                value={formUpdate?.content}
                onChange={(e) => {
                  setFormUpdate({ ...formUpdate, content: e });
                }}
                // readOnly
              />
            </Form.Group>
            <Form.Group controlId="image">
              <Form.ControlLabel>Hình ảnh</Form.ControlLabel>
              <input
                style={{ display: "none" }}
                ref={updateImage}
                type="file"
                onChange={(e) => {
                  if (e.target.files[0]) {
                    // setImage(e.target.files[0]);
                    setImage((old) => [...old, e.target.files[0]]);
                  }
                }}
              />
              {/* <Button
                onClick={() => updateImage.current.click()}
                appearance="primary"
                style={{ backgroundColor: "#f2ae14" }}
              >
                Tải ảnh lên
              </Button> */}
              <div className="d-flex flex-wrap">
                {formUpdate?.image?.length > 0 ? (
                  JSON.parse(formUpdate?.image)?.map((item, key) => (
                    <img
                      key={key}
                      src={item}
                      alt=""
                      style={{
                        width: "49%",
                        objectFit: "cover",
                        height: "10rem",
                        marginLeft: `${key % 2 === 1 ? "2%" : ""}`,
                      }}
                      className="mt-2"
                    />
                  ))
                ) : (
                  <></>
                )}
              </div>
            </Form.Group>
            <Form.Group controlId="status">
              <Form.ControlLabel>Trạng thái</Form.ControlLabel>
              <Form.Control name="status" accepter={Toggle} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseUpdate} appearance="subtle">
            Huỷ bỏ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete */}
      <Modal open={openDelete} onClose={handleCloseDelete}>
        <Modal.Header>
          <Modal.Title>Xoá đường dẫn #{deleteItem?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bạn có chắc chắn muốn xoá #{deleteItem?.id}</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseDelete} appearance="subtle">
            Huỷ bỏ
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  */}
      <div className="mb-2 d-flex justify-content-between">
        <div>
          <Input placeholder="Tìm kiếm" value={search} onChange={setSearch} />
        </div>
        <div>
          <Button onClick={handleOpen}>Thêm mới</Button>
        </div>
      </div>
      {/* listNotify */}
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>#</th>
            <th style={{ width: "13rem" }}>Ngày tạo</th>
            <th style={{ width: "7rem" }}>Trạng thái</th>
            <th style={{ width: "30%" }}>Nội dung</th>
            <th>Hình ảnh</th>
            <th style={{ width: "8rem" }}>Hành động</th>
          </tr>
        </thead>
        <tbody>
          {listNotify?.map((item, key) => {
            return (
              <tr key={item?.id}>
                <td>{key + 1}</td>
                <td>{moment(item?.created_at).format("LLL")}</td>
                <td>
                  {item?.status === 1 ? (
                    <AiFillCheckCircle className="text-success" />
                  ) : (
                    <AiFillCloseCircle className="text-danger" />
                  )}
                </td>
                <td>
                  <div dangerouslySetInnerHTML={{ __html: item?.content }} />
                </td>
                <td>
                  <div className="d-flex flex-wrap gap-2">
                    {item?.image?.length > 0
                      ? JSON.parse(item?.image)?.map((item1, key) => (
                          <img
                            src={item1}
                            alt=""
                            key={key}
                            style={{ width: "5rem", height: "5rem" }}
                          />
                        ))
                      : ""}
                  </div>
                </td>
                <td>
                  <div className="d-flex gap-2 justify-content-center">
                    <Button
                      appearance="primary"
                      onClick={() => {
                        setFormUpdate({
                          image: item?.image,
                          content: item?.content,
                          status: item?.status,
                          id: item?.id,
                        });
                        handleOpenUpdate();
                      }}
                    >
                      Cập nhật
                    </Button>
                    <Button
                      appearance="primary"
                      style={{ backgroundColor: "red" }}
                      onClick={() => {
                        setDeleteItem(item);
                        handleOpenDelete();
                      }}
                    >
                      Xoá
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default AdminNotify;
