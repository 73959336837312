import React, { useRef, useState, useEffect } from "react";
import "./ref.css";
import { FaCoins } from "react-icons/fa";
import { BiLogoTelegram } from "react-icons/bi";
import { FaRocket } from "react-icons/fa";
import { Table } from "react-bootstrap";
import {
  Button,
  Modal,
  Form,
  Message,
  useToaster,
  Pagination,
  Input,
} from "rsuite";
import {
  getUserWithdraw,
  getWithdraw,
  withDraw,
} from "../../../axios/withdrawApi";
import { useCopyToClipboard } from "react-use";

const Ref = () => {
  const textRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedValue, setSelectedValue] = useState();
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("profile")) || {}
  );
  const [textMessage, setTextMessage] = useState("");
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [historyList, setHistoryList] = useState([]);
  const [state, copyToClipboard] = useCopyToClipboard();
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalItems, setTotalItems] = useState(0);

  const handleChangeLimit = (e) => {
    setLimit(e);
    setActivePage(1);
  };

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );
  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);
  const handleCopyClick = () => {
    if (textRef.current) {
      textRef.current.select();
      document.execCommand("copy");
      // Optionally, you can show a notification or feedback message here
      console.log("Copied to clipboard!");
    }
  };
  const getHistroyWithdraw = async () => {
    const input = {
      page: activePage,
      page_size: limit,
      search: search,
    };
    try {
      const res = await getUserWithdraw(input);
      if (res.status === 200) {
        setHistoryList(res.data.data.data);
        setTotalItems(res?.data?.data?.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setActivePage(1);
  }, [limit]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getHistroyWithdraw();
    }, 500);
    return () => clearTimeout(timer);
  }, [limit, search, activePage]);

  const handleWithDraw = async () => {
    const input = {
      bank: selectedValue.bank,
      bank_code: selectedValue.bank_code,
      phone: selectedValue.phone,
      coin: Number(selectedValue.coin),
      fullname: selectedValue.fullname,
    };
    try {
      const res = await withDraw(input);
      if (res.status == 200) {
        handleClose();
        setTextMessage("Thành công");
        setSelectedValue();
      }
    } catch (err) {
      setTextMessage(err?.response?.data?.error_msg);
      // throw err;
    }
  };
  const priceFormat = (price) => {
    return price?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };
  return (
    <div className="col-12 ref__container">
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{`RÚT TIỀN`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={selectedValue} onChange={setSelectedValue} fluid>
            <Form.Group controlId="fullname">
              <Form.ControlLabel>Tên tài khoản</Form.ControlLabel>
              <Form.Control name="fullname" />
            </Form.Group>
            <Form.Group controlId="bank_code">
              <Form.ControlLabel>Số tài khoản</Form.ControlLabel>
              <Form.Control name="bank_code" />
            </Form.Group>
            <Form.Group controlId="bank">
              <Form.ControlLabel>Ngân hàng</Form.ControlLabel>
              <Form.Control name="bank" />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.ControlLabel>Số điện thoại</Form.ControlLabel>
              <Form.Control name="phone" />
            </Form.Group>
            <Form.Group controlId="coin">
              <Form.ControlLabel>Số tiền</Form.ControlLabel>
              <Form.Control name="coin" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              handleWithDraw();
            }}
            appearance="primary"
          >
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="card p-3">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="ref__title">Chương trình giới thiệu</h5>
        </div>
        <p className="ref__sub__title">
          Tra cứu và tìm kiếm tất cả thông tin về chương trình giưới thiệu của
          chúng tôi, thông kê và doanh thu của bạn.
        </p>
        <div className="ref__info mt-3 flex-wrap d-flex justify-content-between">
          <div className="ref__info__item">
            <h6 className="text-danger">Hoa hồng</h6>
            <h4 className="fw-bolder">
              {/* <span className="small">$</span> */}
              <span>
                {priceFormat(userData?.coin)
                  ? priceFormat(userData?.coin)
                  : "0"}
              </span>
            </h4>
          </div>
          <div className="ref__info__item">
            <h6 className="text-danger">Giới thiệu</h6>
            <h4 className="fw-bolder">
              {/* <span className="small">$</span> */}
              <span>{userData?.num_ref ? userData?.num_ref : "0"} lượt</span>
            </h4>
          </div>
          <div className="ref__info__item">
            <h6 className="text-danger">Số dư khả dụng</h6>
            <h4 className="fw-bolder">
              <span>{userData?.coin ? userData?.coin : "0"}</span>
              <span className="small"> coin</span>
            </h4>
          </div>
          {/* <div className="ref__info__item">
            <h6 className="text-danger">Thu nhập</h6>
            <h4 className="fw-bolder">
              <span className="small">$</span>
              <span>23.980</span>
            </h4>
          </div> */}
        </div>
        <div className="ref__code mt-3 flex-wrap d-flex">
          <div className="ref__code__details">
            <h6>Mã giới thiệu</h6>
            <p>Sao chép mã giới thiệu bên dưới và gửi cho bạn bè của bạn.</p>
            <div className="ref__code__copy">
              <p>
                Được tạo 23 ngày trước bởi{" "}
                <span className="fw-bold">softuidash23</span>
              </p>
              <p className="fw-bold">(Sử dụng 1 lần)</p>
              <div className="ref__code__copy__input mb-3">
                <input
                  ref={textRef}
                  value={`${window.location.origin}/login/${userData?.code_ref}`}
                  type="text"
                  disabled
                  placeholder="argon-dashboard-vmsk392"
                />
                <button
                  onClick={() => {
                    copyToClipboard(
                      `${window.location.origin}/login/${userData?.code_ref}`
                    );
                    setTextMessage("Copied");
                  }}
                >
                  Sao chép
                </button>
              </div>
              <p>Nếu bạn không thể tạo mã giới thiệu.</p>
              <p>Hãy liên hệ với chúng tôi để tạo thêm mã giới thiệu.</p>
            </div>
          </div>
          <div className="ref__code__use">
            <h6>Cách sử dụng</h6>
            <p>Tích hợp mã giới thiệu của bạn dễ dàng chỉ với 3 bước:</p>
            <div className="d-flex">
              <div className="col-4 d-flex flex-column justify-content-center text-center align-items-center">
                <div className="icon__container mb-4 mt-4">
                  <FaCoins style={{ color: "#fff" }} />
                </div>
                <p>1. Sao chép mã của bạn.</p>
              </div>
              <div className="col-4 d-flex flex-column justify-content-center text-center align-items-center">
                <div className="icon__container mb-4 mt-4">
                  <BiLogoTelegram style={{ color: "#fff" }} />
                </div>
                <p>2. Chia sẻ mã cho bạn bè của bạn.</p>
              </div>
              <div className="col-4 d-flex flex-column justify-content-center text-center align-items-center">
                <div className="icon__container mb-4 mt-4">
                  <FaRocket style={{ color: "#fff" }} />
                </div>
                <p>
                  3. Nhận 10% hoa hồng khi người dùng sử dụng dịch vụ (trọn đời)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card p-3 mt-3">
        <div className="d-flex justify-content-between mb-2">
          <h5 className="ref__title">Lịch sử rút tiền</h5>
          <div className="d-flex gap-3">
            <Input placeholder="Tìm kiếm" value={search} onChange={setSearch} />
            <Button
              appearance="primary"
              onClick={() => {
                handleOpen();
              }}
            >
              Rút tiền
            </Button>
          </div>
        </div>
        <Table borderless responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Thời gian</th>
              <th>Tiền Rút</th>
              <th>Ngân Hàng</th>
              <th>Mã ngân hàng</th>
              {/* <th>Phone</th> */}
              <th>Trạng thái</th>
              <th>Ghi chú</th>
            </tr>
          </thead>
          <tbody>
            {historyList?.map((item, index) => (
              <tr key={item?.id}>
                <td>{++index}</td>
                <td>{item?.fullname}</td>
                <td>{priceFormat(Number(item?.coin))}</td>
                <td>{item?.bank}</td>
                <td>{item?.bank_code}</td>
                {/* <td>{item?.phone}</td> */}
                <td>
                  {(item?.status == 0 && "Pending") ||
                    (item?.status == 1 && "Faulty") ||
                    (item?.status == 2 && "Completed")}
                </td>
                <td>{item?.note}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {totalItems > 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"xs"}
            prev
            next
            // first
            // last
            ellipsis
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={5}
            activePage={activePage}
            onChangePage={setActivePage}
            onChangeLimit={setLimit}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Ref;
