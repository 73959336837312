import React, { useEffect, useRef, useState } from "react";
import "./adminbanner.css";
import {
  sendDelete,
  sendGet,
  sendPost,
  sendPut,
} from "../../../axios/axiosClient";
import {
  Button,
  Form,
  Message,
  Modal,
  Toggle,
  Uploader,
  useToaster,
} from "rsuite";
import { Table } from "react-bootstrap";
const AdminBanner = () => {
  const [listBanners, setListBanners] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setImage("");
    setOpen(false);
  };
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [image, setImage] = useState("");
  const [formCreate, setFormCreate] = useState({
    link: "",
    image: "",
    status: 1,
  });
  const [formUpdate, setFormUpdate] = useState({
    link: "",
    image: "",
    status: 1,
  });
  const inputImage = useRef(null);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => {
    setImage("");
    setOpenUpdate(false);
  };
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const [deleteId, setDeleteId] = useState(0);

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const getListBanners = async () => {
    try {
      const res = await sendGet(`/admins/banners?status=1`);
      if (res.status === 200) {
        console.log(res);
        setListBanners(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    // const formData = new FormData();
    try {
      const res = await sendPost(`/admins/banners/upload-image`, formData);
      if (res.status === 200) {
        console.log(res.data.data);
        setImage(res.data.data);
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const handleCreateBanner = async () => {
    if (image === "") {
      setTextMessage("Bạn cần tải ảnh lên.");
      return;
    }
    if (formCreate?.link?.trim() === "") {
      setTextMessage("Bạn cần điền link.");
      return;
    }
    const input = {
      link: formCreate?.link,
      status: formCreate?.status ? 1 : 0,
      image: image,
    };
    try {
      const res = await sendPost(`admins/banners`, input);
      if (res.status === 200) {
        getListBanners();
        setTextMessage(res.data.msg);
        handleClose();
        setImage("");
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const handleUpdate = async () => {
    if (image === "") {
      setTextMessage("Bạn cần tải ảnh lên.");
      return;
    }
    if (formUpdate?.link?.trim() === "") {
      setTextMessage("Bạn cần điền link.");
      return;
    }
    const input = {
      link: formUpdate?.link,
      status: formUpdate?.status ? 1 : 0,
      image: image,
    };
    try {
      const res = await sendPut(`admins/banners/${formUpdate?.id}`, input);
      if (res.status === 200) {
        getListBanners();
        setTextMessage(res.data.msg);
        handleCloseUpdate();
        setImage("");
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await sendDelete(`/admins/banners/${deleteId}`);
      if (res.status === 200) {
        setTextMessage(res.data.msg);
        getListBanners();
        handleCloseDelete();
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  useEffect(() => {
    getListBanners();
  }, []);

  return (
    <div>
      <Modal
        backdrop={"static"}
        keyboard={false}
        open={open}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Thêm mới banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={formCreate} onChange={setFormCreate} fluid>
            <Form.Group controlId="link">
              <Form.ControlLabel>
                Link <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="link" />
            </Form.Group>
            <Form.Group controlId="image">
              <Form.ControlLabel>
                Trạng thái <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="status" accepter={Toggle} defaultChecked />
            </Form.Group>
            <div className="d-flex flex-column gap-2">
              <input
                style={{ display: "none" }}
                ref={inputImage}
                type="file"
                onChange={(e) => {
                  if (e.target.files[0]) {
                    handleUploadImage(e.target.files[0]);
                  }
                }}
              />
              <Button
                onClick={() => inputImage.current.click()}
                appearance="primary"
                style={{ backgroundColor: "#f2ae14" }}
              >
                Tải ảnh lên
              </Button>
              {image === "" ? (
                <></>
              ) : (
                <img src={image} alt="image" className="bannerimage" />
              )}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCreateBanner} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        backdrop={"static"}
        keyboard={false}
        open={openUpdate}
        onClose={handleCloseUpdate}
      >
        <Modal.Header>
          <Modal.Title>Chỉnh sửa banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={formUpdate} onChange={setFormUpdate} fluid>
            <Form.Group controlId="link">
              <Form.ControlLabel>
                Link <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="link" />
            </Form.Group>
            <Form.Group controlId="image">
              <Form.ControlLabel>
                Trạng thái <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="status" accepter={Toggle} />
            </Form.Group>
            <div className="d-flex flex-column gap-2">
              <input
                style={{ display: "none" }}
                ref={inputImage}
                type="file"
                onChange={(e) => {
                  if (e.target.files[0]) {
                    handleUploadImage(e.target.files[0]);
                  }
                }}
              />
              <Button
                onClick={() => inputImage.current.click()}
                appearance="primary"
                style={{ backgroundColor: "#f2ae14" }}
              >
                Tải ảnh lên
              </Button>
              {image === "" ? (
                <></>
              ) : (
                <img src={image} alt="image" className="bannerimage" />
              )}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseUpdate} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        backdrop={"static"}
        keyboard={false}
        open={openDelete}
        onClose={handleCloseDelete}
      >
        <Modal.Header>
          <Modal.Title>Xoá banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Bạn có chắc chắn muốn xoá Banner này, hành động này không thể hoàn
            tác?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseDelete} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-end mb-2">
        <Button appearance="primary" onClick={handleOpen}>
          Thêm mới
        </Button>
      </div>
      <div>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Banner</th>
              <th>Link</th>
              <th>Status</th>
              <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {listBanners?.map((item, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>
                  <img src={item?.image} alt="banner" className="bannerimage" />
                </td>
                <td>
                  <a href={item?.link} target="_blank">
                    {item?.link}
                  </a>
                </td>
                <td>{item?.status === 1 ? "Active" : "Unactive"}</td>
                <th>
                  <div className="d-flex gap-2">
                    <Button
                      appearance="primary"
                      onClick={() => {
                        setFormUpdate({
                          link: item?.link,
                          status: item?.status,
                          id: item?.id,
                        });
                        setImage(item?.image);
                        handleOpenUpdate();
                      }}
                    >
                      Cập nhật
                    </Button>
                    <Button
                      appearance="primary"
                      style={{ backgroundColor: "red" }}
                      onClick={() => {
                        setDeleteId(item?.id);
                        handleOpenDelete();
                      }}
                    >
                      Xoá
                    </Button>
                  </div>
                </th>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AdminBanner;
