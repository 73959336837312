import React, { useEffect, useState } from "react";
import Select from "react-select";
import check from "../../assets/images/icons/check.png";
import uncheck from "../../assets/images/icons/uncheck.png";
import { Loader } from "rsuite";

const SelectAdAccounts = ({
  setSelectedAdsAccount,
  selectedAdsAccount,
  listAdsAccount,
}) => {
  const [options, setOptions] = useState([]);
  // console.log("list ad accounts", listAdsAccount);
  // console.log("selected", selectedAdsAccount);
  const [selectedValue, setSelectedValue] = useState({});

  useEffect(() => {
    let arr = [];
    if (listAdsAccount.length > 0) {
      arr = listAdsAccount?.map((item) => ({
        label: (
          <div className="d-flex gap-3 align-items-center">
            {item?.account_status === 2 ? (
              <img
                src={uncheck}
                alt=""
                className="select-account-status-image"
              />
            ) : (
              <img src={check} alt="" className="select-account-status-image" />
            )}
            <span>{item?.account_id}</span>
          </div>
        ),
        value: item?.account_id,
      }));
    }else{
      arr = [{
        label: (
          <div className="d-flex gap-3 align-items-center">
            <Loader />
          </div>
        ),
        value: '',
      }]
    }
    setOptions(arr);
    setSelectedValue(arr[0]);
  }, [listAdsAccount]);
  return (
    <>
      <Select
        onChange={(e) => {
          setSelectedAdsAccount(
            listAdsAccount.find((item) => item.account_id === e.value).id
          );
          setSelectedValue(e);
        }}
        value={selectedValue}
        options={options}
      />
      {/* <Dropdown
      style={{ width: "100%" }}
      className="pageDropdown"
      title={
        selectedAdsAccount !== undefined ? (
          <>
            {listAdsAccount?.length > 0 &&
            listAdsAccount.find((p) => p.id === selectedAdsAccount) ? (
              <div className="d-flex align-items-center gap-3">
                <div className="d-flex gap-2 align-items-center">
                  <span>
                    {
                      listAdsAccount.find((p) => p.id === selectedAdsAccount)
                        ?.account_id
                    }
                  </span>
                  {listAdsAccount.find((p) => p.id === selectedAdsAccount)
                    ?.account_status === 2 ? (
                    <MdRemoveCircle className="text-danger" />
                  ) : (
                    <AiOutlineCheck />
                  )}
                </div>
              </div>
            ) : (
              <div className="text-center">no data</div>
            )}
          </>
        ) : (
          <Loader />
        )
      }
    >
      {listAdsAccount?.map((item, key) => (
        <Dropdown.Item
          eventKey={key}
          key={key}
          onClick={() => setSelectedAdsAccount(item?.id)}
          disabled={item?.account_status === 2}
        >
          <div className="d-flex align-items-center justify-content-between">
            <p className="group_membernumber m-0">{item?.account_id}</p>
            {item?.account_status === 2 ? (
              <MdRemoveCircle className="text-danger" />
            ) : (
              <AiOutlineCheck />
            )}
          </div>
        </Dropdown.Item>
      ))}
    </Dropdown> */}
    </>
  );
};

export default SelectAdAccounts;
