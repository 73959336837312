export const listCardButtons = [
  {
    name: "Like Page",
    value: "LIKE_PAGE",
  },
  {
    name: "No Button",
    value: "NO_BUTTON",
  },
  {
    name: "Learn More",
    value: "LEARN_MORE",
  },
  {
    name: "Book travel",
    value: "BOOK_TRAVEL",
  },
  {
    name: "Contact us",
    value: "CONTACT_US",
  },
  {
    name: "Download",
    value: "DOWNLOAD",
  },
  {
    name: "Get quote",
    value: "GET_QUOTE",
  },
  {
    name: "Install app",
    value: "INSTALL_APP",
  },
  {
    name: "Listen music",
    value: "LISTEN_MUSIC",
  },
  {
    name: "Message page",
    value: "MESSAGE_PAGE",
  },
  {
    name: "Open link",
    value: "OPEN_LINK",
  },
  {
    name: "Play game",
    value: "PLAY_GAME",
  },
  {
    name: "Shop now",
    value: "SHOP_NOW",
  },
  {
    name: "Sign up",
    value: "SIGN_UP",
  },
  {
    name: "Subscribe",
    value: "SUBSCRIBE",
  },
  {
    name: "User app",
    value: "USE_APP",
  },
  {
    name: "Watch more",
    value: "WATCH_MORE",
  },
  {
    name: "Start order",
    value: "START_ORDER",
  },
  {
    name: "See more",
    value: "SEE_MORE",
  },
  {
    name: "Try it",
    value: "TRY_IT",
  },
  {
    name: "Apply now",
    value: "APPLY_NOW",
  },
  // {
  //   name: "Visible",
  //   value: "VISIBLE",
  // },
];
