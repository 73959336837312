import google_image from "../../assets/images/card_google.webp";
import bg2 from "../../assets/images/toolimage/63d141121f6300d96ea0c820_Facebook-carousel-ads1_de455d56d33246828e470dd01cdd9101_2000.jpeg";
import bg3 from "../../assets/images/toolimage/Carousel-Post-on-Facebook.jpeg";
import bg4 from "../../assets/images/toolimage/Facebook-Reels-20-didongviet.jpeg";
import bg5 from "../../assets/images/toolimage/Facebook-carousel-ads-1.jpeg";
import bg6 from "../../assets/images/toolimage/Facebook_ads_-_thumbnail.png";
import bg7 from "../../assets/images/toolimage/facebook-carousel-ad-example-parcellab-1-600x358.png";
import bg8 from "../../assets/images/toolimage/facebook-carousel-ads.jpeg";
import bg9 from "../../assets/images/toolimage/facebook_post_analysis_WG_16a62ead49.webp";
import bg10 from "../../assets/images/toolimage/h2-800x450.jpeg";

import { MdOutlineTravelExplore } from "react-icons/md";

import autoPostFbGroup from "../../assets/images/second-sidebar/post.svg";
import { ReactSVG } from "react-svg";
import { BiCarousel } from "react-icons/bi";
import { FaClone, FaPager } from "react-icons/fa";
import { BsCameraReels } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi";

import iconFbCloneVideoReel from "../../assets/images/second-sidebar/016-document-15.svg";
import iconFbVideoCarousel from "../../assets/images/second-sidebar/012-video_camera.svg";
import iconTikTokPeClone from "../../assets/images/second-sidebar/100-save_time.svg";
import iconFbVideosCarousel from "../../assets/images/second-sidebar/089-layers.svg";
import iconFbCloneVideoPe from "../../assets/images/second-sidebar/088-neural_network.svg";
import iconFbPictureCarousel from "../../assets/images/second-sidebar/085-pictures.svg";
// import iconAutoCommentFbGroup from "../../assets/images/second-sidebar/017-document-16.svg";
import iconAutoPostFbGroup from "../../assets/images/second-sidebar/017-document-16.svg";
import iconAutoCommentFbGroup from "../../assets/images/second-sidebar/037-document-36.svg";
import iconGroupBulkSearch from "../../assets/images/second-sidebar/015-vision.svg";
import iconGetGroupsFormUid from "../../assets/images/second-sidebar/026-group_chat.svg";
import iconGetGidInfo from "../../assets/images/second-sidebar/027-focus.svg";
import iconExtractGidFromSharedPost from "../../assets/images/second-sidebar/026-hierarchy.svg";
import iconExtractUidByPost from "../../assets/images/second-sidebar/029-document-28.svg";
import iconExtractUidByGroup from "../../assets/images/second-sidebar/037-facial_recognition.svg";
import iconGetGroupByHashtag from "../../assets/images/second-sidebar/046-distribution.svg";
import iconPeOneCardv2 from "../../assets/images/second-sidebar/005-businesswoman.svg";
import iconPeOneCardBulk from "../../assets/images/second-sidebar/003-strategic_plan.svg";
import iconFbOneCardVideoCarousel from "../../assets/images/second-sidebar/012-video_camera.svg";
import iconFbOneCardPictureCarousel from "../../assets/images/second-sidebar/085-pictures.svg";
import iconAutoJoinFbGroup from "../../assets/images/second-sidebar/014-teamwork.svg";
import iconMakeMeAdmin from "../../assets/images/second-sidebar/021-strength.svg";
import iconGroupPostsRemover from "../../assets/images/second-sidebar/030-strategic_plan.svg";
import iconGroupPostsAnalyze from "../../assets/images/second-sidebar/051-increase.svg";
import iconGroupPostsDelete from "../../assets/images/second-sidebar/058-cross.svg";
import iconV3GroupsShare from "../../assets/images/second-sidebar/045-share.svg";
import iconPagePostsAnalyzer from "../../assets/images/second-sidebar/076-checklist.svg";
import iconPagePostsClone from "../../assets/images/second-sidebar/057-file-8.svg";
import iconPagePostsRemover from "../../assets/images/second-sidebar/009-delete.svg";
import iconFbShareToPages from "../../assets/images/second-sidebar/044-share.svg";
import iconCreateBmPages from "../../assets/images/second-sidebar/004-idea.svg";
import iconPageProfilesToGroups from "../../assets/images/second-sidebar/011-online_strategy.svg";
import iconHome from "../../assets/images/category-image/homepage.png";
import iconUpToReels from "../../assets/images/category-image/video-image.webp";
import iconCarousel from "../../assets/images/category-image/multi-image-icon.webp";
import iconPage from "../../assets/images/category-image/flag-image.webp";
import iconFakeContentCard from "../../assets/images/category-image/image-icon.webp";
import iconGroup from "../../assets/images/category-image/group-icon.webp";
// import iconPage from "../../assets/images/category-image/facebook-icon.webp";
import iconTutorials from "../../assets/images/second-sidebar/023-prioritize.svg";
import iconNotify from "../../assets/images/second-sidebar/026-marketing.svg";
import iconPackage from "../../assets/images/second-sidebar/100-diamond.svg";
import iconFacebook from "../../assets/images/category-image/facebook-icon.webp";
import iconFacebookGold from "../../assets/images/category-image/facebook-gold-icon.webp";
import iconTikTok from "../../assets/images/category-image/tiktok-icon.webp";
import iconRemove from "../../assets/images/category-image/remove-icon.webp";
import iconComment from "../../assets/images/category-image/comment-icon.webp";
import iconExplore from "../../assets/images/category-image/explore.png";

export const toolCategories = [
  {
    title: "Trang chủ",
    key: "home",
    image: <img src={iconHome} className="secondSidebarIcon" />,
    // image: <BsCameraReels className="fs-5" />,
    tools: [
      {
        title: "Thông báo hệ thống",
        image: google_image,
        description: "",
        path: "/notify",
        icon: (
          <>
            <ReactSVG src={iconNotify} className="categoryImage" />
          </>
        ),
      },
      {
        title: "Hướng dẫn sử dụng",
        image: google_image,
        description: "",
        path: "/tutorials",
        icon: (
          <>
            <ReactSVG src={iconTutorials} className="categoryImage" />
          </>
        ),
      },

      {
        title: "Nâng cấp gói",
        image: google_image,
        description: "",
        path: "/package",
        icon: (
          <>
            <ReactSVG src={iconPackage} className="categoryImage" />
          </>
        ),
      },
    ],
  },
  {
    title: "Bài đăng carousel",
    image: <img src={iconCarousel} className="secondSidebarIcon" />,
    // image: <BiCarousel className="fs-5" />,
    tools: [
      {
        title: "Clone FB Reels & Video to carousel",
        key: "clone_fb_reels_&_video_to_carousel",
        image: google_image,
        description: "Clone FB Reels & Video to carousel",
        path: "/fb-clone-video-reel",
        icon: (
          <>
            <img src={iconFacebook} alt="" className="categoryImage" />
            <img src={iconUpToReels} alt="" className="categoryImage" />
            {/* <ReactSVG
              src={iconFbCloneVideoReel}
              className="categoryImage"
            /> */}
          </>
        ),
      },
      {
        title: "Facebook Video Carousel Post",
        key: "facebook_video_carousel_post",
        image: bg2,
        description: "Clone FB Reels & Video to carousel",
        path: "/fb-video-carousel",
        icon: (
          <>
            <img src={iconFacebook} alt="" className="categoryImage" />
            <img src={iconUpToReels} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "TikTok To Carousel Post",
        key: "tiktok_to_carousel_post",

        image: bg3,
        description: "Clone FB Reels & Video to carousel",
        path: "/tiktok-pe-clone",
        icon: (
          <>
            <img src={iconFacebook} alt="" className="categoryImage" />
            <img src={iconUpToReels} alt="" className="categoryImage" />
            <img src={iconTikTok} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Facebook 2 Card Video Carousel Post",
        key: "facebook_2_card_video_carousel_post",
        image: bg4,
        description: "Clone FB Reels & Video to carousel",
        path: "/fb-videos-carousel",
        icon: (
          <>
            <img src={iconFacebook} alt="" className="categoryImage" />
            <img src={iconUpToReels} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Clone Carousel Post (PE Clone)",
        key: "clone_carousel_post_pe_clone",
        image: bg5,
        description: "Clone FB Reels & Video to carousel",
        path: "/fb-clone-video-pe",
        icon: (
          <>
            <img src={iconFacebook} alt="" className="categoryImage" />
            <img src={iconUpToReels} alt="" className="categoryImage" />
            <img src={iconFakeContentCard} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Picture Carousel Post (PE Post)",
        key: "picture_carousel_post_pe_post",
        image: bg6,
        description: "Clone FB Reels & Video to carousel",
        path: "/fb-picture-carousel",
        icon: (
          <>
            <img src={iconFacebook} alt="" className="categoryImage" />
            <img src={iconFakeContentCard} alt="" className="categoryImage" />
          </>
        ),
      },
    ],
  },
  {
    title: "Khám phá",
    image: <img src={iconExplore} className="secondSidebarIcon" />,
    // image: <MdOutlineTravelExplore className="fs-5" />,
    tools: [
      {
        title: "Pro Bulk Search Facebook Group",
        key: "pro_bulk_search_facebook_group",
        image: bg9,
        description: "Clone FB Reels & Video to carousel",
        path: "/group-bulk-search",
        icon: (
          <>
            <img src={iconFacebookGold} alt="" className="categoryImage" />
            <img src={iconGroup} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Get Public Groups From Any FB Profile",
        key: "get_public_groups_from_any_fb_profile",
        image: bg10,
        description: "",
        path: "/get-groups-from-uid",
        icon: (
          <>
            <img src={iconFacebook} alt="" className="categoryImage" />
            <img src={iconExplore} alt="" className="categoryImage" />
            <img src={iconGroup} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Scan Group IDs and Get Group Information",
        key: "scan_group_ids_and_get_group_information",
        image: google_image,
        description: "",
        path: "/get-gid-info",
        icon: (
          <>
            <img src={iconFacebook} alt="" className="categoryImage" />
            <img src={iconGroup} alt="" className="categoryImage" />
          </>
        ),
      },
      // {
      //   title: "Extract Group IDs Where Post Shared",
      //   image: google_image,
      //   description: "",
      //   path: "/extract-gid-from-shared-post",
      //   icon: (
      //     <>
      //       <ReactSVG
      //         src={iconExtractGidFromSharedPost}
      //         className="categoryImage"
      //       />
      //     </>
      //   ),
      // },
      {
        title: "Extract UIDs from post",
        key: "extract_uids_from_post",
        image: google_image,
        description: "",
        path: "/extract-uid-by-post",
        icon: (
          <>
            <img src={iconFacebook} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Extract UIDs from group",
        key: "extract_uids_from_group",
        image: google_image,
        description: "",
        path: "/extract-uid-by-group",
        icon: (
          <>
            <img src={iconFacebook} alt="" className="categoryImage" />
            <img src={iconGroup} alt="" className="categoryImage" />
          </>
        ),
      },
      // {
      //   title: "Bulk Extract Groups List From Hashtags",
      //   image: google_image,
      //   description: "",
      //   path: "/get-group-by-hashtag",
      //   icon: (
      //     <>
      //       <ReactSVG
      //         src={iconGetGroupByHashtag}
      //         className="categoryImage"
      //       />
      //     </>
      //   ),
      // },
    ],
  },
  {
    title: "Bài đăng content ảo",
    image: <img src={iconFakeContentCard} className="secondSidebarIcon" />,
    // image: <FaClone className="fs-5" />,
    tools: [
      {
        title: "Generate One Card V2",
        key: "generate_one_card_v2",
        image: google_image,
        description: "",
        path: "/pe-one-card-v2",
        icon: (
          <>
            <img src={iconFacebook} alt="" className="categoryImage" />
            <img src={iconFakeContentCard} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Bulk Generate One Card Link",
        key: "bulk_generate_one_card_link",
        image: google_image,
        description: "",
        path: "/pe-one-card-bulk",
        icon: (
          <>
            <img src={iconFacebookGold} alt="" className="categoryImage" />
            <img src={iconFakeContentCard} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Facebook One Card Video Post",
        key: "facebook_one_card_video_post",
        image: google_image,
        description: "",
        path: "/fb-one-card-video-carousel",
        icon: (
          <>
            <img src={iconFacebook} alt="" className="categoryImage" />
            <img src={iconUpToReels} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Facebook One Card Link Image Post",
        key: "facebook_one_card_link_image_post",
        image: google_image,
        description: "",
        path: "/fb-one-card-picture-carousel",
        icon: (
          <>
            <img src={iconFacebook} alt="" className="categoryImage" />
            <img src={iconFakeContentCard} alt="" className="categoryImage" />
          </>
        ),
      },
    ],
  },
  {
    title: "Quản trị nhóm",
    image: <img src={iconGroup} className="secondSidebarIcon" />,
    // image: <HiUserGroup className="fs-5" />,
    tools: [
      {
        title: "Auto Join To Facebook Groups",
        key: "auto_join_to_facebook_groups",
        image: google_image,
        description: "",
        path: "/auto-join-fb-group",
        icon: (
          <>
            <img src={iconFacebookGold} alt="" className="categoryImage" />
            <img src={iconGroup} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Auto Post To Facebook Groups",
        key: "auto_post_to_facebook_groups",
        image: bg7,
        description: "Clone FB Reels & Video to carousel",
        path: "/auto-post-fb-group",
        icon: (
          // <img src={autoPostFbGroup} alt="" className="categoryImage" />
          <>
            <img src={iconFacebookGold} alt="" className="categoryImage" />
            <img src={iconGroup} alt="" className="categoryImage" />
          </>
        ),
        // autoPostFbGroupIcon,
      },

      {
        title: "Auto Comment Facebook Groups",
        key: "auto_comment_facebook_groups",
        image: bg8,
        description: "Clone FB Reels & Video to carousel",
        path: "/auto-comment-fb-group",
        icon: (
          <>
            <img src={iconFacebookGold} alt="" className="categoryImage" />
            <img src={iconGroup} alt="" className="categoryImage" />
            <img src={iconComment} alt="" className="categoryImage" />
          </>
        ),
      },
      // {
      //   title: "Claim Groups as Admin",
      //   image: google_image,
      //   description: "",
      //   path: "/make-me-admin",
      //   icon: (
      //     <>
      //       <ReactSVG src={iconMakeMeAdmin} className="categoryImage" />
      //     </>
      //   ),
      // },
      {
        title: "Emergency Group Posts Remover",
        key: "emergency_group_posts_remover",
        image: google_image,
        description: "",
        path: "/group-posts-remover",
        icon: (
          <>
            {/* <img src={iconFacebookGold} alt="" className="categoryImage" /> */}
            <img src={iconGroup} alt="" className="categoryImage" />
            <img src={iconRemove} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Public Group Posts Analyzer (Viral Finder)",
        key: "public_group_posts_analyzer_(viral_finder)",
        image: google_image,
        description: "",
        path: "/group-posts-analyze",
        icon: (
          <>
            <img src={iconGroup} alt="" className="categoryImage" />
            <img src={iconExplore} alt="" className="categoryImage" />
          </>
        ),
      },
      // {
      //   title: "Admin Group Posts Cleaner V2",
      //   image: google_image,
      //   description: "",
      //   path: "/group-posts-delete",
      //   icon: (
      //     <>
      //       <ReactSVG
      //         src={iconGroupPostsDelete}
      //         className="categoryImage"
      //       />
      //     </>
      //   ),
      // },
      {
        title: "Multi FB Groups Share Post",
        key: "multi_fb_groups_share_post",
        image: google_image,
        description: "",
        path: "/v3-groups-share",
        icon: (
          <>
            <img src={iconGroup} alt="" className="categoryImage" />
          </>
        ),
      },
    ],
  },
  {
    title: "Quản trị trang",
    image: <img src={iconPage} className="secondSidebarIcon" />,
    // image: <FaPager className="fs-5" />,
    tools: [
      {
        title: "Page Posts Analyzer (Viral Finder)",
        key: "page_posts_analyzer_viral_finder",
        image: google_image,
        description: "",
        path: "/page-posts-analyzer",
        icon: (
          <>
            <img src={iconPage} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Page Posts DEEP CLONE",
        key: "page_posts_deep_clone",
        image: google_image,
        description: "",
        path: "/page-posts-clone",
        icon: (
          <>
            <img src={iconPage} alt="" className="categoryImage" />
            {/* <img src={iconRemove} alt="" className="categoryImage" /> */}
          </>
        ),
      },
      {
        title: "High Speed Page Posts Remover",
        key: "high_speed_page_posts_remover",
        image: google_image,
        description: "",
        path: "/page-posts-remover",
        icon: (
          <>
            <img src={iconPage} alt="" className="categoryImage" />
            <img src={iconRemove} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Share Link To Multiple Pages",
        key: "share_link_to_multiple_pages",
        image: google_image,
        description: "",
        path: "/fb-share-to-pages",
        icon: (
          <>
            <img src={iconPage} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Auto Create Multiple Facebook Pages",
        key: "auto_create_multiple_facebook_pages",
        image: google_image,
        description: "",
        path: "/create-bm-pages",
        icon: (
          <>
            <img src={iconFacebook} alt="" className="categoryImage" />
            <img src={iconPage} alt="" className="categoryImage" />
          </>
        ),
      },
      {
        title: "Bulk Share Groups With Pages",
        key: "bulk_share_groups_with_pages",
        image: google_image,
        description: "",
        path: "/page-profiles-to-groups",
        icon: (
          <>
            <img src={iconPage} alt="" className="categoryImage" />
          </>
        ),
      },
    ],
  },
  // {
  //   title: "Đăng reels",
  //   key: "up_to_reels",
  //   image: <img src={iconUpToReels} className="secondSidebarIcon" />,
  //   // image: <BsCameraReels className="fs-5" />,
  //   tools: [
  //     {
  //       title: "UP TO REELS",
  //       key: "up_to_reels",
  //       image: google_image,
  //       description: "",
  //       path: "/up-to-reels",
  //       icon: (
  //         <>
  //           <img src={iconUpToReels} className="categoryImage" />
  //         </>
  //       ),
  //     },
  //   ],
  // },
];
