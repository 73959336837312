import React, { useContext, useEffect, useState } from "react";
import "./pageProfilesToGroup.css";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputPicker,
  Loader,
  Message,
  useToaster,
} from "rsuite";
import flow from "../../../../assets/images/flow.jpg";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import { socket } from "../../../../context/socket";
import Cookies from "js-cookie";
import { sendPost } from "../../../../axios/axiosClient";
import { Table, Form as bForm } from "react-bootstrap";
import SelectedPageNotFromAd from "../../../../components/selectedPage/SelectedPageNotFromAd";
import LinearProgress from "@mui/material/LinearProgress";
import { toolCategories } from "../../../../commons/data/toolCategory";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
import { listIcon } from "../../../../commons/data/icon";
import { AiFillCheckCircle } from "react-icons/ai";
import { DataContext } from "../../../../context/DataContext";

const PageProfilesToGroup = () => {
  const { selectedExtension } = useContext(ExtensionContext);
  const { setListPagesNonAT, listPagesNonAT, isSendScript } =
    useContext(DataContext);
  const [listPages, setListPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState();
  const [postId, setPostId] = useState("");
  const [content, setContent] = useState("");
  const [groupId, setGroupId] = useState("");
  const [delay, setDelay] = useState(30);
  const [isSharing, setIsSharing] = useState(false);
  const toolType = window.location.pathname;
  const [isError, setIsError] = useState(false);
  const [postResults, setPostResults] = useState([]);
  const [randomIcon, setRandomIcon] = useState(false);
  const [randomText, setRandomText] = useState(false);

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      console.log(message);
      switch (message?.params?.key_script) {
        case "get_all_pages_is_admin":
          if (message?.data?.length > 0) {
            setListPagesNonAT(message?.data);
            setSelectedPage(message?.data[0]?.id);
          }
          if (message?.data?.err) {
            setIsError(true);
          }
          break;
        case "share_post_with_page":
          if (message?.done_all === true && message?.data?.status === "done") {
            setTextMessage("Chia sẻ bài viết thành công !!!");
            setPostResults((old) => [
              ...old,
              `https://www.facebook.com/${message?.data?.post_id}`,
            ]);
          } else {
            setPostResults((old) => [...old, 0]);
            setTextMessage("Thất bại.");
          }
          setIsSharing(false);
          break;
        default:
          break;
      }
    });
  }, []);

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const getListPage = async () => {
    // console.log(uid);
    const input = {
      key_script: "get_all_pages_is_admin",
      extension_key: selectedExtension?.key,
      key_tool: "high_speed_page_posts_remover",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSharePost = async () => {
    if (postId === "") {
      setTextMessage("Bạn cần điền ID bài viết");
      return;
    }
    if (groupId?.trim() === "") {
      setTextMessage("Bạn cần điền ID nhóm");
      return;
    }
    let limit = groupId?.split("\n")?.length;
    let b = 0;
    if (limit > 0) {
      sharePost(groupId?.split("\n")[b]);
      ++b;
      if (b < limit) {
        const id = setInterval(() => {
          if (b < limit) {
            sharePost(groupId?.split("\n")[b]);
            ++b;
          } else {
            clearInterval(id);
          }
        }, delay * 1000);
      }
    }
  };

  const sharePost = async (id) => {
    let finalTitle = content;
    if (randomText) {
      let stringLength = 7;
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
      for (let i = 0; i < stringLength; i++) {
        finalTitle += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
    }
    if (randomIcon) {
      finalTitle += listIcon[Math.floor(Math.random() * listIcon.length)];
    }
    const input = {
      key_script: "share_post_with_page",
      extension_key: selectedExtension?.key,
      key_tool: "bulk_share_groups_with_pages",
      params: {
        key_socket: Cookies.get("keySocket"),
        post_id: postId,
        content: finalTitle,
        page_id: selectedPage,
        group_id: id,
      },
    };
    // console.log(input);
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setIsSharing(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (listPagesNonAT?.length > 0) {
      setSelectedPage(listPagesNonAT[0]?.id);
      return;
    } else if (
      selectedExtension?.key &&
      listPagesNonAT?.length === 0 &&
      isSendScript
    ) {
      getListPage();
    }
  }, [selectedExtension]);

  return (
    <div style={{ width: "100%" }} className="d-flex gap-3">
      <div className="col-8">
        <div className="card p-3">
          <div className="mb-3">
            <ToolHeading toolType={window.location.pathname} />
          </div>
          <bForm.Group>
            <bForm.Label className="group_membernumber">
              Trang Facebook :
            </bForm.Label>
            <SelectedPageNotFromAd
              listPages={listPagesNonAT}
              setSelectedPage={setSelectedPage}
              selectedPage={selectedPage}
              err={isError}
            />
          </bForm.Group>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="group_membernumber m-0">Nội dung (Basic+) :</p>
            <div className="d-flex gap-3 group_membernumber">
              <Checkbox
                checked={randomIcon}
                onChange={(e, check) => setRandomIcon(check)}
              >
                Biểu tượng ngẫu nhiên
              </Checkbox>
              <Checkbox
                checked={randomText}
                onChange={(e, check) => setRandomText(check)}
              >
                Nội dung ngẫu nhiên
              </Checkbox>
            </div>
          </div>
          <Input
            as="textarea"
            rows={5}
            onChange={setContent}
            placeholder="Nội dung"
          />
          <p className="group_membernumber my-2">
            ID Bài viết:{" "}
            <a href="https://uid.bufflike.net/" target="_blank">
              <Form.HelpText tooltip>Tìm ID của bạn ở đây</Form.HelpText>
            </a>
          </p>
          <Input onChange={setPostId} placeholder="ID Bài viết" />
          <p className="group_membernumber my-2">Delay (giây):</p>
          <Input type="number" value={delay} onChange={setDelay} />

          <p className="group_membernumber my-2">ID nhóm :</p>
          <Input
            as="textarea"
            rows={8}
            onChange={setGroupId}
            placeholder="ID nhóm, mỗi dòng 1 ID"
          />
          <div className="my-3">
            <Form.HelpText className="text-center">
              Chức năng này chỉ có thể chia sẻ bài viết ở những nhóm cho phép
              Trang Facebook đăng bài
            </Form.HelpText>
          </div>
          <Button
            disabled={isSharing}
            appearance="primary"
            color="green"
            className="fw-bold "
            style={{ width: "100%" }}
            onClick={handleSharePost}
          >
            CHIA SẺ
          </Button>

          {isSharing ? (
            <LinearProgress
              style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="col-4">
        <div className="card p-2">
          <h5 className="fw-bold text-center">Báo cáo bài đăng</h5>
          <Table responsive bordered style={{ background: "white" }}>
            <thead>
              <tr>
                <th>Trạng thái</th>
                <th>Đường dẫn</th>
              </tr>
            </thead>
            <tbody>
              {postResults?.map((item, idx) => {
                if (item === 0) {
                  return (
                    <tr key={idx}>
                      <td></td>
                      <td className="text-danger fw-bold">Thất bại</td>
                    </tr>
                  );
                } else {
                  return (
                    <tr style={{ maxWidth: "20vw" }} key={idx}>
                      <td>
                        <a
                          href={item}
                          target="_blank"
                          className="group_membernumber blackLink"
                        >
                          {item}
                        </a>
                      </td>
                      <td className="text-success fw-bold">Thành công</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default PageProfilesToGroup;
