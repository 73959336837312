import React, { useState, useEffect, useContext } from "react";
import "./getGroupByHashtag.css";
import {
  Button,
  Input,
  Form,
  Loader,
  Pagination,
  RadioGroup,
  Radio,
  InputPicker,
  useToaster,
  Message,
  Modal,
  CheckboxGroup,
  Checkbox,
} from "rsuite";
import { Form as bForm } from "react-bootstrap";
import {
  AiOutlineExport,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { BiCopy, BiSortDown, BiSortUp } from "react-icons/bi";
import { Table } from "react-bootstrap";
import avatar_image from "../../../../assets/images/645fbc5da4a21.png";
import { socket } from "../../../../context/socket";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import Cookies from "js-cookie";
import { sendPost } from "../../../../axios/axiosClient";
import { SiMicrosoftexcel } from "react-icons/si";
import { CSVLink } from "react-csv";
import moment from "moment";
import LinearProgress from "@mui/material/LinearProgress";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
import { DataContext } from "../../../../context/DataContext";
import { MdFileDownload } from "react-icons/md";
let groupLength = 0;
const GetGroupByHashTag = () => {
  const [groupIds, setGroupIds] = useState("");
  const { selectedExtension } = useContext(ExtensionContext);
  const toolType = window.location.pathname;
  const [groupData, setGroupData] = useState();
  const [listGroups, setListGroups] = useState([]);
  const [delays, setDelays] = useState(5);
  const [searchText, setSearchText] = useState("");
  const [filterGroup, setFilterGroup] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [sortingMember, setSortingMember] = useState("");
  const [sortingProp, setSortingProp] = useState("");
  const [intervalId, setIntervalId] = useState();
  const [filterDownload, setFilterDownload] = useState([]);
  const [downloadModal, setDownloadModal] = useState(false);
  const { keepFieldsDownload } = useContext(DataContext);
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const sortingData = [
    { label: "Tổng thành viên", value: "total_member" },
    { label: "Posts hôm trước", value: "posts_last_day" },
    { label: "Post tháng trước", value: "posts_last_month" },
    { label: "Ngày tạo", value: "created_time" },
  ];

  const handleSorting = (direct, prop) => {
    // setSortingMember(direct);\
    // console.log(`direct: ${direct}, props: ${prop}`);
    if (direct === "asc") {
      listGroups.sort(
        (a, b) => parseMetricNumber(a[prop]) - parseMetricNumber(b[prop])
      );
    } else {
      listGroups.sort(
        (a, b) => parseMetricNumber(b[prop]) - parseMetricNumber(a[prop])
      );
    }
    setFilterGroup([direct, prop]);
  };

  useEffect(() => {
    if (sortingMember !== "" && sortingProp !== "") {
      handleSorting(sortingMember, sortingProp);
    }
  }, [sortingMember, sortingProp]);

  useEffect(() => {
    setActivePage(1);
  }, [searchText]);

  const parseMetricNumber = (str) => {
    const multiplier = {
      K: 1000,
      M: 1000000,
      B: 1000000000,
      // Add more prefixes and multipliers if needed
    };

    const numericPart = parseFloat(str);
    const lastChar = str[str.length - 1];

    if (multiplier[lastChar]) {
      return numericPart * multiplier[lastChar];
    } else {
      return numericPart;
    }
  };

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      switch (message?.params?.key_script) {
        case "get_infomation_group_uid":
          // setGroupData((oldArray) => [...oldArray, message?.data]);
          if (groupLength <= 1) {
            setIsLoadingData(false);
            setTextMessage("Kết thúc tìm kiếm");
          }
          if (message?.data !== false) {
            setGroupData({
              id: message?.data?.id,
              name: message?.data?.name,
              created_time: message?.data?.activity_section?.created_time,
              posts_last_day:
                message?.data?.activity_section?.number_of_posts_in_last_day,
              posts_last_month:
                message?.data?.activity_section?.number_of_posts_in_last_month,
              new_member:
                message?.data?.activity_section?.group_new_members_info_text,
              total_member: message?.data?.count_member,
              picture: message?.data?.picture?.data?.url,
              privacy: message?.data?.privacy,
            });
          } else {
            setTextMessage("Có lỗi xảy ra khi tìm kiếm Group ID");
          }

          --groupLength;
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    if (groupData) {
      setListGroups((old) => [...old, groupData]);
    }
  }, [groupData]);

  const handleGetListGroup = () => {
    if (groupIds?.trim() === "") {
      setTextMessage("Hãy nhập ID của nhóm !");
      return;
    }
    setIsLoadingData(true);
    setListGroups([]);
    // console.log(groupIds);
    const limit = groupIds?.trim().split("\n")?.length;
    let b = 0;
    groupLength = limit;
    getListGroup(groupIds?.trim()?.split("\n")[b]);
    ++b;
    if (limit > 1) {
      const id = setInterval(() => {
        if (b < limit) {
          getListGroup(groupIds?.split("\n")[b]);
          ++b;
        } else {
          clearInterval(id);
        }
      }, delays * 1000);
    }
    // setIntervalId(id);
  };

  // const handleStop = () => {
  //   clearInterval(intervalId);
  //   setIsLoadingData(false);
  //   setTextMessage("Dừng tạo tìm kiếm.");
  // };

  const getListGroup = async (id) => {
    if (!id || id === "") {
      --groupLength;
      return;
    }
    const input = {
      key_script: "get_infomation_group_uid",
      extension_key: selectedExtension?.key,
      key_tool: "scan_group_ids_and_get_group_information",
      params: {
        key_socket: Cookies.get("keySocket"),
        group_id: id,
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setIsLoadingData(true);
      }
    } catch (error) {
      console.log(error);
      setTextMessage("Có lỗi xảy ra khi tìm ID nhóm trên !");
      // setIsLoadingData(false);
      --groupLength;
    }
  };

  return (
    <>
      <div className="d-flex gap-3">
        <Modal
          backdrop="static"
          role="alertdialog"
          keyboard={false}
          open={downloadModal}
          style={{ paddingTop: "5rem" }}
          onClose={() => setDownloadModal(false)}
        >
          <Modal.Header>
            <Modal.Title className="fw-bold">
              Chọn các mục bạn muốn tải
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CheckboxGroup
              name="checkbox-group"
              value={filterDownload}
              onChange={(value) => {
                setFilterDownload(value);
              }}
            >
              <Checkbox value="id">ID</Checkbox>
              <Checkbox value="name">Tên</Checkbox>
              <Checkbox value="posts_last_day">Post hôm qua</Checkbox>
              <Checkbox value="posts_last_month">Post tháng trước</Checkbox>
              <Checkbox value="total_member">Thành viên</Checkbox>
              <Checkbox value="created_time">Ngày tạo</Checkbox>
            </CheckboxGroup>
          </Modal.Body>
          <Modal.Footer className="d-flex">
            <CSVLink
              data={listGroups?.map((item) => ({
                UID: item?.id,
                NAME: item?.name,
                CREATED_TIME: item?.created_time,
                POSTS_LAST_DAY: item?.posts_last_day,
                POSTS_LAST_MONTH: item?.posts_last_month,
                NEW_MEMBER: item?.new_member,
                TOTAL_MEMBER: item?.total_member,
                PICTURE: item?.picture,
                PRIVACY: item?.privacy,
              }))
                ?.map((item) => keepFieldsDownload(item, filterDownload))
              }
              filename="SCAN_GROUP_IDS_AND_GET_GROUP_INFORMATION.csv"
              onClick={() => {
                if (!listGroups?.length) {
                  setTextMessage("Không có dữ liệu để tải về");
                  return false; // 👍🏻 You are stopping the handling of component
                }
              }}
            >
              <Button appearance="primary">
                <SiMicrosoftexcel className="me-2" />
                <span>Tải xuống</span>
              </Button>
            </CSVLink>
          </Modal.Footer>
        </Modal>
        <div className="col-9">
          <div className="card p-3">
            <div className="mb-3">
              <ToolHeading toolType={window.location.pathname} />
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <Button
                  appearance="primary"
                  style={{ padding: "0.2rem 0.4rem" }}
                  onClick={() => {
                    setDownloadModal(true);
                  }}
                >
                  <MdFileDownload style={{ fontSize: 21 }} />
                </Button>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2">
              {/* <RadioGroup
              inline
              name="radio-name"
              onChange={(e) => setSortingProp(e)}
              className="group_membernumber"
            >
              <Radio value="total_member">Total member</Radio>
              <Radio value="posts_last_day">Post last day</Radio>
              <Radio value="posts_last_month">Post last month</Radio>
              <Radio value="created_time">Created date</Radio>
            </RadioGroup> */}

              <div className="d-flex gap-2 align-items-center">
                <span className="oneLine group_membernumber">Sắp xếp: </span>
                <bForm.Select
                  aria-label="Default select example"
                  size="sm"
                  onChange={(e) => setSortingProp(e.target.value)}
                >
                  <option value="">Chọn giá trị</option>
                  {sortingData?.map((item) => (
                    <option value={item?.value} key={item?.value}>
                      {item?.label}
                    </option>
                  ))}

                  {/* <option value="des">Descending</option> */}
                </bForm.Select>
                <bForm.Select
                  aria-label="Default select example"
                  size="sm"
                  onChange={(e) => setSortingMember(e.target.value)}
                >
                  <option value="">Thứ tự sắp xếp</option>
                  <option value="asc">Tăng dần</option>
                  <option value="des">Giảm dần</option>
                </bForm.Select>
              </div>
              <div>
                <Input
                  placeholder="Tìm kiếm"
                  onChange={setSearchText}
                  value={searchText}
                />
              </div>
            </div>
            <div className="mt-3">
              <Table>
                <thead>
                  <tr className="group_membernumber text-center">
                    {/* <th>#</th> */}
                    <th style={{ width: "35%" }}>Tên</th>
                    <th>Privacy</th>
                    <th>Tổng thành viên</th>
                    <th>Post hôm qua</th>
                    <th>Post tháng trước</th>
                    <th>Ngày tạo</th>
                  </tr>
                  {isLoadingData ? (
                    <tr>
                      <th colSpan={10}>
                        <LinearProgress style={{ width: "100%" }} />
                      </th>
                    </tr>
                  ) : (
                    <></>
                  )}
                </thead>
                <tbody>
                  {listGroups
                    ?.filter((item) =>
                      item?.name
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                    ?.map((item, key) => (
                      <tr
                        className="text-center"
                        key={key}
                        style={{ fontSize: ".85rem" }}
                      >
                        {/* <td>{key + 1}</td> */}
                        <td style={{ width: "35%" }} className="text-start">
                          <div className="d-flex gap-3">
                            <div className="d-flex align-items-center">
                              <img
                                src={item?.picture}
                                alt="avatar"
                                className="toolHeader__avatar"
                              />
                            </div>
                            <div>
                              <a
                                href={`https://www.facebook.com/${item?.id}`}
                                target="_blank"
                                className="group_membernumber fw-bold m-0 blackLink"
                              >
                                {item?.name}
                              </a>
                              <p className="group_membernumber m-0">
                                {item?.id}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="">
                          {item?.privacy === "Hiển thị" ? (
                            <AiOutlineEye className="text-success" />
                          ) : (
                            <AiOutlineEyeInvisible className="text-danger" />
                          )}
                        </td>
                        <td style={{ fontSize: ".85rem" }}>
                          {item?.total_member}
                        </td>
                        <td>{item?.posts_last_day}</td>
                        <td>{item?.posts_last_month}</td>
                        <td>
                          {moment
                            ?.unix(item?.created_time)
                            .format("MM/DD/YYYY")}
                        </td>
                        {/* <td>{new Date(item?.created_time * 1000)}</td> */}
                      </tr>
                    ))
                    ?.splice((activePage - 1) * limit, limit)}
                </tbody>
              </Table>
              {listGroups?.length > 0 ? (
                <Pagination
                  layout={["limit", "-", "pager"]}
                  size={"xs"}
                  prev
                  next
                  // first
                  // last
                  ellipsis
                  boundaryLinks
                  total={
                    listGroups?.filter((item) =>
                      item?.name
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )?.length
                  }
                  limit={limit}
                  limitOptions={[10, 30, 50, 100, 500]}
                  maxButtons={5}
                  activePage={activePage}
                  onChangePage={setActivePage}
                  onChangeLimit={setLimit}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card p-3">
            <p className="group_membernumber">
              ID nhóm:
              <a href="https://uid.bufflike.net/" target="_blank">
                <Form.HelpText tooltip>Click vào đây để lấy ID</Form.HelpText>
              </a>
            </p>
            <Input
              as="textarea"
              // rows={6}
              placeholder="ID Nhóm, mỗi nhóm 1 dòng"
              rows={5}
              value={groupIds}
              // style={{ height: "100%" }}
              onChange={setGroupIds}
            />
            <div className="mt-2">
              <p className="group_membernumber">Delays (giây) :</p>
              <Input type="number" value={delays} onChange={setDelays} />
            </div>
            {isLoadingData ? (
              <Button className="mt-3 fw-bold" style={{ width: "100%" }}>
                <Loader />
              </Button>
            ) : (
              <Button
                className="mt-3 fw-bold"
                style={{ width: "100%" }}
                appearance="primary"
                color="orange"
                onClick={handleGetListGroup}
              >
                BẮT ĐẦU
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GetGroupByHashTag;
