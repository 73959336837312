import React from "react";
import "./extractGidFromSharedPost.css";
import ProfileHeading from "../../../../components/profileHeading/ProfileHeading";
import { Button, Input } from "rsuite";

const ExtractGidFromSharedPost = () => {
  return (
    <div className="d-flex gap-3">
      <div className="card col-7 p-3">
        <ProfileHeading />
        <p className="mt-5 group_membernumber">
          Link Group Posts (Only Work On link that using spam share)
        </p>
        <Input />
        <Button className="mt-2 fw-bold" appearance="primary" color="orange">
          EXTRACT
        </Button>
      </div>
      <div className="col-5 ">
        <div className="card p-3">
          <p className="group_membernumber">Share Able Group IDs (0)</p>
          <Input as="textarea"></Input>
        </div>
      </div>
    </div>
  );
};

export default ExtractGidFromSharedPost;
