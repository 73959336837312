import React, { useContext, useEffect, useState } from "react";
import avatar from "../../assets/images/645fbc5da4a21.png";
import { Button, Input, InputGroup, Loader } from "rsuite";
import { AiOutlineSearch, AiOutlineUpload } from "react-icons/ai";
import { socket } from "../../context/socket";
import { ExtensionContext } from "../../context/ExtensionContext";
import Cookies from "js-cookie";
import { sendPost } from "../../axios/axiosClient";
import "./publicHeading.css";
const PublicHeading = ({
  selectedPage,
  setLinkUrl,
  handleUploadVideoCarousel,
  postCarousel,
  loaderImage,
  listPages,
  isPublic,
  selectedAdsAccount,
}) => {
  // const [toolType, setToolType] = useState("");
  const { selectedExtension } = useContext(ExtensionContext);
  const toolType = window.location.pathname;
  // const [listPages, setListPages] = useState([]);

  // const getPublicGroup = async () => {
  //   const input = {
  //     key_script: "get_all_pages_is_admin",
  //     extension_key: selectedExtension?.key,
  //     key_tool: "high_speed_page_posts_remover",
  //     params: {
  //       key_socket: Cookies.get("keySocket"),
  //     },
  //   };
  //   try {
  //     const res = await sendPost(`/users/scripts/send`, input);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-3 align-items-center">
          {selectedPage ? (
            <div>
              <img
                src={
                  listPages?.find((item) => item?.id === selectedPage)?.picture
                    ?.data?.url
                }
                alt=""
                className="toolHeader__avatar"
              />
            </div>
          ) : (
            <Loader />
          )}
          <div>
            <p className="group_membernumber m-0">
              {listPages?.find((item) => item?.id === selectedPage)?.name}
            </p>
            <p className="group_membernumber m-0">{selectedPage}</p>
          </div>
        </div>
        <div>
          {isPublic ? (
            <Button className="fw-bold" appearance="primary">
              <Loader />
            </Button>
          ) : (
            <Button
              className="fw-bold"
              appearance="primary"
              onClick={postCarousel}
              disabled={loaderImage || selectedAdsAccount === undefined}
            >
              ĐĂNG BÀI
            </Button>
          )}
        </div>
      </div>

      {(() => {
        switch (toolType) {
          case "/fb-clone-video-reel":
            return (
              <>
                <div className="mt-2">
                  <InputGroup>
                    <Input
                      placeholder="Facebook Video URL"
                      onChange={setLinkUrl}
                    />
                    {loaderImage ? (
                      <InputGroup.Button>
                        <Loader />
                      </InputGroup.Button>
                    ) : (
                      <InputGroup.Button
                        onClick={handleUploadVideoCarousel}
                        disabled={isPublic}
                      >
                        <AiOutlineUpload />
                      </InputGroup.Button>
                    )}
                  </InputGroup>
                </div>
              </>
            );
          case "/tiktok-pe-clone":
            return (
              <>
                <div className="mt-2">
                  <InputGroup>
                    <Input placeholder="Tiktok URL" onChange={setLinkUrl} />
                    {loaderImage ? (
                      <InputGroup.Button>
                        <Loader />
                      </InputGroup.Button>
                    ) : (
                      <InputGroup.Button
                        onClick={handleUploadVideoCarousel}
                        disabled={isPublic}
                      >
                        <AiOutlineUpload />
                      </InputGroup.Button>
                    )}
                  </InputGroup>
                </div>
              </>
            );
          case "/fb-clone-video-pe":
            return (
              <>
                <div className="mt-2">
                  <InputGroup>
                    <Input
                      placeholder="Facebook Carousel URL"
                      onChange={setLinkUrl}
                    />
                    {loaderImage ? (
                      <InputGroup.Button>
                        <Loader />
                      </InputGroup.Button>
                    ) : (
                      <InputGroup.Button
                        onClick={handleUploadVideoCarousel}
                        disabled={isPublic}
                      >
                        <AiOutlineUpload />
                      </InputGroup.Button>
                    )}
                  </InputGroup>
                </div>
              </>
            );
          default:
            break;
        }
      })()}
    </>
  );
};

export default PublicHeading;
