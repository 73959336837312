import React, { useContext, useEffect, useState } from "react";
import "./v3GroupsShare.css";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputPicker,
  Loader,
  Message,
  useToaster,
} from "rsuite";
import flow from "../../../../assets/images/flow.jpg";
import Cookies from "js-cookie";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import { socket } from "../../../../context/socket";
import { sendPost } from "../../../../axios/axiosClient";
import LinearProgress from "@mui/material/LinearProgress";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
import { listIcon } from "../../../../commons/data/icon";
import { CheckFunction } from "../../../../commons/function/CheckURL";
import axios from "axios";

let totalShare = 0;
const V3GroupsShare = () => {
  const data = [{ value: 1, label: 1 }];
  const { selectedExtension } = useContext(ExtensionContext);

  const [formValue, setFormValue] = useState();
  const [content, setContent] = useState("");
  const [shareLinkID, setShareLinkID] = useState("");
  const [groupIDs, setGroupIDs] = useState("");
  const [delays, setDelays] = useState(10);

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [isSharing, setIsSharing] = useState(false);
  const [postResult, setPostResult] = useState("");
  const [postResults, setPostResults] = useState([]);
  const [isRandomText, setIsRandomText] = useState(false);
  const [isRandomIcon, setIsRandomIcon] = useState(false);
  const [isRandomDate, setIsRandomDate] = useState(false);
  const [intervalId, setIntervalId] = useState();

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (postResult !== "") {
      setPostResults((old) => [...old, postResult]);
    }
  }, [postResult]);

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      console.log(message);
      switch (message?.params?.key_script) {
        case "share_post":
          if (message?.data?.length === 0) {
            setTextMessage("Chia sẻ bài viết thất bại.");
          } else {
            setTextMessage("Chia sẻ bài viết thành công.");
            setPostResult(message?.data?.post_id);
          }
          if (totalShare <= 1) {
            setIsSharing(false);
          }
          --totalShare;
          break;
        default:
          break;
      }
    });
  }, []);

  const handleShare = async () => {
    const limit = groupIDs?.trim()?.split("\n")?.length;
    totalShare = groupIDs?.trim()?.split("\n")?.length;
    console.log("totalshare", totalShare);
    if (shareLinkID?.trim() === "") {
      setTextMessage("Bạn chưa nhập liên kết/ID Bài viết");
      return;
    } else if (!limit) {
      setTextMessage("Bạn chưa nhập ID nhóm chia sẻ.");
      return;
    } else {
      if (CheckFunction(shareLinkID?.trim())) {
        setIsSharing(true);
        let postId = "";
        await axios
          .post("https://uid.bufflike.net/fbid", {
            link: shareLinkID?.trim(),
          })
          .then(function (response) {
            if (response.data.status) {
              postId = response?.data?.fbid;
            } else {
              setTextMessage(response?.data?.message);
              setIsSharing(false);
              return;
            }
          })
          .catch(function (error) {
            setTextMessage("Có lỗi xảy ra.");
            setIsSharing(false);
            return;
          });
        if (postId === "") {
        } else {
          sharePost(groupIDs?.split("\n")[0], postId);
          let b = 1;
          if (limit > 1) {
            const id = setInterval(() => {
              if (b < limit) {
                sharePost(groupIDs?.split("\n")[b], postId);
                ++b;
              } else {
                clearInterval(id);
              }
            }, delays * 1000);
            setIntervalId(id);
          }
        }
      } else {
        setIsSharing(true);
        sharePost(groupIDs?.split("\n")[0], shareLinkID.trim());
        let b = 1;
        if (limit > 1) {
          const id = setInterval(() => {
            if (b < limit) {
              sharePost(groupIDs?.split("\n")[b], shareLinkID.trim());
              ++b;
            } else {
              clearInterval(id);
            }
          }, delays * 1000);
          setIntervalId(id);
        }
      }
    }
  };

  const handleStopShare = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    setIsSharing(false);
  };

  const sharePost = async (id, postId) => {
    if (!id || id === "") {
      --totalShare;
      return;
    }
    let finalTitle = content;
    if (isRandomText) {
      let stringLength = 7;
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
      for (let i = 0; i < stringLength; i++) {
        finalTitle += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
    }
    if (isRandomIcon) {
      finalTitle += listIcon[Math.floor(Math.random() * listIcon.length)];
    }
    if (isRandomDate) {
      const today = new Date();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      finalTitle += time;
    }
    const input = {
      key_script: "share_post",
      extension_key: selectedExtension?.key,
      key_tool: "multi_fb_groups_share_post",
      params: {
        key_socket: Cookies.get("keySocket"),
        group_ids: [id],
        content: finalTitle,
        post_id: postId,
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
        setIsSharing(true);
      }
    } catch (error) {
      setIsSharing(false);
    }
  };

  return (
    <div className="">
      <div className="card p-3" style={{ width: "100%" }}>
        <div className="mb-3">
          <ToolHeading toolType={window.location.pathname} />
        </div>
        <div>
          <div className="d-flex align-items-center">
            <p className="group_membernumber mb-1">Nội dung (Basic+) :</p>
            <Checkbox
              checked={isRandomIcon}
              onChange={(e, check) => setIsRandomIcon(check)}
            >
              Biểu tượng ngẫu nhiên
            </Checkbox>
            <Checkbox
              checked={isRandomText}
              onChange={(e, check) => setIsRandomText(check)}
            >
              Nội dung ngẫu nhiên
            </Checkbox>
            <Checkbox
              checked={isRandomDate}
              onChange={(e, check) => setIsRandomDate(check)}
            >
              Thời gian
            </Checkbox>
          </div>
          <Input
            placeholder="Nội dung"
            as="textarea"
            rows={5}
            value={content}
            onChange={setContent}
          />
          <p className="group_membernumber mb-1 mt-2">
            Bài viết chia sẻ:{" "}
            <a href="https://uid.bufflike.net/" target="_blank">
              <Form.HelpText tooltip>Tìm kiếm ID</Form.HelpText>
            </a>
          </p>
          <Input
            placeholder="Đường dẫn hoặc ID bài viết"
            onChange={setShareLinkID}
            value={shareLinkID}
          />
          <p className="group_membernumber mt-3">
            ID nhóm :{" "}
            <a href="https://uid.bufflike.net/" target="_blank">
              <Form.HelpText tooltip>Tìm kiếm ID</Form.HelpText>
            </a>
          </p>
          <Input
            as="textarea"
            placeholder="ID Nhóm, mỗi ID 1 dòng"
            rows={8}
            value={groupIDs}
            onChange={setGroupIDs}
          />
          <Form
            fluid
            formValue={formValue}
            onChange={setFormValue}
            className="mt-3"
          >
            <Form.Group controlId="l" className="col">
              <Form.ControlLabel className="group_membernumber">
                Delay (giây) :
              </Form.ControlLabel>
              <Form.Control
                name="name"
                type="number"
                value={delays}
                onChange={setDelays}
                //   style={{ width: "100%" }}
              />
            </Form.Group>
            <Form.Group>
              {isSharing ? (
                <Button
                  className="fw-bold"
                  style={{ width: "100%", background: "red" }}
                  appearance="primary"
                  onClick={handleStopShare}
                >
                  DỪNG
                </Button>
              ) : (
                <Button
                  className="fw-bold"
                  style={{ width: "100%" }}
                  appearance="primary"
                  onClick={handleShare}
                >
                  BẮT ĐẦU
                </Button>
              )}
              {/* <Button
                disabled={isSharing}
                className="fw-bold"
                style={{ width: "100%" }}
                appearance="primary"
                onClick={handleShare}
              >
                BẮT ĐẦU
              </Button> */}
              {isSharing ? (
                <LinearProgress
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                />
              ) : (
                <></>
              )}
            </Form.Group>
          </Form>
        </div>
      </div>

      {postResults?.length > 0 ? (
        <div className="mt-3 card p-2">
          <h5 className="fw-bold text-center">Kết quả</h5>
          <div>
            {postResults?.map((post, key) => (
              <p key={key}>
                <a
                  href={`https://www.facebook.com/${post}`}
                  className="oneLine blackLink"
                  target="_blank"
                >
                  {`https://www.facebook.com/${post}`}
                </a>
              </p>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default V3GroupsShare;
