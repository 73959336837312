import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Form,
  IconButton,
  Input,
  Loader,
  Message,
  Toggle,
  useToaster,
} from "rsuite";
import { AiOutlineClose, AiOutlineFileImage } from "react-icons/ai";
import "./peOneCardv2.css";
import { Table } from "react-bootstrap";
import { ExtensionContext } from "../../../../context/ExtensionContext";
import { socket } from "../../../../context/socket";
import Cookies from "js-cookie";
import { sendPost } from "../../../../axios/axiosClient";
import SelectedPage from "../../../../components/selectedPage/SelectedPage";
import { listIcon } from "../../../../commons/data/icon";
import ToolHeading from "../../../../components/toolHeading/ToolHeading";
import { DataContext } from "../../../../context/DataContext";
import SelectAdAccounts from "../../../../components/select-ad-accounts/SelectAdAccounts";
import "./album-css.css";
import gray_images from "../../../../assets/images/toolimage/gray-image.png";

import * as htmlToImage from "html-to-image";
import { wait } from "@testing-library/user-event/dist/utils";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

const PEOneCardv2 = () => {
  const { adAccount, setAdAccount } = useContext(DataContext);

  const [cardType, setCardType] = useState(0);
  const [cardImages, setCardImages] = useState([]);
  const inputImage = useRef(null);
  const { selectedExtension } = useContext(ExtensionContext);
  const [fakeContentNumber, setFakeContentNumber] = useState(9);
  const [adAccounts, setAdAccounts] = useState([]);
  const [formValue, setFormValue] = useState({
    message: "",
  });
  const [selectedAdAccount, setSelectedAdAccount] = useState();
  const [selectedPage, setSelectedPage] = useState();
  const [listPages, setListPages] = useState([]);
  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [isFakeMore, setIsFakeMore] = useState(false);
  const divRef = useRef(null);
  const [postingUrls, setPostingUrls] = useState([]);
  const [isPosting, setIsPosting] = useState(false);
  const [randomMenu, setRandomMenu] = useState([]);

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  useEffect(() => {
    socket.on("do_task_response", (message) => {
      console.log(message);
      switch (message?.params.key_script) {
        case "get_ad_account":
          if (message?.data) {
            setAdAccount(message?.data);
            if (message?.data?.ad_account?.length > 0) {
              setAdAccounts(message?.data?.ad_account);
              let check = 0;
              message?.data?.ad_account?.map((item) => {
                if (item?.account_status !== 2) {
                  setSelectedAdAccount(item?.id);
                  check = 1;
                  return;
                }
              });
              if (check === 0) {
                setSelectedAdAccount("");
              }
            }
            if (message?.data?.page?.length > 0) {
              setListPages(message?.data?.page);
              setSelectedPage(message?.data?.page[0]?.id);
            } else {
              setListPages([]);
              setSelectedPage("");
            }
          }

          break;
        case "post_carousel":
          if (message?.data?.success) {
            setPostingUrls((old) => [
              ...old,
              `https://www.facebook.com/${message?.data?.post_id}`,
            ]);
            setTextMessage("Đăng bài viết thành công!!!");
          } else {
            setPostingUrls((old) => [...old, 0]);
            setTextMessage(message?.data?.error_msg);
          }
          setIsPosting(false);
        default:
          break;
      }
    });
  }, []);

  const handleUploadPost = async (img) => {
    setIsPosting(true);
    if (selectedAdAccount === "" || !selectedAdAccount) {
      setTextMessage("Kiểm tra tài khoản Ad của bạn.");
      return;
    }
    let finalTitle = formValue?.message;
    if (randomMenu?.includes("text")) {
      let stringLength = 7;
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
      for (let i = 0; i < stringLength; i++) {
        finalTitle += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
    }
    if (randomMenu?.includes("icon")) {
      finalTitle += listIcon[Math.floor(Math.random() * listIcon.length)];
    }
    if (randomMenu?.includes("time")) {
      const today = new Date();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      finalTitle += time;
    }
    const input = {
      key_script: "post_carousel",
      extension_key: selectedExtension?.key,
      key_tool: "generate_one_card_v2",
      params: {
        key_socket: Cookies.get("keySocket"),
        type: "one_card",
        pageId: selectedPage,
        title: finalTitle,
        videoId1: "",
        videoId2: "",
        thumbnail1: img,
        description1: "",
        thumbnail2: "",
        description2: "",
        button_type: "NO_BUTTON",
        ad_id: selectedAdAccount,
        caption: formValue?.displayUrl,
        link: formValue?.destinationURL,
        name1: "",
        name2: "",
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
      if (res.status === 200) {
      }
    } catch (error) {
      console.log(error);
      setIsPosting(false);
    }
  };

  const getAdAccount = async () => {
    // console.log(uid);
    setSelectedAdAccount(undefined);
    setSelectedPage(undefined);
    const input = {
      key_script: "get_ad_account",
      extension_key: selectedExtension?.key,
      key_tool: "generate_one_card_v2",
      params: {
        key_socket: Cookies.get("keySocket"),
      },
    };
    try {
      const res = await sendPost(`/users/scripts/send`, input);
    } catch (error) {
      console.log(error);
      setTextMessage(error?.message);
    }
  };

  useEffect(() => {
    if (adAccount) {
      if (adAccount.ad_account?.length > 0) {
        setAdAccounts(adAccount?.ad_account);
        let check = 0;
        adAccount?.ad_account?.map((item) => {
          if (item?.account_status !== 2) {
            setSelectedAdAccount(item?.id);
            check = 1;
            return;
          }
        });
        if (check === 0) {
          setSelectedAdAccount("");
        }
      }
      if (adAccount.page?.length > 0) {
        setListPages(adAccount?.page);
        setSelectedPage(adAccount?.page[0]?.id);
      } else {
        setListPages([]);
        setSelectedPage("");
      }
    } else if (selectedExtension?.key) {
      getAdAccount();
    }
  }, [selectedExtension]);

  const numbers = [1, 2, 3, 4, 5];

  const convertFile = async (dataUrl) => {
    const formData = new FormData();

    await fetch(dataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "file.png", {
          type: "image/png",
        });
        console.log(file);
        formData.append("files[0]", file);
      });

    try {
      const res = await sendPost(`/users/scripts/save-file`, formData);
      if (res.status === 200) {
        handleUploadPost(res.data[0]);
      }
    } catch (error) {
      setTextMessage("Có lỗi xảy ra khi tải file lên.");
    }
  };

  const gridImages = numbers.map((number) => {
    if (number === 2 || number === 3 || number === 5) {
      return {
        id: number,
        gridDemo: (
          <>
            <div className={`gridDemo1`}>
              {Array.from({ length: 4 }, (_, index) => (
                <div
                  key={index}
                  className={`gridDemo` + number + `__grid` + (index + 1)}
                  style={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <IconButton
                    icon={<AiOutlineClose />}
                    circle
                    className="deleteImageButton"
                    onClick={() => {
                      setCardImages((oldValues) => {
                        return oldValues.filter((_, i) => i !== index);
                      });
                    }}
                  />
                  {/* {cardImages[index] ? (
                    <img
                      src={URL.createObjectURL(cardImages[index])}
                      alt=""
                      style={{
                        position: "absolute",
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        // width: "100%",
                        // height: "100%",
                      }}
                    />
                  ) : (
                    <></>
                  )} */}
                </div>
              ))}
            </div>
          </>
        ),
      };
    } else {
      return {
        id: number,
        gridDemo: (
          <>
            <div className={`gridDemo1`}>
              {Array.from({ length: 5 }, (_, index) => (
                <div
                  key={index}
                  className={`gridDemo` + number + `__grid` + (index + 1)}
                  style={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <IconButton
                    icon={<AiOutlineClose />}
                    circle
                    className="deleteImageButton"
                    onClick={() => {
                      setCardImages((oldValues) => {
                        return oldValues.filter((_, i) => i !== index);
                      });
                    }}
                  />
                  {/* {cardImages[index] ? (
                    <img
                      src={URL.createObjectURL(cardImages[index])}
                      alt=""
                      style={{
                        position: "absolute",
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        // width: "100%",
                        // height: "100%",
                      }}
                    />
                  ) : (
                    <></>
                  )} */}
                </div>
              ))}
            </div>
          </>
        ),
      };
    }
  });

  // const gridImages1 = [
  //   {
  //     id: 1,
  //     gridDemo: (
  //       <>
  //         <div className="gridDemo1">
  //           {Array.from({ length: 5 }, (_, index) => (
  //             <div
  //               key={index}
  //               className={`gridDemo1__grid` + (index + 1)}
  //               style={{ position: "relative", height: "100%", width: "100%" }}
  //             >
  //               {cardImages[index] ? (
  //                 <img
  //                   src={URL.createObjectURL(cardImages[index])}
  //                   alt=""
  //                   style={{
  //                     position: "absolute",
  //                     objectFit: "cover",
  //                     width: "100%",
  //                     height: "100%",
  //                     // width: "100%",
  //                     // height: "100%",
  //                   }}
  //                 />
  //               ) : (
  //                 <></>
  //               )}
  //             </div>
  //           ))}
  //         </div>
  //       </>
  //     ),
  //   },
  //   {
  //     id: 2,
  //     gridDemo: (
  //       <>
  //         <div className="gridDemo1">
  //           {Array.from({ length: 4 }, (_, index) => (
  //             <div
  //               key={index}
  //               className={`gridDemo1__grid` + (index + 1)}
  //               style={{ position: "relative", height: "100%", width: "100%" }}
  //             >
  //               {cardImages[index] ? (
  //                 <img
  //                   src={URL.createObjectURL(cardImages[index])}
  //                   alt=""
  //                   style={{
  //                     position: "absolute",
  //                     objectFit: "cover",
  //                     width: "100%",
  //                     height: "100%",
  //                     // width: "100%",
  //                     // height: "100%",
  //                   }}
  //                 />
  //               ) : (
  //                 <></>
  //               )}
  //             </div>
  //           ))}
  //         </div>
  //       </>
  //     ),
  //   },
  //   {
  //     id: 3,
  //     gridDemo: (
  //       <>
  //         <div className="gridDemo1">
  //           {Array.from({ length: 4 }, (_, index) => (
  //             <div
  //               key={index}
  //               className={`gridDemo3__grid` + (index + 1)}
  //             ></div>
  //           ))}
  //         </div>
  //       </>
  //     ),
  //   },
  //   {
  //     id: 4,
  //     gridDemo: (
  //       <>
  //         <div className="gridDemo1">
  //           {Array.from({ length: 5 }, (_, index) => (
  //             <div
  //               key={index}
  //               className={`gridDemo4__grid` + (index + 1)}
  //             ></div>
  //           ))}
  //         </div>
  //       </>
  //     ),
  //   },
  //   {
  //     id: 5,
  //     gridDemo: (
  //       <>
  //         <div className="gridDemo1">
  //           <div className="gridDemo5__grid1"></div>
  //           <div className="gridDemo4__grid3"></div>
  //           <div className="gridDemo4__grid4"></div>
  //           <div className="gridDemo4__grid5"></div>
  //         </div>
  //       </>
  //     ),
  //   },
  // ];

  const handleCardImages = (imageLists) => {
    switch (cardType) {
      case 1:
      case 2:
      case 4:
        return Array.from({ length: 4 }, (item, idx) => (
          <img
            src={imageLists[idx] || gray_images}
            alt=""
            className={`type-${cardType}-image-${idx}`}
          />
        ));
      default:
        return Array.from({ length: 5 }, (item, idx) => (
          <img
            src={imageLists[idx] || gray_images}
            alt=""
            className={`type-${cardType}-image-${idx}`}
          />
        ));
    }
  };

  const handleFakeMore = () => {
    if (isFakeMore) {
      return (
        <div
          className={`fake-more-content ${cardType === 1 ? "card-type-1" : ""}`}
          style={fakeMoreStyle[cardType]}
        >
          +{fakeContentNumber}
        </div>
      );
    } else {
    }
  };

  const fakeMoreStyle = [
    {
      width: "calc(49.5% - 1.05rem)",
      aspectRatio: "1/1.32",
    },
    {
      width: "calc(33.33% - 0.75rem)",
      height: "calc(33% - 0.84rem)",
    },
    {
      width: "calc(49.5% - 1.05rem)",
      aspectRatio: "1/1",
    },
    {
      width: "calc(33% - .75rem)",
      aspectRatio: "1/1",
    },
    {
      width: "calc(33% - .75rem)",
      height: "calc(33% - .75rem)",
    },
  ];

  return (
    <>
      <div className="d-flex gap-3">
        <div className="col-5 ">
          <div className="card p-3">
            <div className="mb-3">
              <ToolHeading toolType={window.location.pathname} />
            </div>
            <Form fluid formValue={formValue} onChange={setFormValue}>
              <Form.Group controlId="ad_account">
                <Form.ControlLabel className="group_membernumber">
                  Tài khoản Ad:
                </Form.ControlLabel>
                <SelectAdAccounts
                  setSelectedAdsAccount={setSelectedAdAccount}
                  selectedAdsAccount={selectedAdAccount}
                  listAdsAccount={adAccounts}
                />
              </Form.Group>
              <Form.Group controlId="message">
                <Form.ControlLabel className="group_membernumber d-flex justify-content-between align-items-center">
                  <span>Nội dung:</span>
                  <CheckboxGroup
                    name="checkboxList"
                    value={randomMenu}
                    onChange={setRandomMenu}
                    inline
                  >
                    <Checkbox value="text">Nội dung ngẫu nhiên</Checkbox>
                    <Checkbox value="icon">Biểu tượng ngẫu nhiên</Checkbox>
                    <Checkbox value="time">Thời gian</Checkbox>
                  </CheckboxGroup>
                </Form.ControlLabel>
                <Form.Control
                  rows={5}
                  placeholder="nội dung..."
                  name="message"
                  accepter={Textarea}
                />
              </Form.Group>
              <Form.Group controlId="destinationURL">
                <Form.ControlLabel className="group_membernumber">
                  Đường dẫn :<Form.HelpText tooltip>Bắt buộc</Form.HelpText>
                </Form.ControlLabel>
                <Form.Control name="destinationURL" placeholder="URL" />
                {/* <Form.HelpText>This field is required</Form.HelpText> */}
              </Form.Group>
              <Form.Group controlId="displayUrl">
                <Form.ControlLabel className="group_membernumber">
                  Đường dẫn hiển thị :
                </Form.ControlLabel>
                <Form.Control
                  name="displayUrl"
                  placeholder="VD: amailink.com"
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.ControlLabel className="group_membernumber d-flex justify-content-between">
                  <span>Giả số lượng :</span>
                  <Toggle
                    checked={isFakeMore}
                    onChange={(e) => setIsFakeMore(e)}
                  />
                </Form.ControlLabel>
                <Form.Control
                  name="email"
                  onChange={setFakeContentNumber}
                  value={fakeContentNumber}
                  type="number"
                  min={0}
                />
              </Form.Group>
              <Form.Group>
                {isPosting ? (
                  <Button className="fw-bold" style={{ width: "100%" }}>
                    <Loader />
                  </Button>
                ) : (
                  <Button
                    appearance="primary"
                    className="fw-bold fs-6"
                    style={{ width: "100%" }}
                    onClick={async () => {
                      const dataUrl = await htmlToImage.toPng(divRef.current);
                      convertFile(dataUrl);
                    }}
                  >
                    ĐĂNG BÀI
                  </Button>
                )}
              </Form.Group>
            </Form>
          </div>
          {postingUrls?.length > 0 ? (
            <div className="card p-2 mt-3">
              <h5 className="fw-bold text-center">Báo cáo bài đăng</h5>
              <Table responsive bordered style={{ background: "white" }}>
                <thead>
                  <tr>
                    <th>Trạng thái</th>
                    <th>Đường dẫn</th>
                  </tr>
                </thead>
                <tbody>
                  {postingUrls?.map((item, idx) => {
                    if (item === 0) {
                      return (
                        <tr key={idx}>
                          <td></td>
                          <td className="text-danger fw-bold">Thất bại</td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={idx}>
                          <td style={{ maxWidth: "20vw" }}>
                            <a
                              href={item}
                              target="_blank"
                              className="group_membernumber blackLink"
                            >
                              {item}
                            </a>
                          </td>
                          <td className="text-success fw-bold">Thành công</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
              {/* {postingUrls?.map((item, key) => (
                <div className="mt-2 text-left" key={key}>
                  <a
                    href={`https://www.facebook.com/${item}`}
                    target="_blank"
                    className="blackLink group_membernumber"
                  >{`https://www.facebook.com/${item}`}</a>
                </div>
              ))} */}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="col-7 card p-3">
          <SelectedPage
            listPages={listPages}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />

          <div className="mt-2">
            <div className="">
              <p className="group_membernumber mb-1">Kiểu album ảnh:</p>
              <div className="d-flex justify-content-between">
                {gridImages?.map((item, key) => (
                  <div
                    key={item?.id}
                    className={`card p-3 cardImageType ${
                      cardType === key ? "selected" : ""
                    }`}
                    style={{
                      aspectRatio: "1/1",
                      width: "18%",
                    }}
                    onClick={() => setCardType(key)}
                  >
                    {item?.gridDemo}
                  </div>
                ))}
              </div>
            </div>
            <div className="group_membernumber mt-3 mb-2">
              <input
                style={{ display: "none" }}
                ref={inputImage}
                type="file"
                multiple
                onChange={(e) => {
                  if (e?.target?.files[0]) {
                    setCardImages(
                      [
                        ...Array.from(
                          { length: e.target.files.length },
                          (item, idx) =>
                            URL?.createObjectURL(e.target.files[idx])
                        ),
                        ...cardImages,
                      ].splice(0, 5)
                    );
                  }
                }}
              />
              <Button
                appearance="primary"
                style={{ backgroundColor: "#f2ae14" }}
                onClick={() => inputImage.current.click()}
              >
                <AiOutlineFileImage className="me-2" />
                Tải ảnh lên
              </Button>
              <div className="mt-2 d-flex gap-2">
                {cardImages?.map((item, index) => {
                  return (
                    <img
                      key={index}
                      src={item}
                      alt=""
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        borderRadius: "2px",
                        border: "1px solid gray",
                      }}
                      onClick={() => {
                        if (!isPosting) {
                          const updatedPhotos = cardImages.filter(
                            (photo, k) => photo !== item
                          );
                          setCardImages(updatedPhotos);
                        } else {
                          setTextMessage(
                            "Không thể chỉnh sửa ảnh khi đang đăng bài"
                          );
                        }
                      }}
                    />
                  );
                })}
              </div>
            </div>

            {cardType === 1 ? (
              <div className="album-1" ref={divRef} id="album">
                <div className="album-1-1">
                  <img
                    src={cardImages[0] || gray_images}
                    alt=""
                    className={`type-1-image-0`}
                  />
                </div>
                <div className="album-1-2">
                  <img
                    src={cardImages[1] || gray_images}
                    alt=""
                    className={`type-1-image-1`}
                  />
                  <img
                    src={cardImages[2] || gray_images}
                    alt=""
                    className={`type-1-image-2`}
                  />
                  <img
                    src={cardImages[3] || gray_images}
                    alt=""
                    className={`type-1-image-3`}
                  />
                </div>
                {handleFakeMore()}
              </div>
            ) : (
              <div className="album" ref={divRef} id="album">
                {handleCardImages(cardImages)}
                {handleFakeMore()}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
    // </DndProvider>
  );
};

export default PEOneCardv2;
