import React, { useEffect, useState } from "react";
import "./adminguide.css";
import {
  sendDelete,
  sendGet,
  sendPost,
  sendPut,
} from "../../../axios/axiosClient";
import {
  Button,
  Form,
  Input,
  Message,
  Modal,
  Pagination,
  Toggle,
  useToaster,
} from "rsuite";
import { Table } from "react-bootstrap";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { get } from "js-cookie";
const AdminGuide = () => {
  const [listTutorial, setListTutorial] = useState([]);
  const [formValue, setFormValue] = useState({
    link: "",
    title: "",
    status: 1,
  });
  const [formUpdate, setFormUpdate] = useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [deleteItem, setDeleteItem] = useState();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const type = "info";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    setActivePage(1);
  }, [limit]);

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const handleChangeLimit = (e) => {
    setLimit(e);
    // getAdminTutorial();
  };

  const getAdminTutorial = async () => {
    try {
      const res = await sendGet(
        `/admins/tutorials?page=${activePage}&page_size=${limit}${
          search !== "" ? `&search=${search}` : ""
        }`
      );
      if (res.status === 200) {
        console.log(res);
        setListTutorial(res?.data?.data?.data);
        setTotalItems(res?.data?.data?.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateTutorial = async () => {
    if (formValue?.link.trim() === "" || formValue?.title.trim() === "") {
      setTextMessage("Hãy điền đầy đủ thông tin trước khi tạo.");
      return;
    }
    try {
      const res = await sendPost(`/admins/tutorials`, formValue);
      if (res.status === 200) {
        setTextMessage("Tạo mới hướng dẫn thành công !!!");
        getAdminTutorial();
        setFormValue({ link: "", title: "", status: 1 });
        handleClose();
      }
    } catch (error) {
      setTextMessage("Có lỗi xảy ra khi tạo mới.");
      handleClose();
    }
  };

  const handleUpdate = async () => {
    console.log(formUpdate);
    if (formUpdate?.link?.trim() === "" || formUpdate?.title.trim() === "") {
      setTextMessage("Không được bỏ trống trường ký tự.");
      return;
    }
    const input = {
      title: formUpdate?.title,
      link: formUpdate?.link,
      status: formUpdate?.status === true || formUpdate?.status === 1 ? 1 : 0,
    };
    try {
      const res = await sendPut(`/admins/tutorials/${formUpdate?.id}`, input);
      if (res.status === 200) {
        setTextMessage(`Cập nhật hướng dẫn #${formUpdate?.title} thành công.`);
        getAdminTutorial();
        handleCloseUpdate();
      }
    } catch (error) {
      setTextMessage("Cập nhật thất bại.");
      handleCloseUpdate();
    }
  };

  const handleDelete = async () => {
    if (!deleteItem) {
      setTextMessage("Thử lại.");
      return;
    }
    try {
      const res = await sendDelete(`/admins/tutorials/${deleteItem?.id}`);
      if (res.status === 200) {
        setTextMessage(`Xoá bỏ hướng dẫn #${formUpdate?.title} thành công.`);
        getAdminTutorial();
        handleCloseDelete();
      }
    } catch (error) {
      setTextMessage("Xoá thất bại.");
      handleCloseDelete();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getAdminTutorial();
    }, 500);
    return () => clearTimeout(timer);
  }, [search, activePage, limit]);

  return (
    <div>
      {/* modal create */}
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Thêm hướng dẫn</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={formValue} onChange={setFormValue} fluid>
            <Form.Group controlId="link">
              <Form.ControlLabel>
                Link <Form.HelpText tooltip>Yêu cầu</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="link" />
            </Form.Group>
            <Form.Group controlId="title">
              <Form.ControlLabel>
                Tiêu đề <Form.HelpText tooltip>Yêu cầu</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="title" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCreateTutorial} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Huỷ bỏ
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal update */}
      <Modal open={openUpdate} onClose={handleCloseUpdate}>
        <Modal.Header>
          <Modal.Title>Cập nhật hướng dẫn {formUpdate?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form formValue={formUpdate} onChange={setFormUpdate} fluid>
            <Form.Group controlId="link">
              <Form.ControlLabel>
                Link <Form.HelpText tooltip>Yêu cầu</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="link" />
            </Form.Group>
            <Form.Group controlId="title">
              <Form.ControlLabel>
                Tiêu đề <Form.HelpText tooltip>Yêu cầu</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control name="title" />
            </Form.Group>
            <Form.Group controlId="status">
              <Form.ControlLabel>Trạng thái</Form.ControlLabel>
              <Form.Control name="status" accepter={Toggle} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseUpdate} appearance="subtle">
            Huỷ bỏ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete */}
      <Modal open={openDelete} onClose={handleCloseDelete}>
        <Modal.Header>
          <Modal.Title>Xoá đường dẫn {deleteItem?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bạn có chắc chắn muốn xoá {deleteItem?.title}</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseDelete} appearance="subtle">
            Huỷ bỏ
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  */}
      <div className="d-flex justify-content-between mb-2">
        <div>
          <Input
            placeholder="Tìm kiếm..."
            value={search}
            onChange={setSearch}
          />
        </div>
        <div>
          <Button onClick={handleOpen}>Thêm mới</Button>
        </div>
      </div>
      <Table striped bordered responsive>
        <thead>
          <tr className="text-center">
            <th>#</th>
            <th style={{ width: "25%" }}>Tiêu đề</th>
            <th style={{ width: "45%" }}>Đường dẫn</th>
            <th>Trạng thái</th>
            <th>Hành động</th>
          </tr>
        </thead>
        <tbody>
          {listTutorial?.map((item, key) => {
            return (
              <tr key={item?.id} className="text-center">
                <td>{key + 1}</td>
                <td style={{ width: "25%" }}>{item?.title}</td>
                <td style={{ width: "45%" }}>{item?.link}</td>
                <td>
                  {item?.status === 1 ? (
                    <AiFillCheckCircle className="text-success" />
                  ) : (
                    <AiFillCloseCircle className="text-danger" />
                  )}
                </td>
                <td>
                  <div className="d-flex gap-2 justify-content-center">
                    <Button
                      appearance="primary"
                      onClick={() => {
                        setFormUpdate({
                          link: item?.link,
                          title: item?.title,
                          status: item?.status,
                          id: item?.id,
                        });
                        handleOpenUpdate();
                      }}
                    >
                      Cập nhật
                    </Button>
                    <Button
                      appearance="primary"
                      style={{ backgroundColor: "red" }}
                      onClick={() => {
                        setDeleteItem(item);
                        handleOpenDelete();
                      }}
                    >
                      Xoá
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="_pagination">
        {listTutorial?.length !== 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"xs"}
            prev
            next
            // first
            // last
            ellipsis
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            // limitOptions={[1, 2, 3]}
            // limitOptions={[10, 30, 50, 100, 500]}
            maxButtons={5}
            activePage={activePage}
            onChangePage={setActivePage}
            onChangeLimit={setLimit}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AdminGuide;
