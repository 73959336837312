import React from "react";
import PagePostsAnalyzer from "../page-posts-analyze/PagePostsAnalyzer";
const PagePostsClone = () => {
  return (
    <>
      <PagePostsAnalyzer />
    </>
  );
};

export default PagePostsClone;
