import React, { useEffect } from "react";
import "./card.css";
import card_google from "../../assets/images/card_google.webp";
import { Avatar, Button } from "rsuite";
import { useNavigate } from "react-router-dom";
import { toolCategories } from "../../commons/data/toolCategory";
import { BiCarousel } from "react-icons/bi";
import Cookies from "js-cookie";
import { useMedia } from "react-use";
const Card = ({ title, image, description, path, keyTool }) => {
  const navigate = useNavigate();
  const isAdmin = Cookies?.get("admin");
  // const isWide = window.matchMedia("(max-width: 1024px)").matches;
  const isWide = useMedia("(max-width: 1300px)");

  const findToolType = (e) => {
    // e: keyTool
    const a = toolCategories?.find((item) =>
      item.tools.find((child) => child?.key === e)
    );
    return a?.image;
  };

  return (
    <div className="card p-3 card__body">
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-2">
          {/* {image?.map((item, key) => (
            <img src={item} alt="" className="cardCategory" key={key} />
          ))} */}
          {isWide ? <></> : image}
          {/* {image} */}
          {/* <img src={image} alt="" /> */}
        </div>
        {/* <div>{findToolType(keyTool)}</div> */}
        {/* <div>{handleBadge(findToolType(key))}</div> */}
        <div>
          <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank">
            <Button
              appearance="primary"
              className="px-2 py-1"
              style={{ backgroundColor: "#F2AE14", fontSize: ".65rem" }}
            >
              Hướng dẫn
            </Button>
          </a>
        </div>
      </div>
      <hr className="my-2" />
      <div className={`fw-bold ${description ? "" : "text-danger"}`}>
        <span className={description || isAdmin ? "" : `me-1`}>
          {description || isAdmin ? "" : "VIP -"}
        </span>
        <p className="title-multi-line">{title}</p>
      </div>
      {description ? (
        <div className="cardDescriptionBody">{description}</div>
      ) : (
        <div className={`cardDescriptionBody ${isAdmin ? "" : "text-danger"}`}>
          {isAdmin
            ? `Admin Tools`
            : `Bạn cần kích hoạt VIP để sử dụng chức năng này`}
        </div>
      )}
      {Cookies?.get("token") ? (
        <Button
          className="mt-2"
          appearance="primary"
          onClick={() => {
            navigate(path);
          }}
        >
          Sử dụng
        </Button>
      ) : (
        <Button
          className="mt-2"
          appearance="primary"
          // style={{ backgroundColor: "gray" }}
          onClick={() => {
            navigate("/login");
          }}
        >
          Đăng nhập để sử dụng
        </Button>
      )}
    </div>
  );
};

export default Card;
